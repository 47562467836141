import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';

import { useLanguage } from 'Context/LanguageContext';
import { Col } from 'react-bootstrap';
import TextField from '~/TextField/TextField.component';
import {FielderPhoneField} from '~/FielderElements/FielderElements.component';
import TextareaAutosize from 'react-autosize-textarea';
import { Row } from 'antd';
import { FIELDER } from '@core/constants/base.constants';

const MoreBranchInformation = ({ showMoreBranchInfo, nameValue, lastNameValue,
      emailValue, cellphoneValue, officePhoneValue, commentValue, editData}, ref ) => {
	
    const lan = useLanguage();
    const { txt: TXT_ID, btn: BTN_ID, dtpckr: DTPCK_ID } = FIELDER.idElement;

    console.log(nameValue)

	const [name, setName] = useState(nameValue ? nameValue : '');
    const [lastName, setLastName] = useState(lastNameValue ? lastNameValue : '');
    const [email, setEmail] = useState(emailValue ? emailValue : '');
    const [cellphone, setCellPhone] = useState(cellphoneValue ? cellphoneValue : '');
    const [officePhone, setOfficePhone] = useState(officePhoneValue ? officePhoneValue : '');
    const [comment, setComment] = useState(commentValue ? commentValue : '');

    useEffect(() => {
        if (nameValue) setName(nameValue);
        if (lastNameValue) setLastName(lastNameValue);
        if (emailValue) setEmail(emailValue);
        if (cellphoneValue) setCellPhone(cellphoneValue);
        if (officePhoneValue) setOfficePhone(officePhoneValue);
        if (commentValue) setComment(commentValue);
    }, [nameValue, lastNameValue, emailValue, cellphoneValue, officePhoneValue, commentValue]);



    useImperativeHandle(ref, () => ({
        name,
        lastName,
        email,
        cellphone,
        officePhone,
        comment
      }), [name, lastName, email, cellphone, officePhone, comment]);



	const handleChange = ( event, textField) => {
        
        switch(textField){
            case "name":
                setName(event.target.value);
                break;
            case "lastName":
                setLastName(event.target.value);
                break;
            case "cellPhone":
                setCellPhone(event.target.value);
                break;
            case "officePhone":
                setOfficePhone(event.target.value);
                break;
            case "email":
                setEmail(event.target.value);
                break;
            case "comment":
                setComment(event.target.value);
                break;
            default:
                break;
        }
	};

	return (		
        <>  
        {showMoreBranchInfo && ( 
            <>
                <Col>
                    <Row >
                        <Col className='mt-1'>
                            <TextField
                                id='txt-task-name'
                                value={name}
                                variant='outlined'
                                label={`${lan.name} (${lan.optional})`}
                                onChange={(e) => handleChange(e, "name")}
                                disabled={!editData}
                                fullWidth />
                        </Col>
                        <Col className='mt-1'>
                            <TextField
                                id='txt-task-lastName'
                                value={lastName}
                                variant='outlined'
                                label={`${lan.lastName} (${lan.optional})`}
                                onChange={(e) => handleChange(e, "lastName")}
                                disabled={!editData}
                                fullWidth />
                        </Col>
                    </Row>
                </Col>
            
                <Col className='mt-3'>
                    <Row >
                        <Col className='mt-1'>
                            <FielderPhoneField
                                id={TXT_ID.phone}
                                label={lan.cellphoneClient}
                                value={cellphone}
                                onChange={value => {
                                    let cellphone = value === undefined? '' : value;
                                    setCellPhone(cellphone, "cellPhone");
                                }}
                                lan={lan}
                                disabled={!editData}
                                //externalError={this.state.errorOfficePhone}
                            />
                        </Col>
                        <Col className='mt-1'>
                            <FielderPhoneField
                                id={TXT_ID.mobile}
                                label={lan.officePhone}
                                value={officePhone}
                                onChange={value => {
                                    let officePhone = value === undefined? '' : value;
                                    setOfficePhone(officePhone, "officePhone");
                                }}
                                lan={lan}
                                disabled={!editData}
                                //externalError={this.state.errorOfficePhone}
                            />
                        </Col>
                    
                    </Row>
                </Col>
                <Col sm={6} className='mt-3 mr-5 pr-4 ml-3'>
                    <Row>
                        <TextField
                            id='txt-email'
                            value={email}
                            variant='outlined'
                            label={`${lan.email} (${lan.optional})`}
                            onChange={(e) => handleChange(e, "email")}
                            disabled={!editData}
                            fullWidth 
                        />
                    </Row>
                </Col>  
                <Col sm={12} className='branch-label-input mt-2'>
					{lan.comment}
				</Col> 
                <Col sm={12} className='mt-1'>
					<TextareaAutosize
						id='legalAddress-input'
						type='text'
						className={'task-details-input'}
						value={comment}
						onChange={(e) => handleChange(e, 'comment')}
                        rows={3}
						style={{
							width: '100%',
							paddingTop: '5px',
							paddingLeft: '10px',
							paddingBottom: '5px',
							paddingRight: '10px',
						}}
						disabled={!editData}
					/>
				</Col>
 
            </>
            )}
        </>

	);
};

export default forwardRef(MoreBranchInformation);
