/* eslint-disable max-lines */
const language = () => {
	const global = {
		en_US: {
			location: 'Location',
			selectView: 'Select view',
			filter: 'Filter',
			filterName: 'Filter name',
			viewMoreFilters: 'View more filters',
			noTasks: 'No Tasks',
			away: 'Away',
			agentsInfo: 'Agents Info',
			noTeams: 'No Teams',
			noTeam: 'No Team',
			noCompany: 'No Company',
			filterAgents: 'Filter Agents',
			agentTaskNumberFilter: 'Agent Task Quantity',
			agentStatusFilter: 'Agent Status',
			agentsStatus: 'Agent Status',
			agentTeams: 'Agent Teams',
			allStatus: 'All Status',
			agentDetails: 'Agent Details',
			task: 'Task',
			tasks: 'Tasks',
			couldNotFilterAgentsCommandCenter: "Couldn't filter agents, please try again later",
			couldNotFilterTasksCommandCenter: "Couldn't filter tasks, please try again later",
			noAgentsFound: 'No Agents Found',
			noTasksFound: 'No Tasks Found',
			tryWithOtherFilter: 'Please try again with another filter',
			tasksInfo: 'Tasks Information',
			viewAgent: 'View Agent',
			today: 'Today',
			notShowingDisabledAgents: 'Not showing disabled agents',
			lastLocationUpdate: 'Last Location Update',
			lastIdsCustomUsed:'Last Ids Custom used',
			workload: 'Workload',
			taskInProgress: 'Task In Progress',
			viewAll: 'View All',
			showAgentTasks: 'Show Agent Tasks',
			clickRecenterMap: 'Click to recenter the map',
			assignTasksWithinTimeFrame: 'Assign tasks to the agent within the time frame',
			viewAgentsByStatus: 'View agents by Status',
			viewAgentsByTaskNumber: 'View agents by Task count',
			taskFilterSearchLabel: 'Search by Task Id or Folio',

			totalMissingTasks: 'Total Missing Tasks',
			noLocation: 'No Location',
			noTaskInProgress: 'No task in progress',
			assignTasks: 'Assign Tasks',
			addTasks: 'Add Tasks',
			tasksToAssignToAgent: 'Tasks to assign to agent',
			noClient: 'No Client',
			moving: 'Moving',
			showUnattendedTasks: 'Show animation for tasks with upcoming SLA expiration',
			showUpdatesInRealTime: 'Show updates in real time',
			loaded: 'Loaded',
			newUpdates: 'New updates',
			noOptions: 'No options',
			agentsSelectedHaveNoLocation: "The selected agents have no location recorded",
			by: 'by',
			ids:'Ids',

			hello: "Hello",
			moreInformation:'More information',
			enterCaseNumber: 'Enter case number',
			howCanIHelpYou: "Hello! I'm Maya, your Fielder generative A.I. assistant",
			writeYourMessageHere: "Ask me about Fielder",
			sorryIcantHelp:"Sorry I can't answer that question. You can contact our support team via email for further assistance.",
			forMoreInformation:"For more information I recommend you read the documentation",
			documentationLink: "Documentation link:",
			createTaskDocumentation:"Create Task",
			taskCreationDetailDocumantation:"Detalhe da Criação da Tarefa",
			// global account
			usersIds: 'Users ids',
			example: 'Example',
			send: 'Send',
			topic: 'Topic',
			mailIntegrationNoActivated: 'The domain is being configured',
			mailIntegrationConfig: 'Mail not activated',
			relations: 'Relations',
			clientGlobal: 'Fielder Client Global Account',
			linkedAccount: 'Linked account',
			noLinkedAccount: 'No linked accounts',
			linkedUsers: 'Linked users',
			noLinkedUsers: 'No linked users',
			globalAccountInfo: 'Account Information',
			globalUserInfo: 'User Information',
			adminGlobal: 'Fielder Root Global Account',
			addUserToGlobalAccount: 'Add user to global account',
			editGlobalAccount: 'Show global account',
			idGlobalAccount: 'Id Global Account',
			linkGlobalAccount: 'Link global account to account',
			domain: 'Domínio',
			assignDomain: 'Assign domain',
			assingAccount: 'Assign account',
			globalUseridUser: 'Id user',
			globalUserNormalName: 'Name',
			globalAccount: 'Global account',
			domainCreatedSuccessfully: 'Domain linked successfully',
			accountCreatedSuccessfully: 'Account created successfully',
			accountLinkedSuccessfully: 'Account linked successfully',
			userAddedSuccessfully: 'User linked successfully',
			globalDashboardError: 'Something unexpected happened try again ',
			domainCreatedError: 'There was a problem creating the domain, please try again later.',
			accountErrorReltation: 'The account already has this relationship',
			accountCreatedError: 'There was a problem creating the account, please try again later.',
			assingAccountCreatedError: 'There was a problem assigning the account to the global account. Please try again.',
			addUsertoGlobalAccountCreatedError:
				'There was a problem assigning the user to the global account. Please try again.',
			accountName: 'Account name',
			contactName: 'Contact name',
			contactEmail: 'Email de contacto',
			ERROR_404: 'ERROR 404',
			pageNoAvailable: 'Page no available',
			textNotAvailable:
				'This page is not available for your type of user or is not visible for your role. Please contact your administrator for more information.',
			textNotFound404: 'No page found similar to the one you are looking for.',
			viewOperation: 'View Operation',
			invalidCredentialsForViewOperation:
				'Sorry, you can´t see the operation of this account, please contact FielderTeam to enable this functionality. ',
			pending: 'Pending',
			formFilterInfo: 'Search by form name or any other text',
			userFilterTo: 'Use the advanced filter to make more specific searches for one or more tasks.',
			noResultsFound: 'No results found',

			dynamicAllocation: 'Dynamic Allocation',
			dynamicAllocationConfirmData: 'Do you want to execute the Dynamic Allocation?',
			dynamicAllocationSuccessMessage:
				'Your Dynamic Allocation process is being executed, you will receive an email with the results',
			dynamicAllocationErrorMessage: 'Your Dynamic Allocation process was not executed, please try again.',
			dynamicAllocationDescription:
				'Create routes and edit the order of the tasks, the agent you assigned and the scheduled date in a dynamic way. This functionality is perfect if your operation is flexible and changes rapidly every moment.',
			showWarninigs: 'Review conflict',
			warningscomment:
				'The Schedule established to execute the Dynamic Allocation does not cover some execution periods (schedule) of some tasks you want to assign, Do you want to execute the Dynamic allocation? or Do you want to see the tasks in conflict?',
			warningAddress: 'Changing the address will generate an Branch/Client location update request',
			warningTaskType: 'Some types of tasks have restrictions with some company (s) and / or branch (s)',
			generateRoutesByRange: 'Generate routes only between the ranges dates selected ?',
			generateRoutesWithTheFarthest: 'Generate routes with the furthest task',
			generateRoutesWithTheFarthestInfo: 'Tasks must have the same priority and have the same range of dates',
			generateRoutesWithTheFarthestROInfo: 'The route will only take the distance as a variable',
			generateRouteWithLastLocation: 'Take last location for next Route',
			generateRouteWithLastLocationInfo:
				'When generating more than one route with the same Agent, the starting point will be the location of the last task',
			enableExpensesRoutes: 'Enable expenses?',
			generate: 'Execute',
			noDate: 'Without date',
			noData: 'No data',
			analitycsMessageDatePart1: 'You do not have enough information between ',
			analitycsMessageDatePart2: ', please try again',
			changeParameterAnalitycs: 'Need to change the parameters to generate the chart',
			noImages: 'There are no images in this task',
			noServiceFile: 'Task without files',
			loading: 'Loading...',
			noDataTeamActive: 'You do not have activated teams in this section. Create a new team.',
			noDataTeamDisabled: 'You do not have teams deactivated in this section.',
			noDataUserActive: 'You do not have users activated in this section. Create a new user.',
			noMyPartners:
				'You do not have business partners yet, add new partners and grow your business network with fielder.',
			noIamNotPartner: 'You have not yet been integrated by a partner',
			noInvitations: 'You do not have any invitations, add partners and grow your business network with fielder',

			noDataUserDisable: 'You do not have deactivated users in this section.',
			noDataCompanyActive: 'You do not have companies activated in this section. Create a new company.',
			noDataCompanyDisable: 'You do not have deactivated companies in this section.',
			noDataBranchActive: 'You do not have branches activated in this section. Create a new branch.',
			noDataBranchToUpdate: 'There is no branch to update',
			noDataBranchDisable: 'You do not have deactivated branches in this section.',
			viewRoutes: 'View Routes',
			noDataFilter: 'There are no data that meet this search criteria.',
			appearance: 'Appearance: ',
			considerDateRange: 'Consider date range',
			iconography: 'Iconography',
			withoutIdCustomGroup: 'Without Id Custom',
			numberOfTask: 'Task Number',
			numberOfAgents: 'Number of agents',
			editPhoto: 'Edit photo',
			edit: 'Edit',
			show: 'Show',
			hide: 'Hide',
			more: 'more',
			showMap: 'Show map',
			showMore: 'Show more',
			showTable: 'Show table',
			menu: 'Menu',
			element: 'Element',
			language: 'Language',
			portugues: 'Portuguese',
			english: 'English',
			spanish: 'Spanish',
			login: 'Log in',
			user: 'User',
			tokenName: "Token Name",
			tokenTip: "Tip: Choose a descriptive name so you know that Token will create requests to the Fielder API for your integration with another system.",
			roleType: "Role type",
			apiAdministrator: "Api Administrator",
			apiManager: "Api Manager",
			activityLog: "Activity log",

			pass: 'Password',
			forgotPass: 'Forgot my password',
			login_button: 'Log in',
			sendEmailAction: 'Send email',
			sendEmailError: 'Invalid Mail',
			profile: 'Profile',
			selectAll: 'Select all',
			showDetail: 'Show detail',
			uncheckEverything: 'Uncheck all',
			newLocationAvailable: 'New location available',
			newLocation: 'New location',
			outOfBranchOrClientSchedule: 'Out of Branch or Client Schedule',
			missingFormsToSign:"Missing forms to sign",
			advancedAnalytics: 'Advanced Analytics',
			mailIntegration: 'Mail Integration',
			advancedAnalyticsDescription:
				'Access advanced Charts and Analytics about your operation related to KPIs of Clients, Branches, Tasks, Agents and Business Partners.',
			mailIntegrationDescription: 'Create tasks through the mail provided by the platform',

			// Role Management
			roleManagementDescription: 'Application to generate permissions and roles focused on your accounts.',
			roleManagement: 'Role Management',
			permissions: 'Permissions',
			roles: 'Roles',
			role: 'Role',
			default: 'Default',
			custom: 'Custom',
			specific: 'Specific',
			webAndManagement: 'Web and Management',
			noPermissionsFound: 'No permissions were found',
			noRolesFound: 'No roles were found',
			noNotificationsFound: 'No notifications were found',
			newPermission: 'New Permission',
			newRole: 'New Role',
			newNotification: 'New Notification',
			editRole: 'Edit Role',
			editPermission: 'Edit Permission',
			editNotification: 'Edit Notification',
			addPermissions: 'Add Permissions',
			addNotifications: 'Add Notifications',
			deleteConfirmationNotification: 'Are you sure you want to delete the notification(s)?',
			errorTryingToDeleteNotifications: 'Error trying to delete the notifications',
			someNotificationHasRole: 'At least one notification is used in one or more roles',
			addRole: 'Add Role',
			permissionsAdded: 'Permissions Added',
			notificationsAdded: 'Notifications Added',
			roleSearch: 'Search...',
			permissionActive: 'Active',
			basic: 'Basic',
			roleModules: 'Modules',
			general: 'General',
			deselect: 'Deselect',
			accountConfiguration: 'Account configuration',
			roleDashboard: 'Dashboard',
			viewDeactivates: 'Visualize deactivated',
			deactivate: 'Deactivate',
			activate: 'Activate',
			roleImport: 'Import',
			roleExport: 'Export',
			deletePartnership: 'Delete partnership',
			roleAdd: 'Add',
			assignTeam: 'Team assignment',
			assignPartner: 'Partner assignment',
			assignAsset: 'Asset assignment',
			assignInventory: 'Inventory assignment',
			assignForm: 'Form assignment',
			restartRoutes: 'Restart routes',
			rescheduleRoutes: 'Reschedule routes',
			addTaskByFolio: 'Add task by folio',
			deleteTaskFromRoute: 'Delete task from route',
			exportTasks: 'Export tasks',
			generateAssignments: 'Generate assignments',
			generateOptimizations: 'Generate optimizations',
      editAutoAssignTemplate: 'Edit Auto-Assign template',
			viewTemplates: 'Visualize templates',
			createTemplates: 'Create templates',
			editTemplates: 'Edit templates',
			deleteTemplates: 'Delete templates',
			autoAssignTemplateDeleted: 'This self-allocation template was deleted',
			autoAssignTemplateIsInactive: 'The self-allocation template is inactive',
      msgAutoAssignNotAllowHasGroupRoute: 'Task(s) with group(s) or route(s) cannot be self-allocation',
      msgAutoAssignNotAllowTaskStatus: 'Task(s) in current status cannot be self-allocation',
      msgAutoAssignNotAllowClientUsers: 'Clients are not permitted to perform the self-allocation',
      msgAutoAssignNotAllowedPermissions: 'You do not have the necessary permissions to perform the self-allocation',
      msgAutoAssignInitiateProcess: 'Self-allocation Process Initiated...',
      msgNoTasksWereAutoAssigned: 'No tasks could be auto-assigned, please check the status of the tasks before the self-allocation',
			web: 'Web',
			pushNotification: 'Push Notification',
			startPath: 'Start path',
			toArrive: 'Arrive',
			toStart: 'Start',
			toPause: 'Pause',
			toFinish: 'Finish',
			toReject: 'Reject',
			createComment: 'Create comment',
			reasonForChange: 'Reason for change',
			selectAStatus: 'Select a status',
			createEmergencies: 'Create emergencies',
			iAmHere: "I'm here",
			messageMultiSelect: 'In order to select a subtask please deselect its parent task',
			messageSelectParner: 'Please select a partner',
			messageSelectCompany: 'Please select a company',
			messageSelectTypeTask: 'Please select a task type',
			startTask: 'Start Task',
			restartTasks: 'Restart Tasks',
			addTasksToGroup: 'Add tasks to group',
			enableNotifications: 'Enable notifications in',
			sendNotifications: 'Send notifications to the user when',
			selectMoreOptions: 'You can select one or more options',
			permissionCreatedSuccessfully: 'Permission created successfully',
			permissionUpdatedSuccessfully: 'Permission updated successfully',
			notificationCreatedSuccessfully: 'Notification created successfully',
			notificationUpdatedSuccessfully: 'Notification updated successfully',
			roleCreatedSuccessfully: 'Role created successfully',
			roleUpdatedSuccessfully: 'Role updated successfully',
			roleSearchBy: 'Search by Company, Client and Branch',
			rolePartner: 'Partner Companies, Branches and Customers',
			selectedEntities: 'Selected companies, branches and clients',
			selectedPermissions: 'Selected permissions',
			selectedNotifications: 'Selected notifications',
			dataSheet: 'Technical Sheet',
			userList: 'User list',
			userDescription: 'Select any user to review his technical sheet',
			usersWithoutRole: 'Users without role',
			usersWithRole: 'Users with a role',
			assignRole: 'Assign Role',
			userCreatedSuccessfully: 'The selected roles were assigned to the user successfully',
			userUpdatedSuccessfully: 'The selected user roles were updated successfully',
			editUser: 'Edit User',
			congratAgent: 'Congratulate agent',
			defaultDuplicationNameLabel: 'Leave empty for default name: ',
			copy: 'copy',
			deleteRoleLabel: 'Are you sure you want to delete the Role?',
			deleteRoleWarning: "Once deleted you won't be able to get it back",
			deletePermissionLabel: 'Are you sure you want to delete the Permission?',
			deletePermissionWarning: "Once deleted you won't be able to get it back",
			error: 'Error',
			roleDeletionErrorUserAssigned: "Couldn't delete the Role, verify that the role is not assigned to any user",
			permissionDeletionErrorRoleAssigned:
				"Couldn't delete the Permission, verify that the Permission is not assigned to any role",

			permission_USERS_tasks_0: 'Visualize',
			permission_USERS_tasks_1: 'Edit',
			permission_USERS_tasks_2: 'Reschedule',
			permission_USERS_tasks_3: 'Cancel',
			permission_USERS_tasks_4: 'Team assignment',
			permission_USERS_tasks_5: 'Agent assignment',
			permission_USERS_tasks_6: 'Partner assignment',
			permission_USERS_tasks_7: 'Asset assignment',
			permission_USERS_tasks_8: 'Inventory assignment',
			permission_USERS_tasks_9: 'Form assignment',
			permission_USERS_tasks_10: 'Import',
			permission_USERS_tasks_11: 'Export',
			permission_USERS_tasks_12: 'Create',

			permission_CLIENT_USERS_tasks_0: 'Visualize',
			permission_CLIENT_USERS_tasks_1: 'Edit',
			permission_CLIENT_USERS_tasks_2: 'Reschedule',
			permission_CLIENT_USERS_tasks_3: 'Cancel',
			permission_CLIENT_USERS_tasks_12: 'Create',

			doNotApply: 'Do not apply',
			
			assetTaskStatusPermission:'From what moment should the agent fill out the asset',



			formTaskStatusPermission: 'From what moment should the agent fill out the form',
			formTaskStatusPermission0: 'Anytime',
			formTaskStatusPermission1: 'When the task is accepted',
			formTaskStatusPermission2: "When it's on its way",
			formTaskStatusPermission3: 'When the agent arrived',
			formTaskStatusPermission4: 'When the task starts',

			permission_AGENT_tasks_0: 'Reject',
			permission_AGENT_tasks_1: 'Cancel',
			permission_AGENT_tasks_2: 'Upload Photos',
			permission_AGENT_tasks_3: 'Pause',
			permission_AGENT_tasks_4: 'Write comments',
			permission_AGENT_tasks_5: 'Create task',
			permission_AGENT_tasks_6: 'Change task type',
			permission_AGENT_tasks_7: 'Create subtask',

			permission_USERS_accountconfiguration_0: 'Visualize',
			permission_USERS_accountconfiguration_1: 'Edit',

			permission_USERS_administrators_0: 'Create',
			permission_USERS_administrators_1: 'Visualize',
			permission_USERS_administrators_2: 'Edit',
			permission_USERS_administrators_3: 'Visualize deactivated',
			permission_USERS_administrators_4: 'Deactivate',
			permission_USERS_administrators_5: 'Delete',
			permission_USERS_administrators_6: 'Import',
			permission_USERS_administrators_7: 'Export',

			permission_USERS_agents_0: 'Create',
			permission_USERS_agents_1: 'Visualize',
			permission_USERS_agents_2: 'Edit',
			permission_USERS_agents_3: 'Visualize deactivated',
			permission_USERS_agents_4: 'Deactivate',
			permission_USERS_agents_5: 'Delete',
			permission_USERS_agents_6: 'Import',
			permission_USERS_agents_7: 'Export',
			permission_USERS_agents_8: 'Congratulate agents',

			permission_USERS_clients_0: 'Create',
			permission_USERS_clients_1: 'Visualize',
			permission_USERS_clients_2: 'Edit',
			permission_USERS_clients_3: 'Visualize deactivated',
			permission_USERS_clients_4: 'Deactivate',
			permission_USERS_clients_5: 'Delete',
			permission_USERS_clients_6: 'Import',
			permission_USERS_clients_7: 'Export',

			permission_AGENT_clients_0: 'Visualize',
			permission_AGENT_clients_1: 'Create',

			permission_USERS_companies_0: 'Create',
			permission_USERS_companies_1: 'Visualize',
			permission_USERS_companies_2: 'Edit',
			permission_USERS_companies_3: 'Visualize deactivated',
			permission_USERS_companies_4: 'Deactivate',
			permission_USERS_companies_5: 'Delete',
			permission_USERS_companies_6: 'Import',
			permission_USERS_companies_7: 'Export',

			permission_USERS_managers_0: 'Create',
			permission_USERS_managers_1: 'Visualize',
			permission_USERS_managers_2: 'Edit',
			permission_USERS_managers_3: 'Visualize deactivated',
			permission_USERS_managers_4: 'Deactivate',
			permission_USERS_managers_5: 'Delete',
			permission_USERS_managers_6: 'Import',
			permission_USERS_managers_7: 'Export',

			permission_USERS_partners_0: 'Add',
			permission_USERS_partners_1: 'Visualize',
			permission_USERS_partners_2: 'Delete partnership',
			permission_USERS_partners_3: 'Accept invitations',

			permission_USERS_teams_0: 'Visualize',
			permission_USERS_teams_1: 'Edit',
			permission_USERS_teams_2: 'Deactivate',
			permission_USERS_teams_3: 'Delete',
			permission_USERS_teams_4: 'Visualize deactivated',
			permission_USERS_teams_5: 'Create',

			permission_USERS_branches_0: 'Create',
			permission_USERS_branches_1: 'Visualize',
			permission_USERS_branches_2: 'Edit',
			permission_USERS_branches_3: 'Visualize deactivated',
			permission_USERS_branches_4: 'Deactivate',
			permission_USERS_branches_5: 'Delete',
			permission_USERS_branches_6: 'Import',
			permission_USERS_branches_7: 'Export',

			permission_AGENT_branches_0: 'Visualize',
			permission_AGENT_branches_1: 'Create',

			permission_USERS_analytics_0: 'Visualize',

			permission_USERS_assets_0: 'Visualize',
			permission_USERS_assets_1: 'Edit category',
			permission_USERS_assets_2: 'Edit asset',
			permission_USERS_assets_3: 'Import and Export assets',

			permission_AGENT_assets_0: 'Visualize',
			permission_AGENT_assets_1: 'Edit',

			permission_USERS_autoassign_0: 'Visualize templates',
			permission_USERS_autoassign_1: 'Edit templates',
			permission_USERS_autoassign_2: 'Create templates',
			permission_USERS_autoassign_3: 'Delete templates',

			permission_USERS_billing_0: 'Visualize',

			permission_USERS_calendar_0: 'Visualize',

			permission_CLIENT_USERS_calendar_0: 'Visualize',

			permission_USERS_dashboard_0: 'Visualize',

			permission_CLIENT_USERS_dashboard_0: 'Visualize',

			permission_USERS_dynamicallocation_0: 'Generate assignments',

			permission_USERS_forms_0: 'Visualize templates',
			permission_USERS_forms_1: 'Edit templates',
			permission_USERS_forms_2: 'Create templates',
			permission_USERS_forms_3: 'Delete templates',
			permission_USERS_forms_4: 'Edit task form',
			permission_USERS_forms_5: 'Delete task form',
			permission_USERS_forms_6: 'Download task forms',
			permission_USERS_forms_7: 'Import template',

			permission_CLIENT_USERS_forms_0: 'Visualize',
			permission_CLIENT_USERS_forms_4: 'Edit task form',

			permission_AGENT_forms_0: 'Visualize',
			permission_AGENT_forms_1: 'Edit',

			permission_USERS_grouptasks_0: 'Create',
			permission_USERS_grouptasks_1: 'Visualize',
			permission_USERS_grouptasks_2: 'Add tasks to group',
			permission_USERS_grouptasks_3: 'Unlink tasks',
			permission_USERS_grouptasks_4: 'Edit',
			permission_USERS_grouptasks_5: 'Download task images from Group',
			permission_USERS_grouptasks_6: 'Export tasks',
			permission_USERS_grouptasks_7: 'Delete',

			permission_AGENT_grouptasks_0: 'Visualize',
			permission_AGENT_grouptasks_1: 'Cancel',
			permission_AGENT_grouptasks_2: 'Pause',
			permission_AGENT_grouptasks_3: 'Execute',

			permission_USERS_inventory_0: 'Visualize',
			permission_USERS_inventory_1: 'Edit category',
			permission_USERS_inventory_2: 'Edit inventory',
			permission_USERS_inventory_3: 'Import and Export inventories',

			permission_AGENT_inventory_0: 'Visualize',
			permission_AGENT_inventory_1: 'Edit',

			permission_USERS_isochronoes_0: 'Visualize',
			permission_USERS_isochronoes_1: 'Edit',
			permission_USERS_isochronoes_2: 'Delete',
			permission_USERS_isochronoes_3: 'Create',

			permission_USERS_resourceallocation_0: 'Generate assignments',

			permission_USERS_routeoptimization_0: 'Generate optimizations',

			permission_USERS_routes_0: 'Visualize',
			permission_USERS_routes_1: 'Edit',
			permission_USERS_routes_2: 'Cancel',
			permission_USERS_routes_3: 'Restart route tasks',
			permission_USERS_routes_4: 'Restart canceled tasks',
			permission_USERS_routes_5: 'Restart route',
			permission_USERS_routes_6: 'Reschedule route',
			permission_USERS_routes_7: 'Reorganize route',
			permission_USERS_routes_8: 'Add task by folio',
			permission_USERS_routes_9: 'Delete task from route',
			permission_USERS_routes_10: 'Reassign agent',

			permission_AGENT_routes_0: 'Visualize',
			permission_AGENT_routes_1: 'Cancel',
			permission_AGENT_routes_2: 'Reject',
			permission_AGENT_routes_3: 'Create emergencies',

			permission_USERS_skills_0: 'Visualize',
			permission_USERS_skills_1: 'Delete',
			permission_USERS_skills_2: 'Create',

			permission_USERS_sms_0: 'Visualize',
			permission_USERS_sms_1: 'Edit',

			permission_USERS_tasktypepreferences_0: 'Visualize',
			permission_USERS_tasktypepreferences_1: 'Edit',

			permission_CLIENT_USERS_tasktypepreferences_0: 'Visualize',

			mobile: 'Mobile',
			deselectAllNotificationsTooltip: 'Deselect all the notifications in this group',
			selectAllNotificationsTooltip: 'Restore to default, select all the notifications in this group',

			usersNotifications: 'Users Notifications',
			myNotifications: 'My Notifications',
			configureYourNotifications: 'Configure Your notifications',
			configureNotificationsDescription:
				'Select how you would like to receive the notifications for the following activities',
			configureUsersNotifications: 'Configure the notifications for the users in your account',
			configureUsersNotificationsDescription: 'Select which notifications you would like each type of user to receive',
			smsNotificationExtraCost: 'Enabling the SMS will add an additional cost to the notifications',

			accepted: 'Accepted',
			withoutAccepting: 'Without accepting',

			notificationAction_0: 'Task Created',
			notificationAction_65: 'Task Created by Client',
			notificationAction_66: 'Task Created by Mail',
      notificationAction_67: 'Self-allocated Task is not for today',
			notificationAction_1: 'Task Assigned Agent',
			notificationAction_14: 'Task Assigned to Partner',
			notificationAction_48: 'Task Reassigned to Partner',
			notificationAction_62: 'Task Assigned to Task Group',
			notificationAction_32: 'Task Assigned to Team',
			notificationAction_33: 'Form Assigned to Task',
			notificationAction_35: 'Inventory Assigned to Task',
			notificationAction_34: 'Asset Assigned to Task',
			notificationAction_2: 'Task Accepted by Agent',
			notificationAction_5: 'Task Rejected by Agent',
			notificationAction_3: 'Task Started',
			notificationAction_24: 'Agent Arrived to Task',
			notificationAction_51: 'Agent On Way',
			notificationAction_7: 'Task was Cancelled',
			notificationAction_53: 'Task was Finished',
			notificationAction_10: 'Task was Rescheduled',
			notificationAction_13: 'Task Cancelled by Partner',
			notificationAction_20: 'Task Location was Updated',
			notificationAction_55: 'Task was edited',
			notificationAction_54: 'Comment was added to Task',
			notificationAction_40: 'Task was reset',
			notificationAction_80: 'Form not approved',
			notificationAction_81: 'Form approved',

			notificationAction_23: 'Route assigned to agent',
			notificationAction_57: 'Tasks From route were restarted',
			notificationAction_15: 'Route Accepted',
			notificationAction_16: 'Route Rejected',
			notificationAction_17: 'Route Finished',
			notificationAction_37: 'Route Rescheduled',
			notificationAction_38: 'Route Restarted',
			notificationAction_18: 'Route Cancelled',
			notificationAction_9: 'Route Started',
			notificationAction_21: 'Route Deleted',
			notificationAction_25: 'Task Added to Route',
			notificationAction_56: 'Route updated',
			notificationAction_22: 'Task Deleted from Route',
			notificationAction_58: 'Incident reported from route',
			notificationAction_59: 'Restart cancelled tasks from route',

			notificationAction_27: 'Assigned Agent to Task Group',
			notificationAction_60: 'Task Group cancelled',
			notificationAction_61: 'Task Group Paused',
			notificationAction_62: 'Task added to Task Group',
			notificationAction_63: 'Task Removed from Task Group',
			notificationAction_64: 'Task Group updated',

			botName:'Virtual Assistant',

			// isochronoes
			area: 'Area',
			originPoint: 'Point of origin',
			none_agents: 'No agents',
			noneteam: 'No teams',
			circle: 'Circle',
			polygon: 'Polygon',
			errorMessageIsoDates:
				'Incorrect Data info / Please verify,  the first Task Date has to be before the Second Task Date',
			clear: 'Clear',
			loginInvalidUserPassword: 'Invalid user name and/or password',
			loginDisabledAccount: 'Account disabled, contact the administrator',
			loginDisabledUser: 'Disabled user, contact your administrator for more information',
			loginAgentAccesMessage:
				'As an agent you do not have access to the web application, please download the agent mobile app to enter.',
			loginAgentAccessDenied: 'Agent has no access to Fielder Web',

			showIsochronous: 'Show isochronous',
			showAgentsStartLocation: 'Show agents start location',
			isoType: 'Type',
      editIsochronous: 'Edit Isochronous',
			addIsochronous: 'Add Isochronous',
			searchLocation: 'Search Location',
			accountDoesNotHaveIsochronous: 'You don\'t have active isochronous in this section. Create a new one',
			noneIsochronoes: "You still don't have isochronous created",
			confirmDeleteIscronoes: 'Are you sure you want to delete ischronous?',
			selectIsochronous: 'Select one or more isochronous',

			// Form Validation Errors
			requiredName: 'Name is required',
			isRequired: 'is required',
			requiredIsochronousName: 'Isochronous name is required',
			requiredAddress: 'Address is required',

			gatheringLocationInformation: 'Gathering location information...',
			creatingIsochronousArea: 'Creating isochronous area...',
			assigningAgentsTeams: 'Assigning agents and teams...',
			preparingInformation: 'Preparing information...',
			generatingNewIsochronousData: 'Generating new isochronous data...',
			updatingAccountData: 'Updating account data...',
			savingInformation: 'Saving information...',

			// Resource allocation
			allocateResources: 'Allocate resources',
			resourceAllocation: 'Resource allocation',
			errorEnsureInformationCorrect: 'Please ensure that the information is correct and try again',
			resourceAllocationSuccess:
				'The Resource allocation process was created successfully. You will receive an email with the result of the process in a moment.',

			// ResourceAllocation and Dynamic Allocation variables
			routeTaskVariables: 'Route variables',
			routeTaskAddVariable: 'Add variable',

			// My operation
			downloadTaskFileName: 'fielderTasks',

			downloadManagerFileName: 'fielderManagers.csv',
			downloadDisableManagerFileName: 'fielderDisableManagers.csv',

			downloadAdministratorFileName: 'fielderAdministrators.csv',
			downloadDisableAdministratorFileName: 'fielderDisableAdministrators.csv',

			downloadClientsFileName: 'fielderClients.csv',
			downloadDisableClientsFileName: 'fielderDisableClients.csv',

			downloadCompaniesFileName: 'fielderCompanies.csv',
			downloadDisableCompaniesFileName: 'fielderDisableCompanies.csv',

			downloadBranchesFileName: 'fielderBranches.csv',
			downloadDisableBranchesFileName: 'fielderDisableBranches.csv',

			downloadAgentsFileName: 'fielderAgents.csv',
			downloadDisableAgentsFileName: 'fielderDisableAgents.csv',

			generateZip: 'Generate Zip',
			modulesZipSuffix: 'modules.zip',

			statusModifiedByUser: 'Status modified by user',
			theTaskWasNotFound: 'The task was not found',
			notFound:'Not found',
			taskStatusNotAllowed: 'Task status not allowed',
			canceledTask: 'Canceled task',
			taskIsInactive: 'Task is inactive',
			tryAgainUpdateStatus: 'It was not possible to update the status of the task, please try again',
      		tryAgainAutoAssignTasks: 'Could not auto-assign the task(s), try again',
			idTaskName:'ID Task',
			idTask: 'Fielder ID',
			idCustom: 'Custom ID',
			idCustomBranch: 'Custom Branch ID',
			branchNameText: 'Branch Name',
			companyIdCustom: 'Custom Company ID',
			companyIdAndName:'Id-Company Name',
			companyName: 'Company Name',
			parentCompanyNameText: 'Parent Company Name',
			addressCompany:'Company Address',

			idParentTask: 'Parent Task ID',
			parentTask: 'Parent Task',
			parentTaskType: 'Parent Task Type',
			deleteTaskTooltip: 'Delete Task(s)',
			disableTaskTooltip: 'Disable Task(s)',
			deleteRouteTooltip: 'Delete Route(s)',
			deleteGroupTaskTooltip: 'Delete Group Task(s)',
			taskRouteReturnedNoResults: 'Task Route retured no results',
			taskRouteAvailableForTaskStatus: 'Task Routes only available for tasks with status Waiting On Way',
			document: 'Document',
			documents: 'Documents',
			folio: 'Folio',
			caseNumber:'Case Number',
			noFolioFolder: 'No_folio',
			noFolio: 'No folio',
			noForm: 'No form selected',
			noWorkingHourFrom: 'No entry time',
			noWorkingHourTo: 'No departure time',
			group: 'Group',
			statusGroup: 'Group status',
			groupTask: 'Group task',
			unknownCategory: 'Others',
			module: 'Module',
			severity: 'Severity',
			status: 'Status',
			notAvailable: 'Not available',
			tags: 'Tags',
			tag: 'Tag',
			agentStartLocation: 'Execution direction',
			agentStartLocationExcel: 'Task start address',
			routeTimeTraveled: 'Estimated Travel Time of the Route',
			pauseFormattedHour: 'Task pause time',
			pauseFormattedDate: 'Task pause date',
			pauseResumeFormattedHour: 'Paused task resume time',
			pauseResumeFormattedDate: 'Paused task resume date',
			countPause: 'Number of pauses',
			countResume: 'Number of resumes',

			routeDistanceTraveled: 'Estimated distance of the Route',
			agentEndLocation: 'Completion address',
			agentEndLocationExcel: 'Task completion address',
			name: 'Name',
			names:'names',
			lastConnection: 'Last connection',
			lastDataObtained: 'Last data obtained',
			lastName: 'Last Name',
			idCountryCode: 'Id country',
			selectedElements: 'Selected items',
			idBranches: 'Id Branches',
			noBranchLink: 'Not linked to branches',
			branchName: 'Branch',
			idCustomBranchDuplicated: 'Id Custom or name  duplicated',
			country: 'Country',
			accountByCountry: 'Account by country',
			alreadySenInvitacion: 'You have already sent an invitation to this email',
			partnertNotFound: 'The partner you are trying to invite does not exist or the e-mail is wrong',
			pendentingInvitation: 'Pending invitation',
			unnamed: 'Unnamed',

			createRegion: 'Create Region',
			editRegion: 'Edit Region',
			deleteRegion: 'Do you want to delete the Region?',
			region: 'Region',
			createRegionSuccessMessage: 'The Region was created correctly',
			createRegionErrorMessage: 'The Region was not created correctly, change the information and try again later',
			newRegion: 'New region',
			agent: 'Agent',
			startWay: 'Scheduled Date',
			startTime: 'Scheduled Date',
			onWayDateExcel: 'Journey start date',
			onWayHourExcel: 'Journey start hour',
			onWayDate: 'On way date',
			endDateExcel: 'Task Completion Date',
			endHourExcel: 'Task End Time',
			endAgent: 'End Date',
			endHour: 'End Hour',
			score: 'Score',
			timeZone: 'Time Zone',
			rescheduledDate: 'Rescheduled date',
			rescheduledHour: 'Rescheduled hour',
			rescheduledComment: 'Rescheduled comment',
			rescheduledCommentDate: 'Rescheduled comment date',
			rescheduleUserName:'User who rescheduled',
			cancelReason: 'Cancellation reason',
			taskActivities: 'Task activities',
			reason: 'Reason',
			cancelBy: 'Canceled by',
			cancelComment: 'Cancel comments',
			taskPauseComment: 'Task pause commments',
			taskRejectionDate: 'Task rejection date',
			taskRejectTime: 'Task reject time',
			taskRejectDependency: 'Task reject dependency',
			taskRejectionReason: 'Task rejection reason',
			taskRejectionComment: 'Task rejection comments',

			mobileInformation: 'Mobile information',
			locationPrecisePermission: 'Precise location permission',
			locationApproximatePermission: 'Approximate location permission',
			locationBackgroundPermission: 'Background location permission',

			taskGroupNotification:
				'Only the Task Groups in "Created" status are shown, if the Group is in another status, create a New Group or assign the task individually"',

			searchByTask:'Search by task',
			searchByForm:'Search by form',
			userCancelComment: 'User cancellation comments',
			executionMode: 'Execution mode: WITH OUT network',
			kmsDistance: 'Estimated distance traveled in kms',
			messageForm: 'The final date is missing',
			messageTo: 'The start date is missing',
			messagePrevious: 'The start date must be previous to the end date',
			messageFieldEmpty: 'The Search field is empty',
			messageGeneralSearch: 'General search',
			disableAvacedSearch: 'Remove advanced search to use general search',
			messageEmptyTasks: 'You should select a task',
			messageEmptyRoutes: 'You should select a route',
			messageEmptyGroupTasks: 'You should select a group task',
			messageTaskGroupDeletePartner:'You have selected one or more groups assigned by a partner and you cannot delete them',
			messageNoMoreThanTwoTask: 'You must select at least 2 tasks to create a task group',
			messageNoMoreThanTwoTaskRoutes: 'You must select at least 2 tasks to generate routes',
			messageMusntDelete: 'You must not delete this tasks',
			messageCantRestore: 'This task cannot be retrieved, please try again later',
			messageTaskDeleteError: 'You cannot eliminate these tasks because they are assigned to other resources.',
			messageTaskDisableError:
				'You cannot disable this tasks. Only task on ""managing"" and ""waiting for agent"" status can be deleted.',
			messageTaskWithRoute: 'You cannot delete this task because it is linked to a route',
			messageTaskDeleteSuccess: 'Task(s) successfully deleted',
			messageTaskDisableSucess: 'Task(s) successfully disabled',
			messageConfirmDeleteTasks: 'Are you sure you want to delete this task(s)?',
			messageConfirmRestoreTasks: 'Are you sure you want to restore this task(s)?',
			messageConfirmDeleteRoutes: 'Are you sure you want to delete this route(s)?',
			messageConfirmDeleteGroupTasks: 'Are you sure you want to delete this group task(s)?',
			messageConfirmaDisableTask: 'Are you sure you want to disabled this task(s)?',
			messageConfirmaCancelTask: 'Why do you want to cancel your task?',
			cancelInformation:'*Remember that the tasks canceled by administrators and/or managers will be completely canceled and you will have to create a new task. Tasks canceled by Agents and/or partners can be reassigned.',
			messageMoreTasksSelected: 'Please  select just one task',
			messageMusntReschedule: 'A cancelled task can´t be rescheduled',
			messageErrorReschedule:
				'You cannot reschedule a task with status Execution, On the Way, Arriving, Canceled, Finished or Canceled by a partner',
			messageErrorRescheduleClient:
				'You cannot reschedule a task with status Execution, On the Way, Arriving, Canceled, Finished ',

			messageErrorRescheduleGpoTask:
				'The task is in a Task Group, to take an action on it you must first unlink it from the group',
			messageAutoassignError:
				'You can´t assign an agent to a task while the self-assignment process is running or the status is different from "Managing", "Canceled" "Waiting for Agent", "Assigned to team", "Cancelled by Partner" or if the task was canceled by a client or administrator',
			messageAssignAgentPartnersError: 'Unable to assign an agent to a task assigned to a partner',

			messageAssignAgentPartnersCancelError:
				'Cannot assign an agent to a canceled task if you are not the owner and have a partner relationship',

			messageErrorAssignTeamPopUp:
				'You cannot assign a team to a task with a status different from "Managing", "Assigned to team", "Canceled", "Cancelled by Partner" or if the task was canceled by a client or administrator ',
			messageRateFinishedTaskOnly: 'You could not rate a task with a status other than "Finished"',
			messageRatedAlready: 'The task was already rated',

			messageAssingAgentPartnersError: "You cannot assign an agent to a partner's tasks ",

			messageErrorGroupTaskWitTask:'You cannot create a task group if you selected a task already with a group assigned to it, we recommend that you first unlink the task from the group or create a new group from the task group tab.',

			messageErrorAssignGroupTaskWitTask:'You cannot assign a group to a task that already has one, first unlink the task from the group.',

			messageErrorGroupTaskStatus:
				'You can´t add a Task Group to a task with status "Finished", "Executing", "Start route" or "Arrived", please verify the information and try again',

			messageErrorGroupTaskParner:
				'You cannot create a task group group to a task canceled or paused by you or your partner if you are not the owner of the task',

			messageErrorCancelTaks: 'You cannot cancel task(s) with status "Finished" or "Cancelled"',
			messageErrorCancelTaksOwner:
				'You cannot cancel task(s) with status "Finished" or "Cancelled" "Cancelled by partner"',

			messageErrorGroupTaskPartner:
				'You cannot assign a task group to a task canceled or paused by you or your partner if you are not the owner of the task',

			messageErrorAssignPartner: 'You can´t assign a partner to a task with a status different from "Managing". If you want to change the partner assigned to the task, use the "reassign partner" function. ',
			messageErrorUserReasingPartner: 'Please use the "reassign partner" function in the actions menu ',
			messageErrorUserReasingPartnerCancelClient:
				'You cannot assign a task to a partner if the client or administrator previously cancels and owns it',

			messageErrorReAssignPartner: 'You can´t reassign a partner to a task that is on execution or finished',
			messageErrorReAssignPartnerNoPartner:
				'You cannot reassign a task to a partner if it was not previously assigned to one',
			messageErrorReAssignPartnerExecutor:
				'You can´t reassign a partner to a task when you are the last partner assigned to it',
			selectOnePartner: 'You must select at least one partner',
			setOneReason: 'You must write a reason',
			setCategory: 'You must set a category',
			fail: 'Did not meet the condition',
			reassignedByMe: 'Show tasks reassigned by me',
			reassignedByOther: 'Show tasks reassigned by other partners',
			taskReassignedInfo: 'Dashboard of total task re-assigned',
			messageErrorAssignAsset: 'You can´t assign an asset to tasks with different branches',
			messageErrorAssignAssetwithNoBranch: 'You can´t assign an asset to tasks without a branch',
			messageErrorAssignAssetPartner:"You cannot assign an asset to a task assigned by a partner",
			messageErrorAssignAssetWrongStatus: "You can't assign an asset to a task with cancelled or finished status",
			messageErrorAssignFormWrongStatus: "You can't assign a form to a task with cancelled or finished status",
			messageErrorAssignInventaryPartner:"You cannot assign an inventory to a task assigned by a partner",
			messageErrorAssignInventoryWrongStatus:
				"You can't assign a inventory to a task with cancelled or finished status",
			messageErrorDeletedTask: "Ups, we couldn't delete this photo(s)",
			messageConfirmationAssignAgent: 'Are you sure you want to assign an "off duty" agent?',
			messageAssignAgentSuccessfully: 'The Agent was successfully assigned',
			messageAssignTeamSuccessfully: 'The Team was successfully assigned',
			messageErrorAssignTeam: 'The team could not be assigned, try again',

			tasksUpperCase: 'TASKS',
			taskUpperCase: 'TASK',
			messageErrorAssignAgent: 'The Agent was not assigned, try again',

			newTasks: 'New tasks',
			newTaskGroups:'New task groups',
			showUpdates: 'Show Task Updates',
			showTaskGroupUpdates:'Show Task Group Updates',
			taskStatusAnalitycs: 'TASKS STATUS',
			statusAnalitycs: 'STATUS',

			titleIconCalendar: 'Reschedule',
			titleIconDetail: 'Detail',
			titleIconGroup: 'Create a task group',
			titleIconEditGroup: 'Task group detail',
			titleIconEditGroupAction: 'Group Detail',

			updateFormInTaskByCsv:'Update form in task by CSV',
			titleIconAssignGroup: 'Assign a task group',
			titleIconAssignGroupAction: 'Assign Group',
			route: 'Route',
			assignResources: 'Assign Resources',
			updateTemplateForm:'Template for task update',
			import: 'Import data',
			export: 'Export data',
			updateTaskForms:'Update tasks forms',
			updateTasks:'Update tasks',
			importTask: 'Import tasks',
			exportTask: 'Export tasks',
			selectOptions: 'Select the fields you want to export',
			tasksRelatedReason: 'Tasks related to reason',
			exportCalendar: 'Export Calendar',
			exportError: 'No data to export',
			exportMessage: 'We are processing and downloading your file ...',
			loadingTextAssitedForm:'Creating template. This action may take a few minutes.',
			getTaskFromGroup:'We are bringing your group tasks',
			uploadFileMessage: 'We are processing and uploading your file ...',

			obtainTaskMessage: 'We are obtaining the information of your tasks',
			obtainFormMessage: 'We are generating the forms of your tasks',
			obtainFormImageMessage: 'We are getting the images from the forms. Please wait.',
			exportingModules: 'Exporting Modules. Please wait',
			noFormsToDownload: 'There were no forms that have an html or xlsx template',
			noFormsToDownloadImages: 'There were no forms containing images',
			importMessageProcess: 'We are processing and uploading your file... ',
			maxImportMessage:'The maximum quantity of tasks to import through Excel is 200 tasks per import. To import a larger volume, use the Fielder API',
			maxUpdateMessage:'The maximum quantity of tasks to update through Excel is 200 tasks per import. To update a larger volume, use the Fielder API',
			updateForms:'Update forms',
			downloadFormTemplateMessage1: '1. Click ',
			downloadFormTemplateMessage11: ' here ',
			downloadFormTemplateMessage12:' to go to the Forms Module',
			downloadFormTemplateMessage2: '2. Click on the name of the form you want to update',
			downloadFormTemplateMessage3: '3. Within the details of the form, download the template',
			downloadFormTemplateMessage4: '4. Upload your edited template with the updates you made in the following "Update Form" button.',
			
			downloadFormTemplateManagerMessage1: '1. Click on task detail',
			downloadFormTemplateManagerMessage2: '2. Enter the functionality tab and then open the forms section',
			downloadFormTemplateManagerMessage3: '3. Select the desired form',
			downloadFormTemplateManagerMessage4: '4. Download the template using the top right button',
			
			from: 'From',
			to: 'To',
			outOf: 'out of',
			executeReport: 'Execute',
			offDuty: 'Off duty',
			importTaskSuccess:
				'Your task-import process was created successfully, soon you will receive an email with the results',
			importTaskError:
				'Sorry, your task-import process was unsuccessful. Please review the information on your .csv file and try again.',
			importTaskExceeds: 'The number of tasks exceeds the maximum allowed (500 tasks)',
			importTaskNotLastCVS:
				'The number of columns in the format does not match its latest version. Please download the format and try again.',
			downloadTaskTemplate: 'Download the task-import .csv template',
			downloadUpdateTaskTemplate:'Dowload the task-update .csv template',
			downloadUserTemplate: 'Download the user-import file .csv template',
			downloadBranchTemplate: 'Download the branch-import file .csv template',
			downloadCompanyTemplate:'Download the company-import file .csv template',
			createTaskShownClientWarning: 'Remember that only customers who are not linked to a branch appear.',
			rememberCsvFile: 'Remember you can only upload a .csv file',
			useLastCsvTemplate: 'Ensure you are using the last version of the template / file',
			importBranchNote: 'If you want to update the information through the import mark the last box with an x',
			useCorrectCsvUpdateTemplate: 'Make sure you have the correct csv to update the form within the task',
			cellsLeftBlankInfo: 'Blank cells will be ignored and the information in that question will not be updated',
			useCorrectCsvTemplate: 'Make sure you are using the correct csv template for this category',
			cancelRoute: 'Cancel Route',
			cancelTask: 'Cancel Task',
			rateTask: 'Rate Task',
			rate: 'Rate',
			rateTaskAction: 'Rate',
			clientComment: 'Client Comment',
			comment: 'Comment',
			rateTooltipText: 'Select from minor to major the number of stars',
			cancelRouteConfirmation: 'Do you want to cancel the selected routes?',
			resetRouteTasks: 'Reset tasks from routes',
			resetCanceledTasks: 'Reset canceled tasks',
			resetRouteTasksConfirmation: 'Do you want to reset the selected routes?',
			resetCanceledTasksConfirmation: 'Do you want to reset the canceled tasks from the route?',
			resetRouteTasksNotificacion: 'The route was reset',
			resetCanceledTasksNotification: 'The route canceled task have been reset',
			restartTaskExecution: 'Task was restarted',
			assetAssigned: 'Asset assigned to task',
			templateAssigned: 'Form assigned to task',
			inventoryAssigned: 'Inventory assigned to task',
			cancelRouteMessage: 'Remember that to keep your tasks you must save them first',
			resetRouteTasksMessage:
				'This action will cause the tasks of your route return to their initial state in the section of tasks outside your route and that your route is eliminated',
			resetCanceledTasksMessage:
				'This action will cause the canceled tasks of your route return to their initial state in the section of tasks outside your route',
			reschedule: 'Reschedule',
			rescheduleComment: 'Reschedule´s Comment',
			dateOfRescheduleComment:'Date of change',
			rescheduleTask: 'Reschedule Task',
			rescheduleRoute: 'Reschedule Route',
			routeRescheduled: 'The route was rescheduled',
			restartRoute: 'Restart Route',
			restart: 'Restart',
			routeNumber: 'Route number',
			routeName: 'Route name',
			routeCost: 'Estimated Cost of the Route',
			agentName: 'Agent name',
			routeEndPoint: 'Return from the route',
			routeStartPointTitle: 'Route start address',
			routeEndPointTitle: 'Route return address',
			routeEndPointDefault: 'Take direction of the last task of the Route',
			routeExpensesConfig: 'Route expenses configuration',

			selectProgramDate: 'You should select a scheduled date',
			selectTasks: 'You must select one or more tasks',
			selectTasksFinish: "You can't unlink finished tasks",
			messageMoreRoutesSelected: 'You selected more than one (1) route',
			messageCancelRoutes: 'You can´t cancel a route that has the following status:  executing, cancelled or finished.',
			messageRescheduleRoutes: 'You can´t reschedule a route that is being executed, was cancelled or was finished.',
			messageResetRouteTasks: 'You can´t reset a route that is being executed, was cancelled or was finished.',
			messageResetCanceledTasks: 'You can only reset a route that is in a canceled state',
			messageReassignAgent:
				"You can only assign an agent to a route that is in the following status: 'Accepted', Waiting for Agent' or 'Rejected'.",
			messageRestartRoute: 'You can´t restart a route that has no canceled or rejected status.',
			selectRescheduledDate: 'Select a new date',
			selectNewAgent: 'Select a new agent',
			messageSelectAgent: 'You must select an agent',
			messageUpdateRoute: 'The route cannot be updated, one of the tasks changed its status',
			messageIdTeams: 'The tasks must belong to the same team',
			messageNullStartTime:
				'Some of the tasks do not have an execution date, please reschedule the task and try again.',
			selectRoute: 'Select one Route',
			selectTask: 'Select one Task',
			resetedTask: 'Task reset',
			routeChangedByAgent: 'The operation could not be performed because the agent has updated the route',

			// NOTIFICATION
			notificationType0: 'A Task has been created',
			notificationType65: 'A Task has been created by Client',
			notificationType66: 'A Task has been created by Email',
			notificationType1: 'The Task was assigned',
			notificationType2: 'The Task was accepted by Agent',
			notificationType3: 'The Task has started',
			notificationType4: 'The Task has ended',
			notificationType5: 'The agent rejected the route',
			notificationType6: 'Notification',
			notificationType7: 'The Task has been cancelled',
			notificationType9: 'The agent has started his route',
			notificationType10: 'The Task has been rescheduled',
			notificationType11: 'New invitation to be a Partner ',
			notificationType12: 'has declined to be your partner',
			notificationType13: 'The Task has been canceled by Partner',
			notificationType14: 'The Task was assigned by Partner',
			notificationType15: 'The agent accepted the route',
			notificationType16: 'The route was rejected by Agent',
			notificationType17: 'The route was finished by the Agent',
			notificationType18: 'The Route was cancelled',
			notificationType19: 'The agent has started his route',
			notificationType20: 'The task location has changed',
			notificationType21: 'The Route was deleted',
			notificationType22: 'Task Removed from Route',
			notificationType23: 'Route assigned to an Agent',
			notificationType24: 'The agent has arrived',
			notificationType25: 'The task has the route ID',
			notificationType26: 'The partnership invite was sent to ',
			notificationType27: 'Assigned task group',
			notificationType28: 'Error trying to self-assign the task ',
			notificationType29: 'Self-assignment process not successful: No agents available for the task were found ',
			notificationType30:
				'Self-assignment process not successful: No Self-assignment templates to attend the task were found ',
			notificationType31: 'You have declined a partnership with',
			notificationType32: 'Task Assigned to a Team',
			notificationType33: 'Assigned to task form',
			notificationType34: 'Active Assigned to task',
			notificationType35: 'Task Assigned Inventory',
			notificationType36:
				'Self-assignment process not successful: No agents were found with the specified skill or isochronous that contain the specified template',
			notificationType37: 'Changed the schedule of the Route',
			notificationType38: 'The route was restarted',
			notificationType40: 'The Task was Restarted',
			notificationType41: 'Self-assignment process not successful: No self-assignment templates where found',
			notificationType42: "Self-assignment process not successful: Task location isn't inside an isochronous",
			notificationType43:
				'Self-assignment process not successful: No templates where found inside the task isochronous',
			notificationType44: 'Self-assignment process not successful: No templates where found for the task origin',
			notificationType45: 'Self-assignment process not successful: No templates where found for the task type',
			notificationType46: 'Self-assignment process not successful: No available agents found',
			notificationType47: 'Self-assignment process not successful: No templates where found for the task priority',
			notificationType66: 'Self-assignment process not successful: No online resources found for the template',
      notificationType67: 'Self-assignment process not successful: The task is not for today',
			notificationType48: 'The Task was reassigned',
			notificationType50:
				'Self-assignment process not successful: The range of time of attention of the Branch is out of the time of range of attention specified in the task.',
			notificationType51: 'Agent is on way to the Task',
			notificationType53: 'The Task was finished',
			notificationType54: 'New Comment added',
			notificationType55: 'The Task Information was Edited',
			notificationType56: 'The tasks from the Route were reorganized',
			notificationType57: 'The Route Tasks were Reset',
			notificationType58: 'Incident reported in the route',
			notificationType59: 'The Cancelled tasks from the Route were reset',
			notificationType60: 'Task Group was cancelled',
			notificationType61: 'Task Group was paused',
			notificationType62: 'A task was added to the Group',
			notificationType63: 'A task was removed from the Group',
			notificationDefault: 'Fielder Notification',
			notificationWhitout: 'You don´t have notifications',

			where: 'Where',
			orCapitalLetter: 'Or',
			andCapitalLetter: 'And',
			contain: 'Contain',
			// ChangePassword
			changePasswordTitle: 'Change password',
			password: 'Password',
			currentPassword: 'Current password',
			newPassword: 'New password',
			confirmNewPassword: 'Confirm new password',
			changePasswordButton: 'Change password',
			messageErrorChangePassword1: 'Incorrect password',
			messageErrorChangePassword2: 'New password does not match',
			messageErrorChangePassword3: 'Password changed successfully',
			messageErrorChangePassword4: 'An error occurred, please try again',
			welcomePasswordMessage:
				'Your password must be at least 8 characters and must include lowercase letters,  uppercase letters and numbers.',

			// TaskDetail
			lastUpdateDate: 'Last update date',
			responsible:'Responsible',
			withoutTeam: 'Without team',
			notAssigned: 'Not assigned',
			notAssignedSchedule: 'There si no date or hour assigned',
			nothingToComment: 'No Comment',
			execution: 'Execution',
			photos: 'Photos',
			activity: 'Activity',
			functionality: 'Functionality',
			taskDetail: 'Task Detail',
			nameOfClient: 'Client Name',
			nameOfBranch: 'Branch Name',
			phoneNumberClient: 'Client mobile phone number',
			emailOfClient: 'Client´s email',
			typeTask: 'Task Type',
			serviceOrigin: 'Service Origin',
			commentTask: 'Task Comment',
			descriptionTask: 'Task Description',
			rating: 'Rating',
			editTask: 'Edit Task',
			generatePdf: 'Generate PDF',
			generateXlsx: 'Generate XLSX',
			generateXlsxModule: 'Generate XLSX Template',
			generatePDFfromXlsx: 'Generate PDF Template',
			generatedPDFfromXlsx: 'Generated PDF Template',
			buyGeneratePDFfromXlsx: 'Buy functionality "Generate PDF Template"',
			generatePDFModule: 'Generate PDF Fielder Format',
			assistedForm: "Create FORM with A.I.", 
			beta:"BETA",
			editGroupTask: 'Edit Task Group',
			scheduledDate: 'Scheduled Date',
			endDate: 'End Date',
			assignedTeam: 'Assigned Team',
			assignedAgent: 'Assigned Agent',
			assignedWork: 'Working hours',
			phoneAgent: 'Agent mobile phone number',
			emailAgent: 'Agent´s email',
			typeVehicle: 'Vehicle type',
			idVehicle: 'Id Vehicle',
			timeTraveled: 'Estimated Time Traveled',
			distanceTraveled: 'Estimated Distance Traveled',
			distance: 'Distance',
			photosGallery: 'Gallery Photos',
			history: 'History',
			headerOfTitle: 'Header Title',
			downloadPDF: 'Download PDF',
			downloadForms: 'Download forms',
			downloadApprovedForms: "Download Approved Forms",
			approvedForms:"Approved Forms",
			downloadFormsImages: 'Download forms images',
			addressTask: 'Task Address',
			timeExecution: 'Execution Time',
			until: 'Until',
			commentsAgent: 'Agent comments',
			lastAgentComment: 'Agent last comment',
			rejectionReason: 'Rejection Reason',
			pausedReason: 'Pause Reason',
			taskPausedReason: 'Task Paused Reason',
			taskStatusModificationCommentByAdministrator: 'Task status modification comment by administrator',
			taskStatusModificationCommentByManager: 'Task status modification comment by manager',

			taskRejectionComments: 'Task Rejection Comments',
			taskPauseComments: 'Task Pause Comments',
			
			dateInUTCFormat:'Fecha en formato UTC',

			commentTitle: 'Comment',
			commentsUser: 'Client comments',
			commentUserDate: 'Customer comment date',
			automationUser: 'Fielder automation',

			// Create Group Tasks
			createGroupTaskTitle: 'Create Task Group',
			createGroupTaskAction: 'Create Group',
			idCustomGroupTask: 'ID Custom Task Group',
			executionDate: 'Execution date',
			creationDate: 'Creation date',
			disableDate: 'Disable date',
			creationDateExcel: 'Task creation date',
			creationHourExcel: 'Task creation hour',
			executionTimeError: 'The execution date must be the same or later than today',

			// Assigned group task
			assignedGroupTaskTitle: 'Assign Task Group',
			assign: 'Assign',
			reassign: 'Reassign',

			// Calendar
			titleCalendar: 'My Calendar',
			assignedTask: 'Assigned to branch',
			assignedBranch: 'Assigned to client',
			notAssignedAgent: 'Agent not assigned',
			assignedToTaskGroup: 'Agent assigned to a Task Group',

			// Profile
			profileTitle: 'MY PROFILE',
			messageErrorEmail: 'Invalid email',
			messageErrorPhone: 'Invalid phone number',
			messageFailedUpdatePhone: 'Failed to update phone number',
			messageEmailAlreadyUsed: 'The email has already been used by another super administrator',
			messageErrorUpdateProfile: 'Something went wrong, the profile was not updated',
			messageUpdatedProfile: 'Saved successfully',

			// StatusFormatter
			taskStatusManaging: 'Managing',
			taskStatusWaittingForAgent: 'Waiting for agent',
			taskStatusInProgress: 'In progress',
			taskStatusExecuting: 'Executing',
			taskStatusFinish: 'Finished',
			taskStatusCancel: 'Cancelled',
			taskStatusStartRoute: 'Agent on way',
			taskStatusCancelByPartner: 'Cancelled by Partner',
			taskStatusAssignedToPartner: 'Assigned to Partner',
			taskStatusAssignedToTeam: 'Assigned to team',
			taskStatusArrived: 'Agent has arrived',
			taskStatusDeleted: 'Deleted',
			taskStatusAutoassigning: 'Self-assignment in process',
			taskStatusAutoassigningSuccess: 'Self-assignment was sucessful',
			taskStatusAutoassigningFailed: 'Self-assignment failed',

			accountReview: 'Account review',
			findAccount: 'Find account',

			firstName: 'First Name',
			idGroupTask: 'Id',
			age: 'Age',
			phone: 'Phone number',
			company: 'Company',
			companyEditToken: 'Select the Company(s)/Project(s) (Obligatory)',
			searchByCompany: 'Search by company',
			searchByBranch: 'Search by branch',
			companyBranch: 'Company',
			email: 'E-mail',
			emailCompany: 'Business E-mail (example johndoe@company.com)',
			emailCompanyConfirmation: 'Business E-mail confirmation',
			emailAccount: 'Account email',
			confirmEmail: 'Confirm email',
			plate: 'License plate',
			createTaskTitle: 'CREATE TASK',
			createRecurrentTask: 'Recurring tasks are being created in the background',
			createVolumenTask: 'Volume tasks are being created in the background',
			notifications: 'Notifications',
			recentNotifications: 'Recent Notifications',
			olderNotifications: 'Older Notifications',
			addUser: 'Add user',
			typeOfTask: 'Task Type',
			select: 'Select',
			selectedMasc: 'Selected',
			client: 'Client',
			companyClient: 'Company / Client',
			changePhoto: 'Change Photo',
			crop: 'Crop',
			chooseOtherImage: 'Choose other image',
			addPhotoToDisplay: 'Add a photo to display',
			priorityDefaultDelete:'"High", "Medium" or "Low" priorities cannot be removed',
			priorityDeleteError:'Cannot delete priority(s) ',

			branch: 'Branch',
			branchAnalytics: 'BRANCH',
			amount:"Amount",
			amountOfServices: 'AMOUNT OF TASKS',
			amountOfServicesLowerCase: 'Amount of tasks',
			amountOfServicesFinishedByAgent: 'Number of agents that completed a service',
			amountOfServicesAnalititcsLowerCase: 'Amount of tasks',
			searchBy: 'Search by',
			placeHolderClient: 'Name, last name, email, address',
			placeHolderBranch: 'Id custom, name, company, address',
			placeHolderBranchByIdCompany:'Search by Custom ID, name or branch ID',
			placeHolderCompany: 'Id custom, name',
			placeHolderTeam: 'Id, name',
			nameBranch: 'Name',
			nameCompany: 'Company',
			nameClient: 'Name',
			lastNameClient: 'Last name',
			cellphoneClient: 'Cell Phone',
			emailClient: 'Email',
			directionClient: 'Address',
			description: 'Description',
			high: 'High',
			medium: 'Medium',
			low: 'Low',
			severity_1: 'High',
			severity_2: 'Medium',
			severity_3: 'Low',
			editIsochrone: 'Save isochronous',
			createIsochrone: 'Create Isochronous',
			canOnlyAddRadiusToCircle: 'You can only add radius to circle isochronous',
			chooseDate: 'Choose a date',
			executionPeriod: 'Execution Period',
			initialDate: 'Start Date',
			toDate: 'To: ',
			finalDate: 'End Date',
			optional: 'Optional',
			recurrentTask: 'Recurring task',
			volumeTask: 'Volume task',
			repeatFrom: 'Repeat',
			repeat: 'Repeat',
			daily: 'Daily',
			weekly: 'Weekly',
			monthly: 'Monthly',
			annually: 'Annually',
			month: 'Show month',
			week: 'Week',
			showWeek: 'Show week',
			quarterlyBasis: 'Quarterly basis',
			semiannually: 'Semiannually',
			dailyMonday_Friday: 'Daily (Monday-Friday)',

			repeatUntil: 'Repeat until',
			numberofTimes: 'Number of times',
			objectiveToCover: 'Objective to cover',
			time: 'time',
			times: 'times',
			team: 'Team',
			byDirection: 'By direction',
			byCoordinates: 'By coordinates',
			partner: 'Partner',
			taskStatusModifiedDate: 'Task Status modification date',
			taskStatusModifiedHour: 'Task Status modification time',
			taskStatusModifiedCommnet: 'Task Status modification comment',
			taskStatusModifiedByAdministrator: 'Task Status Modified by Administrator',
			taskStatusModifiedByManager: 'Task Status Modified by Manager',
			assignedPartner: 'Assigned partner',

			didNotFindTasks:
				'The information was not found, please select a period of time or change your search parameters.',
			partnerWarningAssing: '*You can only assign tasks to partners with activated accounts',
			assignTo: 'Assign to',
			assignedTo: 'Assingned to',
			assignedBy: 'Assingned by',
			doNotAssign: 'Do not assign',
			onlyAgentsWitouthTeamMessage:'Only agents that are not in a team are shown',

			requiredFields: 'Required fields',
			createTask: 'Create task',
			viewTask: 'View Task',
			apiUrl: 'Api Url',
			id: 'Id',
			idUserConfig: 'Id User',
			idRoute: 'Id',
			idTaskGroup: 'Id',
			capitalLettersID: 'ID',
			createdDate: 'Creation date',
			view: 'Visualize',
			originType: 'Origin type',
			originId: 'Origin id',
			form: 'Form',
			companiesUpperCase: 'COMPANIES',
			desktop: 'Dashboard',
			myOperation: 'My Operation',
			commandCenter: 'Command Center',
			calendar: 'Calendar',
			agentsDashboard: 'Agents_Dashboard',
			taskCompanyDashboard: 'Company Task Dashboard',
			taskCompanyDashboardSla: 'Task With SLA',
			agents: 'Agents',
			managers: 'Managers',
			administrators: 'Administrators',
			partners: 'Partners',
			clients: 'Clients',
			uppercaseBranches: 'BRANCHES',
			uppercaseCompanies: 'COMPANIES',
			uppercaseClients: 'CLIENTS',
			typeOfService: 'TYPE OF SERVICE',
			typeOfServiceAnalitycs: 'TYPE OF TASK',
			typeOfServiceLowerCase: 'Type of task',
			branches: 'Branches',
			activeBranches: 'Active Branches',
			inactiveBranches: 'Inactive Branches',
			uppercaseTeams: 'TEAMS',
			companiesLowerCase: 'Companies',
			teams: 'Teams',
			agentTeamList:'Team',
			analytics: 'Analytics',
			isochronous: 'Isochronous',
			marketPlace: 'Marketplace',
			account: 'Account',
			globalDashboard: 'Global Dashboard',
			configuration: 'Settings',
			help: 'Help',
			users: 'Users',
			optinalField: 'Optional field',
			addClient: 'Add client',
			addBranch: 'Add branch',
			addBranchAndCreateTask: 'Create branch with task',
			addTeam: 'Add team',
			addPartner: 'Add partner',
			addCompany: 'Add company',
			addManager: 'Add manager',
			noManager: 'No manager',
			legalAddress: 'Legal Address',
			showBranchesToUpdateLocation: 'See location update pending',
			parentCompanyName: 'Parent company',
			workingHoursFrom: 'Work hour from',
			workingHoursTo: 'Working time to',
			parentCompanyId: 'Id Parent Company',
			businessName: 'Business name',
			selectParentCompany: 'Select a parent company',
			selectPlaceHolder: 'Select one',
			selectAgentsPlaceHolder: 'Select agents',
			idCompany: 'Id Company',
			idBranch: 'Id Branch',
			branchLogs: 'Branch Logs',
			createBranchLog: 'Branch Created',
			createBranch: 'Create Branch',
			editBranch: 'Edit Branch',

			editBranchLog: 'Branch Information Edited',
			updateLocationBranchLog: 'Branch Location Updated',
			disabledBranchLog: 'Branch Disabled',
			enabledBranchLog: 'Branch Enabled',
			apiUpdateBranchLog: 'Branch updated through API',
			createBranchAPILog: 'Branch Created through API',
			createBranchMobileLog: 'Branch Created through Mobile device',
			importedBranchLog: 'Branch Imported',
			versionUpdatesHilighted: 'The updates from the previous version are highlighted in a different color',
			updateLocationRequestLog: 'Location Update Request',
			deniedLocationRequestLog: 'Denied Location update',
			updated: 'Updated',
			branchLocation: 'Branch Location',
			placeHolderAddDirection: 'Write an address',
			placeHolderCancelComment: 'Write a comment...',
			addCustomer: 'Add a client',
			userName: 'Username',
			data: 'Data',
			header: 'Header',
			text: 'Text',
			fields: 'Fields',
			inventoryAsset: 'Inventory',
			categories: 'Categories',
			numberID: 'ID number',
			brand: 'Brand',
			title: 'Title',
			modules: 'MODULES',
			functionalitiesUpperCase: 'FUNCTIONALITIES',
			placeHolderForm: 'Form name',
			continue: 'Continue',
			add: 'Add',
			functionalities: 'Functionalities',
			iconTaskInformation: 'Functionalities',
			teamNames: 'Teams',

			placeHolderInventory: 'Id, name, brand, module or serial number',
			formUpdateWarning: "All changes to your forms will be reflected in the next task created, perform the necessary tests so that it meets your objectives. Learn more in Fielder's documentation",
			choose: 'Choose',
			yes: 'Yes',
			no: 'No',
			accept: 'Aceptar',
			cancel: 'Cancel',
			showTasks: 'Show tasks',
			return: 'Return',
			delete: 'Delete',
			close: 'Close',
			idAgent: 'Id Agent',
			fillTheRequiredFields: 'Fill the required fields',
			sameCompanyParentError: 'You cannot select the same company as the parent company',

			// Marketplace
			marketplace: 'Marketplace',
			taskTypeMkt: 'Task Customization',

			pdfAprovedMessage: 'The document has been successfully approved',
			pdfApprovedMessageError :'The form could not be generated correctly, try again',
			pdfCancelMessagePart1: 'The document has not been approved. Edit the form by clicking on the edit icon',

			marketplaceGraphTitle: 'Status of your operation',
			marketplaceYouAreHere: 'You are here',
			marketplaceGraphDescription: 'You have made your operations work with few clicks to disperse your activities.',
			connected: 'Connected',
			connect: 'Connect',
			buy: 'Buy',
			administration: 'Administration',
			manage: 'Manage',
			rol: 'Role',
			rules: 'Rules',
			rule: 'Rule',
			inventoryDescription:
				'Configure and manage parts, spare parts, packages, any input in the process of your tasks in the field',
			formsDescription:
				'Configure Forms of different styles and include them in different phases of the process of your tasks',
			groupTasksDescription:
				'Manage a large volume of activities in the same location, with one or several agents and an easy administration through tags.',
			supportDescription: 'Acquire better levels of attention from the team of Fielder.',
			consultingDescription:
				'Do you need support to digitize or integrate some process? Our experts are ready to support you.',
			storageDescription:
				'Determine the volume of information you need in your process (images, requests, logs, databases, etc ...)',
			taskTypeDescription:
				'Customize the name of the service or activity that your agents will execute according to your real service catalog.',
			assetManagementDescription:
				'Configure and register assets for life cycle management and problem identification in real time',
			autoassignDescription: 'Assign tasks to your agents, partners or work teams in an automated way',
			resourceAllocationDescription: 'Distribute and assign your agents in the most optimal way to reduce costs',
			isochronousDescription:
				'Create geo-fences to ensure the level of service or coverage you need to provide to your customers',
			skillsDescription: 'Manage the skills of your resources to execute the corresponding types of tasks ',
			apiDescription: 'Connect all your systems with Fielder ',
			routeOptimizationDescription:
				'Generate the most optimal routes for your agents based on important variables that impact the main indicators in your business',
			expensesDescription: 'Configure your route expenses parameters',
			isochroneDescription: 'Define your coverage ranges and create',
			functionalityConfirm: 'Do you want to acquire this functionality?',
			functionalityRequestDescription:
				'This functionality has an extra amount, therefore, we will contact you to validate your order.',
			smsDescription: 'Configure your SMS´s according to the rules that you require in your business.',
			sms: 'SMS',
			// Route Preferences
			routePreferences: 'Route Preferences',
			availableTasks: 'Available Tasks',
			expireOn: 'Expires on',

			// Route Optimization
			optimizeRoute: 'Optimize route',
			routeOptimization: 'Route Optimization',
			commandCenterDescription:
				'Check variables of interest in real time to take immediate decisions and optimize the execution of your operation based on your daily objectives.',

			noAgentLocation: 'No agent location',
			expenses: 'Expenses',
			addExpenses: 'Add expenses',
			enableExpensesRoutesInfo: 'Apply expenses to the route',
			expensesAverage: 'Average expenses per ROUTE',
			expensesFood: 'Food',
			expensesSleeping: 'Lodging',
			expensesKm: 'Percentage to add to the estimated cost of mileage (between 1 and 100)',
			// Api
			api: 'Api',
			webHook: 'Web Hook',
			apiUsers: 'Api Users',
			addApiUser: 'Create API Token',
			editApiUser: 'Edit API Token',
			// Forms
			createForm: 'Create Form',
			finishedForms: 'Completed Forms',

			forms: 'Forms',
			pdfConverterTitle: 'PDF Template Converter',
			pdfConverterDescription: 'Convert and create PDF from XLSX',
			formsImages: 'Forms Images',
			images: 'Images',
			templateName: 'Template name',
			headerTitle: "Header's title",
			addQuestion: 'Add Question',
			allowEditableFormLabel: "Do you want the form to be editable after the client's signature?",
			onlyOneEditableLabel: 'You can only have one signature with this option selected',
			option: 'Option',
			checkbox: 'Checkbox',
			multipleChoice: 'Multiple Choice',
			radioButton: 'Radio button',
			radius: 'Radius (meters)',
			textFieldQuestion: 'Text Field',
			numericRating: 'Numeric Rating',
			binaryRating: 'Binary Rating',
			starsRating: 'Stars Rating',
			eSignature: 'Electronic Signature',
			photograph: 'Photograph',
			qrCode: 'QR Code',
			barcode: 'Bar code',
			dateHour: 'Date and Hour',
			textFile: 'Text File',
			catalogue: 'Catalogue',

			footer: 'Footer',
			noFormsFound: 'No Forms were found in your account',
			detailForm: 'Form Details',
			editForm: 'Edit Form',
			uploadTemplateForm: 'Upload template',
			templateHistory: 'Template history',
			assistedTemplateText:"Select a pdf format file so that the Fielder assistant can create your form. Fielder's assistant can help you with documents of no more than 5MB.",
			uploadTemplateFormSuccess: 'Template uploaded successfully',
			deleteForm: 'Delete Form',
			deleteConfirmationForm: 'Are you sure you want to delete the form?',
			selectOneFormDelete: 'Select at least one form to delete',
			startRange: 'Initial number ',
			endRange: 'Final number',
			characterNumber: 'Maximum number of characters',
			chooseDateFormat: 'Choose date format',
			dateFormatDesc:
				'DD: Day of the Month, MM: Month number, MMMM: Month Name, YY: 2 digit Year, YYYY: 4 digit Year, HH: hours, mm: minutes',
			allowAgentEdit: 'Allow Agent to edit the field',
			chars150: '150 characters',
			chars200: '200 characters',
			chars250: '250 characters',
			questions: 'Questions',
			messagMoreTasksSelected: 'You selected more than one task',

			messageYouCanOnlyDuplicateOwnTasks:'Only tasks that have been created in your account can be duplicated',
			messageDuplicateTasksStatusError: 'Only completed and paused tasks can be duplicated',
			messageDuplicateTasksBranchClientError: 'You can only duplicate tasks from the same branch or client',
			messagParentTasksError: 'Selected task has no parent task',
			messagDuplicateTasksLimitError: 'Maximum number of tasks to duplicate is 50',
			messagNoGroup: 'This task is not in a Task Group',
			messageThisTaskIsNotInATaskGroup:"This task is not in a Task Group",
			date: 'Date',
			updateDate:'Date for last update',
			formClientNameLabel: {
				client: 'Client´s name',
				branch: 'Branch´s name',
			},
			formIdCustomLabel: {
				client: 'Id of the Client',
				branch: 'Id custom of the Branch',
			},
			stars: 'Star (s)',
			selectedForms: 'Selected Forms',
			autoSelectedFormsAdvice: 'We have automatically selected forms according to your task needs',
			assignForms: 'Assign Forms',
			formList: 'Form List',
			currentlyAssignedForms: 'Currently assigned forms',
			restoreFormsToDefault: 'Restore Forms to Default',
			formLimitReached: 'Forms limit reached',
			formTableWasNotCreated: "Couldn't assign the form because the module is inactive",
			enableGalleryPhotoForms: 'Allow the agent to upload photos from the gallery',
			enableMetadata: 'Show geolocation of the photo in the report',
			hideFieldToAgent: 'Hide for Agent',
			doNotAllowAgentToEdit: 'Do not allow agent to edit',
			
			restrictValue:'Restrict value',
			alphanumericValue: 'Alphanumeric value',
			numericValue: 'Numeric value',
			stringValue: 'Text string value',

			formsTemplateSaved: 'Forms Template saved successfully',
			chooseFile: 'Choose file',
			selectOrDragAndDrop: 'Drag and drop some file here, or click to select File',
			clickToSelectFile: 'Click to select File',
			selectImageOrDragAndDrop: 'Drag and drop some image here, or click to select image',
			clickToSelectImage: 'Click to select Image',
			uploadXlsxOrHtmlTemplate: 'Upload a template with .xlsx or .html extension',
			formTemplateHistory: 'Form template history',
			createFormTemplateAssisted:'Assisted Form',
			questionNotation: 'Notation for questions',
			assetInventoryQuestionInfo: `For Custom Asset/Inventory Fields add F before the id, for Agent questions add A (Example: Field id 1 => {{F1}} Agent question id 3 => {{A3}})`,
			taskInfoNotation: 'Task info notation',
			taskAddress: 'Task Address',
			taskDate: 'Task Date',
			taskBranchId: 'Branch Id',
			taskBranchIdCustom: 'Branch Id Custom',
			taskCompanyId: 'Company Id',
			taskCompanyName: 'Company Name',
			onlyXlsxTemplate: 'Only for the .xlsx template',
			optionNumber: 'Option Number',
			allLines: 'All lines',
			widthInPixels: 'Width change in px',
			heightInPixels: 'Height change in px',
			linesPerCell: 'Lines per cell',
			notAllFormsDeleted: "Couldn't delete all forms",
			deleteInactiveForms: 'You can only delete inactive forms',
			deleteFormImageConfirmation: 'Are you sure you like to delete the image? Once done it cannot be restored',
			deleteFormSignaturesConfirmation: 'Confirm delete Signatures',
			deleteFormSignaturesExtraMessage:'Good practices suggest removing both signatures after any modification made',
			january: 'January',
			february: 'February',
			march: 'March',
			april: 'April',
			may: 'May',
			june: 'June',
			july: 'July',
			august: 'August',
			september: 'September',
			october: 'October',
			november: 'November',
			december: 'December',
			noFileFound: 'No file found',
			viewFileContent: 'View file content',
			reactivateForm: 'Reactivate Form',
			reactivateFormWarning: 'If you reactivate the form, all the Electronic Signatures will be removed',
			removeForm: 'Remove Form',
			remove: 'Remove',
			removeFormWarning: 'If you remove the form from the task, all the information will be lost',
			duplicate: 'Duplicate',
			duplicateTaskWithUpdates: 'Include changes',
			duplicateTaskAddAutomatedForms: 'Include auto-assigned forms',
			showQuestionToPartner: 'Show field to partners',
			hideQuestionToPartner: 'Hide field to partners',
			partnersInheritableForm: 'Allow Form to be inherited to partners',
			partnersEditableForm: 'Allow Partners to edit the form',
			partnerSettings: 'Partner Settings',
			formsReport: 'Forms Report',
			none:'None',
			generateFormsReport: 'Generate Forms Report',
			generateFormsReportDescription: 'Select one or more forms and their questions to include them in the report',
			selectMoreThanOneForm: 'Add more than one form to complete the request',
			tryAgainLater: 'Try Again Later',
			noTaskHasForms: 'No task found with your selected forms',
			noTaskHasInventories: 'No tasks were found with the selected inventories',
			noTaskAssets: 'No tasks were found with the selected assets',
			noTaskSelected: 'No tasks in selected time period',
			hideToClient: 'Hide to Client',
			hideToClientInfo: 'Hide to final Client profile',
			allowUpdatesViaWebhook:'Allow updates via webhook (optional)',
			showToClient: 'Show to Client',
			enableClientEditing: 'Enable Client Editing',
			reasonForNotApproved:'Reason for non-approval',
			approveQuestion: 'Approve Question',
			approve: 'Approve',
			approveAllQuestions: 'Approve All Questions',
			disapproveAllQuestions: 'Disapprove All Questions',
			viewForm: 'View the selected form',
			selected: 'Selected',
			addOption: 'Add Option',
			optionTitle: 'Option Title',
			idToMapXlsx: 'This id is used to map the question in an xlsx file when exporting the form',
			includeValueColumn: 'Include the value column',
			valueColumnInfo: 'This column is to give a specific value to the option',
			includeCommentColumn: 'Include the comment column',
			commentColumnInfo: 'This column is to add any extra comments to the option',
			value: 'Value',
			hasNotApprovedForms: 'Form(s) not approved',
			hasNotApprovedForm: 'Form not approved',
			AllFormsFinished: 'All Forms Finished',
			inReviewForms: 'Form(s) waiting for review',
			inReviewForm: 'Form waiting for review',
			chooseFormOrigin: 'Choose form origin',
			source: 'Source',
			webhook: 'Webhook URL',
			apiToken: 'API Token',
			updateByHook:'Update by Webhook',
			selectPartnerFormOrigin: 'Select Partner form source',
			partnerSpecificOptions: 'Partner specific options',
			integratedPartnerInfoViewWarning:
				'The Partner selected was integrated by this account, therefore the information shown is what you allowed the partner to see from your account',
			enableFormReview: 'Enable Form Review',
			runApprovalViaApi: 'Run review via api',
			formReviewableApiInfo: 'Approval of forms through the API is done using the webhook',
			hideClientSwitchInfo: 'By activating the switch this type of task will not be shown in the final client profile',
			hideClientSwitchInfoPriority: 'By activating the switch this priority will not be shown in the final client profile',
			receiveUpdatesThroughTheWebhook:'By making a request through the API you will be able to receive updates through the webhook',
			taskTypeInfoApplyToAll: 'This type of task will be shown to all companies, branches and final clients',
			taskPriorityApplyToAll: 'This priority will be shown to all companies, branches and final clients',
			taskTypeInfoSpecify:'This type of task will be shown only to the specified companies, branches and final clients',
			taskPrioirtyInfoSpecify: 'This priority will be shown only to the specified companies, branches and final clients',
			userApiApplyToAll: 'This user will not be given a webhook url and will receive updates at the default url',
			userApiInfoSpecify:'This user will only receive updates related to their company and at the defined webhook.',
			webhookInfoSpecify:'URL through which updates made within the platform arrive',
			formReviewableInfo: "Allow the form's questions to be reviewable before finishing it",
			formDownloadInfo:'The forms will be downloaded (in any status) in Fielder PDF and Excel format.',
			formApprovedDownloadInfo:'The PDFs that will be downloaded correspond to completed Forms only or, if applicable, those that have gone through an approval process',
			improveDownloadExperienceText:'To improve your experience, we recommend downloading using filters and short time periods. For large download volumes we recommend using the Fielder API.',
			selectPeriodOfTime: 'Select a period of time to export the information.',
			slaInformation:
				'These are the tasks in which the Agent has not arrived at the location in the scheduled time or are paused.',
			noPermission: 'No Permissions',
			partnerCanEdit: 'Partner can edit',
			partnerCanView: 'Partner can view',
			partnerPermissions: 'Partner Permissions',
			reviewable: 'Reviewable',
			numberFields: 'Number of Fields',
			updatedFormsSuccessfully: 'Updated Forms Successfully',
			anyOrigin: 'Any Origin',

			idForm: 'Id Form',
			textForm:'Keyword',
			// Asset Management
			assetList: 'Asset List',
			selectedAssets: 'Selected Assets',
			downloadAssetFileName: 'assetsFielder.csv',
			downloadCSVTemplate: 'Download the importation file .csv template',
			downloadCSVTemplateCategory: 'Download the importation file .csv template for this category',
			model: 'Model',
			serialNumber: 'Serial Number',
			idCategory: 'Id Category',
			parentCategory: 'Parent Category',
			newCategory: 'New Category',
			editCategory: 'Edit Category',
			defaultFields: 'Default Template Fields',
			selectDefaultFields: 'Select the fields that you want to be required',
			assetManagement: 'Asset Management',
			noAssetsFound: 'No assets were found in the selected category',
			noCategoriesFound: 'No categories were found',
			assetStatus: 'Status',
			active: 'Active',
			activeF: 'Active',
			activeM:'Active',
			dashboardInactive: 'Inactive',
			inactive: 'Inactive',
			addSubCategory: 'Add Subcategory',
			asset: 'Asset',
			assets: 'Assets',
			assetDetail: 'Asset Detail',
			addField: 'Add field',
			addAsset: 'Add Asset',
			editAsset: 'Edit Asset',
			newAsset: 'New Asset',
			disableAsset: 'Disable Asset(s)',
			isTheAssetPacked: 'Is the asset in a specific category?',
			addBarcode: 'Add a bar code',
			productCondition: 'Asset condition',
			goodCondition: 'In Good condition',
			badCondition: 'In bad condition',
			create: 'Create',
			newTemplate: 'New Template',
			editTemplate: 'Edit Template',
			addTemplate: 'Add Template',
			deleteConfirmationAsset: 'Are you sure you want to delete the asset?',
			disableConfirmationAsset: 'Are you sure you want to deactivate the asset?',
			deleteConfirmationCategory: 'Are you sure you want to delete the category?',
			changeStatus: 'Change Status',
			question: 'Question',
			singleAnswerLabel: 'This question may only be answered once',
			agentQuestionLabel: 'Agent Fields',
			selectAtLeastOne: 'Select at least one asset to perform the operation',
			good: 'Good',
			bad: 'Bad',
			satisfactory: 'Satisfactory',
			notSatisfactory: 'No Satisfactory',
			downloadImages: 'Download Images',
			assetImportSuccess: 'Imported the asset(s) successfully',
			assetImportError: 'The import was not successful',
			errorAssetMustHaveBranch: "You can't assign an asset to your task because it doesn't have a branch",
			assetCategoryHasSubcategoryOrAsset: 'The category has at least one subcategory or asset',
			assetLogs: 'Asset Logs',
			assetSavedSuccessfully: 'The asset was saved successfully',
			viewAsset: 'View the selected asset',
			createTaskWithAsset: 'Save and create task with asset',
			downloadAssets: 'Download Assets',
			generateAssetReport: 'Generate Asset Report',
			generateAssetReportDescription: 'Select one or more assets and their questions to include them in the report',
			canOnlyAddOrRemoveFieldsNotEdit: 'Can only add or remove fields, editing is disabled',

			// Inventory
			deleteConfirmationInventory: 'Are you sure you want to delete the inventory?',
			downloadInventoryFileName: 'inventoriesFielder.csv',
			noInventoryFound: 'No inventory was found in the selected category',
			addInventory: 'Add Inventory',
			newInventory: 'New Inventory',
			editInventory: 'Edit Inventory',
			inventoryLogs: 'Inventory Log',
			inventory: 'Inventory',
			inventories: 'Inventories',
			inventoriesAssociated:'Inventories associated with the asset',
			linkWithAsset: 'Link Inventory with an Asset',
			selectedInventories: 'Selected Inventories',
			inventoryCategoryHasSubcategoryOrInventory: 'The category has at least one subcategory or inventory',
			inventorySavedSuccessfully: 'The inventory was saved successfully',
			viewInventory: 'View the selected inventory',
			createTaskWithInventory: 'Create task with Inventory',
			downloadInventories: 'Download Inventories',
			generateInventoryReport: 'Generate Inventory Report',
			generateInventoryReportDescription:
				'Select one or more inventories and their questions to include them in the report',
			inventoryImportSuccess: 'Imported the inventories successfully',

			// AssetInventory
			customStatus: 'Custom Status',
			manageCustomStatus: 'Manage custom status',
			assetAssignedToTaskStatus: 'Asset status when assigned to a task',
			inventoryAssignedToTaskStatus: 'Inventory status when assigned to a task',
			noStatus: 'No Status',
			toRemoveTheElement: 'To remove the element, select it again',
			dontChange: "Don't change",
			selectStatus: 'Select Status',
			statusChangeWhenAssetInTask:
				'Asset Status will change automatically to the selected option when it is attached to a task',
			statusChangeWhenInventoryInTask:
				'Inventory Status will change automatically to the selected option when it is attached to a task',
			statusDisabledForTasks: 'Disabled status for tasks',
			assetsWithSelectedStatusDisabledFromTasks: "The assets with the selected status won't be able to add to tasks",
			inventoryWithSelectedStatusDisabledFromTasks: "The assets with the selected status won't be able to add to tasks",
			optionToSelect: 'Option to select',
			optionsToSelect: 'Options to select (separated by "&" )',
			optionsCatalogue: 'Options to select (id##value##comment separated by &)',
			numberInsideRatingValue: 'Numeric value inside the range set in the category',
			positiveOrNegative: 'Insert 1 for positive option and 0 for negative option',
			starsRatingRange: 'Rating from 0 to 5',
			notAvailableForUpdate: 'Not available to update',
			notAvailableForImport: 'Not available while importing',
			insertBarcode: 'Insert the bar code',
			insertDateInFormat: 'Insert date in format DD/MM/YYYY or DD/MM/YYYY HH:MM',
			insertDateInFormFormat: 'Insert the date in the format indicated in the csv header',
			updateFormInfo: 'Information to update form questions',
			importingInfo: 'Information for the importing process',
			incorrectCsvFormatAssets: 'Could not import the assets: Verify your csv to be formatted correctly and not empty',
			incorrectCsvFormatInventory:
				'Could not import the inventories: Verify your csv to be formatted correctly and not empty',
			couldNotImportFollowingAssets: 'Could not import the following assets',
			couldNotImportFollowingInventories: 'Could not import the following inventories',
			showingLatestLogInfo: 'Showing latest log information',
			logAction: ['Updated', 'Agent updated', 'Admin updated', 'Asset assigned to task'],
			onlyShowingTheLast10Logs: 'Only showing the last 10 logs',
			thereIsAnUnfinishedService:"There is an unfinished service",

			// auto assign
			geolocationLevel: 'Type of level',
			serviceLevel: 'Service Level',
      serviceLevelPersonalized: 'Service Level Personalized',
			autoassignOrigin: 'Self-allocation origin',
			autoassignType: 'Self-allocation type',
			isochroneClientErrorMessage:
				"You can't create a new template with repeated isochronous and origin from other template",
			deleteTemplateConfirmation: 'Are you sure you want to delete the selected template(s)?',
			deleteTemplateTooltip: 'Delete Template(s)',
			createTemplate: 'Create Template',
			saveTemplate: 'Save Template',
			noTemplatesFound: 'No templates found',
			autoassign: 'Self-allocate',
			autoassignation: 'Self-allocation',
			skills: 'Skills',
			skillsList: 'List of Skills',
			createSkills: 'Create new skills',
			responseTimePriority: 'Response time by priority',
			agentSkills: 'Agent´s skills',
			addedRepeatedSkill: 'This skill already exists',
			skillsToAdd: 'Enter a new skill',
			deleteConfirmationSkill: 'Are you sure you want to delete the skill?',
			unexpectedErrorOcurred: 'An unexpected error occurred. Please try again',
			createUserError: 'The user could not be created, please verify that the is correct and try again. (Remember that the limit for the first and last name is 150 characters)',
			createTeamError: 'The team could not be created, please try again',
			createCompanyError: 'The company could not be created, please try again',
			createBranchError: 'The branch could not be created, please try again',
			addAutoassignTemplate: 'Add self-allocation template',
			viewAutoassignTemplate: 'View self-allocation template',
			templates: 'Templates',
			likeToAddPartner: 'Do you want to assign the task to a partner?',
			autoassignTo: 'Self-assign to: ',
			createAutoassignTemplate: 'Create Self-assignment template',
			editAutoassignTemplate: 'Edit Template for Self-allocation',
			byPriority: 'Pin color by priority',
			byStatus: 'Pin color by status',
      personalizedPriorities: 'Personalized Priorities',
			priority: 'Priority',
			idPriority: 'Id Priority',
			lowPriority: 'Low',
			medPriority: 'Medium',
			highPriority: 'High',
			coverage: 'Coverage',
			viewIsochronous: 'View Isochornous',
			viewSkills: 'View Skills',
			applyToAll: 'Apply to all',
			apply: 'Apply',
			addFilter: ' + Add filter',
			selectSpecificAgents: 'Select specific agents',
			selectAgents: 'Select agents',
			selectSpecificTeams: 'Select specific teams',
			selectTeams: 'Select teams',
			selectSpecificPartners: 'Select specific partners',
			selectPartners: 'Select partners',
			resources: 'Resources',
			selectTaskTypes: 'Select task type',
			responseTime: 'Response Time',
			immediateResponse: 'Immediate Response',
			successCounter: 'Times used successfully',
			useCounter: 'Times used',
			infoAutoassignType: 'Depending on what you want to self-assign ',
			autoassignDetail: 'Self-allocation Detail',
			failureReason: 'Failure reason',
			selectResourcesToAutoassign:
				'Select the resources you want to self-allocate to the tasks. Arrastre la tarjeta de tipo de recurso para determinar la prioridad durante el proceso de auto-asignación',
			dragAndDropForAutoassignPriority: 'Drag and Drop the active resources to assign a priority order',
			autoassignTemplateSaved: 'Self-allocation template saved successfully',
			templateDeletedSuccessfully: 'Template(s) deleted successfully',
			configureResponseTimePerPriority:
				'Configure the response time so that the agent is at the task location prior to the start of the task according to the priority',
			configureResponseTimePerPriorityInformation:
				'Margin time for the agent to arrive to the task location before the actual start time',
			color: 'Color',
			updatedTemplatesSuccessfully: 'Updated Templates Successfully',
			successRate: 'Success Rate',
			assignBySkills: 'Assign by Skills',
			errorCouldntRetrieveTemplate: 'Could not retrieve the selected template at this moment. Try again later',
			errorFieldNotEmpty: 'This field must not be empty',
			errorMustSelectAtLeastOneOption: 'You must select at least one option',
			errorMustHaveAtLeastOneActiveResource: 'You must activate at least one Resource',
			errorMustHaveAtLeastOneActivePriority: 'You must activate at least one Priority',
			templateSavedSuccessfully: 'The template was saved successfully',

			unselectAll: 'Uncheck all',
			// Status
			executingAutoassign: 'Executing',
			successful: 'Successful',
			unsuccessful: 'Unsuccessful',
			waiting: 'Waiting',
			unknown: 'Unknown',

			noAgentsAvailable: 'No Agents Available were found to execute the task',
			noAgentsWithSkills: 'No Agents were found with the required skills to execute the task',
			availableAgentsInMultipleTeams: 'The available Agents were found on multiple teams',
			agentInMultipleTeams: 'The Agents available are part of multiple teams',
			retrying: 'Retrying',

			dashboardPausedTasks: 'Dashboard Paused Tasks',
			dashboardRejectedTasks: 'Dashboard Rejected Tasks',

			// Task Types - Task Personalization
			taskPersonalization: 'Task Customization',
			rejectedTaskPersonalization: 'Customization of Rejected Tasks',
			pausedTaskPersonalization: 'Customization of Paused Tasks',
			prioritiesPersonalization:'Customizing priorities',

			addRejectedTask: 'Add reason for Rejected Tasks',
			addPausedTask: 'Add reason for Paused Tasks',
			addPriority:'Add Priority',
			addTaskType: 'Add Task Type',
			reasonName: 'Reason name',
			priorityName:'Priority name',
			addReasonRejectTaskTitle: 'Add reason for rejected tasks',
			editReasonRejectTaskTitle: 'Edit reject reason',
			addReasonPauseTaskTitle: 'Add reason for paused tasks',
			editReasonPauseTaskTitle: 'Edit paused task reason',
			addPriorityTaskTitle: 'Add priority',
			editPriorityTaskTitle: 'Edit priority',

			hideToAgent: 'Do not show this type of task to the agent',
			showToAgent: 'Do show this type of task to the agent',
			showAgentType: 'Show to agent',
			specifyCompany: 'Specify the company',
			specifyBranch: 'Specify the branch',
			specifyClient: 'Specify the client',
			editTaskType: 'Edit task type',
			taskTypeName: 'Task type name',
			specificTime: 'Specify the execution time',
			specifyWebhookUrl: 'Enter your endpoint/url for the webhook (optional)',
			apiRequestAllowed: "API requests allowed to the user:",
			price: 'Price',
			selectDependency: 'Select dependency',
			taskPauseDependency: 'Task Pause Dependency',
			dependency: 'Dependency',
			pausedByFinalClient: 'Paused by Final Client',
			pausedByAgent: 'Paused by Agent',
			rejectedByFinalClient: 'Rejected by Final Client',
			rejectedByAgent: 'Rejected by Agent',
			selectParent: 'Select parent',
			taskTypeConstraintDesc: 'Apply only to (optional)',
			userApiInfoConstraintDesc: 'Select option:',
			selectTaskTypeId: 'Select Task Type Id',
			taskVariableNumber: 'Number',
			taskVariableTime: 'Time',
			taskTypesNotDeleted: "Couldn't delete the following task Types: ",
			taskTypesCantBeDeleted: 'Cannot be deleted',
			taskTypesDeletedWithChildren: 'Has at least one subtype',
			taskTypesDeletedWithTasks: 'Some task was created with this task type',
			taskTypesDeletedSuccess: 'The Following task types were deleted successfully ',
			taskTypeAddSuccess: 'It has been saved successfully',
			taskTypeInvalidRouteVariable: "Only one variable of type 'Numeric' can be selected",
			infoTaskTypeRouteVariable:
				'Set variables by task type for best results when generating routes with Resource Allocation or Dynamic Allocation',
			infoTaskTypeRouteVariableType:
				'Time: will be added to the total execution time of the Task; Numeric: will be multiplaced by the Task execution time',
			enableSearch: 'Enable Search',

			globalSearchMessage:
				'Results from 3 months before and after the current search date are displayed. We recommend you use the advanced filters of My Operation for a more precise search.',
			// support
			messageCreated: 'The ticket has been created',
			sendTicket: 'Send',
			messageFormatFiles: 'Please be sure that the files are in:  .jpg, .png, .gif, .jpeg, .csv, .xls or .pdf',
			messageNumberFiles: 'Sorry, you can´t upload more than 5 files',
			titleSupport: 'Support',
			category: 'Category',
			webPlatform: 'Web Platform',
			movilApp: 'Mobile App',
			mailsSMS: 'Mails / SMS',
			subscription: 'Subscription',
			training: 'Training',
			other: 'Other',
			updateFileMessage: 'Remember that when updating the link or file it is overwritten, do you want to continue?',
			updateTemplateMessagePdf:
				'When updating the file, the latter will replace the approved Template pdf. Do you want to update?',
			externalLink: 'External link',
			modifyStatus: 'Modify status',

			deleteFileMessage: 'Do you want to delete the task file?',
			deleteLinkMessage: 'Do you want to remove the link linked to the task?',

			externalStorage: 'Go to external storage',
			updateLink: 'Update link',
			uploadLink: 'Upload link',
			linkDocument: 'Document link',
			fileName: 'File name',
			link: 'Link',
			uploadFile: 'Upload File',
			uploadFiles: 'Upload Files',
			updateFile: 'Update file',
			pressEnterToSearch:'Press enter to search',

			geolocationDesciptionType0:
				'This configuration allows the geolocation (latitude and longitude) of agents only at the "start" and ”finish" stages of their activities. Agents will be able to receive tasks in the Fielder Agent application, without collecting information as location, routes, ETA, among other variables. This configuration is recommended in non-dynamic operations, where there are dedicated resources, on site, in pre-determined locations, and it is only required to collaborate in real time with the agent and collect evidence of the execution of the assigned tasks.',
			geolocationDesciptionType1:
				'This configuration allows the geolocation (latitude and longitude) of the agents, balancing the performance of the agent’s mobile battery and the data plan. The Fielder Agent app obtains location information between every 30 seconds (approx. when there is an agent displacement) and every 5 minutes (approx. when there is no displacement). On devices running Android 9.0 or lower, the Fielder Agent app in the foreground and background will have the mentioned behavior. On Android versions from 10.0 onwards, you get the location only in the foreground. The variables can be modified when the battery level is low on the mobile. This configuration is recommended when the operation is dynamic, there are dispersed agents attending to different tasks in different locations, and it is required to collaborate in real time with the agent and collect information on locations, routes, ETA and evidence of the execution of assigned tasks.',
			geolocationDesciptionType2:
				"This configuration allows the geolocation (latitude and longitude) of the agents with high consumption between the agent's mobile battery and the data plan. The Fielder Agent app obtains location information between  15 seconds (approx. when there is an agent displacement) and every 2 and a half minutes (approx. when there is no displacement). On devices running Android 9.0 or lower, the Fielder Agent app in the foreground and background will have the mentioned behavior. In versions of Android from 10.0 onwards, only the location is obtained in the foreground, and in the background as long as the Agent grants the corresponding permission in the application. The variables can be modified when the battery level is low on the mobile. This configuration is recommended when the operation is extremely dynamic and with a high demand for tasks, there are dispersed agents attending  different tasks in different locations, and it is required to collaborate in real time with the agent and end users to provide an 'On-Demand' experience.",

			requiredTaskType: 'Please change the task type other than "By mail" to continue',
			requiredTeam: 'Please select a team to continue',

			createdTaskBy: 'Task created by',
			DO_ACTION_UNKNOWN: ' ',
			DO_ACTION_CREATE_GROUP: 'Task group created',
			DO_ACTION_SET_ON_WAY: 'Task group on the way',
			DO_ACTION_ARRIVE: 'Task group arrived',
			DO_ACTION_STARTED: 'Task group started',
			DO_ACTION_PAUSE: 'Task group paused',
			DO_ACTION_FINISH: 'Task group finished',
			DO_ACTION_CANCELED: 'Task group canceled',
			DO_ACTION_TRASH_GROUP: 'Task group disabled',
			DO_ACTION_DELETE_GROUP: 'Task group deleted ',
			DO_ACTION_CANCEL_TRASH_GROUP: 'Task group canceled',
			DO_ACTION_ASSIGN_AGENT: 'Assigned agent',
			DO_ACTION_RESCHEDULE: 'Rescheduled task group',
			DO_ACTION_UPDATE_ADDRESS: 'Change of address',
			DO_ACTION_UPDATE: 'Task group updated',
			DO_ACTION_ASSIGN_TASK: 'Assigned task',
			DO_ACTION_TRASH_GROUP_TASK: 'Task disabled',
			DO_ACTION_UNLINK_TASK_FROM_GROUP: 'Task unlinked from the group',
			DO_ACTION_UPDATE_BRANCH:'Updated branch',

			DO_ACTION_CREATE_TASK: 'Task Created',
			DO_ACTION_UPDATE_TASK: 'Task Updated',
			DO_ACTION_UPDATE_ADDRESS_TASK: 'Task Location Updated',
			DO_ACTION_RESCHEDULE_TASK: 'Rescheduled Task',
			DO_ACTION_ASSIGN_AGENT_TASK: 'Agent Assigned',
			DO_ACTION_ASSIGN_TEAM_TASK: 'Team Assigned',
			DO_ACTION_ASSIGN_PARTNER_TASK: 'Assigned to partner',
			DO_ACTION_CANCEL_TASK: 'Task Cancelled',
			DO_ACTION_CANCEL_PARTNER_TASK: 'Canceled by partner',
			DO_ACTION_RATE_TASK: 'Task rated',
			DO_ACTION_RESTART_TASK: 'Restarted Task',
			DO_ACTION_TRASH_TASK: 'Disabled Task',
			DO_ACTION_DELETE_TASK: 'Removed Task',
			DO_ACTION_CANCEL_TRASH_TASK: 'Restored Task',
			DO_START_TASK_AGENT: 'Agent  started the Task',
			DO_ARRIVE_TASK_AGENT: 'Agent arrived',
			DO_ON_WAY_TASK_AGENT: 'Agent on the way',
			DO_REJECT_TASK_AGENT: 'Agent rejected task',
			DO_FINISH_TASK_AGENT: 'Agent finished task',
			DO_CANCEL_TASK_AGENT: 'Agent cancelled task',
			DO_ACCEPT_TASK_AGENT: 'Agent accepted task',
			DO_ADD_COMMENT: 'Agent  created comment',
			DO_ACTION_ASSIGN_GROUP: 'Assigned Group',
			DO_ACTION_REFUSE_GROUP:'Task unlinked from the group',
			DO_ACTION_ASSIGN_FORM: 'Form assigned to Task',
			DO_ACTION_ASSIGN_ASSET: 'Asset assigned to Task',
			DO_ACTION_ASSIGN_INVENTORY: 'Inventory assigned to Task',
			DO_ACTION_PAUSED_TASK: 'Paused task',
			DO_ACTION_AUTOASSIGNING_STARTED: 'Self-assignment started',
			DO_ACTION_AUTOASSIGNING_FAILED: 'Self-assignment failed',
			DO_ACTION_UNLINK_TASK: 'Task unlinked from the route',
			DO_ACTION_RESET_EXECUTION_TASK: 'Execution restarted',
			DO_ACTION_RESET_CANCELED_TASK: 'Task reset',
			ADD_TASK_TO_ROUTE: 'Task added to route',
			DO_ACTION_REMOVE_TASK_FROM_ROUTE: 'Task removed from route',
			DO_ACTION_ADD_TASK_TO_ROUTE: 'Task added to route',
			DO_ACTION_UPDATED_FORM: 'Saved Form',
			DO_ACTION_UPDATED_ASSET: 'Asset was modified',
			DO_ACTION_UPDATED_INVENTORY: 'Inventory was modified',
			DO_TAKE_TASK_ON_GROUP:'Task taken by agent',

			DO_ACTION_UPLOAD_TASK_DOCUMENT: 'Document attached to task',
			DO_ACTION_DELETE_TASK_DOCUMENT: 'Document removed from task',
			DO_ACTION_UPDATE_TASK_DOCUMENT: 'Updated task document',

			DO_ACTION_UPLOAD_TASK_LINK_DOCUMENT: 'Link attached to the task',
			DO_ACTION_DELETE_TASK_LINK__DOCUMENT: 'Link removed from task',
			DO_ACTION_UPDATE_TASK_LINK__DOCUMENT: 'Updated task link',

			DO_ACTION_FINISH_FORM:'Finished Form',
			DO_ACTION_REVIEW_FORM: 'Form sent for review',
			DO_ACTION_APPROVED_FORM: 'Form approved',

			DO_ACTION_UPLOAD_TASK_PDF_FORM: 'PDF form approved',
			DO_ACTION_UPDATE_TASK_PDF_FORM: 'Updated approved form PDF',
			DO_ACTION_ADMIN_MODIFY_TASK: 'Administrator modified task status',
			DO_ACTION_MANAGER_MODIFY_TASK: 'Manager modified task status',
			DO_ACTION_SEND_FORM_BY_MAIL:'Agent sent forms by mail',

			CREATED: 'Created',
			ON_WAY: 'Agent on way',
			ARRIVE: 'Agent Arrive',
			EXECUTING: 'Executing',
			PAUSE: 'Paused',
			FINISH: 'Finished',
			CANCELED: 'Cancelled',
			ON_TRASH: 'Disabled',

			finish: 'Finish',
			continue: 'Continue',
			back: 'Back',
			documentation:'Documentation',
			businessIntelligence:"BI",

			taskType: 'Task Type',
			tasktypeMinus1: 'Remote',
			tasktipeMail: 'By mail',
			tasktype0: 'Volume',
			tasktype1: 'Pick up',
			tasktype2: 'Workforce',
			tasktype3: 'Delivery',
			tasktype4: 'Group Task',
			volume: 'Volume',
			remote: 'Remote',
			pickup: 'Pick up',
			workforce: 'Workforce',
			delivery: 'Delivery',
			grouptask: 'Group Task',
			tasktupeSimple: 'In a Group',
			tasktypeAll: 'All of them',
			unassigned: 'Unassigned',
			days: 'days',
			hours: 'hours',
			minutes: 'minutes',
			seconds: 'seconds',
			hour: 'hour',
			minute: 'minute',
			second: 'second',
			reorderByModifyStatus: 'Organize by status modification',
			reorderBySignature:'Organize by missing signature',
			activity_type_0: 'Route created',
			activity_type_1: 'Agent assigned',
			activity_type_2: 'Route accepted',
			activity_type_3: 'Route executed',
			activity_type_4: 'Route canceled',
			activity_type_5: 'Route finished',
			activity_type_6: 'Route rejected',
			activity_type_7: 'Re-organized route',
			activity_type_8: 'Incidence created',
			activity_type_9: 'Route rescheduled',
			activity_type_10: 'Task removed',
			activity_type_11: 'Task added',
			label_position_add_task: 'Task position',
			// TASK INFO
			numTask: 'Task number',
			disabled: 'disabled',

			deleteCompanies: 'Delete company(ies)',
			deactivateCompanies: 'Disable company(ies)',
			enableCompanies: 'Enable company(ies)',

			deleteBranches: 'Delete branch(es)',
			disabledBranches: 'Disable branch(es)',
			enableBranches: 'Enable branch(es)',

			enableTask: 'Enable task(s)',
			duplicateTask: 'Duplicate task',

			deleteTeams: 'Delete team(s)',
			deactivateTeams: 'Disable team(s)',
			enableTeams: 'Enable team(s)',

			assistedFormError: 'PDF format not suitable, please try again.',
			assistedFormSuccess:'The Document has been digitized. Our artificial intelligence has created for you a form and Excel template with the suggested question structure. Please review this structure and mapping in the template. You can adjust with the different types of questions that Fielder offers you according to your specific needs.',

			cancelPartnership: 'Cancel partnership',
			deleteClients: 'Delete client(s)',
			deactivateClients: 'Disable client(s)',
			enableClients: 'Enable client(s)',

			deactivateAdministrators: 'Disable admin(s)',
			deleteAdministrators: 'Delete administrator(s)',
			enableAdministrators: 'Enable administrator(s)',

			deleteManagers: 'Delete manager(s)',
			enableManagers: 'Enable manager(s)',
			deactivateManagers: 'Disable manager(s)',

			news: 'News',
			functionalitiesImprovement: 'Functional improvements',
			newFeatures: 'New features',
			share: 'Share',
			myProfile: 'My profile',
			changePassword: 'Change Password',
			support: 'Support',
			logOut: 'Log out',

			showOnlyDeleted: 'Show disabled',
			showOnlyDisabled: 'Show disabled',

			showTaskByMail: 'Show tasks created by mail',

			questionDeactivateCompany: 'Are you sure you want to disable the company(ies)?',
			questionDeletedCompanies: 'Are you sure you want to delete the company(ies)?',
			questionRestoreCompanies: 'Are you sure you want to restore the company(ies)?',

			questionDeactivateBranches: 'Are you sure you want to disable the branch(es)?',
			questionRestoreBranches: 'Are you sure you want to restore the branch(es)?',
			questionDeletedBranches: '¿Are you sure you want to delete the branch(es)',

			questionDeactivateClients: 'Are you sure you want to disable the client(s)?',
			questionDeletedClients: 'Are you sure you want to delete the client(s)?',
			questionRestoreClients: 'Are you sure you want to restore the client(s)?',

			questionDeactivateAdministrator: 'Are you sure you want to disable the administrator(s)?',
			questionDeletedAdministrator: 'Are you sure you want to delete the administrator(s)?',
			questionRestoreAdministrator: 'Are you sure you want to restore the administrator(s)?',

			questionDeactivateManager: 'Are you sure you want to disable the manager(s)?',
			questionDeletedManager: 'Are you sure you want to delete the manager(s)?',
			questionRestoreManager: 'Are you sure you want to restore the manager(s)?',

			questionAcceptInvitations: 'Are you sure you want to accept the invitation(s)?',
			questionRefuseInvitations: 'Are you sure you want to reject the invitation(s)?',

			questionCancelPartnership: 'Are you sure you want to cancel the partnership(s)?',

			questionDeactivateTeams: 'Are you sure you want to disable the team(s)?',
			questionDeletedTeams: 'Are you sure you want to delete the team(s)?',
			questionRestoreTeams: 'Are you sure you want to restore the team(s)?',

			idUser: 'Id',
			idUserHeader:'Client (id Fielder)',
			mobileNumber: 'Mobile phone',
			jobPosition: 'Job position',
			detailCompany: 'Company detail',
			logoCompany: 'Company logo',
			detailClient: 'Client detail',
			detailAdministrator: 'Administrator detail',
			detailManager: 'Manager detail',

			detailBranch: 'Branch detail',
			editCompany: 'Edit company',
			addCompanySubtitle: 'Create a new company into your operations',
			editCompanySubtitle: 'View or update your company information',
			editClient: 'Edit client',
			editAdministrator: 'Edit administrator',
			editManager: 'Edit manager',
			editBranch: 'Edit branch',
			editAgent: 'Edit agent',
			save: 'Save',
			saveFile: 'Save File',
			saveFilter: 'Save Filter',
			removeFilter: 'Remove filter',
			loadFilter: 'Load filter',
			loadedFilter: 'Filter successfully loaded',
			savedFilter: 'Filter successfully saved',
			removedFilter: 'Filter removed successfully',
			saveFiles: 'Save Files',
			dropFiles: 'Drop the files here ...',
			deleteFile: 'Delete File',
			removeTemplateFile: 'Are you sure you want to remove this template file?',
			importFileDetails: 'Import file details',
			toUploadToYourAccount: 'To upload to your account',
			uploadAssetInventoryTemplate: 'Upload a file to use as template when exporting inside task details',
			unsupportedFileType: 'Unsupported file type, please upload a supported type',
			activateAccountEmail: 'Activate email account',
			print: 'Print',
			plus: ' More... ',
			// Configuration
			configIdAccount: 'Id Account',
			accountStatus: 'Status',
			action: 'Action',
			configBusinessName: 'Company Name',
			configPhone: 'Company Phone',
			configAddress: 'Company Address',
			configRFC: 'Company Business Name',
			configUploadLogo: 'Upload Logo',
			configAccount: 'Fielder Account',
			configAccess: 'Permissions',
			mailSettings: 'Mail Settings',

			permissionAgentAllwaysExecute: 'Allow to execute any task regardless the established date and time',
			permissionAgentCreateTasks: 'Allow to create tasks',
			permissionAgentDuplicateTasks: 'Allow to create subtasks',
			permissionAgentChangeTypeTasks: 'Allow agent to change task type',
			permissionAgentRescheduleTasks: 'Allow agent to reschedule tasks',
			permissionAccessClient: 'Allow access to Clients',
			permissionAccessClientImport: 'Allow import tasks',
			permissionAccessClientExport: 'Allow export tasks',
			permissionManagerToCreate: 'Allow Managers to create tasks',
			permissionManagerAccessToAnalytics: 'Allow Managers to access Analytics',
			permissionManagerAccessToRoute: 'Allow Managers access to Route Optimization Functionality',
			permissionManagerAccessToResource: 'Allow Managers access to Resource Allocation Functionality',
			permissionManagerAccessToIsochronous: 'Allow Managers access to Isochronous Functionality',
			permissionManagerAccessToDowloadExcel: 'Allow Managers to Export Tasks',
			permissionManagerAccessToImportExcel: 'Allow Managers to Import Tasks',
			permissionManagerToModifyTaskStatus: 'Allow Managers to modify task status',
			permissionManagerToDownloadFormExcelTaskDetail: 'Allow Managers to download excel form in task detail',
			permissionManagerToEditFormTaskDetail: 'Allow manager to edit form in task detail',
			configAllowManagerToSeeTaskByMail: 'Allow manager to see tasks by mail',
			configAllowManagerToGeneratePdf:'Allow Managers to convert the form to PDF in the task detail',
      		configAllowManagerToApproveForms: 'Allow Managers to approve forms',
			configAllowManagerToGenerateAutoAssignTasks:'Allow Managers to generate the Auto-assign tasks',
			configAllowManagerToGenerateAndDownloadForms:'Allow Managers to generate and download forms reports',
			configAllowManagerToGenerateAndDownloadAssets:'Allow Managers to generate and download assets reports',
			configAllowManagerToGenerateAndDownloadInventories:'Allow Managers to generate and download inventory reports',

			permissionAdminToModifyTaskStatus: 'Allow Administrators to modify task status',
			permissionAdminAccessToAnalytics: 'Allow Administrators to access analytics',
			permissionAdminToViewTaskToCash: 'Allow Administrators to view Task to Cash',
			permissionAdminToReceiveEmailWeeklyAccountSummary: 'Allow Administrators to receive a weekly account summary email',
			size: 'Size',

			modifyTaskStatus: 'Modify task status',

			// AGENTS
			routeNote: 'Remember, tasks executed in offline mode do not show the path made by the agent.',
			addAgent: 'Add new agent',
			inactiveAgents: 'Active agents',
			activeAgents: 'Inactive agents',
			questionDeleteAgents: 'Are you sure you want to delete the Agent(s)?',
			questionDeactivateAgents: 'Are you sure you want to disable the Agent(s)?',
			questionRestoreAgents: 'Are you sure you want to restore the Agent(s)?',
			restoreAgentTooltip: 'Restore Agent(s)',
			deleteAgentTooltip: 'Delete Agent(s)',
			deactivateAgentTooltip: 'Deactivate Agent(s)',
			emailPasswordSuccess: 'Mail sent successfully',
			agentEditedCorrectly: 'Updated correctly!',
			agentAddedCorrectly: 'Added correctly!',
			createAgentPermission: 'User does not have permission to create agents',
			agentDuplicatedUserName: 'Username already exists, choose another',
			congratulate: 'Congratulate',
			congratulationConfirmation: 'Please insert a comment for the agents',
			congratulationMessage: 'Congratulation message',
			congratulations: 'Congratulations',
			congratulationsWereSent: 'The congratulations were sent to the agents',
			assignAgent: 'Assign Agent',

			agentRestoredCorrectly: 'Agent(s) restored correctly!',
			agentDeletedCorrectly: 'Agent(s) deleted correctly!',
			agentDisabledCorrectly: 'Agent(s) disabled correctly!',
			pleaseSelectDateOneMonth:'Please select a date no older than one month',

			createAgentTitle: 'Create Agent',
			maxLimitTaskGroupReached: '* The maximum limit is 30 characters.',
			maxLimitTaskGroupNameReached:'* The maximum limit is 128 characters',
			userNameFieldHelp: '*User name must be at least 3 characters long',
			nameFieldHelp: '*Name must be at least 3 characters long',
			lastNameFieldHelp: '*Last name must be at least 3 characters long',
			emailFieldHelp: '*Enter a valid e-mail',
			validateSameEmail: 'The email is not the same, please enter it again',
			validateCompany:'Please enter a company name',
			createAgentCompanyNameField: 'Company Name',
			phoneFieldHelp: '*The field cannot be left empty',
			createAgentWorkingHoursFieldHelp: '* The Start Time must not be a time before or the same time as the End Time',
			createAgentVehicleTypeField: 'Vehicle type',
			bicycle: 'Bicycle',
			motorcycle: 'Motorcycle',
			car: 'Car',
			bus: 'Bus',
			truck: 'Truck',
			walking: 'Walking',
			deviceName: 'Device name',
			deviceSystemVersion: 'Device system version',
			fielderVersion: 'Fielder version',
			battery: 'Battery',
			licensePlateFieldHelp: '*License Plate must be at least 4 characters long',
			createAgentStartingPointField: 'Starting point',
			update: 'Update',
			createAgentCreateButtonHelp: '*Required fields',
			detailAgentTitle: 'Agent detail',
			geolocations: 'Geolocations',
			getInto: 'Log in',
			signUp: 'Sign up',
			disabledUser: 'Deactivate user',
			activateUser: 'Activate user',
			resendPassword: 'Resend Password',
			recoverPassword: 'Forgot Password',
			recoverPasswordBtn: 'Recover',
			recoverPasswordSuccessText: 'We will send and email with your password.',
			recoverPasswordUserNotFound: 'E-mail not found, please verify your e-mail.',
			errorGralFail: 'An error occured, please try again later',
			errorGroupWasReassigned:'The group was reassigned',
			errorRouteTaskNull:
				'The route could not be rescheduled because one of its tasks has no execution date. Please reschedule it and try again.',
			sameCompanyHint: 'The parent company cannot be the same as the sub company',
			cancelServiceByPartner: 'Services canceled by partner',
			idParentCompany: 'Id Parent Company',
			address: 'Address',
			addressExcel: 'Arrival address to the locality',
			latitude: 'Latitude',
			longitude: 'Longitude',
			attentionTimeFrom: 'Attention time from',
			attentionTimeTo: 'Attention time to',

			workScheduleFrom: 'Working hours from',
			workScheduleTo: 'Working hours to',
			workSchedule: 'Working hours',
			schedule: 'Date/Time',
			linkedToBranch: 'Linked to branch',
			type: 'Type',
			officePhone: 'Company phone Number',
			mobilePhone: 'Mobile phone number',
			administrative: 'Administrative',
			executive: 'Executive',
			technical: 'Technical',
			trade: 'Commercial',

			hourValidation: 'The first date must be less than the second!',
			userNameValidation: 'The username already exist',
			workingHours: 'Working Hours',
			rangeOfAttentionTime: 'Service Hours',
			download: 'Download',
			downloadTask: 'Download tasks',

			// REGISTER
			register: 'Register',
			specify: 'Specify',
			jobDescription: 'What best describes your job title?',
			jobDescriptionOps: ['Business owner', 'Team leader', 'Freelancer', 'Director / Level C / VP'],

			industryQuestion: 'What is your Industry about?',
			industryQuestionOps: [
				'Banking / Finance',
				'Logistic / Transport',
				'Logistic / Parcel',
				'Manufacture / Industrial',
				'Maintenance Services',
				'Distribution of products / services',
				'Foods',
				'Building',
				'Education',
				'Hospitality / Tourism',
				'Health Services',
				'Public services',
				'Textile / Footwear',
				'Transport',
				'Pets',
				'Beauty',
				'Freight and removals',
				'Dry cleaner',
				'Commerce',
				'Other',
			],

			findUsQuestion: 'Where did you find us?',
			findUsQuestionOps: ['Facebook', 'Twitter', 'LinkedIn', 'Google', 'Mail', 'Other'],
			requestTrial: 'Request your trial',

			privacyPolicyTilte: 'Terms of service and Privacy Policy',
			privacyPolicyText1: 'In order to access Fielder Web we need you to accept our Service Terms and Privacy Policy ',
			privacyPolicyCheckbox1: 'I accept and agree with the ',
			privacyPolicyCheckboxLink1: 'Terms of service',
			privacyPolicyText2: ' and ',
			privacyPolicyCheckboxLink2: 'Privacy policy',
			acceptTermsAndConditions: 'Accept the terms and conditions to continue',

			privacyPolicy: 'Privacy Policy',
			termsAndConditions1: 'By creating an account in Fielder you are accepting the ',
			termsAndConditions2: 'Terms of Service',
			termsAndConditionsLogin: 'By accessing Fielder you are accepting our ',
			registerSuccessText: 'Registered successfully!  We will get in touch with you very soon!',
			functionalityAnaliticsDescription:
				'This is an advanced feature with additional cost, please contact your administrator to enable it.',
			registerFailedText: 'An error ocurred, please try again',
			registerFailedUserAlreadyExists: ' already has a Fielder account',
			createAccountUserNameFieldHelp: '*User name must be at least 8 characters long',
			pleaseEnterName: '*Please enter a name',
			pleaseEnterLastName: '*Please enter a last name',
			pleaseEnterANumberAndCountry: 'Please enter a number and country',
			pleaseSelectJobDescription: 'Please select a job description',
			pleaseSelectAnIndustry: 'Please select an industry',
			pleaseSelectWhereYouFoundUs:'Please select where you found us',
			theAccountHasNoPreferences: 'The account has no preferences',

			addAdministrator: 'Add administrator',
			editTaskGroup: 'Edit Task Group',
			taskGroup: 'Task Group',
			unlinkTaskGroup: 'Unlink task(s)',
			taskGroupUpperCase: 'Tasks groups',
			downloadTaskGroupImages: 'Download task images from Group',
			deletedTaskGroup: 'Deleted task group',
			confirmDeleteTaskGroup: 'Are you sure you want to delete this task group?',
			noGroupTaskImages: 'This Task Group do not have any images saved',
			detailTaskGroup: 'Task Group Detail',
			imageTaskFile: 'group-tasks-images',
			upperCaseAdministrators: 'ADMINISTRATORS',
			upperCaseManagers: 'MANAGERS',
			selectTeamZone: 'Select team / zone',
			selectTeamZoneApi: 'Select team / zone (Obligatory): ',
			map: 'MAP',
			dashboard: 'Dashboard',
			statusTodayDashboard: 'TASK STATUS / TODAY',
			total: 'Total',
			inProgress: 'In progress',
			finished: 'Finished',
			rejected: 'Rejected',
			totalAgents: 'Total Agents',
			agentsAnalytics: 'AGENTS',
			executeServices: 'TASKS FINISHED',
			tasksFinished: 'Tasks finished',
			pieGraph: 'Show pie graph',
			normalGraph: 'Show normal graph',
			onlineDashboard: 'Online',
			offlineDashboard: 'Offline',
			online: 'Online',
			offline: 'Offline',
			messageAgentDashboard: 'The agent has not updated their information or logged into the application',
			customerSatisfaction: 'CUSTOMER SATISFACTION',
			taskPlan: 'TASKS PLAN',
			taskPlanAlert:
				'You have exceeded the contracted monthly task plan. The additional tasks will be  considered in your next invoice.',
			completedTasks: 'Completed tasks',
			remainingTasks: 'Remaining tasks',
			totalPlan: 'Total Plan',
			filterByStatusModification: 'Filter by status modification',
			showTableColumns: 'Show table columns',

			taskToCash: 'Task to Cash',
			taskToCashReportFilename: 'taskToCashReport',
			moreBranchTooltip: 'more branches',
			moreTaskTypeTooltip: 'more task types',
			taskQuantity: 'Quantity of tasks',
			amountToCash: 'Amount to cash',
			currency: 'Currency',
			progressOfTheProject: 'Progress of the project',
			contributionPercentage: 'Contribution percentage',
			pricePerTask: 'Price per task',
			generalTaskStatus: 'General task status',
			restOfTasksWeight: 'Rest of tasks',

			summaryOfTask: 'Summary of Tasks',
			summaryTaskSla: 'Tasks with SLA expired or about to expire today',
			summaryOfAgent: 'Summary of Agents',
			unassignedAgent: 'Unassigned agent',
			idFielder: 'ID Fielder',
			customId: 'ID Custom',
			idCustomGroup: 'ID Custom',
			tasksCompleted: 'COMPLETED TASKS',
			taskCreated: 'Created task',
			taskFinished: 'Completed tasks',
			completed: 'Completed',
			information: 'Information',
			countTask: 'Number',
			uppercasePartners: 'PARTNERS',
			invitations: 'Invitations',
			myPartners: 'Partners that I have integrated',
			iAmPartner: 'Partners that integrated me',
			integratedByMe: 'Integrated by me',
			newAgent: 'New agent',
			newClient: 'New client',
			newManager: 'New manager',
			newBranch: 'New branch',
			newAdministrator: 'New administrator',
			updateInvitation: 'Update invitation',
			updateInvitationSuccess: 'Invitation updated successfully',
			updatePartnerSuccess: 'Partner updated successfully',
			acceptInvitation: 'Accept invitation',
			acceptInvitations: 'Accept invitations',
			refuseInvitation: 'Reject invitation',
			validEmail: 'Valid email',
			invalidEmail: 'Invalid email',
			sendEmail: 'Email sent',
			activatedUser: 'User activated',
			deactivatedUser: 'User disabled',
			statusInvitation: 'Status',
			manager: 'Manager',
			teamName: 'Team name',
			selectManager: 'Select a manager',
			addAnAgent: 'Add an agent',
			administrator: 'Administrator',
			superAdmin: 'Super Administrator',
			superAdmins: 'Super Administrators',
			periodTime: 'Select a period time',
			noneTasks: 'No Tasks',
			noTags: 'No tags',
			noneEmergencies: 'No incidences',
			routeStatus: 'Status',
			totalShipment: 'Total shipment',
			programDate: 'Task scheduled date',
			programHour: 'Task scheduled hour',
			startDateExcel: 'Task start date',
			startHourExcel: 'Task start time',
			executionFormattedDate: 'Execution date',
			executionFormattedHour: 'Execution hour',
			arriveFormattedDate: 'Arrival date',
			arriveFormattedHour: 'Arrival hour',
			startedFormattedDate: 'Start date',
			startedFormattedHour: 'Start hour',
			finishedFormattedDate: 'Finish date',
			originalDates: 'Original execution period',
			emergencies: '# Incidences',
			incidences: 'Incidences',
			totalTasks: 'Total Tasks',
			unlinkTaskQuestion: 'Do you want to unlink the following tasks from your task group?',

			addAgents: 'Add agents',
			addTeams: 'Add teams',

			errorTasksForRouteDate: 'The tasks must have the same execution day ',
			errorTasksForRouteRoute: 'One or more tasks are already in a route, please verify the information and try again',
			errorTasksForRouteGroup: 'One or more tasks belong to a Task Group',
			errorCreateTask: 'There was an error creating the task',
			errorDateAnalytics: 'Second date must be greater than first date',
			errorTasksForRouteStatus: 'The tasks must be in the status ',

			taskStatus_0: 'Managing',
			taskStatus_1: 'Cancelled',
			taskStatus_2: 'Finished',
			taskStatus_3: 'Waiting for agent',
			taskStatus_4: 'In progress',
			taskStatus_5: 'Executing',
			taskStatus_6: 'On way',
			taskStatus_Agent: 'Agent on route',
			taskStatus_7: 'Cancelled by partner',
			taskStatus_8: 'Assigned to partner',
			taskStatus_8_1: 'Assigned by partner',
			taskStatus_9: 'Assigned to team',
			taskStatus_10: 'Arrived',
			taskStatus_11: 'Deleted',
			taskStatus_12: 'Auto assigning',
			taskStatus_13: 'Auto assigned',
			taskStatus_14: 'Auto assigned failed',
			taskStatus_15: 'Paused',
			taskStatus_16: 'Review',
			taskStatus_17: 'Rejected',

			taskGroup_0: 'Created',
			taskGroup_1: 'On way',
			taskGroup_2: 'Arrived',
			taskGroup_3: 'Executing',
			taskGroup_4: 'Paused',
			taskGroup_5: 'Finished',
			taskGroup_6: 'Cancelled',
			taskGroup_7: 'Disabled',

			grandTotal: 'Grand total',

			linkFile: 'Attach file',
			linkFiles: 'Attach files',
			fileSizeExceeded:
				'The allowed size of files per task has been exceeded, use a link or attach other files from the task detail.',
			maxSizeFileTask: '(Maximum total size 4MB)',
			review: 'Review',
			wrongImageSize: 'Wrong image size',

			formatDay: 'days-hh:mm:ss',

			or: ' or ',
			and: ' and ',
			validateAgentsAndPriority: 'Verify properties and make sure to add agents who have a location',
			validateTaskTypes: 'Cannot generate Routes for Remote tasks',
			errorCreatedRoute: 'Unable to create routes,  please verify the tasks info and try again later',
			successCreatedRoutes: '{0} routes  were created successfully',
			successCreatedRoute: 'One route was created successfully ',
			detailTeam: 'Team detail',
			editTeam: 'Edit team',
			countWaitingForAgent: 'Tasks waiting for agent',
			anotherJob: 'Another job',
			meetFielder: 'Meet Fielder',
			shareText: 'Invite other companies to collaborate in your ecosystem using Fielder',
			errorAcceptMyInvitation: 'You cannot accept your own invitations',
			addedCorrectly: 'Successfully added!',
			assignGroupTask: 'The address and execution date of the task will change to the predefined one in the task group',
			savedCorrectly: 'Successfully saved!',
			groupTasksAreBeingAssigned: 'Group tasks are being assigned',
			groupTasksAreBeingUnlinked:'The task was unlinked from the Group',
			savedCorrectlyReschedule: 'Tasks rescheduled successfully. Total number:',
			accountUpdateSavedCorrectly:
				'Successfully saved! , \n *Remember the update of the logo will be reflected shortly, we appreciate your patience',
			emailAccountCreated: 'Email account activated successfully',
			emailAccountCreatedError: 'The email account could not be activated, try again late',
			cancelSuccessfully: 'Task canceled successfully',
			rateSuccessfully: 'Task rated successfully',
			cancelError: 'Unable to cancel task, please verify the tasks info and try again later',
			rateError: 'Unable to rate the task, please verify the task info and try again later',
			minimunCharacters: 'minimum characters: {0}',

			managerRestoredCorrectly: 'Manager(s) restored correctly!',
			managerDeletedCorrectly: 'Manager(s) deleted correctly!',
			managerDisabledCorrectly: 'Manager(s) disabled correctly!',

			administratorsRestoredCorrectly: 'Administrator(s) restored correctly!',
			administratorsDeletedCorrectly: 'Administrator(s) deleted correctly!',
			administratorsDisabledCorrectly: 'Administrator(s) disabled correctly!',

			clientRestoredCorrectly: 'Client(s) restored correctly!',
			clientDeletedCorrectly: 'Client(s) deleted correctly!',
			clientDisabledCorrectly: 'Client(s) disabled correctly!',

			branchRestoredCorrectly: 'Branch(es) restored correctly!',
			branchDeletedCorrectly: 'Branch(es) deleted correctly!',
			branchDisabledCorrectly: 'Branch(es) disabled correctly!',

			EXIST_ASSOCIATE_BY_TASK: 'The element cannot be deleted because it is associated with an existing task', // 301
			existAssociateDeleteClientsByTask: 'Client(s):  {0}  cannot be deleted, because associated tasks were found', //301
			existAssociateBySystemClient: 'Client(s):  {0}  cannot be deleted, because there are several business units', // 303
			unknownErrorDeleteClients: 'Client(s):  {0}  cannot be deleted because of an unmanaged error', // 304
			existAssociateDeleteManagersByTask: 'Manager(s):  {0}  cannot be deleted, because associated tasks were found', //301
			existAssociateDeleteManagersByTeam: 'Manager(s):  {0}  cannot be deleted, because associated teams were found', // 302
			existAssociateBySystemManager: 'Manager(s):  {0} cannot be deleted, because there are several business units', // 303
			unknownErrorDeleteManagers: 'Manager(s):  {0}  cannot be deleted, because of an unmanaged error', // 304
			EXIST_ASSOCIATE_BY_TEAM: 'The element cannot be deleted  because it is associated with an existing team', // 302
			EXIST_ASSOCIATE_BY_SYSTEM: 'The element cannot be deleted  because there are several business units', // 303
			existAssociateBySystemAdmin: 'Admin(s): {0} cannot be deleted  because there are several business units', // 303
			UNKNOWN_ERROR_DELETE_USER: 'Unknown error while trying to delete user', // 304
			unknown_error_delete_admins: 'Admin(s):  {0}  cannot be deleted because of an unmanaged error', // 304
			unknown_error_delete_agents: 'Agent(s):  {0}  cannot be deleted because of an unmanaged error', // 304
			CONFLICT_EMERGENCIES: 'The route has emergencies', // 334
			CONFLICT_ROUTE: 'The route is finished or executing', // 335
			CONFLICT_SERVICE_ON_ROUTE: "Some tasks of the route can't be deleted", // 336
			EXIST_CLIENT_ASSOCIATE: 'The element cannot be deleted because there is an association with a customer', // 351
			EXIST_BRANCHES_BY_COMPANY:
				'The element cannot be deleted  because there are branches associated with the company', // 400
			EXIST_SUBCOMPANIES_BY_COMPANY: 'The element cannot be deleted  because there are associated sub-companies', // 401
			EXIST_ASSOCIATE_BY_TASK_AGENT: 'The element cannot be deleted  because there are tasks associated with the agent', // 450
			existAssociateByTaskAgent: 'Agent(s):  {0}  not removed because there are tasks associated with the agent with ID: {0}',
			EXIST_ASSOCIATE_BY_SYSTEM_AGENT: 'The element cannot be deleted because there are several business units', // 451
			existAssociateBySystemAgent: 'Agent(s):  {0}  not removed because there are several business units',
			UNKNOWN_ERROR_DELETE_AGENT: 'Unknown error while trying to remove agent', // 452

			EXIST_ASSOCIATE_BY_AGENT: 'The element cannot be deleted because there are agents related to the team', // 500;
			EXIST_ASSOCIATE_BY_MANAGER: 'The element cannot be deleted because there are managers related to the team', // 501;
			UNKNOWN_ERROR_DELETE_TEAM: 'Unknown error when trying to delete the team', // 502;
			unknownErrorDeleteTeams: 'Teams(s):  {0}  cannot be deleted because of an unmanaged error', // 502
			permissionMissignDeleteTeams: 'Teams(s):  {0}  cannot be deleted because you have not permission to this action', // 990

			FAIL_UPDATE_GROUP_TASKS:
				'Something unexpected happen while trying to disable the task group, please try it again', // 810
			FAIL_DELETE_TASKS: 'The group task cannot be disabled because it is associated with an existing task', // 811
			WRONG_DELETE_STATUS:
				'The group task cannot be disabled. Only groups with created and canceled status can be disabled', // 812
			GROUP_NOT_FOUND: 'The group task cannot be disabled because it was not found.', // 813

			failSendInvitation: 'Something unexpected happen while trying to send the invitation, please try it again',
			idTeam: 'Id',
			idTeamHeader:'Team (Id Team)',
			companyRestoredCorrectly: 'Company(ies) restored correctly!',
			companyDeletedCorrectly: 'Company(ies) deleted correctly!',
			companyDisabledCorrectly: 'Company(ies) disabled correctly!',

			selectGlobalAccountClient: 'Select an account to create a task or a ticket:',
			teamRestoredCorrectly: 'Team(s) restored correctly!',
			teamDeletedCorrectly: 'Team(s) deleted correctly!',
			teamDisabledCorrectly: 'Team(s) disabled correctly',
			cancelPartnershipSuccess: 'Association cancelled successfully',
			acceptInvitationSuccess: 'Invitation accepted successfully',
			rejectedInvitationSuccess: 'Invitation rejected successfully',
			teamsAndAgents: 'Teams and Agents',
			// Analytics
			analyticsUpperCase: 'ANALYTICS',
			incidenceCount: 'Incidence #',
			incidenceAgent: 'Agent incidences ',
			/// GLOBAL SEARCH ///
			teamsAssigned: 'No teams assigned',
			clientAddress: 'No address',
			photoMessagePermission:
				'Remember that the photos without information were uploaded from the gallery or the agent has not accepted the permissions to be geolocated.',
			search: 'Search',
			filterBy: 'Filter by',
			formFilter:'Form filter',
			advancedFilter: 'Advanced filter',
			filterByDate: 'Filter by date',
			all: 'All',

			goTeams: 'Go to teams',

			searchFormsByTasks: 'Search forms by tasks',
			emailTask: 'Tasks by email',
			disabledTask: 'Tasks disabled',

			groups: 'Task Groups',
			groupUpperCase: 'GROUPS',
			routes: 'Routes',
			companies: 'Companies',
			resultsFound: 'results found for',
			results: 'results',
			fullName: 'Name and Last Name',
			idManager: 'Manager Id',
			groupName: 'Group Name',

			teamManager: 'Team Manager',
			tasksNumber: 'Number of Tasks',
			contractNumber: 'Contract number',
			myPartner: 'Partners that I have integrated',
			iPartner: 'Partners that integrated me',

			// Import users
			importUsersSpecification:
				'* Verify that the Users to be imported do not contain commas. The username, email,  name,  last name ,  country code and mobile phone  are required fields.',
			row: 'Row',
			errorType: 'Error type',
			countryCode: 'Country code',
			importUsersSubject: 'Fielder Users CSV import results',
			importBranchSubject: 'Fielder Branch CSV import results',
			importCompanySubject: 'Fielder Company CSV import results',
			importAssetsSubject: 'Fielder Asset CSV import results',
			importInventorySubject: 'Fielder Inventory CSV import results',
			importMessage: 'Your import process has finished.  You will receive an email with the process results',
			updateMessage: 'The update process is being carried out',
			importIsRequired: 'is required',
			if: ' if ',
			notExists: ' does not exist ',

			oneCell: ' One cell of ',

			// Error codes
			errorCode301: 'Incorrect required fields',
			errorCode407: 'The attention format is incorrect or the start time is greater than the end time',
			errorCode300: 'Duplicated user',
			errorCode350: 'Branch already exists',
			errorCode352: 'The company does not exist or the custom id is incorrect',
			errorCode353: 'Duplicated Id company',
			errorCode500: 'Incorrect user information',
			errorCode1001: 'Incorrect .csv format.  Please download the correct template',
			errorCode520: 'Incorrect user information',
			errorCode710: 'Branch and Category are required fields',
			errorCode711: 'Category is required field',
			errorCode712: 'Category has not Template',
			errorCode713: 'Category has not Template',
			errorCode714: 'Category not found',
			errorCode715: 'Branch not found',
			errorCode716: 'File is empty',
			errorCode960: 'Duplicate user',
			errorCode961: 'Error creating user',

			errorCode310:'The username exceeds the allowed character limit (45 characters)',
			errorCode311:'The email exceeds the allowed character limit (75 characters)',
			errorCode312:'The name exceeds the allowed character limit (150 characters)',
			errorCode313:'The last name exceeds the allowed character limit (150 characters)',
			errorCode314:'The phone number exceeds the allowed character limit (45 characters)',
			errorCode315:'The mobile phone number exceeds the allowed character limit (45 characters)',
			errorCode316:'The job position exceeds the allowed character limit (80 characters)',
	

			createUser: 'Create user',
			createGlobalAccount: 'Create global account',
			errorFormatXslxHtml: 'Incorrect file format. Please upload a .xlsx file or .html',

			timeOfResponse: 'RESPONSE TIME',
			timeOfSolution: 'SOLUTION TIME',
			solutionResponse: 'SOLUTION / RESPONSE',
			serviceStatus: 'TASK STATUS',
			serviceStatusLowerCase: 'Task Status',
			executionTimeInitialDateUpperCase: 'EXECUTION TIME / START DATE',
			taskGroupStatus: 'TASK GROUP STATUS',
			taskGroupStatusLowerCase: 'Status',
			timeOfResponseLowerCase: 'Response Time ',
			timeOfSolutionLowerCase: 'Solution time',
			executionTime: 'Execution time',
			startDate: 'Start date',
			solutionResponseLowerCase: 'Solution / Response',

			executionTimeInitialDate: 'Average between Execution time / Agent Start Time',
			puntuality: 'Puntuality',
			customTaskType: 'Custom task type',
			customTaskTypeName: 'Custom task ',
			serviceStatusTaskGroupLowerCase: 'Task group status',
			dashboardAnalitycs: 'Dashboard General Analytics',
			dashboardAnalitycsTask: 'Dashboard Tasks',
			dashboardAnalitycsGroup: 'Dashboard Task Group',
			dashboardAnalitycsAgent: 'Dashboard Agents',
			dashboardAnalitycsClient: 'Dashboard Clients',
			dashboardAnalitycsBranch: 'Dashboard Branches',
			dashboardAnalitycsCompany: 'Dashboard Companies',
			dashboardAnalitycsPartner: 'Dashboard Partners I integrated',
			dashboardAnalitycsIPartner: 'Dashboard Partners who integrated me',

			executedServices: 'EXECUTED SERVICES',
			solutionExecutedServices: 'SOLUTION TIME / EXECUTED SERVICES',
			offLineTime: 'OFFLINE TIME',
			offlineExecution:'Offline execution',

			offCause_1: 'Sickness',
			offCause_2: 'Vacation',
			offCause_3: 'Permission',
			offCause_4: 'Disability Leave',
			offCause_5: 'End of Workday',

			executedServicesLowerCase: 'Finished services',
			solutionExecutedServicesLowerCase: 'Solution time / Executed service',
			offLineTimeLowerCase: 'Offline time',
			canceledServices: 'Canceled Services',
			inProgressServices: 'In Progress Services',
			executingServices: 'Executing Services',

			deletedSuccessfullyText: 'Successfully Deleted',
			linkedTextBranch: 'Could not be deleted because there is a link to a company, task or client.',
			linkedTextCompany: 'Could not be deleted because there is a link to a branch',
			linkedTextClient: 'Could not be removed because there are dependencies',
			linkedTextTeam: 'Could not be removed because there are dependencies',
			linkedTextManager: 'Could not be removed because there are dependencies',
			linkedTextAdministratador: 'Could not be removed because there are dependencies',
			linkedTextAgent: 'Could not be removed because there are dependencies',

			phoneNumberInput: 'Set phone number',
			note: 'Note: for more information check the icon',
			questionWhatToSee: 'What do you want to see?',
			addAction: 'Add action',
			writeYourSMSEsText: 'Write your sms spanish text here',
			writeYourSMSEnText: 'Write your sms english text here',
			writeYourSMSPtText: 'Write your sms Portuguese text here',
			ln_es_MX: 'Spanish',
			ln_en_US: 'English',
			ln_pt_BR: 'Portuguese',
			variables: 'Vars.',
			sms_first_part_text_0: 'When agent',
			sms_first_part_text_1: 'When agent',
			sms_first_part_text_2: 'When agent',
			sms_second_part_text_2: 'the task, send',
			sms_second_part_text_1: 'to the location, send',
			sms_second_part_text_0: 'to the task, send',
			sms_target: 'to client.',
			sms_action_type_2: 'start',
			sms_action_type_1: 'arrive',
			sms_action_type_0: 'go on the way',
			sms_vars_date: 'Date',

			// HELP
			instructions: 'Instructions',
			instructionsText:
				'In order to create a service through the mail, it is necessary to respect the following nomenglature:',
			folioInstructions: '*The folio field can be left empty but not omitted',
			forBranches: '-For branches:',
			forClients: '-For clients',
			emailTextBranchPart1: '{{BranchID}}6793',
			emailTextBranchPart2: '{{Description}}Task created by email to a branch',
			emailTextClientPart1: '{{Folio}}Sample Text',
			emailTextClientPart2: '{{ClientID}}10447',
			emailTextClientPart3: '{{Description}}Task created by email to a client',
			contactSupportTitle: 'CONTACT FIELDER TECHNICAL SUPPORT: CONTACTO@APPFIELDER.COM',
			helpWhatIsFielder: 'What is fielder?',
			helpWhatIsFielderAnswer:
				'Fielder is a task management platform that allows you to manage the tasks assigned to your staff, monitoring in real time the status of the tasks and your workforce in the field, giving your customers a different experience in the request, delivery and quality of your services.',
			helpTypeOfUserQuestion: '2. What kind of users exist in Fielder?',
			helpTypeOfUserAnswer:
				'There are 5 types of users: Super-Administrator, Administrator, Agent, Manager and Client. The Super-administrator is the user who acquires Fidelder, and this user and the Administrator user have full access to the functionalities. The Agent user is the staff that is in the field and has access to the Agent mobile APP. The Manager user is the one who manages the teams and the agents assigned to the teams. The Client user is the customer who receive the service and can create service requests.',
			helpCreateTaks: '3. How can I create a Task?',
			helpCreateTaksAnswer:
				'You can create tasks by clicking on the "+" symbol that appears on the top of the different screens of the platform.',
			helpAdress: '4. Is the address an important thing when creating a task?',
			helpAdressAnswer: 'Yes, so whenever you create a task, verify that the Fielder Pin is on the map.',
			helpStatusTask: '5. Where do I see the status of a task?',
			helpStatusTaskAnswer:
				'In the My Operation section you will see all the tasks and you can select one by one to review the detail.',
			helpReschedule: '6. Can I reschedule a service?',
			helpRescheduleAnswer:
				'Yes, any task can be rescheduled. Select the task and in the menu of actions under the map, select the option "Task Detail" ',
			helpAgentClient: '7. What happens if my Agents or Clients are not receiving Fielder mails?',
			helpAgentClientAnswer:
				'Ask users to check their "SPAM", if they definitely did not receive the emails please send an email to support@appfielder.com',
			helpExecuteApplication: '8. What tasks can I execute in the application?',
			helpExecuteApplicationAnswer:
				'You can execute any task that is executed in the field, among which deliveries, pick-ups or services in general (i.e. maintenance, facilities or any activity carried out on site) stand out.',
			helpGeolocalization: '9. How can I geolocate my Agents?',
			helpGeolocalizationAnswer:
				'In the Dashboard and in the Map of My Operation you will be able to visualize the geolocation of your agents.',
			helpClientRate: '10. How can my client rate my task?',
			helpClienRateAnswer:
				'From the mobile application, the client can select the service and click on “qualify”. From the web view, your client will have to enter, go to the tasks menu and click on the action buttons selecting “qualify” and adding his comments.',
			helpTaskDay: '11. How do I see how many tasks I have in a specific day (Today)?',
			helpTaskDayAnswer:
				'In the My Operation section you will be able to visualize the tasks that your clients have assigned you on the current day and those that you have created, showing you the status in  real time.',
			helpTechnicalRequirements: '12. What are the minimum technical requirements to use the application?',
			helpTechnicalRequirementsAnswer:
				'On our website you can find the minimum technical requirements for the best performance of the app.',

			taskDifference: 'Task difference',
			lastMonthTask: 'Tasks from last month',
			tasksOfTheMonth: 'Tasks of the month',
			// Billing
			billing: 'Billing',
			billingUpperCase: 'BILLING',
			billingSummary: 'BILLING SUMMARY',
			billingDynamicAllocationTask: 'Tasks used in Dynamic Allocation',
			billingRouteOptimizationTask: 'Tasks in route',
			billingResurceAllocationTask: 'Tasks in Resource Allocation',
			billingFormsTask: 'Forms in tasks',
			billingTasksInGroups: 'Tasks in Group',
			billingAssetsInTasks: 'Assets assigned to a task',
			billingInventoriesInTasks: 'Inventories assigned to a task',
			billingInternalUpperCase: 'BILLING INTERNAL',
			billingHistory: 'History',
			billingHistoryUpperCase: 'PREVIOUS BILLING HISTORY',
			billlingView: 'VIEW BILLING',
			billingTotal: 'TOTAL',
			billingConsultingDate: 'Date of review',
			billingCutoffDate: 'Estimated daily consumption',
			billingMonthDate: 'Consumption at ',
			disableAccount: 'Disable Account',
			enableAccount: 'Enable Account',
			activeAccount: 'Active account',
			accountDeactivated: 'Account deactivated',
			configAlertPaymentAccount: 'Configure Payment Alert',
			descAlertPayment: 'Set the remaining days to make the payment',
			daysToPay: 'Days to pay',
			hideAlertPaymentAccount: 'Remove Payment Alert',
			billingShowAlertAccountSuccess: 'No Pay Alert enabled successfully',
			billingHideAlertAccountSuccess: 'No payment alert has been successfully deleted',
			day: 'day',

			billingAlertNotPaid1: 'There was a problem with your payment, you have ',
			billingAlertNotPaid2: ' to regularize. Avoid suspending your account.',
			disableAccountSuccess: 'Account disabled successfully',
			enableAccountSuccess: 'Account enabled successfully',
			taskPackage: 'TaskPackage',
			summary: 'Summary',
			monthSummary: 'Month summary',
			monthSummaryTitleUpperCase: 'TOTAL SUMMARY OF OPERATIONS',
			paymentType: 'Payment type',
			billingPrepaid: 'Prepaid',
			billingOnDemand: 'On demand',

			agentLocalization: 'Agent location',
			gelocalizationLevel0: 'No Geolocation',
			gelocalizationLevel1: 'With Moderate Geolocation',
			gelocalizationLevel2: 'With High Geolocation',

			period: 'Period',
			exchangeType: 'Exchange type',
			billingMonthly: 'Monthly',
			billingBimonthly: 'Bimonthly',
			billingQuarterly: 'Quarterly',
			billingBiannual: 'Biannual',
			billingAnnual: 'Annual',
			billingBasicPlan: 'Basic Fielder',
			billingOptimizedPlan: 'Optimized Fielder',
			billingTaskPrice: 'Price per task',
			billingTaskAdditionalPrice: 'Price per additional task',
			billingRequestPrice: 'Price per request',
			billingRequestAdditionalPrice: 'Price per additional request',
			billingPricePerReorganization: 'Price per route reorganization',
			billingPricePerAdditionalReorganization: 'Price per additional route reorganization',
			billingPricePerIsochronous: 'Price per isochronous',
			billingPricePerForm: 'Price per form template',
			billingPricePerGroup: 'Price per task group',
			numberOfTasks: 'Number of tasks',
			numberOfSkills: 'Number of skills',
			billingPricePerSkill: 'Price per skill',
			billingSkillAdditionalPrice: 'Additional price per skill',
			pricePerPackage: 'Price per package',
			concept: 'Concept',
			quantity: 'Quantity',
			unitPrice: 'Unit price',
			subtotal: 'Subtotal',
			additionalTaskCreated: 'Additional created tasks',
			executions: 'Executions',
			additionalExecutions: 'Additional executions',
			reorganizeRoutes: 'Reorganize routes',
			additionalReorganizeRoutes: 'Additional reorganize routes',
			routeTasks: 'Route tasks',
			additionalRouteTasks: 'Additonal tasks en route',
			totalBalanceToPaid: 'Total balance to be paid',
			billingTermsAndConditions1:
				'The applicable Terms and Conditions are those contained in the current contract signed between the “parties”.',
			billingTermsAndConditions2: 'Any questions or clarification please send to email management@smartstc.com',
			actions: 'Actions',
			consulting: 'Consultancy',
			storage: 'Storage',
			billingManagementControl: 'Management and Control',
			billingPlanningOptimization: 'Planning and Optimization',
			optimizationSideBar: 'Optimization',
			plannngSideBar: 'Planning',
			billingAutomation: 'Automation',
			billingComplement: 'Additional Services',
			of: ' of ',

			// InternalAdmin
			accounts: 'Accounts',
			noRecordsLoginText: 'There are no login records on the selected account',
			noRecordsAgentText: 'There are no assigned agents records on the selected account',
			noRecordsRouteText: 'There are no route records on the selected account',

			totalNumberOfAdministrators: 'Total number of administrators',
			totalNumberOfAdministratorsViewer: 'Total number of administrators viewers',
			totalNumberOfManagers: 'Total number of managers',
			totalNumberOfClients: 'Total number of clients',
			totalNumberOfCompanies: 'Total number of companies',
			totalNumberOfBranches: 'Total number of branches',
			totalNumberOfTeams: 'Total number of teams',
			totalNumberOfAccountPartner: 'Total number of partners',
			totalNumberOfAgents: 'Total number of agents',
			totalUsersInFielder: 'Total users in fielder',
			totalUsers: 'Total users',
			noUsers: 'No users',
			taskComparison: 'Task comparison',

			lastLogin: 'Last login',
			numberOfRoutes: 'Number of routes',
			numberOfAssignedAgents: 'Number of assigned agents',
			noLastLogin: 'No last login logs',
			records: 'Records',
			addGlobalUser: 'Add global user',
			globalUser: 'Global user',
			loginsRecords: 'Logins Records',
			routesRecords: 'Route logs',
			agentsRecords: 'Agent records',
			accountsUpperCase: 'ACCOUNTS',
			numberOfRequest: 'Number of requests',
			numberOfReorganization: 'Number of route reorganizations',
			numberOfForms: 'Number of forms',
			taskWithForms: 'Tasks with forms',
			additionalTaskWithForms: 'Additional tasks with forms',
			numberOfGroups: 'Number of Task Groups',
			taskInGroups: 'Tasks in Group',
			additionalTaskInGroups: 'Additional tasks in Group',
			taskInDynamicAllocation: 'Tasks in dynamic allocation',
			additionalTaskInDynamicAllocation: 'Additional tasks in dynamic allocation',
			taskWithAssets: 'Tasks with assets',
			additionalTaskWithAssets: 'Addiotional tasks with assets',
			taskWithInventories: 'Tasks with inventories',
			additionalTaskWithInventories: 'Additional tasks with inventories',
			welcome: 'Welcome ',

			//Api User
			loginApi: 'Login',
			createCompanyApi: 'Create Company',
			updateCompanyApi: 'Update Company',
			getTotalCompaniesApi: 'Get total companies',
			getCompaniesApi:'Get Companies',
			createBranchApi: 'Create Branch',
			updateBranchApi: 'Update Branch',
			getTotalBranchesApi: 'Get Total Branches',
			getBranchesApi: 'Get Branches',
			createAgentApi: 'Create Agent',
			updateAgentApi: 'Update Agent',
			getTotalAgentsApi: 'Get total Agents',
			getAgentsApi: 'Get Agents',
			createTaskApi: 'Create Task',
			getTaskApi: 'Get Task',
			getTasksByDateApi: 'Get Tasks Ids By Date',
			getTaskActivityApi: 'Get Task Activity',
			getRescheduleTaskApi: 'Reschedule Task',
			getCancelTaskApi: 'Cancel Task',
			getPauseTaskApi: 'Pause Task',
			getReasingPartnerApi: 'Reasing Partner',
			getCancelTaskPartnerApi: 'Cancel Task Partner',
			getAssignTaskApi: 'Assign Task',
			getAssignInventoryAssetToTaskApi: 'Assign Inventory/Asset To Task',
			getApproveTaskFormsApi: 'Approve Task Forms',
			getUpdateTaskApi: 'Update Task',
			getDuplicateTaskApi: 'Duplicate Task',
			getCreateTaskGroupApi: 'Create Task Group',
			getUpdateTaskGroupApi: 'Update Task Group',
			getAssignTaskGroupApi: 'Assign Task Group',
			getDetailTaskGroupApi: 'Detail Task Group',
			getTaskGroupNameAndIdCustomApi: 'Get Task Group Name and Id Custom',
			getUnlinkTaskFromTaskGroupApi: 'Unlink Task From Task Group',
			getClientsApi: 'Get Clients',
			getTotalClientsApi: 'Get Total Clients',
			getCreateClientApi: 'Create Client',
			getUpdateClientApi: 'Update Client',
			getTeamAgentsApi: 'Get Team Agents',
			getTaskTypesApi: 'Get Task Types',
			getCreateTaskTypeApi: 'Create Task Type',
			getUpdateTaskTypeApi: 'Update Task Type',
			getTaskPrioritiesApi: 'Get Task Priorities',
			getCustomStatusAssetsApi: 'Get Custom Status (Assets)',
			getCategoriesAssetsApi: 'Get Categories',
			getCategoryByIdAssetsApi: 'Get Category by Id',
			getTotalAssetsByCategoryApi: 'Get total Assets by Category',
			getAssetsApi: 'Get Assets',
			getAssetByIdApi: 'Get Asset by Id',
			getCreateAssetApi: 'Create Asset',
			getUpdateAssetApi: 'Update Asset',
			getDisableAssetApi: 'Disable Asset',
			getDeleteAssetApi: 'Delete Asset',
			getEnableAssetApi: 'Enable Asset',
			getCustomStatusInventoryApi: 'Get Custom Status (Inventory)',
			getCategoriesInventoryApi: 'Get Categories',
			getCategoryByIdInventoryApi: 'Get Category by Id',
			getTotalInventoryByCategoryApi: 'Get total Inventory by Category',
			getInventoriesApi: 'Get Inventories',
			getInventoryByIdApi: 'Get Inventory by Id',
			getCreateInventoryApi: 'Create Inventory',
			getUpdateInventoryApi: 'Update Inventory',
			getDeleteInventoryApi: 'Delete Inventory',
			getDisableInventoryApi: 'Disable Inventory',
			getEnableInventoryApi: 'Enable Inventory',
			getFormsFromTaskApi: 'Get Forms From Task',
			getUpdateFormTaskApi: 'Update Form Task',

			apiUserActivity_0:'Api User Created',
			apiUserActivity_1:'API User Edited',
			apiUserActivity_2:'User Api Activated',
			apiUserActivity_3:'User Api Disabled',

			//Taks Files Massive Download
			massiveDownload: 'Massive Downloads',
			massiveDownloadComponentTitle: 'Mass download of Forms',
			massiveDownloadDescription: 'Mass download the forms contained in a Company',
			massiveDownloadCompanyId: 'Company ID:',
			massiveDownloadBranchId: 'Branch ID:',
			massiveDownloadZipButton: 'Send Information',
			massiveDownloadCompanyPlaceholder: 'Enter the Company ID',
			massiveDownloadBranchPlaceholder: 'Enter the Branch ID',
			requiredIdAccount: 'ID Account is required',
			massiveDownloadSuccess: 'We will send a download link to your email',
			massiveDownloadError: 'An error occurred while trying to generate the file',
			massiveDownloadIdMustBeNumeric: 'The ID must be numeric',
			massiveDownloadMandatoryDates: 'A date range must be seleted',
			massiveDownloadStatusCheckError: 'Was not possible to check if there is a download already in progress',
			massiveDownloadStatusAlreadyInProgress: 'There is already a download in progress',

			//Maya Marketplace
			mayaManagerMarketplace: 'Maya Virtual Assistant for your operation',
			mayaTechnicianMarketplace: 'Maya Virtual Assistant for field technicians',
			mayaClientMarketplace: 'Maya Virtual Assistant for your customers',
		},
		es_MX: {
			location: 'Ubicación',
			selectView: 'Seleccione vista',
			filter: 'Filtro',
			filterName: 'Nombre del filtro',
			viewMoreFilters: 'Ver más filtros',
			noTasks: 'Sin Tareas',
			away: 'Ausente',
			agentsInfo: 'Información de los Agentes',
			noTeams: 'Sin Equipos',
			noTeam: 'Sin Equipo',
			noCompany: 'Sin Compañía',
			filterAgents: 'Filtrar Agentes',
			agentTaskNumberFilter: 'Número de Tareas del Agente',
			agentStatusFilter: 'Estado del Agente',
			agentsStatus: 'Estado de los Agentes',
			agentTeams: 'Equipos del Agente',
			allStatus: 'Todos los Estados',
			agentDetails: 'Detalles del Agente',
			task: 'Tarea',
			tasks: 'Tareas',
			couldNotFilterAgentsCommandCenter: 'No fue posible filtrar agentes, favor de intentarlo de nuevo más tarde',
			couldNotFilterTasksCommandCenter: 'No fue posible filtrar tareas, favor de intentarlo de nuevo más tarde',
			noAgentsFound: 'No se Encontraron Agentes',
			noTasksFound: 'No se Encontraron Tareas',
			tryWithOtherFilter: 'Por favor intente con otro filtro',
			tasksInfo: 'Información de Tareas',
			viewAgent: 'Ver Agente',
			today: 'Hoy',
			notShowingDisabledAgents: 'No mostrando agentes deshabilitados',
			lastLocationUpdate: 'Última Actualización de Ubicación',
			lastIdsCustomUsed:'Últimos IDs personalizados usados',
			workload: 'Carga de Trabajo',
			taskInProgress: 'Tarea en Proceso',
			viewAll: 'Ver todos',
			showAgentTasks: 'Mostrar Tareas de Agente',
			clickRecenterMap: 'Click para volver a centrar el mapa',
			assignTasksWithinTimeFrame: 'Asigna tareas al agente dentro del periodo de tiempo',
			viewAgentsByStatus: 'Ver agentes por estado',
			viewAgentsByTaskNumber: 'Ver agentes por número de tareas',
			taskFilterSearchLabel: 'Busca por Id Fielder o Folio',
			totalMissingTasks: 'Tareas Restantes',
			noLocation: 'Sin ubicación',
			noTaskInProgress: 'Sin tareas en proceso',
			assignTasks: 'Asignar Tareas',
			addTasks: 'Agregar Tareas',
			tasksToAssignToAgent: 'Tareas a asignar al agente',
			noClient: 'No Cliente',
			moving: 'Moviendo',
			showUnattendedTasks: 'Mostrar animación para tareas desatendidas',
			showUpdatesInRealTime: 'Mostrar actualizaciones en tiempo real',
			loaded: 'Cargado',
			newUpdates: 'Nuevas actualizaciones',
			noOptions: 'Sin opciones',
			agentsSelectedHaveNoLocation: "Los agentes seleccionados no tienen ubicación registrada",
			by: 'por',
			ids:'Ids',

			hello: "Hola",
			moreInformation:'Más información',
			enterCaseNumber: 'Ingresa número de caso',
			howCanIHelpYou: "Hola! Soy Maya, tu asistente de I.A. generativa de Fielder",
			writeYourMessageHere: "Pregúntame sobre Fielder",
			sorryIcantHelp:"Lo siento, no puedo responder a esa pregunta. Puedes contactar a nuestro equipo de soporte por correo electrónico para obtener más ayuda.",
			forMoreInformation:"Para mas informacion te recomiendo leer la documentación",
			documentationLink: "Link de documentacion:",
			createTaskDocumentation:"Creación de Tarea",
			taskCreationDetailDocumantation:"Detalle de creación tarea",
			// global account
			usersIds: 'Ids de usuarios',
			example: 'Ejemplo',
			send: 'Enviar',
			topic: 'Asunto',
			mailIntegrationNoActivated: 'Correo no activado',
			mailIntegrationConfig: 'El dominio se está configurando',
			relations: 'Relaciones',
			clientGlobal: 'Fielder Client Global Account',
			linkedAccount: 'Cuenta vinculada',
			noLinkedAccount: 'No hay cuentas vinculadas',
			linkedUsers: 'Usuarios vinculados',
			noLinkedUsers: 'No hay usuarios vinculados',
			globalAccountInfo: 'Información de la cuenta',
			globalUserInfo: 'Información del usuario',
			adminGlobal: 'Fielder Root Global Account',
			addUserToGlobalAccount: 'Agregar usuario a la cuenta global',
			editGlobalAccount: 'Mostrar conta global',
			idGlobalAccount: 'Id Cuenta Global',
			linkGlobalAccount: 'Vincular cuenta global con cuenta',
			domain: 'Dominio',
			assignDomain: 'Asignar dominio',
			assingAccount: 'Asignar cuenta',
			globalUserNormalName: 'Nombre',
			globalUseridUser: 'Id usuario',
			globalAccount: 'Cuenta global',
			domainCreatedSuccessfully: 'Dominio vinculado con éxito',
			accountCreatedSuccessfully: 'Cuenta creada correctamente',
			accountLinkedSuccessfully: 'Cuenta vinculada correctamente',
			userAddedSuccessfully: 'Usuario vinculado con éxito',
			globalDashboardError: 'Algo inesperado pasó vuelve a intentarlo',
			domainCreatedError: 'Hubo un problema al crear el dominio. Vuelve a intentarlo más tarde.',
			accountErrorReltation: 'La cuenta ya cuenta con esta relación',
			accountCreatedError: 'Hubo un problema al crear la cuenta, intentelo de nuevo mas tarde.',
			assingAccountCreatedError: 'There was a problem assigning the account to the global account. Please try again.',
			addUsertoGlobalAccountCreatedError:
				'Hubo un problema al asignar el usuario a la cuenta global. Inténtalo de nuevo.',
			accountName: 'Nombre de la cuenta',
			contactName: 'Nombre de contacto',
			contactEmail: 'Email de contacto',
			ERROR_404: 'ERROR 404',
			pageNoAvailable: 'Página no disponible',
			textNotAvailable:
				'Esta pagina no esta disponible para tu tipo de usuario o no es visible para tu rol. Por favor ponte en contacto con tu administrador para más información.',
			textNotFound404: 'No encontramos ninguna página similar a la que buscas.',
			viewOperation: 'Ver Operación',
			invalidCredentialsForViewOperation:
				'Lo sentimos, no tienes habilitado ver la operación de esta cuenta, por favor contacta al equipo de Fielder para habilitar la visualización de la cuenta.',
			pending: 'Pendientes',
			formFilterInfo: 'Busca por nombre de formulario o por cualquier otro texto',
			userFilterTo: 'Utilice el filtro avanzado para hacer busquedas mas especificas de  una o mas tareas.',
			noResultsFound: 'No se encontraron resultados',

			dynamicAllocation: 'Asignación dinámica',
			dynamicAllocationConfirmData: '¿Deseas generar la asignación dinámica?',
			dynamicAllocationSuccessMessage:
				'Tu proceso de Asignación Dinámica se ha creado con éxito, en un momento recibirás un correo con los resultados',
			dynamicAllocationErrorMessage:
				'Tu proceso de Asignación Dinámica no se ha podido crear, por favor intentalo más tarde',
			dynamicAllocationDescription:
				'Crea rutas y edita el orden, el agente asignado y el horario de forma dinámica. Esta funcionalidad es excelente si tu operación es flexible y cambia rápidamente en todo momento.',
			showWarninigs: 'Ver conflicto',
			warningscomment:
				'El Horario establecido para ejecutar la asignación dinámica no cubre  algunos periodos de ejecución (horario) de algunas tareas a asignar,  ¿Deseas generar la asignación dinámica? ó ¿Deseas ver las tareas en conflicto? ',
			warningAddress: 'Al cambiar la dirección se generará una solicitud de actualización de ubicación de Sucursal/Cliente',
			warningTaskType: 'Algunos tipos de tarea tienen restricción con alguna compañía(s) y/o sucursal(s)',
			generateRoutesByRange: '¿Generar las rutas entre las fechas seleccionada?',
			generateRoutesWithTheFarthest: 'Generar rutas con la tarea más lejana',
			generateRoutesWithTheFarthestInfo: 'Las tareas deben tener la misma prioridad y tener el mismo periodo de fechas',
			generateRoutesWithTheFarthestROInfo: 'La ruta sólo tomará como variable la distancia',
			generateRouteWithLastLocation: 'Tomar última ubicación para siguiente Ruta',
			generateRouteWithLastLocationInfo:
				'Al generar más de una ruta con el mismo Agente tomará como punto de partida la ubicación de la última tarea',
			enableExpensesRoutes: '¿Habilitar viáticos?',
			generate: 'Generar',
			noDate: 'Sin fecha',
			noData: 'Sin información',
			analitycsMessageDatePart1: 'No tienes suficiente información con las fechas ',
			analitycsMessageDatePart2: ', inténtalo de nuevo',
			changeParameterAnalitycs: 'Cambia los parámetros  para generar tu gráfica',
			noImages: 'No hay imágenes en esta tarea',
			noServiceFile: 'Tarea sin archivos',
			loading: 'Cargando...',
			noDataTeamActive: 'No cuentas con equipos activados en esta sección. Crea un nuevo equipo.',
			noDataTeamDisabled: 'No tienes equipos desactivados en esta sección.',
			noDataUserActive: 'No cuentas con usuarios activados en esta sección. Crea un nuevo usuario.',
			noMyPartners: 'No cuentas con socios de negocio aún, agrega y crece tu red de negocio con fielder',
			noIamNotPartner: 'Aún no has sido integrado por un socio',
			noInvitations: 'No cuentas con invitaciones, agrega socios y crece tu red de negocio con fielder',

			noDataUserDisable: 'No cuentas con usuarios desactivados en esta sección.',
			noDataCompanyActive: 'No cuentas con compañías activadas en esta sección. Crea una nueva compañía.',
			noDataCompanyDisable: 'No cuentas con compañías desactivadas en esta sección.',
			noDataBranchActive: 'No cuentas con sucursales activadas en esta sección. Crea una nueva sucursal.',
			noDataBranchToUpdate: 'No hay sucursal para actualizar',
			noDataBranchDisable: 'No cuentas con sucursales desactivadas en esta sección',
			viewRoutes: 'Ver Rutas',
			noDataFilter: 'No hay datos que cumplan con este criterio de búsqueda.',
			appearance: 'Apariencia: ',
			considerDateRange: 'Considerar rango de fechas',
			iconography: 'Iconografía',
			withoutIdCustomGroup: 'Sin Id Externo',
			numberOfTask: 'Número de tareas',
			numberOfAgents: 'Cantidad de agentes',
			editPhoto: 'Editar foto',
			edit: 'Editar',
			show: 'Ver',
			hide: 'Esconder',
			more: 'más',
			showMap: 'Ver mapa',
			showMore: 'Ver más',
			showTable: 'Ver tabla',
			menu: 'Menú',
			element: 'Elemento',
			language: 'Idioma',
			portugues: 'Portugués',
			english: 'Ingles',
			spanish: 'Español',
			login: 'Inicio',
			user: 'Usuario',
			tokenName: "Nombre del Token",
			tokenTip: "Consejo: elige un nombre descriptivo para que puedas saber que Token creará requests a la API de Fielder para tu integración con otro sistema.",
			roleType: "Tipo de rol",
			apiAdministrator: "Administrador API",
			apiManager: "Gerente API",
			activityLog: "Registro de actividad",

			pass: 'Contraseña',
			forgotPass: 'He olvidado mi contraseña',
			login_button: 'Ingresar',
			sendEmailAction: 'Enviar correo',
			sendEmailError: 'Correo no valido',
			profile: 'Perfil',
			selectAll: 'Seleccionar todo',
			showDetail: 'Ver detalle',
			uncheckEverything: 'Desmarcar todo',
			newLocationAvailable: 'Nueva localización disponible',
			newLocation: 'Nueva localización',
			outOfBranchOrClientSchedule: 'Fuera del horario del cliente o sucursal',
			missingFormsToSign:"Faltan formularios por firmar",
			advancedAnalytics: 'Analíticos Avanzados',
			mailIntegration: 'Integración de correo',
			advancedAnalyticsDescription:
				'Accede a Gráficos y Analíticos avanzados sobre tu operación relacionada a KPIs de Clientes, Sucursales, Tareas, Agentes y Socios de Negocio.',
			mailIntegrationDescription: 'Crea tareas a través del correo proporcionado por la plataforma',

			// Role Management
			roleManagementDescription: 'Aplicación para generar permisos y roles centrados en sus cuentas.',
			roleManagement: 'Administración de Roles',
			permissions: 'Permisos',
			roles: 'Roles',
			role: 'Rol',
			default: 'Predeterminados',
			custom: 'Personalizados',
			specific: 'Específico',
			webAndManagement: 'Web y Management',
			noPermissionsFound: 'No se encontraron permisos',
			noRolesFound: 'No se encontraron roles',
			noNotificationsFound: 'No se encontraron notificaciones',
			newPermission: 'Nuevo Permiso',
			newRole: 'Nuevo Rol',
			newNotification: 'Nueva Notificación',
			editRole: 'Editar Rol',
			editPermission: 'Editar Permiso',
			editNotification: 'Editar Notificación',
			addPermissions: 'Agregar Permisos',
			addNotifications: 'Agregar Notificaciones',
			deleteConfirmationNotification: '¿Estás seguro que deseas eliminar la(s) notificación(es)?',
			errorTryingToDeleteNotifications: 'Error al tratar de eliminar una notificación',
			someNotificationHasRole: 'Al menos una notificación seleccionada se encuentra presente en uno o más roles',
			addRole: 'Agregar Rol',
			permissionsAdded: 'Permisos Agregados',
			notificationsAdded: 'Notificaciones Agregadas',
			roleSearch: 'Buscar...',
			permissionActive: 'Activo',
			basic: 'Básico',
			roleModules: 'Módulos',
			general: 'General',
			deselect: 'Deseleccionar',
			accountConfiguration: 'Configuración de la cuenta',
			roleDashboard: 'Dashboard',
			viewDeactivates: 'Visualizar desactivados',
			deactivate: 'Desactivar',
			activate: 'Activar',
			roleImport: 'Importar',
			roleExport: 'Exportar',
			deletePartnership: 'Eliminar sociedad',
			roleAdd: 'Agregar',
			assignTeam: 'Asignación de equipos',
			assignPartner: 'Asignación de socios',
			assignAsset: 'Asignación de activos',
			assignInventory: 'Asignación de inventarios',
			assignForm: 'Asignación de formularios',
			restartRoutes: 'Reiniciar rutas',
			rescheduleRoutes: 'Reagendar rutas',
			addTaskByFolio: 'Agregar tarea por folio',
			deleteTaskFromRoute: 'Eliminar tarea de la ruta',
			exportTasks: 'Exportar tareas',
			generateAssignments: 'Generar asignaciones',
			generateOptimizations: 'Generar optimizaciones',
      editAutoAssignTemplate: 'Editar Template Auto-Asignación',
			viewTemplates: 'Visualizar templates',
			createTemplates: 'Crear templates',
			editTemplates: 'Editar templates',
			deleteTemplates: 'Eliminar templates',
			autoAssignTemplateDeleted: 'Éste template de auto asignación fue eliminado',
			autoAssignTemplateIsInactive: 'El template de auto asignación está inactivo',
      msgAutoAssignNotAllowHasGroupRoute: 'Las tareas con grupos o rutas no se pueden Auto-Asignar',
      msgAutoAssignNotAllowTaskStatus: 'La(s) tarea(s) en el estado actual no se puede(n) Auto-Asignar',
      msgAutoAssignNotAllowClientUsers: 'Los clientes no pueden realizar la Auto-Asignación',
      msgAutoAssignNotAllowedPermissions: 'No tienes los permisos necesarios para realizar la Auto-Asignación',
      msgAutoAssignInitiateProcess: 'Proceso de Auto-Asignación Iniciado...',
      msgNoTasksWereAutoAssigned: 'No se pudo auto asignar ninguna tarea, por favor revise el estado de las tareas antes de realizar la auto asignacion',
			web: 'Web',
			pushNotification: 'Push Notification',
			startPath: 'Iniciar trayecto',
			toArrive: 'Llegar',
			toStart: 'Iniciar',
			toPause: 'Pausar',
			toFinish: 'Finalizar',
			toReject: 'Rechazar',
			createComment: 'Crear comentario',
			reasonForChange: 'Razón del cambio',
			selectAStatus: 'Selecciona un estatus',
			createEmergencies: 'Crear emergencias',
			iAmHere: 'Ya llegué',
			messageMultiSelect: 'Para poder seleccionar una subtarea por favor deselecciona su tarea padre',
			messageSelectParner: 'Por favor selecciona un socio',
			messageSelectCompany: 'por favor selecciona una compañía',
			messageSelectTypeTask: 'Por favor selecciona un tipo de tarea',
			startTask: 'Iniciar Tarea',
			restartTasks: 'Reiniciar Tareas',
			addTasksToGroup: 'Agregar tareas al grupo',
			enableNotifications: 'Habilitar notificaciones en',
			sendNotifications: 'Enviar notificaciones al usuario cuando',
			selectMoreOptions: 'Puedes seleccionar una o varias opciones',
			permissionCreatedSuccessfully: 'Permiso creado exitosamente',
			permissionUpdatedSuccessfully: 'Permiso modificado exitosamente',
			notificationCreatedSuccessfully: 'Notificación creada exitosamente',
			notificationUpdatedSuccessfully: 'Notificación modificada exitosamente',
			roleCreatedSuccessfully: 'Rol creado exitosamente',
			roleUpdatedSuccessfully: 'Rol modificado exitosamente',
			roleSearchBy: 'Buscar por Compañía, Cliente y Sucursal',
			rolePartner: 'Compañías, sucursales y clientes del Socio',
			selectedEntities: 'Compañías, sucursales y clientes seleccionados',
			selectedPermissions: 'Permisos seleccionados',
			selectedNotifications: 'Notificaciones seleccionadas',
			dataSheet: 'Ficha Técnica',
			userList: 'Lista de usuarios',
			userDescription: 'Selecciona cualquier usuario para ver su ficha técnica',
			usersWithoutRole: 'Usuarios sin rol',
			usersWithRole: 'Usuarios con rol',
			assignRole: 'Asignar Rol',
			userCreatedSuccessfully: 'Los roles seleccionados fueron asignados al usuario satisfactoriamente',
			userUpdatedSuccessfully: 'Los roles de usuario seleccionados se actualizaron satisfactoriamente',
			editUser: 'Editar Usuario',
			congratAgent: 'Felicitar agente',
			defaultDuplicationNameLabel: 'Dejar vacío para el nombre predeterminado: ',
			copy: 'copia',
			deleteRoleLabel: '¿Estás seguro que deseas eliminar el rol?',
			deleteRoleWarning: 'Una vez eliminado, no se podrá recuperar',
			deletePermissionLabel: '¿Estás seguro que deseas eliminar el permiso?',
			deletePermissionWarning: 'Una vez eliminado, no se podrá recuperar',
			error: 'Error',
			roleDeletionErrorUserAssigned: 'No se pudo eliminar el rol, verifique que ningun usuario lo tenga asignado',
			permissionDeletionErrorRoleAssigned: 'No se pudo eliminar el permiso, verifique que ningun rol lo tenga asignado',

			permission_USERS_tasks_0: 'Visualizar',
			permission_USERS_tasks_1: 'Editar',
			permission_USERS_tasks_2: 'Reagendar',
			permission_USERS_tasks_3: 'Cancelar',
			permission_USERS_tasks_4: 'Asignación de equipos',
			permission_USERS_tasks_5: 'Asignación de agentes',
			permission_USERS_tasks_6: 'Asignación de socios',
			permission_USERS_tasks_7: 'Asignación de Activos',
			permission_USERS_tasks_8: 'Asignación de Inventarios',
			permission_USERS_tasks_9: 'Asignación de Formularios',
			permission_USERS_tasks_10: 'Importar',
			permission_USERS_tasks_11: 'Exportar',
			permission_USERS_tasks_12: 'Crear',

			permission_CLIENT_USERS_tasks_0: 'Visualizar',
			permission_CLIENT_USERS_tasks_1: 'Editar',
			permission_CLIENT_USERS_tasks_2: 'Reagendar',
			permission_CLIENT_USERS_tasks_3: 'Cancelar',
			permission_CLIENT_USERS_tasks_12: 'Crear',

			doNotApply: 'No aplicar',

			assetTaskStatusPermission:'A partir de qué momento debe de llenar el activo el agente',


			formTaskStatusPermission: 'A partir de qué momento debe de llenar el formulario el agente',

			formTaskStatusPermission0: 'En cualquier momento',
			formTaskStatusPermission1: 'Cuando se acepta la tarea',
			formTaskStatusPermission2: 'Cuando va en camino',
			formTaskStatusPermission3: 'Cuando llegó el agente',
			formTaskStatusPermission4: 'Cuando inicia la tarea',

			permission_AGENT_tasks_0: 'Rechazar',
			permission_AGENT_tasks_1: 'Cancelar',
			permission_AGENT_tasks_2: 'Subir fotos',
			permission_AGENT_tasks_3: 'Pausar',
			permission_AGENT_tasks_4: 'Crear comentarios',
			permission_AGENT_tasks_5: 'Crear tarea',
			permission_AGENT_tasks_6: 'Cambiar tipo de tarea',
			permission_AGENT_tasks_7: 'Crear subtarea',

			permission_USERS_accountconfiguration_0: 'Visualizar',
			permission_USERS_accountconfiguration_1: 'Editar',

			permission_USERS_administrators_0: 'Crear',
			permission_USERS_administrators_1: 'Visualizar',
			permission_USERS_administrators_2: 'Editar',
			permission_USERS_administrators_3: 'Visualizar desactivados',
			permission_USERS_administrators_4: 'Desactivar',
			permission_USERS_administrators_5: 'Eliminar',
			permission_USERS_administrators_6: 'Importar',
			permission_USERS_administrators_7: 'Exportar',

			permission_USERS_agents_0: 'Crear',
			permission_USERS_agents_1: 'Visualizar',
			permission_USERS_agents_2: 'Editar',
			permission_USERS_agents_3: 'Visualizar desactivados',
			permission_USERS_agents_4: 'Desactivar',
			permission_USERS_agents_5: 'Eliminar',
			permission_USERS_agents_6: 'Importar',
			permission_USERS_agents_7: 'Exportar',
			permission_USERS_agents_8: 'Felicitar agentes',

			permission_USERS_clients_0: 'Crear',
			permission_USERS_clients_1: 'Visualizar',
			permission_USERS_clients_2: 'Editar',
			permission_USERS_clients_3: 'Visualizar desactivados',
			permission_USERS_clients_4: 'Desactivar',
			permission_USERS_clients_5: 'Eliminar',
			permission_USERS_clients_6: 'Importar',
			permission_USERS_clients_7: 'Exportar',

			permission_AGENT_clients_0: 'Visualizar',
			permission_AGENT_clients_1: 'Crear',

			permission_USERS_companies_0: 'Crear',
			permission_USERS_companies_1: 'Visualizar',
			permission_USERS_companies_2: 'Editar',
			permission_USERS_companies_3: 'Visualizar desactivados',
			permission_USERS_companies_4: 'Desactivar',
			permission_USERS_companies_5: 'Eliminar',
			permission_USERS_companies_6: 'Importar',
			permission_USERS_companies_7: 'Exportar',

			permission_USERS_managers_0: 'Crear',
			permission_USERS_managers_1: 'Visualizar',
			permission_USERS_managers_2: 'Editar',
			permission_USERS_managers_3: 'Visualizar desactivados',
			permission_USERS_managers_4: 'Desactivar',
			permission_USERS_managers_5: 'Eliminar',
			permission_USERS_managers_6: 'Importar',
			permission_USERS_managers_7: 'Exportar',

			permission_USERS_partners_0: 'Agregar',
			permission_USERS_partners_1: 'Visualizar',
			permission_USERS_partners_2: 'Eliminar sociedad',
			permission_USERS_partners_3: 'Aceptar invitaciones',

			permission_USERS_teams_0: 'Visualizar',
			permission_USERS_teams_1: 'Editar',
			permission_USERS_teams_2: 'Desactivar',
			permission_USERS_teams_3: 'Eliminar',
			permission_USERS_teams_4: 'Visualizar desactivados',
			permission_USERS_teams_5: 'Crear',

			permission_USERS_branches_0: 'Crear',
			permission_USERS_branches_1: 'Visualizar',
			permission_USERS_branches_2: 'Editar',
			permission_USERS_branches_3: 'Visualizar desactivados',
			permission_USERS_branches_4: 'Desactivar',
			permission_USERS_branches_5: 'Eliminar',
			permission_USERS_branches_6: 'Importar',
			permission_USERS_branches_7: 'Exportar',

			permission_AGENT_branches_0: 'Visualizar',
			permission_AGENT_branches_1: 'Crear',

			permission_USERS_analytics_0: 'Visualizar',

			permission_USERS_assets_0: 'Visualizar',
			permission_USERS_assets_1: 'Editar categoría',
			permission_USERS_assets_2: 'Editar activo',
			permission_USERS_assets_3: 'Importar y Exportar activos',

			permission_AGENT_assets_0: 'Visualizar',
			permission_AGENT_assets_1: 'Editar',

			permission_USERS_autoassign_0: 'Visualizar templates',
			permission_USERS_autoassign_1: 'Editar templates',
			permission_USERS_autoassign_2: 'Crear templates',
			permission_USERS_autoassign_3: 'Eliminar templates',

			permission_USERS_billing_0: 'Visualizar',

			permission_USERS_calendar_0: 'Visualizar',

			permission_CLIENT_USERS_calendar_0: 'Visualizar',

			permission_USERS_dashboard_0: 'Visualizar',

			permission_CLIENT_USERS_dashboard_0: 'Visualizar',

			permission_USERS_dynamicallocation_0: 'Generar asignaciones',

			permission_USERS_forms_0: 'Visualizar templates',
			permission_USERS_forms_1: 'Editar templates',
			permission_USERS_forms_2: 'Crear templates',
			permission_USERS_forms_3: 'Eliminar templates',
			permission_USERS_forms_4: 'Editar formulario de tarea',
			permission_USERS_forms_5: 'Eliminar formulario de tarea',
			permission_USERS_forms_6: 'Descargar formularios de tarea',
			permission_USERS_forms_7: 'Importar template',

			permission_CLIENT_USERS_forms_0: 'Visualizar',
			permission_CLIENT_USERS_forms_4: 'Editar formulario de tarea',

			permission_AGENT_forms_0: 'Visualizar',
			permission_AGENT_forms_1: 'Editar',

			permission_USERS_grouptasks_0: 'Crear',
			permission_USERS_grouptasks_1: 'Visualizar',
			permission_USERS_grouptasks_2: 'Agregar tareas al grupo',
			permission_USERS_grouptasks_3: 'Desvincular tareas',
			permission_USERS_grouptasks_4: 'Editar',
			permission_USERS_grouptasks_5: 'Descargar imágenes',
			permission_USERS_grouptasks_6: 'Exportar tareas',
			permission_USERS_grouptasks_7: 'Eliminar',

			permission_AGENT_grouptasks_0: 'Visualizar',
			permission_AGENT_grouptasks_1: 'Cancelar',
			permission_AGENT_grouptasks_2: 'Pausar',
			permission_AGENT_grouptasks_3: 'Ejecutar',

			permission_USERS_inventory_0: 'Visualizar',
			permission_USERS_inventory_1: 'Editar categoría',
			permission_USERS_inventory_2: 'Editar inventario',
			permission_USERS_inventory_3: 'Importar y exportar inventarios',

			permission_AGENT_inventory_0: 'Visualizar',
			permission_AGENT_inventory_1: 'Editar',

			permission_USERS_isochronoes_0: 'Visualizar',
			permission_USERS_isochronoes_1: 'Editar',
			permission_USERS_isochronoes_2: 'Eliminar',
			permission_USERS_isochronoes_3: 'Crear',

			permission_USERS_resourceallocation_0: 'Generar asignaciones',

			permission_USERS_routeoptimization_0: 'Generar optimizaciones',

			permission_USERS_routes_0: 'Visualizar',
			permission_USERS_routes_1: 'Editar',
			permission_USERS_routes_2: 'Cancelar',
			permission_USERS_routes_3: 'Reiniciar tareas de la ruta',
			permission_USERS_routes_4: 'Reiniciar tareas canceladas',
			permission_USERS_routes_5: 'Reiniciar ruta',
			permission_USERS_routes_6: 'Reagendar ruta',
			permission_USERS_routes_7: 'Reorganizar ruta',
			permission_USERS_routes_8: 'Agregar tarea por folio',
			permission_USERS_routes_9: 'Eliminar tarea de la ruta',
			permission_USERS_routes_10: 'Reasignar agente',

			permission_AGENT_routes_0: 'Visualizar',
			permission_AGENT_routes_1: 'Cancelar',
			permission_AGENT_routes_2: 'Rechazar',
			permission_AGENT_routes_3: 'Crear emergencias',

			permission_USERS_skills_0: 'Visualizar',
			permission_USERS_skills_1: 'Eliminar',
			permission_USERS_skills_2: 'Crear',

			permission_USERS_sms_0: 'Visualizar',
			permission_USERS_sms_1: 'Editar',

			permission_USERS_tasktypepreferences_0: 'Visualizar',
			permission_USERS_tasktypepreferences_1: 'Editar',

			permission_CLIENT_USERS_tasktypepreferences_0: 'Visualizar',

			mobile: 'Móvil',
			deselectAllNotificationsTooltip: 'Deseleccionar todas las notificaciones de este grupo',
			selectAllNotificationsTooltip:
				'Restablecer configuración predeterminada, seleccionar todas las notificaciones del grupo',

			usersNotifications: 'Notificaciones de Usuarios',
			myNotifications: 'Mis Notificaciones',
			configureYourNotifications: 'Configura tus notificaciones',
			configureNotificationsDescription:
				'Selecciona cómo te gustaría recibir las notificaciones para las siguientes actividades',
			configureUsersNotifications: 'Configura las notificaciones para los usuarios de tu cuenta',
			configureUsersNotificationsDescription: 'Selecciona cuáles notificaciones deberá recibir cada tipo de usuario',
			smsNotificationExtraCost: 'Habilitar los SMS resultaría en un costo adicional a las notificaciones',

			accepted: 'Aceptado',
			withoutAccepting: 'Sin aceptar',

			notificationAction_0: 'Tarea Creada',
			notificationAction_65: 'Tarea Creada por Cliente',
			notificationAction_66: 'Tarea Creada por Mail',
      notificationAction_67: 'La tarea autoasignada no es para hoy',
			notificationAction_1: 'Tarea Asignada a un Agente',
			notificationAction_14: 'Tarea Asignada a un Socio',
			notificationAction_48: 'Tarea Reasignada a un Socio',
			notificationAction_62: 'Tarea Asignada a un Grupo de Tareas',
			notificationAction_32: 'Tarea Asignada a un Equipo',
			notificationAction_33: 'Formulario Asignado a tarea',
			notificationAction_35: 'Inventario Asignado a tarea',
			notificationAction_34: 'Activo Asignado a tarea',
			notificationAction_2: 'Tarea Aceptada por Agente',
			notificationAction_5: 'Tarea Rechazada por Agente',
			notificationAction_3: 'Tarea Iniciada',
			notificationAction_24: 'Agente llegó a la Tarea',
			notificationAction_7: 'Tarea Cancelada',
			notificationAction_51: 'Agente en Camino',
			notificationAction_53: 'Tarea Finalizada',
			notificationAction_10: 'Cambió el horario de la Tarea',
			notificationAction_13: 'Tarea Cancelada por Socio',
			notificationAction_20: 'La ubicación de la Tarea cambió',
			notificationAction_54: 'Se agregó un comentario a la Tarea',
			notificationAction_55: 'La Tarea fue Editada',
			notificationAction_40: 'La Tarea fue Reiniciada',
			notificationAction_80: 'Formulario no aprobado',
			notificationAction_81: 'Form aprobado',

			notificationAction_23: 'Ruta asignada a un Agente',
			notificationAction_57: 'Tareas de Ruta reiniciadas',
			notificationAction_15: 'Ruta Aceptada',
			notificationAction_16: 'Ruta Rechazada',
			notificationAction_17: 'Ruta Finalizada',
			notificationAction_37: 'Cambió el horario de la Ruta',
			notificationAction_38: 'Se Reinició la Ruta',
			notificationAction_18: 'Ruta Cancelada',
			notificationAction_9: 'Ruta Iniciada',
			notificationAction_21: 'Ruta Eliminada',
			notificationAction_25: 'Tarea Agregada a Ruta',
			notificationAction_22: 'Tarea Eliminada de la Ruta',
			notificationAction_56: 'Ruta Actualizada',
			notificationAction_58: 'Incidente reportado en la Ruta',
			notificationAction_59: 'Reiniciar tareas canceladas de la Ruta',

			notificationAction_27: 'Agente Asignado al Grupo de Tareas',
			notificationAction_60: 'Grupo de Tareas Cancelado',
			notificationAction_61: 'Grupo de Tareas en Pausa',
			notificationAction_62: 'Tarea añadida al Grupo de Tareas',
			notificationAction_63: 'Tarea eliminada de Grupo de Tareas',
			notificationAction_64: 'Grupo de Tareas actualizado',

			notificationAction_11: 'Invitación de Socio',
			notificationAction_12: 'Sociedad Eliminada',

			botName:'Asistente Virtual',

			// isochronoes
			area: 'Área',
			originPoint: 'Punto de origen',
			none_agents: 'Sin agentes',
			noneteam: 'Sin equipos',
			circle: 'Círculo',
			polygon: 'Polígono',
			errorMessageIsoDates: 'Las fechas son incorrectas, verifíca que la primera sea menor a la segunda',
			clear: 'Limpiar',
			loginInvalidUserPassword: 'Usuario y/o Contraseña inválidos',
			loginDisabledAccount: 'Cuenta deshabilitada, contacte al administrador',
			loginDisabledUser: 'Usuario deshabilitado, comuníquese con su administrador para obtener más información',
			loginAgentAccesMessage:
				'Como agente no tienes acceso a la aplicación web, por favor descaga la app movil de agente para poder ingresar.',
			loginAgentAccessDenied: 'Agente no tiene acceso a Fielder Web',
			showIsochronous: 'Mostrar isócronas',
			showAgentsStartLocation: 'Mostrar punto partida de los agentes',
			isoType: 'Tipo',
      editIsochronous: 'Editar Isócrona',
			addIsochronous: 'Agregar Isócrona',
			searchLocation: 'Buscar Ubicación',
			accountDoesNotHaveIsochronous: 'No cuentas con isócronas activas en esta sección. Crea una nueva.',
			noneIsochronoes: 'Aún no tienes isócronas creadas',
			confirmDeleteIscronoes: '¿Estás seguro que deseas eliminar las isócronas?',
			selectIsochronous: 'Selecciona una o más isócronas',

			// Form Validation Errors
			requiredName: 'Se requiere nombre',
			isRequired: 'se requiere',
			requiredIsochronousName: 'Se requiere nombre de la isócrona',
			requiredAddress: 'Se requiere dirección',

			gatheringLocationInformation: 'Recopilando información de ubicación...',
			creatingIsochronousArea: 'Creando área isócrona...',
			assigningAgentsTeams: 'Asignando agentes y equipos...',
			preparingInformation: 'Preparando información...',
			generatingNewIsochronousData: 'Generando nuevos datos isócronos...',
			updatingAccountData: 'Actualizando datos de la cuenta...',
			savingInformation: 'Guardando información...',
			// Resource allocation
			allocateResources: 'Asignar recursos',
			resourceAllocation: 'Asignación  de recursos',
			errorEnsureInformationCorrect: 'Por favor, valida que la información sea correcta e intentalo más tarde',
			resourceAllocationSuccess:
				'El proceso de Asignación de recursos se ha creado correctamente, en unos momentos recibirás un correo con los resultados de tu asignación',

			// ResourceAllocation and Dynamic Allocation variables
			routeTaskVariables: 'Variables de Ruta',
			routeTaskAddVariable: 'Agregar variable',

			// My operation
			downloadTaskFileName: 'fieldertasks',

			downloadManagerFileName: 'gerentesFielder.csv',
			downloadDisableManagerFileName: 'gerentesDesactivadosFielder.csv',

			downloadAdministratorFileName: 'administradoresFielder.csv',
			downloadDisableAdministratorFileName: 'administradoresDesactivadosFielder.csv',

			downloadClientsFileName: 'clientesFielder.csv',
			downloadDisableClientsFileName: 'clientesDesactivadosFielder.csv',

			downloadCompaniesFileName: 'compañíasFielder.csv',
			downloadDisableCompaniesFileName: 'compañíasDesactivadasFielder.csv',

			downloadBranchesFileName: 'sucursalesFielder.csv',
			downloadDisableBranchesFileName: 'sucursalesDesactivadasFielder.csv',

			downloadAgentsFileName: 'agentesFielder.csv',
			downloadDisableAgentsFileName: 'agentesDesactivadosFielder.csv',

			generateZip: 'Generar Zip',
			modulesZipSuffix: 'modulos.zip',

			statusModifiedByUser: 'Estado modificado por usuario',
			theTaskWasNotFound: 'La tarea no fue encontrada',
			notFound:'No encontrado',
			taskStatusNotAllowed: 'Estatus de tarea no permitido',
			canceledTask: 'Tarea cancelada',
			taskIsInactive: 'La tarea está inactiva',
			tryAgainUpdateStatus: 'No fue posible actualizar el estado de la tarea, intente nuevamente',
      tryAgainAutoAssignTasks: 'No fue posible auto-asignar la(s) tarea(s), intente nuevamente',
			idTaskName:'ID de Tarea',
			idTask: 'ID Fielder',
			idCustom: 'ID Personalizado',
			idCustomBranch: 'ID Personalizado de la Sucursal',
			branchNameText: 'Nombre de la Sucursal',
			companyIdCustom: 'ID Personalizado Compañia',
			companyIdAndName:'Id-Nombre de la Compañia',
			companyName: 'Nombre de la Compañia',
			parentCompanyNameText: 'Nombre Compañia Padre',
			addressCompany:'Dirección de la Compañia',

			idParentTask: 'ID Tarea Padre',
			parentTask: 'Tarea Padre',
			parentTaskType: 'Tipo de Tarea Padre',
			deleteTaskTooltip: 'Borrar Tarea(s)',
			disableTaskTooltip: 'Desactivar Tarea(s)',
			deleteRouteTooltip: 'Borrar Ruta(s)',
			deleteGroupTaskTooltip: 'Borrar Grupo(s) de tareas',
			taskRouteReturnedNoResults: 'La ruta de la tarea no obtuvo resultados',
			taskRouteAvailableForTaskStatus:
				'Las rutas de tareas solo están disponibles para las tareas con estado Agente en Camino',
			document: 'Documento',
			documents: 'Documentos',
			folio: 'Folio',
			caseNumber:'Número de caso',
			noFolioFolder: 'Sin_folio',
			noFolio: 'Sin folio',
			noForm: 'Sin formulario seleccionado',
			noWorkingHourFrom: 'Sin horario de entrada',
			noWorkingHourTo: 'Sin horario de salida',
			group: 'Grupo',
			statusGroup: 'Estatus del grupo',
			groupTask: 'Grupo de tareas',
			unknownCategory: 'Otros',
			module: 'Módulo',
			severity: 'Prioridad',
			status: 'Estado',
			notAvailable: 'No disponible',
			tags: 'Tags',
			tag: 'Tag',
			agentStartLocation: 'Dirección de ejecución',
			agentStartLocationExcel: 'Dirección de inicio de la tarea',
			routeTimeTraveled: 'Tiempo Estimado de Recorrido de la Ruta',
			pauseFormattedHour: 'Hora de pausado de la tarea',
			pauseFormattedDate: 'Fecha de pausado de la tarea',
			pauseResumeFormattedHour: 'Hora de reanudación de la tarea pausada',
			pauseResumeFormattedDate: 'Fecha de reanudación de la tarea pausada',

			countPause: 'Número de pausas',
			countResume: 'Número de reanudaciones',

			routeDistanceTraveled: 'Distancia estimada de la Ruta',
			agentEndLocation: 'Dirección de finalizacion',
			agentEndLocationExcel: 'Dirección de terminación de la tarea',
			name: 'Nombre',
			names:'nombres',
			lastConnection: 'Última conexión',
			lastDataObtained: 'Último dato obtenido',
			lastName: 'Apellido',
			idCountryCode: 'Id país',
			selectedElements: 'Elementos Seleccionados',
			branchName: 'Sucursal',
			idCustomBranchDuplicated: 'Id personalizado o nombre duplicado',
			idBranches: 'Id Sucursales',
			noBranchLink: 'No vinculado a sucursales',
			country: 'País',
			accountByCountry: 'Cuenta por país',
			alreadySenInvitacion: 'Ya has mandado una invitación a este correo',
			partnertNotFound: 'El socio que estas tratando de invitar no existe o el correo esta equivocado',
			pendentingInvitation: 'Invitación pendiente',
			unnamed: 'Sin nombre',

			createRegion: 'Crear Región',
			editRegion: 'Editar Región',
			deleteRegion: '¿Deseas eliminar la region?',
			createRegionSuccessMessage: 'A região foi criada corretamente',
			createRegionErrorMessage: 'Sua região não foi criada corretamente, tente novamente mais tarde',
			region: 'Región',
			newRegion: 'Nueva región',
			agent: 'Agente',
			startWay: 'Fecha Programada',
			startTime: 'Fecha Programada',
			onWayDateExcel: 'Fecha de inicio de trayecto',
			onWayHourExcel: 'Hora de inicio de trayecto',
			onWayDate: 'Fecha en camino',
			endDateExcel: 'Fecha de Término de la tarea',
			endHourExcel: 'Hora de Término de la tarea',
			endAgent: 'Fecha de Término',
			endHour: 'Hora de Término',
			score: 'Calificación',
			timeZone: 'Zona horaria',
			rescheduledDate: 'Fecha recalendarizada',
			rescheduledHour: 'Hora recalendarizada',
			rescheduledComment: 'Comentarios por recalendarizacion',
			rescheduledCommentDate: 'Fecha de comentarios por recalendarizacion',
			rescheduleUserName:'Usuario que recalendarizó',
			cancelReason: 'Motivo de cancelación',
			taskActivities: 'Actividades de la tarea',
			reason: 'Razón',
			cancelBy: 'Cancelado por',
			cancelComment: 'Comentarios de cancelación',
			taskPauseComment: 'Comentarios del pausado de la tarea',
			taskRejectionDate: 'Fecha de rechazo de la tarea',
			taskRejectTime: 'Hora de rechazo de la tarea',
			taskRejectDependency: 'Dependencia de rechazo de la tarea',
			taskRejectionReason: 'Razón de rechazo de la tarea',
			taskRejectionComment: 'Comentarios de rechazo de la tarea',

			mobileInformation: 'Información móvil',
			locationPrecisePermission: 'Permiso de localización precisa',
			locationApproximatePermission: 'Permiso de localización aproximada',
			locationBackgroundPermission: 'Permiso de localización en segundo plano',

			taskGroupNotification:
				'Solo se muestran los Grupos de Tareas en estatus "Creado", si el Grupo se encuentra en otro estatus, crea un Nuevo Grupo ó asigna la tarea de forma individual',

			searchByTask: 'Búsqueda por tarea',
			searchByForm:'Buscar por formulario',
			userCancelComment: 'Comentarios de cancelación del usuario',
			executionMode: 'Modo de ejecución: SIN internet',
			kmsDistance: 'Distancia estimada en km',
			messageForm: 'Falta la fecha final',
			messageTo: 'Falta la fecha inicial',
			messagePrevious: 'La Fecha fin debe ser posterior a la Fecha inicio',
			messageFieldEmpty: 'Campo Busqueda vacio',
			messageGeneralSearch: 'Búsqueda general',
			disableAvacedSearch: 'Elimina la búsqueda avanzada para utilizar la búsqueda general',
			messageEmptyTasks: 'No hay tareas seleccionadas',
			messageEmptyGroupTasks: 'No hay grupo de tareas seleccionados',
			messageTaskGroupDeletePartner:'Haz seleccionado uno o mas grupos asignados por un partner y no puedes eliminarlos',
			messageNoMoreThanTwoTask: 'Debes selecionar 2 o más tareas para crear un grupo de tareas',
			messageNoMoreThanTwoTaskRoutes: 'Debes selecionar 2 o más tareas para generar una ruta',
			messageMusntDelete: 'No puedes eliminar estas tareas',
			messageCantRestore: 'No se puede recuperar esta tarea, intentelo mas tarde',
			messageTaskDeleteError: 'No puedes eliminar estas tareas debido a que estan asiganadas a otros recursos.',
			messageTaskDisableError:
				'No se pudieron desactivar la(s) tareas. Unicamente tareas esperando agente o gestionando pueden ser eliminadas.',
			messageTaskWithRoute: 'No puedes eliminar esta tarea porque está ligada a una ruta',
			messageTaskDeleteSuccess: 'Tarea(s) eliminada(s) correctamente',
			messageTaskDisableSucess: 'Tarea(s) desactivadas correctamente',
			messageConfirmDeleteTasks: '¿Usted esta seguro en eliminar esta(s) tarea(s)?',
			messageConfirmRestoreTasks: '¿Usted esta seguro en restaurar esta(s) tarea(s)?',
			messageConfirmDeleteRoutes: '¿Usted esta seguro en eliminar esta(s) rutas(s)?',
			messageConfirmDeleteGroupTasks: '¿Usted esta seguro en eliminar estos grupos de tareas?',
			messageConfirmaDisableTask: '¿Usted esta seguro en desactivar esta(s) tarea(s)?',
			messageConfirmaCancelTask: '¿Por qué deseas cancelar tu tarea?',
			cancelInformation:'*Recuerda que las tareas canceladas por los administradores y/o gerentes quedarán completamente canceladas y tendrás que crear una nueva tarea. Las tareas canceladas por los Agentes y/o socios pueden ser re-asignadas.',
			messageMoreTasksSelected: 'Se seleccionó más de una tarea',
			messageMusntReschedule: 'No puede recalendarizar una tarea cancelada',
			messageErrorReschedule:
				'No puede recalendarizar una tarea con estatus Ejecutandose, En camino, Llegando, Cancelado, Finalizado o Cancelado por un socio',
			messageErrorRescheduleClient:
				'No puede recalendarizar una tarea con estatus Ejecutandose, En camino, Llegando, Cancelado, Finalizado',
			messageErrorRescheduleGpoTask:
				'La tarea se encuentra en un Grupo de Tareas, para tomar una acción sobre de ella debes primero desvincularla del grupo',
			messageAutoassignError:
				'No se puede asignar un agente a la tarea cuando se encuentra en proceso de auto asignación o su estatus es diferente de "Gestionando", "Cancelado", "Esperando Agente", "Asignado a equipo", "Cancelado por un socio" o si la tarea fue cancelada por un cliente o administrador',
			messageAssignAgentPartnersError: 'No se puede asignar un agente a una tarea asignada a un socio',

			messageAssignAgentPartnersCancelError:
				'No se puede asignar un agente a una tarea cancelada si tu no eres el propietario y tiene una relacion de socio',

			messageErrorAssignTeamPopUp:
				'No puedes asignar un equipo a una tarea con un estatus diferende de "Gestionando", "Asignado a equipo", "Cancelado", "Cancelado por un socio" o si la tarea fue cancelada por un cliente o administrador',
			messageRateFinishedTaskOnly: 'No pudes calificar una tarea con un estatus diferente a "Terminado"',
			messageRatedAlready: 'La tarea ya fue calificada',
			messageAssingAgentPartnersError: 'No puedes asignar un agente a un tareas de un socio',

			messageErrorGroupTaskWitTask:'No puedes crear un grupo de tareas si  selecionaste una tarea ya con un grupo asignado, te recomendamos primero desvincular la tarea del grupo o crear un grupo nuevo desde la pestaña de grupo de tareas.',

			messageErrorAssignGroupTaskWitTask:'No puedes asignar un grupo a una tarea que previamente ya cuenta con uno, desvincula primero la tarea del grupo.',

			messageErrorGroupTaskStatus:
				'No puedes agregar a un grupo de tareas una tarea con estatus "Terminado", "Ejecutando", "Agente en camino" o "Ha llegado", por favor revisa la información e intenta más tarde',

			messageErrorGroupTaskParner:
				'No se puede crear un grupo grupo de tareas a una tarea cancelada o pausado por usted o su socio si no es el propietario de la tarea',

			messageErrorCancelTaks: 'No puede cancelar tarea(s) con el estado "Terminado" o "Cancelado"',
			messageErrorCancelTaksOwner:
				'No puede cancelar tarea(s) con el estado "Terminado", "Cancelado" o "Cancelado por socio"',

			messageErrorGroupTaskPartner:
				'No puedes asignar un grupo de tareas a una tarea cancelada o pausado por ti o por tu partner si no eres el propietario de esta',

			messageErrorAssignPartner: 'No puedes asignar un socio a una tarea con un estatus diferente de "Gestionando". Si quieres cambiar el socio asignado a la tarea usa la funcion de "reasignar socio"',
			messageErrorUserReasingPartner: 'Por favor usa la función de "reasignar socio" en el menu de acciones',
			messageErrorUserReasingPartnerCancelClient:
				'No puedes asignar una tarea a un partner si el cliente o administrador cancela previamente y es dueño de esta',

			messageErrorReAssignPartner: 'No puedes reasignar un socio a una tarea que esta en ejecución o finalizada',
			messageErrorReAssignPartnerNoPartner:
				'No puedes reasignar una tarea a un partner si esta no fue previamente asignada a uno',
			messageErrorReAssignPartnerExecutor:
				'No puedes reasignar un socio a una tarea cuando tu eres el último partner asignado a ella',
			selectOnePartner: 'Debes seleccionar por lo menos una socio',
			setOneReason: 'Debes escribir una razón',
			setCategory: 'Debes seleccionar una categoria',
			fail: 'No cumplió con la condición',
			reassignedByMe: 'Mostrar tareas reasignadas por mi',
			reassignedByOther: 'Mostrar tareas reasignadas por otros socios',
			taskReassignedInfo: 'Dashboard del total de tareas reasignadas ',
			messageErrorAssignAsset: 'No puedes asignar un activo a tareas con diferentes sucursales',
			messageErrorAssignAssetwithNoBranch: 'No puedes asignar un activo a tareas sin sucursal',
			messageErrorAssignAssetPartner:"No puede asignar un activo a una tarea asignada por un partner",
			messageErrorAssignAssetWrongStatus: 'No puedes asignarle un activo a una tarea con estatus cancelado o terminado',
			messageErrorAssignFormWrongStatus:
				'No puedes asignarle un formulario a una tarea con estatus cancelado o terminado',
			messageErrorAssignInventaryPartner:"No puede asignar un inventario a una tarea asignada por un socio",
			messageErrorAssignInventoryWrongStatus:
				'No puedes asignarle inventario a una tarea con estatus cancelado o terminado',
			messageErrorDeletedTask: 'Ups, no hemos podido eliminar esta(s) foto(s)',
			messageConfirmationAssignAgent: '¿Esta seguro que desea asignar a este agente fuera de servicio?',
			messageAssignAgentSuccessfully: 'El agente fue asignado correctamente',
			messageAssignTeamSuccessfully: 'El equipo fue asignado correctamente',
			messageErrorAssignTeam: 'El equipo no pudó ser asignado, intenta de nuevo',

			tasksUpperCase: 'TAREAS',
			taskUpperCase: 'TAREA',
			messageErrorAssignAgent: 'El agente no pudó ser asignado, intenta de nuevo',

			newTasks: 'Nuevas tareas',
			newTaskGroups:'Nuevos grupos de tareas',
			showUpdates: 'Mostrar actualizaciones de tareas',
			showTaskGroupUpdates:'Mostrar actualizaciones de grupos de tareas',
			taskStatusAnalitycs: 'ESTATUS DE TAREAS',
			statusAnalitycs: 'ESTATUS',
			titleIconCalendar: 'Reagendar',
			titleIconDetail: 'Detalle',
			titleIconGroup: 'Crear Grupo de tareas',
			titleIconEditGroup: 'Detalle de grupo de tareas',
			titleIconEditGroupAction: 'Detalle Grupo',

			updateFormInTaskByCsv:'Actualizar formulario en tarea por CSV',
			titleIconAssignGroup: 'Asignar Grupo de tareas',
			titleIconAssignGroupAction: 'Asignar Grupo',
			route: 'Ruta',
			assignResources: 'Asignar Recursos',
			updateTemplateForm:'Template para actualización de tarea',
			import: 'Importar información',
			export: 'Exportar información',
			updateTaskForms:'Actualizar formularios de tareas',
			updateTasks:'Actualizar tareas',
			importTask: 'Importar tareas',
			exportTask: 'Exportar tareas',
			selectOptions: 'Selecciona los campos que quieres exportar',
			tasksRelatedReason: 'Tareas relacionadas a razón',
			exportCalendar: 'Exportar Calendario',
			exportError: 'No hay datos para exportar',
			exportMessage: 'Estamos procesando y bajando tu archivo...',
			loadingTextAssitedForm:'Creando template. Esta acción puede tardar algunos minutos.',
			getTaskFromGroup:'Estamos traemos tus tareas grupales',
			uploadFileMessage: 'Estamos procesando y cargando su archivo ...',

			obtainTaskMessage: 'Estamos obteniendo la información de tus tareas',
			obtainFormMessage: 'Estamos generando los formularios de tus tareas',
			obtainFormImageMessage: 'Estamos obteniendo las imágenes de los formularios. Por favor espera.',
			exportingModules: 'Exportando Módulos. Por favor espere',
			noFormsToDownload: 'No hubo formularios que tengan un template html o xlsx',
			noFormsToDownloadImages: 'No había formularios que contuvieran imágenes',
			importMessageProcess: 'Estamos procesando y subiendo tu archivo...',
			maxImportMessage:'La cantidad máxima para importar tareas por excel es de 200 tareas por importación. Para importación de un volumen mayor utiliza la API de Fielder',
			maxUpdateMessage:'La cantidad máxima para actualizar tareas por excel es de 200 tareas por importación. Para importación de un volumen mayor utiliza la API de Fielder',
			updateForms: 'Actualizar formularios',
			downloadFormTemplateMessage1: '1. Da click ',
			downloadFormTemplateMessage11: ' aquí ',
			downloadFormTemplateMessage12: ' para ir al Módulo de Formularios',
			downloadFormTemplateMessage2: '2. Da click en el nombre del formulario que deseas actualizar',
			downloadFormTemplateMessage3: '3. Dentro del detalle del formulario, descarga el template',
			downloadFormTemplateMessage4: '4. Sube tu template editado con las actualizaciones que realizaste en el siguiente botón de "Actualizar Formulario".',
			
			downloadFormTemplateManagerMessage1: '1. Da click en detalle de tarea',
			downloadFormTemplateManagerMessage2: '2. Entra en la pestaña de funcionalidades y posteriormente abre la seccion de formularios',
			downloadFormTemplateManagerMessage3: '3. Selecciona el formulario deseado',
			downloadFormTemplateManagerMessage4: '4. Descarga el template usando el botón superior derecho',

			from: 'De',
			to: 'A',
			outOf: 'de',
			executeReport: 'Ejecutar',
			offDuty: 'Fuera de servicio',
			importTaskSuccess:
				'Tu proceso de importación se creó exitosamente, pronto recibirás un correo con los resultados  ',
			importTaskError:
				'Lo sentimos, no se ha podido crear tu proceso de importación, porfavor revisa la información de tu archivo .csv e intentalo más tarde',
			importTaskExceeds: 'El número de tareas excede el maximo permitido (500 tareas)',
			importTaskNotLastCVS:
				'El número de columnas del formato no concuerda con su ultima versión. Porfavor descarga el formato y vuelve a intantarlo.',
			downloadTaskTemplate: 'Descarga el formato de csv para importar tus tareas',
			downloadUpdateTaskTemplate:'Descarga el formato de csv para actualizar tus tareas',
			downloadUserTemplate: 'Descarga el formato de csv para importar tus usuarios',
			downloadBranchTemplate: 'Descargue la plantilla .csv del archivo de importación de sucursales',
			downloadCompanyTemplate:'Descargue la plantilla .csv del archivo de importación de la compañia',
			createTaskShownClientWarning: 'Recuerda que solo aparecen los clientes que no estan ligados a una sucursal',
			rememberCsvFile: 'Recuerda que sólo puedes subir un archivo .csv',
			useLastCsvTemplate: 'Siempre usa la última versión del formato',
			importBranchNote: 'Si quieres actualizar la información por medio de la importación marca la ultima casilla con una x',
			useCorrectCsvUpdateTemplate: 'Asegurate de tener el csv correcto para actualizar el formulario dentro de la tarea',
			cellsLeftBlankInfo: 'Las celdas en blanco serán ignoradas y no actualizará la información en dicha pregunta',
			useCorrectCsvTemplate: 'Asegurese de que está utilizando la plantilla csv correcta para esta categoría',
			cancelRoute: 'Cancelar Ruta',
			cancelTask: 'Cancelar Tarea',
			rateTask: 'Calificar Tarea',
			rate: 'Calificación',
			rateTaskAction: 'Calificar',
			rateTooltipText: 'Selecciona de menor a mayor el número de estrellas',
			clientComment: 'Comentario del Cliente',
			comment: 'Comentario',
			cancelRouteConfirmation: '¿Deseas cancelar las rutas seleccionadas?',
			resetRouteTasks: 'Resetear tareas de rutas',
			resetCanceledTasks: 'Resetear tareas canceladas',
			resetRouteTasksConfirmation: '¿Deseas reiniciar las rutas seleccionadas?',
			resetCanceledTasksConfirmation: '¿Deseas reiniciar las tareas canceladas de tu ruta?',
			resetRouteTasksNotificacion: 'La ruta fue reseteada',
			resetCanceledTasksNotification: 'Las tareas canceladas de la ruta han sido reiniciadas',
			restartTaskExecution: 'La Tarea fue reiniciada',
			assetAssigned: 'Activo asignado a tarea',
			templateAssigned: 'Formulario asignado a tarea',
			inventoryAssigned: 'Inventario asignado a tarea',
			cancelRouteMessage: 'Recuerda que para conservar tus tareas debes desvincularlas primero.',
			resetRouteTasksMessage:
				'Esta acción hará que las tareas de tu ruta vuelvan a su estado inicial en la seccion de tareas fuera de tu ruta y que tu ruta sea eliminada',
			resetCanceledTasksMessage:
				'Esta acción hará que las tareas canceladas de tu ruta vuelvan a su estado inicial en la seccion de tareas fuera de tu ruta',
			reschedule: 'Reagendar',
			rescheduleComment: 'Comentario de recalendarización',
			dateOfRescheduleComment:'Fecha de cambio',
			rescheduleTask: 'Reagendar Tarea',
			rescheduleRoute: 'Reagendar Ruta',
			routeRescheduled: 'La Ruta fue reagendada',
			restartRoute: 'Reiniciar Ruta',
			restart: 'Reiniciar',
			routeNumber: 'Número de la ruta',
			routeName: 'Nombre de la ruta',
			agentName: 'Nombre del agente',
			routeCost: 'Costo Estimado de la Ruta',
			routeEndPoint: 'Retorno de la ruta',
			routeStartPointTitle: 'Dirección de inicio de ruta',
			routeEndPointTitle: 'Dirección de retorno de ruta',
			routeEndPointDefault: 'Tomar dirección de la última tarea de la Ruta',
			routeExpensesConfig: 'Configuración de gastos de ruta',
			messageEmptyRoutes: 'Debes seleccionar una ruta',
			selectProgramDate: 'Debes seleccionar una fecha programada',
			selectTasks: 'Debes de seleccionar una o más tareas',
			selectTasksFinish: 'No puedes desvincular tareas finalizadas',
			messageMoreRoutesSelected: 'Se seleccionó mas de una ruta',
			messageCancelRoutes: 'No puedes cancelar una ruta con estatus Ejecutandose, Cancelado o Finalizado',

			messageRescheduleRoutes: 'No puedes reagendar una ruta con estatus Ejecutandose, Cancelado o Finalizado',
			messageResetRouteTasks: 'No puedes reiniciar una ruta con estatus Ejecutandose, Cancelado o Finalizado',
			messageResetCanceledTasks: 'Solamente puedes resetear una ruta que esta en estado cancelado',
			messageRestartRoute: 'No puedes reiniciar una ruta que no esta cancelada o rechazada.',
			messageReassignAgent:
				"Sólo puedes asignar un agente a una ruta que se encuentre en estatus de: 'Aceptada', 'Esperando Agente' o 'Rechazada'.",
			selectRescheduledDate: 'Selecciona una nueva fecha',
			selectNewAgent: 'Selecciona un nuevo agente',
			messageSelectAgent: 'Debes seleccionar un agente',
			messageUpdateRoute: 'No se puede actualizar la ruta, una de las tareas cambió su status',
			messageIdTeams: 'Las tareas deben pertenecer al mismo equipo',
			messageNullStartTime:
				'Alguna de las tareas no tiene fecha de ejecucion.Por favor reagende la tarea e intentelo de nuevo.',
			selectRoute: 'Selecciona una Ruta',
			selectTask: 'Selecciona una Tarea',
			resetedTask: 'Reseteo de Tarea',
			routeChangedByAgent: 'No se pudo realizar la operación porque el agente ha actualizado la ruta',

			// NOTIFICATION
			notificationType0: 'Se ha creado una Tarea',
			notificationType65: 'Se ha creado una Tarea por medio de un Cliente',
			notificationType66: 'Se ha creado una Tarea por mail',
			notificationType1: 'La Tarea ha sido asignada',
			notificationType2: 'La Tarea ha sido aceptada por el Agente',
			notificationType3: 'La Tarea ha iniciado',
			notificationType4: 'La Tarea ha terminado',
			notificationType5: 'El agente ha rechazado la tarea',
			notificationType6: 'Notificación',
			notificationType7: 'La Tarea ha sido cancelada',
			notificationType9: 'El agente ha iniciado su ruta',
			notificationType10: 'La Tarea ha sido recalendarizada',
			notificationType11: 'Nueva invitación para ser socio de ',
			notificationType12: 'a declinado ser tu socio',
			notificationType13: 'La Tarea ha sido cancelada por el Socio',
			notificationType14: 'La Tarea ha sido asignada por el Socio',
			notificationType15: 'El agente ha aceptado la ruta',
			notificationType16: 'La Ruta con el agente ha rechazado la ruta',
			notificationType17: 'La Ruta con el agente ha finalizado la ruta',
			notificationType18: 'La Ruta fue cancelada',
			notificationType19: 'El agente ha inciado su ruta',
			notificationType20: 'La dirección de la tarea fue modificada',
			notificationType21: 'La Ruta fue eliminada',
			notificationType22: 'Tarea Eliminada de la Ruta',
			notificationType23: 'Ruta asignada a un Agente',
			notificationType24: 'El agente ha llegado',
			notificationType25: 'La Tarea tiene la ruta con id',
			notificationType26: 'Se envió invitación para ser socio a la cuenta ',
			notificationType27: 'Grupo de Tareas asignado ',
			notificationType28: 'Ocurrio un error durante la auto asignación ',
			notificationType29: 'Auto-asignación no exitosa: No se encontraron agentes para la tarea ',
			notificationType30:
				'Auto-asignación no exitosa: No se encontraron templates para la auto asignación de la tarea ',
			notificationType31: 'Has declinado la sociedad con',
			notificationType32: 'Tarea Asignada a un Equipo',
			notificationType33: 'Formulario Asignado a tarea',
			notificationType34: 'Activo Asignado a tarea',
			notificationType35: 'Inventario Asignado a tarea',
			notificationType36:
				'Auto-asignación no exitosa:  No se encontraron agentes con las habilidades especificadas o bien isócronas que contengan el template especificado',
			notificationType37: 'Cambió el horario de la Ruta',
			notificationType38: 'Se Reinició la Ruta',
			notificationType40: 'La Tarea fue Reiniciada',
			notificationDefault: 'Notificación fielder',
			notificationType41: 'Auto-asignación no exitosa: No se encontraron templates de auto-asignacion',
			notificationType42: 'Auto-asignación no exitosa: La ubicación de la tarea no pertenece a una isócrona',
			notificationType43: 'Auto-asignación no exitosa: No se encontraron templates con la isócrona asignada',
			notificationType44: 'Auto-asignación no exitosa: No se encontraron templates para el origen de la tarea',
			notificationType45: 'Auto-asignación no exitosa: No se encontraron templates para el tipo de tarea',
			notificationType46: 'Auto-asignación no exitosa: No se encontraron agentes disponibles',
			notificationType47: 'Auto-asignación no exitosa: No se encontraron templates para la prioridad de la tarea',
			notificationType66: 'Auto-asignación no exitosa: No se encontraron recursos conectados para el template de auto-asignación',
			notificationType67: 'Auto-asignación no exitosa: La tarea no es para el dia de hoy',
      notificationType48: 'La tarea fue reasignada',
			notificationType50:
				'Auto-asignación no exitosa: La ventana de atención del servicio de la sucursal está fuera del rango de horario de atención especificada en la tarea.',
			notificationType51: 'El agente va en camino a la tarea',
			notificationType54: 'Nuevo comentario agregado',
			notificationType53: 'La Tarea fue finalizada',
			notificationType55: 'La información de la tarea fue editada',
			notificationType56: 'Las tareas de la Ruta fueron reorganizadas',
			notificationType57: 'Las tareas de la ruta fueron reseteadas',
			notificationType58: 'Incidente reportado en la ruta',
			notificationType59: 'Las tareas canceladas de la ruta fueron reiniciadas',
			notificationType60: 'El Grupo de tareas fue cancelado',
			notificationType61: 'El Grupo de tareas fue pausado',
			notificationType62: 'Una tarea fue agregada al grupo',
			notificationType63: 'Una tarea fue eliminada del grupo',
			notificationWhitout: 'Sin notificaciones',

			where: 'Dónde',
			orCapitalLetter: 'O',
			andCapitalLetter: 'Y',
			contain: 'Contiene',
			// Change password
			changePasswordTitle: 'Cambiar contraseña',
			currentPassword: 'Contraseña actual',
			password: 'Contraseña',
			newPassword: 'Nueva contraseña',
			confirmNewPassword: 'Confirmar nueva contraseña',
			changePasswordButton: 'Cambiar contraseña',
			messageErrorChangePassword1: 'Contraseña incorrecta',
			messageErrorChangePassword2: 'La nueva contraseña no coincide',
			messageErrorChangePassword3: 'La contraseña se ha cambiado',
			messageErrorChangePassword4: 'Ocurrio un error',
			welcomePasswordMessage:
				'Tu contraseña debe de tener como mínimo 8 caracteres y debe incluir numeros y letras en minúsculas y mayúsculas.',

			// TaskDetail
			lastUpdateDate: 'Fecha de última actualización',
			responsible:'Responsable',
			withoutTeam: 'Sin equipo',
			nothingToComment: 'Sin Comentarios',
			notAssigned: 'No asignado',
			notAssignedSchedule: 'Sin horario asignado',
			execution: 'Ejecución',
			photos: 'Fotos',
			activity: 'Actividad',
			functionality: 'Funcionalidad',
			taskDetail: 'Detalle de Tarea',
			nameOfClient: 'Nombre del Cliente',
			nameOfBranch: 'Nombre de sucursal',
			phoneNumberClient: 'Teléfono Móvil del Cliente',
			emailOfClient: 'Correo del cliente',
			typeTask: 'Tipo de Tarea',
			serviceOrigin: 'Origen del Servicio',
			commentTask: 'Comentario de Tarea',
			descriptionTask: 'Descripción de la Tarea',
			rating: 'Calificación',
			editTask: 'Editar Tarea',
			generatePdf: 'Generar PDF',
			generateXlsx: 'Generar XLSX',
			generateXlsxModule: 'Generar Template XLSX',
			generatePDFfromXlsx: 'Generar Template PDF',
			generatedPDFfromXlsx: 'Template PDF Generado',
			buyGeneratePDFfromXlsx: 'Comprar funcionalidad "Generate PDF Template"',
			generatePDFModule: 'Generar PDF Formato Fielder',
			assistedForm: "Crear Formulario con I.A.",
			beta:"BETA",
			editGroupTask: 'Editar grupo de tareas',
			scheduledDate: 'Fecha Programada',
			endDate: 'Fecha de Término',
			assignedTeam: 'Equipo Asignado',
			assignedAgent: 'Agente Asignado',
			assignedWork: 'Horario de Trabajo',
			phoneAgent: 'Teléfono del Agente',
			emailAgent: 'Correo del Agente',
			typeVehicle: 'Tipo de Vehículo',
			idVehicle: 'Id del Vehículo',
			timeTraveled: 'Tiempo Estimado de Recorrido',
			distanceTraveled: 'Distancia Recorrida',
			distance: 'Distancia',
			photosGallery: 'Galeria de Fotos',
			history: 'Historial',
			headerOfTitle: 'Título de Header',
			downloadPDF: 'Descargar PDF',
			downloadForms: 'Descargar formularios',
			downloadApprovedForms: 'Descargar Formularios Aprobados',
			approvedForms:"Formularios aprobados",
			downloadFormsImages: 'Descargar imagenes de formularios',
			addressTask: 'Dirección de Tarea',
			timeExecution: 'Periodo de ejecución',
			until: 'Hasta',
			commentsAgent: 'Comentarios del agente',
			lastAgentComment: 'Ultimo comentario del agente',
			rejectionReason: 'Razón de Rechazo',
			pausedReason: 'Razón de Pausado',
			taskPausedReason: 'Razón del Pausado de la Tarea',
			taskStatusModificationCommentByAdministrator:
				'Comentario de modificación de estado de la tarea por administrador',
			taskStatusModificationCommentByManager: 'Comentario de modificación de estado de la tarea por gerente',

			taskRejectionComments: 'Comentarios de Rechazo de la Tarea',
			taskPauseComments: 'Comentarios del Pausado de la Tarea',

			dateInUTCFormat:'Fecha en formato UTC', 

			commentTitle: 'Comentario',
			commentsUser: 'Comentarios del cliente',
			commentUserDate: 'Fecha del comentario del cliente',
			automationUser: 'Automatización Fielder',

			// Create Group Tasks
			createGroupTaskTitle: 'Crear grupo de tareas',
			createGroupTaskAction: 'Crear Grupo',
			idCustomGroupTask: 'ID Externo de Grupo',
			executionDate: 'Fecha de ejecución',
			creationDate: 'Fecha de creación',
			disableDate: 'Fecha de desactivación',
			creationDateExcel: 'Fecha de creación de la tarea',
			creationHourExcel: 'Hora de creación de la tarea',
			executionTimeError: 'La fecha de ejecución debe ser igual o posterior a hoy',

			// Asigned group task
			assignedGroupTaskTitle: 'Asignar grupo de tareas',
			assign: 'Asignar',
			reassign: 'Reasignar',

			// Calendar
			titleCalendar: 'Mi calendario',
			assignedTask: 'Tarea asignada a sucursal',
			assignedBranch: 'Tarea asignada a cliente',
			notAssignedAgent: 'Agente no asignado',
			assignedToTaskGroup: 'Agente asignado en un Grupo de Tareas',

			// Profile
			profileTitle: 'MI PERFIL',
			messageErrorEmail: 'El email no tiene una estructura adecuada',
			messageErrorPhone: 'Número de teléfono invalido',
			messageFailedUpdatePhone: 'El teléfono no tiene una estructura adecuada',
			messageEmailAlreadyUsed: 'El correo ya ha sido usado por otro superadministrador',
			messageErrorUpdateProfile: 'Ups, no pudimos actualizar tu perfil',
			messageUpdatedProfile: 'Guardado exitosamente',

			// StatusFormatter
			taskStatusManaging: 'Gestionando',
			taskStatusWaittingForAgent: 'Esperando agente',
			taskStatusInProgress: 'En progreso',
			taskStatusExecuting: 'Ejecutando',
			taskStatusFinish: 'Finalizado',
			taskStatusCancel: 'Cancelado',
			taskStatusStartRoute: 'Agente en camino',
			taskStatusCancelByPartner: 'Cancelado por un socio',
			taskStatusAssignedToPartner: 'Reasignado por un socio',
			taskStatusAssignedToTeam: 'Asignado a un equipo',
			taskStatusArrived: 'Llego el agente',
			taskStatusDeleted: 'Eliminado',
			taskStatusAutoassigning: 'Auto asignando',
			taskStatusAutoassigningSuccess: 'Auto asignación exitosa',
			taskStatusAutoassigningFailed: 'Auto asignación fallida',

			accountReview: 'Revisión de cuenta',
			findAccount: 'Buscar cuenta',

			firstName: 'Nombre',
			idRoute: 'Id',
			idGroupTask: 'Id',
			userName: 'Nombre de Usuario',
			age: 'Edad',
			phone: 'Teléfono',
			company: 'Compañía',
			companyEditToken: 'Selecciona la(s) Compañía(s)/Proyecto(s) (Obligatorio)',
			searchByCompany: 'Buscar por compañía',
			searchByBranch: 'Buscar por sucursal',
			companyBranch: 'Compañía',
			email: 'Correo',
			emailCompany: 'E-mail de Compañía (ejemplo johndoe@company.com)',
			emailCompanyConfirmation: 'Confirmación de E-mail de Compañía',
			emailAccount: 'Correo de cuenta',
			confirmEmail: 'Confirmar correo',
			plate: 'Placas',
			createTaskTitle: 'CREAR TAREA',
			createRecurrentTask: 'Las tareas recurentes se estan creando en segundo plano',
			createVolumenTask: 'Las tareas en volumen se estan creando en segundo plano',
			typeOfTask: 'Tipo de tarea',
			select: 'Seleccionar',
			selectedMasc: 'Selecionados',
			client: 'Cliente',
			companyClient: 'Compañía / Cliente',
			changePhoto: 'Cambiar Foto',
			crop: 'Recortar',
			chooseOtherImage: 'Elegir otra imagen',
			addPhotoToDisplay: 'Añadir una foto para mostrar',
			priorityDefaultDelete:'No se pueden eliminar prioridades "Alta", "Media" o "Baja"',
			priorityDeleteError:'No se puede eliminar la(s) prioridad(es) ',

			branch: 'Sucursal',
			branchAnalytics: 'SUCURSAL',
			amount:"Cantidad",
			amountOfServices: 'CANTIDAD DE SERVICIOS',
			amountOfServicesLowerCase: 'Cantidad de servicios',
			amountOfServicesFinishedByAgent: 'Número de agentes que completaron un servicio',
			amountOfServicesAnalititcsLowerCase: 'Cantidad de tareas',
			searchBy: 'Buscar por',
			placeHolderClient: 'Nombre, apellido, correo, dirección',
			placeHolderBranch: 'Id Personalizado, nombre, compañía, dirección',
			placeHolderBranchByIdCompany:'Busca por ID Personalizado, nombre o ID de la sucursal',
			placeHolderCompany: 'Id externo, nombre',
			placeHolderTeam: 'Id, nombre',
			nameBranch: 'Nombre',
			nameCompany: 'Compañía',
			nameClient: 'Nombre',
			lastNameClient: 'Apellido',
			cellphoneClient: 'Teléfono Móvil',
			emailClient: 'Correo',
			directionClient: 'Dirección',
			description: 'Descripción',
			high: 'Alta',
			editIsochrone: 'Guardar Isócrona',
			createIsochrone: 'Crear Isócrona',
			canOnlyAddRadiusToCircle: 'Sólo puedes agregar un radio a una isócrona circular',
			medium: 'Media',
			low: 'Baja',
			severity_1: 'Alta',
			severity_2: 'Media',
			severity_3: 'Baja',
			chooseDate: 'Elige una fecha',
			executionPeriod: 'Periodo de ejecución',
			initialDate: 'Fecha inicial',
			toDate: 'Hasta',
			finalDate: 'Fecha final',
			optional: 'Opcional',
			recurrentTask: 'Tarea recurrente',
			volumeTask: 'Tarea por volumen',
			repeatFrom: 'Repetir',
			repeat: 'Repetir',
			daily: 'Diariamente',
			weekly: 'Semanalmente',
			monthly: 'Mensualmente',
			annually: 'Anualmente',
			month: 'Ver mes',
			week: 'Semana',
			showWeek: 'Ver semana',
			quarterlyBasis: 'Trimestralmente',
			semiannually: 'Semestralmente',
			dailyMonday_Friday: ' Diariamente (Lunes-Viernes)',
			repeatUntil: 'Repetir hasta',
			numberofTimes: 'Número de veces',
			objectiveToCover: 'Objetivo a cubrir',

			time: 'vez',
			times: 'veces',
			team: 'Equipo',
			byDirection: 'Por dirección',
			byCoordinates: 'Por coordenadas',
			partner: 'Socio',
			taskStatusModifiedDate: 'Fecha de modificación del estado de la tarea',
			taskStatusModifiedHour: 'Hora de modificación del estado de la tarea',
			taskStatusModifiedCommnet: 'Comentario de modificación del estado de la tarea',
			taskStatusModifiedByAdministrator: 'Estado de tarea modificada por Administrador',
			taskStatusModifiedByManager: 'Estado de la tarea modificado por el gerente',

			assignedPartner: 'Socio asignado ',
			didNotFindTasks:
				'No se encontró la información buscada, por favor selecciona un periodo de tiempo o cambia tus parámetros de búsqueda.',
			partnerWarningAssing: '*Sólo puedes asignar tareas a socios con cuentas activadas',
			assignTo: 'Asignar a',
			assignedTo: 'Asignado a',
			assignedBy: 'Asignado por',
			doNotAssign: 'No asignar',
			onlyAgentsWitouthTeamMessage:'Solo se muestran los agentes que no estan en un equipo',

			requiredFields: 'Campos obligatorios',
			createTask: 'Crear tarea',
			viewTask: 'Ver Tarea',
			apiUrl: 'Api Url',
			id: 'Id',
			idUserConfig: 'Id de Usuario',

			idTaskGroup: 'Id',
			capitalLettersID: 'ID',
			createdDate: 'Fecha de creación',
			view: 'Ver',
			originType: 'Tipo de origen',
			originId: 'Id del origen',
			form: 'Formulario',
			companiesUpperCase: 'COMPAÑÍAS',
			desktop: 'Dashboard',
			myOperation: 'Mi Operación',
			commandCenter: 'Centro de Comando',
			calendar: 'Calendario',
			agentsDashboard: 'Agentes_Dashboard',
			taskCompanyDashboard: 'Tareas de Compañía Dashboard',
			taskCompanyDashboardSla: 'Tareas con SLA',
			agents: 'Agentes',
			managers: 'Gerentes',
			administrators: 'Administradores',
			partners: 'Socios',
			clients: 'Clientes',
			idAgent: 'Id Agente',
			uppercaseBranches: 'SUCURSALES',
			uppercaseCompanies: 'COMPAÑÍAS',
			uppercaseClients: 'CLIENTES',
			typeOfService: 'TIPO DE SERVICIO',
			typeOfServiceAnalitycs: 'TIPO DE TAREA',
			typeOfServiceLowerCase: 'Tipo de tarea',
			branches: 'Sucursales',
			activeBranches: 'Sucursales Activas',
			inactiveBranches: 'Sucursales Inactivas',
			uppercaseTeams: 'EQUIPOS',
			companiesLowerCase: 'Compañías',
			teams: 'Equipos',
			agentTeamList:'Equipo',
			analytics: 'Analíticos',
			isochronous: 'Isócronas',
			marketPlace: 'Marketplace',
			account: 'Cuenta',
			globalDashboard: 'Dashboard Global',
			configuration: 'Configuración',
			help: 'Ayuda',
			users: 'Usuarios',
			optinalField: 'Campo opcional',
			addClient: 'Agregar cliente',
			addBranch: 'Agregar sucursal',
			addBranchAndCreateTask: 'Crear sucursal con tarea',
			addTeam: 'Agregar equipos',
			addPartner: 'Agregar socio',
			addCompany: 'Agregar compañía',
			addManager: 'Agregar gerente',
			legalAddress: 'Dirección Fiscal',
			noManager: 'Sin gerente',
			showBranchesToUpdateLocation: 'Ver pendientes de actualización de ubicación',
			parentCompanyName: 'Compañía padre',
			workingHoursFrom: 'Hora de trabajo desde',
			workingHoursTo: 'Hora de trabajo hasta',
			parentCompanyId: 'Id Compañía Padre',
			businessName: 'Razón social',
			selectParentCompany: 'Selecciona una compañía padre',
			selectPlaceHolder: 'Selecciona uno',
			selectAgentsPlaceHolder: 'Selecciona agentes',
			idCompany: 'Id de la Compañía',

			// AGENTS
			routeNote: 'Recuerda, tareas ejecutadas en modo Offline, no muestran la ruta efectuada por el agente.',
			addAgent: 'Agregar agente',
			addAgents: 'Agregar agentes',
			addTeams: 'Agregar equipos',
			inactiveAgents: 'Agentes activos',
			activeAgents: 'Agentes inactivos',
			questionDeleteAgents: '¿Estás seguro que deseas eliminar agente(s)?',
			questionDeactivateAgents: '¿Estás seguro que deseas desactivar agente(s)?',
			questionRestoreAgents: '¿Estás seguro que deseas restaurar agente(s)?',
			restoreAgentTooltip: 'Habilitar Agente(s)',
			deleteAgentTooltip: 'Borrar Agente(s)',
			deactivateAgentTooltip: 'Desactivar Agente(s)',
			emailPasswordSuccess: 'Correo enviado con éxito',
			agentEditedCorrectly: '¡Actualizado correctamente!',
			agentAddedCorrectly: '¡Agregado correctamente!',
			createAgentPermission: 'El usuario no tiene permiso para crear agentes',
			agentDuplicatedUserName: 'Nombre de usuario ya existe, elige otro',
			assignGroupTask: 'La dirección y fecha de ejecución de la tarea cambiará al predefinido en el grupo de tareas',
			congratulate: 'Felicitar',
			congratulationConfirmation: 'Por favor introduzca un comentario para los agentes',
			congratulationMessage: 'Mensaje de felicitación',
			congratulations: 'Felicidades',
			congratulationsWereSent: 'Las felicitaciones fueron enviadas a los agentes',
			assignAgent: 'Asignar Agente',

			agentRestoredCorrectly: '¡Agente(s) restaurado(s) correctamente!',
			agentDeletedCorrectly: '¡Agente(s) eliminado(s) correctamente!',
			agentDisabledCorrectly: '¡Agente(s) desactivado(s) correctamente!',
			pleaseSelectDateOneMonth:'Por favor selecciona una fecha no mayor a un mes',

			managerRestoredCorrectly: '¡Gerente(s) restaurado(s) correctamente!',
			managerDeletedCorrectly: '¡Gerente(s) eliminado(s) correctamente!',
			managerDisabledCorrectly: '¡Gerente(s) desactivado(s) correctamente!',

			createAgentTitle: 'Crear Agente',
			maxLimitTaskGroupReached: '* El limite maximo es de 30 caracteres',
			maxLimitTaskGroupNameReached:'*  El limite maximo es de 128 caracteres',
			userNameFieldHelp: '*Nombre de usuario debe ser igual o mayor a 5 caractéres',
			nameFieldHelp: '*Nombre de agente debe ser igual o mayor a 3 caractéres',
			lastNameFieldHelp: '*Apellido de agente debe ser igual o mayor a 3 caractéres',
			emailFieldHelp: '*Ingrese un email válido',
			validateSameEmail: 'El correo no es el mismo, por favor vuelva a introducirlo',
			validateCompany:'Por favor, introduzca un nombre de empresa',
			createAgentCompanyNameField: 'Nombre de empresa',
			phoneFieldHelp: '*El campo no puede quedar vacío',
			createAgentWorkingHoursFieldHelp: '*La hora de inicio no puede ser después o igual a la de termino',
			createAgentVehicleTypeField: 'Tipo de vehículo',
			bicycle: 'Bicicleta',
			motorcycle: 'Motocicleta',
			car: 'Coche',
			bus: 'Camión',
			truck: 'Camión',
			walking: 'Caminando',
			deviceName: 'Nombre del dispositivo',
			deviceSystemVersion: 'Versión del sistema del dispositivo',
			fielderVersion: 'Fielder versión',
			battery: 'Batería',
			licensePlateFieldHelp: '*Placas deben ser mayor a 4 caractéres',
			createAgentStartingPointField: 'Punto de partida',
			update: 'Actualizar',
			createAgentCreateButtonHelp: '*Campos obligatorios',
			detailAgentTitle: 'Detalle Agente',
			geolocations: 'Geolocalizaciones',

			idBranch: 'ID Sucursal',
			newLocalization: 'Nueva localizacion disponible',
			branchLocation: 'Ubicación de la sucursal',
			placeHolderAddDirection: 'Escribe una dirección',
			placeHolderCancelComment: 'Escribe un comentario...',
			addCustomer: 'Agrega un cliente',

			data: 'Datos',
			header: 'Encabezado',
			text: 'Texto',
			fields: 'Campos',
			inventoryAsset: 'Inventario',
			categories: 'Categorías',
			numberID: 'Númbero ID',
			brand: 'Marca',
			title: 'Título',
			modules: 'MÓDULOS',
			functionalitiesUpperCase: 'FUNCIONALIDADES',
			placeHolderForm: 'Nombre del formulario',
			continue: 'Continuar',
			add: 'Añadir',
			functionalities: 'Funcionalidades',
			iconTaskInformation: 'Funcionalidades',
			teamNames: 'Equipos',

			placeHolderInventory: 'Id, nombre, marca, módulo o número de serie',
			formUpdateWarning: 'Todos los cambios a tus formularios se verán reflejados en la próxima tarea creada, realiza las pruebas necesarias para que cumpla con tus objetivos. Conoce más en la documentación de Fielder',
			choose: 'Elige',
			fillTheRequiredFields: 'Completa los campos obligatorios',
			sameCompanyParentError: 'No puedes seleccionar la misma compañia como compañia padre',
			// REGISTER
			register: 'Registro',

			specify: 'Especificar',
			jobDescription: '¿Qué describe mejor su puesto de trabajo?',
			jobDescriptionOps: ['Propietario de empresa', 'Líder de equipo', 'Freelancer', 'Director / Level C / VP'],

			industryQuestion: '¿Cuál es tu industria?',
			industryQuestionOps: [
				'Banca / Finanzas',
				'Logística / Transporte',
				'Logística / Paquetería',
				'Manufactura / Industrial',
				'Servicios de Mantenimiento',
				'Distribución de productos / servicios',
				'Alimentos',
				'Construcción',
				'Educación',
				'Hotelería / Turismo',
				'Servicios de Salud',
				'Servicios Públicos',
				'Textil / Calzado',
				'Transporte',
				'Mascotas',
				'Belleza',
				'Fletes y Mudanzas',
				'Tintorería',
				'Comercio',
				'Otro',
			],
			findUsQuestion: '¿Dónde nos encontraste?',
			findUsQuestionOps: ['Facebook', 'Twitter', 'Linkedin', 'Google', 'Correo', 'Otro'],
			requestTrial: 'Solicita tu prueba',

			privacyPolicyTilte: 'Terms of service and Privacy Policy',
			privacyPolicyText1: 'In order to access Fielder Web we need you to accept our Service Terms and Privacy Policy ',
			privacyPolicyCheckbox1: 'I accept and agree with the ',
			privacyPolicyCheckboxLink1: 'Terms of service',
			privacyPolicyText2: ' and ',
			privacyPolicyCheckboxLink2: 'Privacy policy',
			acceptTermsAndConditions: 'Accept the terms and conditions to continue',

			privacyPolicy: 'Aviso de Privacidad',
			termsAndConditions1: 'Al crear una cuenta en Fielder estas aceptando los ',
			termsAndConditions2: 'Terminos y Condiciones',
			termsAndConditionsLogin: 'Al ingresar a Fielder estas aceptando los ',
			registerSuccessText: '¡Gracias por registrarse! \n\n Un agente de ventas se pondrá en contacto contigo.',
			functionalityAnaliticsDescription:
				'Esta es una funcionalidad avanzada con costo adicional, por favor contacta a tu administrador para habilitarla.',
			registerFailedText: 'Hubo un error, inténtalo de nuevo más tarde',
			registerFailedUserAlreadyExists: ' ya tiene una cuenta en Fielder',
			createAccountUserNameFieldHelp: '*Nombre de Usuario debe ser mínimo de 8 caractéres',
			pleaseEnterName: '*Por favor ingrese un nombre',
			pleaseEnterLastName: '*Por favor ingrese un apellido',
			pleaseEnterANumberAndCountry: "*Por favor ingrese un número y país",
			pleaseSelectJobDescription: 'Por favor selecciona una descripción de trabajo',
			pleaseSelectAnIndustry: 'Por favor seleccione una industria',
			pleaseSelectWhereYouFoundUs:'Por favor selecciona donde nos encontró',
			theAccountHasNoPreferences: 'La cuenta no tiene preferencias',

			yes: 'Sí',
			no: 'No',
			accept: 'Accept',
			cancel: 'Cancelar',
			return: 'Regresar',
			delete: 'Eliminar',
			close: 'Cerrar',
			// Marketplace
			marketplace: 'Marketplace',
			taskTypeMkt: 'Personalización de Tareas',

			pdfAprovedMessage: 'El documento ha sido aprobado exitosamente',
			pdfApprovedMessageError :'El formulario no pudo ser generado correctamente vuelve a intentarlo',
			pdfCancelMessagePart1: 'El documento no ha sido aprobado. Edita el formulario dando click en el ícono de  editar',

			marketplaceGraphTitle: 'Estado de tu operación',
			marketplaceYouAreHere: 'Tú estás aquí',
			marketplaceGraphDescription:
				'Has logrado que tus operaciones funcionen con pocos clicks para la dispersión de tus actividades.',
			connected: 'Conectado',
			connect: 'Conectar',
			buy: 'Comprar',
			administration: 'Administración',
			manage: 'Administrar',
			rol: 'Rol',
			rules: 'Reglas',
			rule: 'Regla',
			assetManagementDescription:
				'Configura el alta, baja y administra activos en campo para gestión de ciclos de vida e identificación de problemas',
			formsDescription:
				'Configura Formularios de distintos estilos e inclúyelos en diferentes fases del proceso de tus tareas',
			groupTasksDescription:
				'Gestiona un gran volumen de actividades en la misma localidad, con uno o varios recursos y una fácil administración a través de tags.',
			supportDescription: 'Adquiere mejores niveles de atención por parte del equipo Fielder.',
			consultingDescription:
				'¿Necesitas apoyo para digitalizar o integrar algún proceso? Nuestros expertos están listos para apoyarte.',
			storageDescription:
				'Determina el volumen de información que necesitas en tu proceso ( imágenes, requests, logs, bases de datos, etc… )',
			taskTypeDescription:
				'Personaliza el nombre del servicio o actividad que tus agentes ejecutarán de acuerdo a tu catálogo de servicios reales.',
			inventoryDescription:
				'Configura y administra partes, refacciones, paquetes, cualquier insumo en el proceso de tus tareas en campo',
			autoassignDescription: 'Auto asigna tareas a tus agentes, socios o equipos de trabajo',
			resourceAllocationDescription: 'Distribuye y asigna tus agentes de la forma más óptima para disminuir costos',
			isochronousDescription: 'Zonifica tus recursos y tu operación en todo el mundo ',
			skillsDescription:
				'Administra las habilidades de tus recursos para ejecutar los tipos de tarea que correspondan ',
			apiDescription: 'Conecta todos tus sistemas con Fielder ',
			functionalityConfirm: '¿Deseas adquirir esta funcionalidad?',
			functionalityRequestDescription:
				'Esta funcionalidad cuenta con un monto extra, por lo mientras, nosotros nos pondremos en contacto contigo para validar tu pedido.',
			smsDescription: 'Configura tus SMS en función a las reglas que requieras en tu negocio.',
			sms: 'SMS',

			routeOptimizationDescription:
				'Genera las rutas más óptimas para tus agentes en función de variables importantes que impactan los principales indicadores en tu negocio',
			expensesDescription: 'Configura los parámetros de tus viáticos para tus rutas',
			isochroneDescription: 'Delimita espacios de cobertura y genera mapas de calor',

			// Route Preferences
			routePreferences: 'Route Preferences',
			availableTasks: 'Available Tasks',
			expireOn: 'Expires on',
			// Route Optimization
			optimizeRoute: 'Optimizar Ruta',
			routeOptimization: 'Optimización de rutas',
			commandCenterDescription:
				'Monitorea en tiempo real variables de interés para tomar decisiones inmediatas y optimizar la ejecución de tu operación en función al cumplimiento de tus objetivos diarios.',

			noAgentLocation: 'Sin punto de partida',
			expenses: 'Viáticos',
			addExpenses: 'Agregar viáticos',
			enableExpensesRoutesInfo: 'Aplicar viático a la ruta',
			expensesAverage: 'Viáticos en promedio por RUTA',
			expensesFood: 'Comida',
			expensesSleeping: 'Hospedaje',
			expensesKm: 'Porcentaje a agregar al costo estimado de kilometraje (entre 1 y 100)',
			// Api
			api: 'Api',
			webHook: 'Web Hook',
			apiUsers: 'Usuarios de API',
			addApiUser: 'Crear token de API',
			editApiUser: 'Editar API Token',
			// Forms
			forms: 'Formularios',
			pdfConverterTitle: 'Convertidor de Templates a PDF',
			pdfConverterDescription: 'Crea y convierte templates de XLSX a PDF',
			formsImages: 'Imágenes de formularios',
			images: 'Imágenes',
			createForm: 'Crear Formulario',
			finishedForms: 'Formularios Finalizados',
			templateName: 'Nombre del template',
			headerTitle: 'Título del header',
			addQuestion: 'Añadir Pregunta',
			allowEditableFormLabel: '¿Deseas permitir que este formulario sea editable después de que el cliente firme?',
			onlyOneEditableLabel: 'Sólo puede existir una firma con esta condición selecionada',
			option: 'Opción',
			multipleChoice: 'Opción Multiple',
			checkbox: 'Selección Múltiple',
			radioButton: 'Opción Múltiple',
			radius: 'Radio (metros) ',
			textFieldQuestion: 'Pregunta Abierta',
			numericRating: 'Calificación Numérica',
			binaryRating: 'Calificación Binaria',
			starsRating: 'Calificación de Estrellas',
			eSignature: 'Firma Electrónica',
			photograph: 'Fotografía',
			qrCode: 'Código QR',
			barcode: 'Código de Barras',
			dateHour: 'Fecha y hora',
			textFile: 'Archivo de Texto',
			catalogue: 'Catálogo',

			footer: 'Pie de Página',
			noFormsFound: 'No se encontraron formularios en su cuenta',
			detailForm: 'Detalle del Formulario',
			editForm: 'Editar Formulario',
			uploadTemplateForm: 'Subir template',
			templateHistory: 'Historial del template',
			assistedTemplateText:'Selecciona un archivo de formato pdf para que el asistente de Fielder pueda crear tu formulario. El asistente de Fielder puede apoyarte con documentos de no más de 5MB.',
			uploadTemplateFormSuccess: 'El template se subió correctamente',
			deleteForm: 'Eliminar Formulario',
			deleteConfirmationForm: '¿Estás seguro que deseas eliminar el Formulario?',
			selectOneFormDelete: 'Selecciona un formulario para eliminar',
			startRange: 'Número inicial',
			endRange: 'Número final',
			characterNumber: 'Número máximo de caracteres',
			chooseDateFormat: 'Elige formato de fecha',
			dateFormatDesc:
				'DD: Día del Mes, MM: Número del Mes, MMMM: Nombre del Mes, YY: Año con 2 dígitos, YYYY: Año con 4 dígitos, HH: Horas, mm: Minutos',
			allowAgentEdit: 'Permitir el agente edite el campo',
			chars150: '150 caracteres',
			chars200: '200 caracteres',
			chars250: '250 caracteres',
			questions: 'Preguntas',
			good: 'Bien',
			bad: 'Mal',
			satisfactory: 'Satisfactorio',
			notSatisfactory: 'No Satisfactorio',
			selectedForms: 'Formularios Seleccionados',
			date: 'Fecha',
			updateDate:'Fecha de última actualización',
			formClientNameLabel: {
				client: 'Nombre del Cliente',
				branch: 'Nombre de la Sucursal',
			},
			formIdCustomLabel: {
				client: 'Id del Cliente',
				branch: 'Id externo de la sucursal',
			},
			downloadImages: 'Descargar Imágenes',
			stars: 'Estrella (s)',
			autoSelectedFormsAdvice:
				'Te hemos asignado automáticamente algunos formularios de acuerdo con la necesidad de tu tarea',
			assignForms: 'Asignar Formularios',
			formList: 'Lista de Formularios',
			currentlyAssignedForms: 'Formularios asignados hasta el momento',
			restoreFormsToDefault: 'Restaurar a los formularios por default',
			formLimitReached: 'Límite de formularios alcanzado',
			formTableWasNotCreated:
				'Las siguiente tarea no se ha podido asignar el formulario debido a que la cuenta de origen no tiene habilitado ese módulo',
			enableGalleryPhotoForms: 'Permitir subir fotos desde la galería del agente',
			enableMetadata: 'Mostrar geolocalización de la foto en el reporte',
			hideFieldToAgent: 'Ocultar para el Agente',
			doNotAllowAgentToEdit: 'No permitir agente editar',

			restrictValue:'Restringir valor',
			alphanumericValue: 'Valor alfanumérico',
			numericValue: 'Valor numérico',
			stringValue: 'Valor de cadena de texto',
		
			alphanumericValue: 'Valor alfanumérico',
			formsTemplateSaved: 'Se guardó el template del formulario con éxito',
			chooseFile: 'Elija un archivo',
			selectOrDragAndDrop: 'Arrastra un archivo aquí, o da click para seleccionar archivo',
			clickToSelectFile: 'Da click para seleccionar archivo',
			selectImageOrDragAndDrop: 'Arrastra una imagen aquí, o da click para seleccionar una imagen',
			clickToSelectImage: 'Da click para seleccionar una imagen',
			uploadXlsxOrHtmlTemplate: 'Sube un template con extensión .xlsx o .html',
			formTemplateHistory: 'Historial de templates del formulario',
			createFormTemplateAssisted:'Formulario Asistido',
			questionNotation: 'Notación para las preguntas',
			assetInventoryQuestionInfo: `Para campos de inventario/activos personalizados, agregue F antes del id, para las preguntas del agente agregue A (Ejemplo: campo con id 1 => {{F1}} pregunta del agente con id 3 => {{A3}})`,
			taskInfoNotation: 'Notación para la información de la Tarea',
			taskAddress: 'Dirección de la Tarea',
			taskDate: 'Fecha de la Tarea',
			taskBranchId: 'Id de la Sucursal',
			taskBranchIdCustom: 'Id Personalizado de la Sucursal',
			taskCompanyId: 'Id de la Compañía',
			taskCompanyName: 'Nombre de la Compañía',
			optionNumber: 'Número de la opción',
			onlyXlsxTemplate: 'Sólo para el template .xlsx',
			allLines: 'Todas las lineas',
			widthInPixels: 'Cambio de anchura en px',
			heightInPixels: 'Cambio de altura en px',
			linesPerCell: 'Renglones por celda',
			notAllFormsDeleted: 'No se pudiereon eliminar todos los formularios',
			deleteInactiveForms: 'Sólo puedes eliminar formularios inactivos',
			deleteFormImageConfirmation: '¿Estás seguro que deseas borrar la imagen? Una vez borrada no se podrá recuperar',
			deleteFormSignaturesConfirmation:'Confirmar borrar Firmas',
			deleteFormSignaturesExtraMessage:'Las buenas prácticas sugieren eliminar ambas firmas tras cualquier modificación realizada',
			reactivateForm: 'Reactivar Formulario',
			reactivateFormWarning: 'Al reactivar el formulario se borrarán las firmas electrónicas existentes',
			removeForm: 'Remover Formulario',
			remove: 'Remover',
			removeFormWarning: 'Si remueves el formulario de la tarea, toda su información se perderá',
			duplicate: 'Duplicar',
			duplicateTaskWithUpdates: 'Incluir cambios',
			duplicateTaskAddAutomatedForms: 'Incluir formularios asignados automáticamente',
			showQuestionToPartner: 'Mostrar pregunta a los socios',
			hideQuestionToPartner: 'Ocultar pregunta a los socios',
			partnersInheritableForm: 'Permitir que el formulario se herede a los socios',
			partnersEditableForm: 'Permitir que los socios editen el formulario',
			partnerSettings: 'Configuración de socios',
			formsReport: 'Reporte de formularios',
			none:'Ninguna',
			generateFormsReport: 'Generar reporte de formularios',
			generateFormsReportDescription:
				'Selecciona uno o más formularios y las preguntas de dicho formulario para agregarlos al reporte',
			selectMoreThanOneForm: 'Agrega más de un formulario para completar la solicitud',
			tryAgainLater: 'Intenta de nuevo más tarde',
			noTaskHasForms: 'No se encontraron tareas con los formularios seleccionados',
			noTaskHasInventories: 'No se encontraron tareas con los inventarios seleccionados',
			noTaskAssets: 'No se encontraron tareas con los activos seleccionados',
			noTaskSelected: 'No hay tareas en el periodo de tiempo seleccionado',
			hideToClient: 'Ocultar al Cliente',
			hideToClientInfo: 'Ocultar perfil Cliente final',
			allowUpdatesViaWebhook:'Permitir actualizaciones por medio del webhook (opcional)',
			showToClient: 'Mostrar al Cliente',
			enableClientEditing: 'Permitir que el cliente edite',
			reasonForNotApproved:'Razón de no aprobación',
			approveQuestion: 'Aprobar pregunta',
			approve: 'Aprobar',
			approveAllQuestions: 'Aprobar todas las preguntas',
			disapproveAllQuestions: 'Desaprobar todas las preguntas',
			viewForm: 'Ver el formulario seleccionado',
			selected: 'Seleccionado',
			addOption: 'Agregar Opción',
			optionTitle: 'Título de la opción',
			idToMapXlsx: 'Este id es utilizado para mapear la pregunta en un archivo xlsx al exportar el formulario',
			includeValueColumn: 'Incluir la columna de valor',
			valueColumnInfo: 'Ésta columna sirve para añadir un valor a la opción',
			includeCommentColumn: 'Incluir la columna de comentario',
			commentColumnInfo: 'Ésta columna sirve para añadir un comentario a la opción',
			value: 'Valor',
			hasNotApprovedForms: 'Formulario(s) no aprobado(s)',
			hasNotApprovedForm: 'Formulario no aprobado',
			AllFormsFinished: 'Formularios finalizados',
			inReviewForms: 'Formulario(s) esperando revisión',
			inReviewForm: 'Formulario esperando revisión',
			chooseFormOrigin: 'Elige origen del formulario',
			source: 'Origen',
			webhook: 'Webhook URL',
			apiToken: 'Token de API',
			updateByHook:'Actualizar por Webhook',
			selectPartnerFormOrigin: 'Seleccione origen del formulario del socio',
			partnerSpecificOptions: 'Opciones específicas de Socio',
			integratedPartnerInfoViewWarning:
				'El socio seleccionado fue integrado por esta cuenta, por lo tanto, la información que se muestra es lo que le permitió al socio ver desde su cuenta',
			enableFormReview: 'Habilitar revisión del formulario',
			runApprovalViaApi: 'Ejecutar revisión por medio de la api',
			formReviewableApiInfo: 'La aprobación de formularios a través de la API se realiza mediante el webhook',
			hideClientSwitchInfo: 'Al activar el switch este tipo de tarea no se mostrara al perfil cliente final',
			hideClientSwitchInfoPriority: 'Al activar el switch esta prioridad no se mostrara al perfil cliente final',
			receiveUpdatesThroughTheWebhook:'Al hacer request a través de la api podras recibir actualizacion mediante el webhook',
			taskTypeInfoApplyToAll: 'Este tipo de tarea se mostrará a todas las compañias , sucursales y clientes finales',
			taskPriorityApplyToAll: 'Este tipo de prioridad se mostrará a todas las compañias, sucursales y clientes finales',
			taskTypeInfoSpecify: 'Este tipo de tarea se mostrará solo a las compañias, sucursales y clientes finales especificos',
			taskPrioirtyInfoSpecify: 'Esta prioridad se mostrará solo a las compañias, sucursales y clientes finales especificos',
			userApiApplyToAll: 'Este usuario no se le especificara un url de webhook y se recibiran actualizaciones en el url por defecto',
			userApiInfoSpecify:'Este usuario solo recibira las actualizaciones vinculadas a su compañia y en el webhook definido.',
			webhookInfoSpecify:'Url por la cual llegan las actualizaciones realizadas dentro de la plataforma',
			formReviewableInfo: 'Permita que las preguntas del formulario se puedan revisar antes de completarlo',
			formDownloadInfo:'Se descargarán los formularios en cualquier estatus, en formato Fielder PDF y Excel.',
			formApprovedDownloadInfo:'Los PDFs que se descargarán corresponden a los Formularios terminados o en caso de aplicar, aquellos que hayan pasado por un proceso de aprobación',
			improveDownloadExperienceText:'Para mejorar tu experiencia, te recomendamos descargar utilizando filtros y periodos de tiempo cortos. Para grandes volúmenes de descarga te recomendamos utilizar la API de Fielder',
			selectPeriodOfTime: 'Selecciona un periodo de tiempo para poder exportar la información',
			slaInformation:
				'Se muestran las tareas con SLA vencido o próximo a vencer y que no se han atendido o se encuentran pausadas.',
			noPermission: 'Sin Permisos',
			partnerCanEdit: 'Los socios pueden editar',
			partnerCanView: 'Los socios pueden ver',
			partnerPermissions: 'Permisos de los Socios',
			reviewable: 'Revisable',
			numberFields: 'Número de Campos',
			updatedFormsSuccessfully: 'Formularios actualizados exitosamente',
			anyOrigin: 'Cualquier origen',

			idForm: 'Id de Formulario',
			textForm:'Palabra clave',
			// Asset Management
			assetList: 'Lista de Activos',
			selectedAssets: 'Activos Seleccionados',
			downloadAssetFileName: 'activosFielder.csv',
			downloadCSVTemplate: 'Descarga el formato de csv para importar',
			downloadCSVTemplateCategory: 'Descarga el formato de csv para importar dentro de ésta categoría',
			model: 'Modelo',
			serialNumber: 'Número de Serie',
			newCategory: 'Nueva Categoría',
			editCategory: 'Editar Categoría',
			defaultFields: 'Campos predeterminados del template',
			selectDefaultFields: 'Selecciona los campos que quieras que sean obligatorios',
			idCategory: 'Id Categoría',
			parentCategory: 'Categoría Padre',
			branchLogs: 'Historial de la Sucursal',
			createBranchLog: 'Sucursal Creada',
			createBranch: 'Crear Sucursal',
			editBranch: 'Editar Sucursal',

			editBranchLog: 'Información de la Sucursal editada',
			updateLocationBranchLog: 'Dirección de la Sucursal actualizada',
			disabledBranchLog: 'Sucursal deshabilitada',
			enabledBranchLog: 'Sucursal habilitada',
			apiUpdateBranchLog: 'Sucursal actualizada por la API',
			createBranchAPILog: 'Sucursal creada por la API',
			createBranchMobileLog: 'Sucursal creada por un dispositivo móvil',
			importedBranchLog: 'Sucursal importada',
			versionUpdatesHilighted: 'Las actualizaciones con respecto a la versión anterior se resaltan en un color diferente',
			updateLocationRequestLog: 'Solicitud de actualización de ubicación',
			deniedLocationRequestLog: 'Actualización de ubicación denegada',
			updated: 'Actualizado',
			assetManagement: 'Administración de Activos',
			noAssetsFound: 'No se encontraron activos dentro de esa categoría',
			noCategoriesFound: 'No tienes categorías',
			assetStatus: 'Estado',
			active: 'Activo',
			activeF: 'Activas',
			activeM: 'Activos',
			dashboardInactive: 'Inactivos',
			inactive: 'Inactivo',
			addSubCategory: 'Añadir Subcategoría',
			asset: 'Activo',
			assets: 'Activos',
			assetDetail: 'Detalle del Activo',
			addField: 'Añadir Campo',
			addAsset: 'Agregar Activo',
			editAsset: 'Editar Activo',
			newAsset: 'Nuevo Activo',
			disableAsset: 'Desactivar Activo(s)',
			isTheAssetPacked: '¿El activo viene empaquetado?',
			addBarcode: 'Agrega un código de barras',
			productCondition: 'Estado del producto',
			goodCondition: 'En buen estado',
			badCondition: 'Pésimo estado',
			create: 'Crear',
			newTemplate: 'Nuevo Template',
			editTemplate: 'Editar Template',
			addTemplate: 'Agregar Template',
			deleteConfirmationAsset: '¿Estás seguro que deseas eliminar el Activo?',
			disableConfirmationAsset: '¿Estás seguro que deseas desactivar el Activo?',
			deleteConfirmationCategory: '¿Estás seguro que deseas eliminar la Categoría?',
			changeStatus: 'Cambiar Status',
			question: 'Pregunta',
			singleAnswerLabel: 'Respuesta no modificable una vez respondida',
			agentQuestionLabel: 'Campos del agente',
			selectAtLeastOne: 'Selecciona por lo menos un activo para realizar la operacion',
			assetImportSuccess: 'Los activos se importaron correctamente',
			assetImportError: 'No se pudieron importar todos los activos ingresados',
			errorAssetMustHaveBranch: 'No puede asignar un activo a su tarea, debido a que no está ligada a una sucursal',
			assetCategoryHasSubcategoryOrAsset: 'La categoría contiene más de una subcategoría o activo',
			january: 'Enero',
			february: 'Febrero',
			march: 'Marzo',
			april: 'Abril',
			may: 'Mayo',
			june: 'Junio',
			july: 'Julio',
			august: 'Agosto',
			september: 'Septiembre',
			october: 'Octubre',
			november: 'Noviembre',
			december: 'Diciembre',
			noFileFound: 'No se encontró archivo',
			viewFileContent: 'Ver contenido del archivo',
			assetLogs: 'Historial del Activo',
			assetSavedSuccessfully: 'El Activo fue guardado con éxito',
			viewAsset: 'Ver el activo seleccionado',
			createTaskWithAsset: 'Guardar y crear tarea con activo',
			downloadAssets: 'Descargar Activos',
			generateAssetReport: 'Generar reporte de activos',
			generateAssetReportDescription:
				'Selecciona uno o más activos y las preguntas de dicho activo para agregarlos al reporte',
			canOnlyAddOrRemoveFieldsNotEdit: 'Sólo se pueden agregar o eliminar campos, la edición está deshabilitada',

			// Inventory
			inventory: 'Inventario',
			deleteConfirmationInventory: '¿Estás seguro que deseas eliminar el Inventario?',
			downloadInventoryFileName: 'inventoriesFielder.csv',
			noInventoryFound: 'No se encontró inventario dentro de esa categoría',
			addInventory: 'Agregar Inventario',
			newInventory: 'Nuevo Inventario',
			editInventory: 'Editar Inventario',
			inventoryLogs: 'Historial de Inventario',
			adminInventory: 'Administración de inventario',
			inventories: 'Inventarios',
			inventoriesAssociated:'Inventarios asociados al asset',
			linkWithAsset: 'Vincular Inventario con un Activo',
			selectedInventories: 'Inventarios selecionados',
			inventoryCategoryHasSubcategoryOrInventory: 'La categoría contiene más de una subcategoría o inventario',
			inventorySavedSuccessfully: 'El inventario fue guardado con éxito',
			viewInventory: 'Ver el inventario seleccionado',
			createTaskWithInventory: 'Crear tarea con inventario',
			downloadInventories: 'Descargar Inventarios',
			generateInventoryReport: 'Generar reporte de inventarios',
			generateInventoryReportDescription:
				'Seleccione uno o más inventarios y las preguntas de dicho inventario para agregarlos al reporte',
			inventoryImportSuccess: 'Los inventarios se importaron existosamente',

			// AssetInventory
			customStatus: 'Estado personalizado',
			manageCustomStatus: 'Administrar Estados Personalizados',
			assetAssignedToTaskStatus: 'Estado del Activo cuando se encuentra asignado a una tarea',
			inventoryAssignedToTaskStatus: 'Estado del Inventario cuando se encuentra asignado a una tarea',
			noStatus: 'Sin Estado',
			toRemoveTheElement: 'Para quitar el elemento vuelve a seleccionarlo',
			dontChange: 'No cambiar',
			selectStatus: 'Seleccionar estado',
			statusChangeWhenAssetInTask:
				'El estado del activo se cambiara autimáticamente a la opción seleccionada cuando se agregue a una tarea',
			statusChangeWhenInventoryInTask:
				'El estado del inventario se cambiara autimáticamente a la opción seleccionada cuando se agregue a una tarea',
			statusDisabledForTasks: 'Estados deshabilitados para las tareas',
			assetsWithSelectedStatusDisabledFromTasks:
				'Los activos con el estado seleccionado no podrán agregarse a las tareas',
			inventoryWithSelectedStatusDisabledFromTasks:
				'Los inventarios con el estado seleccionado no podrán agregarse a las tareas',
			optionToSelect: 'Opción a elegir',
			optionsToSelect: 'Opciones a elegir (separadas por "&" )',
			optionsCatalogue: 'Opciones a elegir (id##valor##comentario seperadas por &)',
			numberInsideRatingValue: 'Valor numérico dentro del rango configurado en la categoría',
			positiveOrNegative: 'Ingresa 1 para opción positiva 0 para opción negativa',
			starsRatingRange: 'Clasificación del 0 al 5',
			notAvailableForUpdate: 'No disponible para actualizar',
			notAvailableForImport: 'No disponible al importar',
			insertBarcode: 'Inserta el código de barras',
			insertDateInFormat: 'Inserte la fecha en el formato DD/MM/YYYY o DD/MM/YYYY HH:MM',
			insertDateInFormFormat: 'Inserta la fecha en el formato indicado en el encabezado del csv',
			updateFormInfo: 'Información para actualizar preguntas del formulario',
			importingInfo: 'Información para el proceso de importación',
			incorrectCsvFormatAssets:
				'No se pudieron importar los activos: Verifique que su csv tenga el formato correcto y no esté vacío',
			incorrectCsvFormatInventory:
				'No se pudieron importar los inventarios: Verifique que su csv tenga el formato correcto y no esté vacío',
			couldNotImportFollowingAssets: 'No se pudieron importar los siguientes activos',
			couldNotImportFollowingInventories: 'No se pudieron importar los siguientes inventarios',
			showingLatestLogInfo: 'Mostrando la información más reciente del historial',
			logAction: ['Actualizado', 'Actualizado por agente', 'Actualizado por administrador', 'Activo asignado a la tarea'],
			onlyShowingTheLast10Logs: 'Existe un servicio sin terminar',
			thereIsAnUnfinishedService:"Hay un servicio no terminado.",

			// auto assign
			autoassignOrigin: 'Origen de la auto asignación',
			autoassignType: 'Tipo de la auto asignación',
			geolocationLevel: 'Tipo de nivel',
			serviceLevel: 'Nivel de servicio',
      serviceLevelPersonalized: 'Nivel de servicio Personalizado',
			isochroneClientErrorMessage:
				'No se puede crear un template con la isócrona y el origen repetido de otro template',
			deleteTemplateConfirmation: '¿Esta seguro que desea eliminar el template seleccionado?',
			deleteTemplateTooltip: 'Borrar Template(s)',
			createTemplate: 'Crear Template',
			saveTemplate: 'Guardar Template',
			noTemplatesFound: 'No se encontraron templates',
			autoassign: 'Auto Asignar',
			autoassignation: 'Auto-Asignación',
			skillsList: 'Lista de Habilidades',
			skills: 'Habilidades',
			createSkills: 'Crear nuevas habilidades',
			addedRepeatedSkill: 'No es posible agregar una habilidad que ya existe',
			skillsToAdd: 'Ingresa una nueva habilidad',
			agentSkills: 'Skills de agente',
			responseTimePriority: 'Tiempo de respuesta por prioridad',
			deleteConfirmationSkill: '¿Estás seguro que deseas eliminar la habilidad?',
			unexpectedErrorOcurred: 'Ocurrió un error inesperado. Por favor intenta nuevamente',
			createUserError:
				'El usuario no pudo ser creado, favor de verificar que la información esté correcta e intente nuevamente. (Recuerda que el limite para el nombre y el apellido es 150 caracteres)',
			createTeamError: 'El equipo no pudo ser creado, por favor intenta nuevamente',
			createCompanyError: 'La compañía no pudo ser creada, por favor intenta nuevamente',
			createBranchError: 'La sucursal no pudo ser creada, por favor intenta nuevamente',
			addAutoassignTemplate: 'Agregar template de auto asignación',
			viewAutoassignTemplate: 'Ver template de auto asignación',
			templates: 'Templates',
			likeToAddPartner: '¿Deseas asignarle la tarea a un socio?',
			autoassignTo: 'Auto asignar a: ',
			createAutoassignTemplate: 'Crear Template para auto asignación',
			editAutoassignTemplate: 'Editar Template para auto asignación',
			byPriority: 'Color del marcador por prioridad',
			byStatus: 'Color del marcador por estatus',
      personalizedPriorities: 'Prioridades Personalizadas',
			priority: 'Prioridad',
			idPriority: 'Id Prioridad',
			lowPriority: 'Baja',
			medPriority: 'Media',
			highPriority: 'Alta',
			coverage: 'Cobertura',
			viewIsochronous: 'Ver Isócronas',
			viewSkills: 'Ver Habilidades',
			applyToAll: 'Aplicar a todos',
			apply: 'Aplicar',
			addFilter: ' + Añadir filtro',
			selectSpecificAgents: 'Seleccionar agentes específicos',
			selectAgents: 'Seleccionar agentes',
			selectSpecificTeams: 'Seleccionar equipos específicos',
			selectTeams: 'Seleccionar equipos',
			selectSpecificPartners: 'Seleccionar socios específicos',
			selectPartners: 'Seleccionar socios',
			resources: 'Recursos',
			selectTaskTypes: 'Selecciona tipo de tarea',
			responseTime: 'Tiempo de Respuesta',
			immediateResponse: 'Respuesta Inmediata',
			successCounter: 'Veces usado exitosamente',
			useCounter: 'Veces utilizado',
			infoAutoassignType: 'En función de qué te gustaría auto asignar',
			autoassignDetail: 'Detalle de la Auto Asignación',
			failureReason: 'Motivo del Fallo',
			selectResourcesToAutoassign:
				'Selecciona los recursos que desea auto asignar a las tareas. Drag the resource type card to determine the priority for the self-allocation process',
			dragAndDropForAutoassignPriority: 'Drag and Drop the active resources to assign a priority order',
			dragAndDropForAutoassignPriority:
				'Arrastra los recursos activos para asignar un orden de prioridad en la selección de los mismos',
			autoassignTemplateSaved: 'Se guardó el template de auto asignación con éxito',
			templateDeletedSuccessfully: 'Template(s) eliminado(s) con éxito',
			configureResponseTimePerPriority:
				'Configura el tiempo de respuesta para que el agente se encuentre en la ubicación de la tarea previo al inicio de la misma, de acuerdo con la prioridad',
			configureResponseTimePerPriorityInformation:
				'Tiempo colchón para que el agente llegue con anticipación a la ubicación de la tarea',
			color: 'Color',
			updatedTemplatesSuccessfully: 'Templates actualizados correctamente',
			successRate: 'Tasa de éxito',
			assignBySkills: 'Asignar por habilidades',
			errorCouldntRetrieveTemplate: 'No se pudo obtener la plantilla seleccionada. Intente más tarde',
			errorFieldNotEmpty: 'Este campo no debe estar vacío',
			errorMustSelectAtLeastOneOption: 'Debes seleccionar al menos una opción',
			errorMustHaveAtLeastOneActiveResource: 'Debes activar al menos un recurso',
			errorMustHaveAtLeastOneActivePriority: 'Debes activar al menos una prioridad',
			templateSavedSuccessfully: 'El template fue guardado con éxito',

			unselectAll: 'Deselecciona todo',
			// Status
			executingAutoassign: 'Ejecutando',
			successful: 'Exitoso',
			unsuccessful: 'No exitoso',
			waiting: 'Esperando',
			unknown: 'Desconocido',

			noAgentsAvailable: 'No se encontraron agentes disponibles para realizar la tarea',
			noAgentsWithSkills: 'No se encontraron agentes con las habilidades necesarios para realizar la tarea',
			availableAgentsInMultipleTeams: 'Los agentes disponibles se encontraron en varios equipos',
			agentInMultipleTeams: 'Todos los agentes disponibles fueron encontrados en múltiples equipos',
			retrying: 'Volviendo a Intentar',

			dashboardPausedTasks: 'Dashboard Tareas Pausadas',
			dashboardRejectedTasks: 'Dashboard Tareas Rechazadas',

			// Task Types - Task Personalizaton
			taskPersonalization: 'Personalización de Tareas',
			rejectedTaskPersonalization: 'Personalización de Tareas Rechazadas',
			pausedTaskPersonalization: 'Personalización de Tareas Pausadas',
			prioritiesPersonalization:'Personalización de Prioridades',

			addRejectedTask: 'Agregar el motivo de las tareas rechazadas',
			addPausedTask: 'Agregar motivo para Tareas Pausadas',
			addPriority:'Agregar Prioridad',
			addTaskType: 'Agregar tipo de tarea',
			reasonName: 'Nombre de la razón',
			priorityName:'Nombre de prioridad',
			addReasonRejectTaskTitle: 'Agregar razón para tareas rechazadas',
			editReasonRejectTaskTitle: 'Editar razón de rechazo',
			addReasonPauseTaskTitle: 'Agregar razón para tareas pausadas',
			editReasonPauseTaskTitle: 'Editar razón de tarea pausada',
			addPriorityTaskTitle: 'Agregar Prioridad',
			editPriorityTaskTitle: 'Editar Prioridad',

			hideToAgent: 'No mostrar este tipo de tarea al agente',
			showToAgent: 'Mostrar este tipo de tarea al agente',
			showAgentType: 'Mostrar al agente',
			specifyCompany: 'Especificar Compañía',
			specifyBranch: 'Especificar Sucursal',
			specifyClient: 'Especificar Cliente',
			editTaskType: 'Editar tipo de tarea',
			taskTypeName: 'Nombre del tipo de tarea',
			specificTime: 'Especificar tiempo de ejecución',
			specifyWebhookUrl: 'Ingresa tu endpoint/url para el webhook (opcional)',
			apiRequestAllowed: "Solicitudes de API permitidas al usuario:",
			price: 'Precio',
			selectDependency: 'Selecciona la dependencia',
			taskPauseDependency: 'Dependencia del pausado de la tarea',
			dependency: 'Dependencia',
			pausedByFinalClient: 'Pausada por Cliente Final',
			pausedByAgent: 'Pausada por Agente',
			rejectedByFinalClient: 'Rechazado por el cliente final',
			rejectedByAgent: 'Rechazado por el agente',
			selectParent: 'Selecciona padre',
			taskTypeConstraintDesc: 'Aplicar sólo a (opcional)',
			userApiInfoConstraintDesc: 'Selecciona la opción:',
			selectTaskTypeId: 'Selecciona el Id del tipo de tarea',
			taskVariableNumber: 'Númerico',
			taskVariableTime: 'Tiempo',
			taskTypesNotDeleted: 'No fue posible eliminar los siguientes tipos de tarea: ',
			taskTypesCantBeDeleted: 'No se puede borrar',
			taskTypesDeletedWithChildren: 'Contiene algún sub tipo',
			taskTypesDeletedWithTasks: 'Alguna tarea existente lo utiliza',
			taskTypesDeletedSuccess: 'Los siguientes tipos de tarea fueron borrados existosamente: ',
			taskTypeAddSuccess: 'Se ha guardado exitosamente',
			taskTypeInvalidRouteVariable: "Sólo se puede seleccionar una variable de tipo 'Numérico'",
			infoTaskTypeRouteVariable:
				'Configure las variables por tipo de tarea para obtener un mejor resultado al generar rutas con Asignación  de recursos o Asignación Dinámica',
			infoTaskTypeRouteVariableType:
				'Tiempo: se sumará al tiempo total de ejecucución de la Tarea; Númerico: se multiplacará por el tiempo de ejecución de la Tarea',
			enableSearch: 'Habilitar la búsqueda',

			globalSearchMessage:
				'Se muestran resultados de 3 meses anteriores y posteriores a la fecha actual de búsqueda. Te recomendamos utilizar los filtros avanzados de Mi Operación para una búsqueda más precisa.',
			// support
			messageCreated: 'Se ha creado el ticket',
			sendTicket: 'Enviar',
			messageFormatFiles: 'Los archivos aceptados son jpg, png, gif y jpeg imagenes y txt, csv, xls y pdf archivos',
			messageNumberFiles: 'No puede subir más de 5 archivos',
			titleSupport: 'Soporte',
			category: 'Categoría',
			webPlatform: 'Plataforma Web',
			movilApp: 'Aplicación Movil',
			mailsSMS: 'Mails / SMS',
			subscription: 'Suscripción',
			training: 'Capacitación',
			other: 'Otro',
			updateFileMessage: 'Recuerda que al actualizar el link o archivo este es  sobreescrito, ¿Desea continuar?',
			updateTemplateMessagePdf:
				'Al actualizar el archivo, éste último reemplazará el Template pdf aprobado. ¿Deseas actualizar?',
			externalLink: 'Link externo',
			modifyStatus: 'Modificar estado',

			deleteFileMessage: '¿Deseas eliminar el archivo de la tarea?',
			deleteLinkMessage: '¿Quieres eliminar el link vinculado a la tarea?',

			externalStorage: 'Ir al almacenamiento externo',
			updateLink: 'Actualizar link',
			uploadLink: 'Subir link',
			linkDocument: 'Link del documento',
			fileName: 'Nombre del archivo',
			link: 'Link',
			uploadFile: 'Subir Archivo',
			uploadFiles: 'Subir Archivos',
			updateFile: 'Actualizar archivo',
			pressEnterToSearch:'Presiona enter para buscar',

			geolocationDesciptionType0:
				'Esta configuración permite la geolocalización (latitud y longitud) de los agentes únicamente en el “inicio” y “término” de sus actividades. Los agentes podrán recibir tareas en la aplicación de Fielder Agente, sin recopilar información de ubicación, trayectos, ETA, histórico de trayectos, entre otras variables. Se recomienda esta configuración en operaciones no dinámicas, donde se cuentan con recursos dedicados, en sitio, en localidades pre-determinadas, y sólo se requiere colaborar en tiempo real con el agente y recopilar evidencias de la ejecución de las tareas asignadas.',
			geolocationDesciptionType1:
				'Esta configuración permite la geolocalización (latitud y longitud) de los agentes equilibrando el rendimiento de la batería del móvil del agente y el plan de datos. La aplicación de Fielder Agente obtiene información de ubicación entre 30 segundos (aprox. Cuando existe un desplazamiento del agente) y cada 5 minutos (aprox. Cuando no hay un desplazamiento). En dispositivos con Android 9.0 o menor, la aplicación de Fielder Agente en primer plano y Segundo plano tendrán el comportamiento mencionado. En versiones de Android de 10,0 en Adelante, se obtiene solo la ubicación en primer plano. Las variables pueden modificarse al tener bajo nivel de batería en el móvil. Se recomienda esta configuración cuando la operación es dinámica, se cuentan con recursos dispersos atendiendo diferentes tareas en diferentes localidades, y se requiere colaborar en tiempo real con el agente y recopilar información de ubicaciones, trayectos, ETA y evidencias de la ejecución de las tareas asignadas.',
			geolocationDesciptionType2:
				'Esta configuración permite la geolocalización (latitud y longitud) de los agentes con alto consumo entre la batería del móvil del agente y el plan de datos. La aplicación de Fielder Agente obtiene información de ubicación entre 15 segundo (aprox. Cuando existe un desplazamiento del agente) y cada 2 minutos y medio (aprox. Cuando no hay un desplazamiento). En dispositivos con Android 9.0 o menor, la aplicación de Fielder Agente en primer plano y Segundo plano tendrán el comportamiento mencionado. En versiones de Android de 10.0 en Adelante, se obtiene solo la ubicación en primer plano, y en Segundo plano siempre y cuando el Agente otorgue el permiso correspondiente en la aplicación. Las variables pueden modificarse al tener bajo nivel de batería en el móvil. Se recomienda esta configuración cuando la operación es sumamente dinámica y con alta demanda de tareas, se cuentan con recursos dispersos atendiendo diferentes tareas en diferentes localidades, y se requiere colaborar en tiempo real con el agente y usuarios finales para brindar una experiencia “On-Demand”.',

			requiredTaskType: 'Por favor cambia el tipo de tarea diferente al de "Por correo" para continuar',
			requiredTeam: 'Por favor selecciona un equipo para continuar',

			createdTaskBy: 'Tarea creada por',

			DO_ACTION_UNKNOWN: ' ',
			DO_ACTION_CREATE_GROUP: 'Grupo de tarea creado',
			DO_ACTION_SET_ON_WAY: 'Grupo de tarea en camino',
			DO_ACTION_ARRIVE: 'Grupo de tarea llegó',
			DO_ACTION_STARTED: 'Grupo de tarea ejecutandose',
			DO_ACTION_PAUSE: 'Grupo de tarea en pausa',
			DO_ACTION_FINISH: 'Grupo de tarea finalizado',
			DO_ACTION_CANCELED: 'Grupo de tarea cancelado',
			DO_ACTION_TRASH_GROUP: 'Grupo de tareas desactivado ',
			DO_ACTION_DELETE_GROUP: 'Grupo de tareas eliminado ',
			DO_ACTION_CANCEL_TRASH_GROUP: 'Grupo de tareas cancelado',
			DO_ACTION_ASSIGN_AGENT: 'Agente asignado',
			DO_ACTION_RESCHEDULE: 'Grupo de tareas recalendarizado',
			DO_ACTION_UPDATE_ADDRESS: 'Cambio de dirección',
			DO_ACTION_UPDATE: 'Grupo de tareas actualizado',
			DO_ACTION_ASSIGN_TASK: 'Tarea asignada ',
			DO_ACTION_TRASH_GROUP_TASK: 'Tarea desactivada',
			DO_ACTION_UNLINK_TASK_FROM_GROUP: 'Tarea desvinculada del grupo',
			DO_ACTION_UPDATE_BRANCH:'Sucursal actualizada',

			DO_ACTION_CREATE_TASK: 'Tarea creada',
			DO_ACTION_UPDATE_TASK: 'Tarea actualizada',
			DO_ACTION_UPDATE_ADDRESS_TASK: 'Cambio de dirección',
			DO_ACTION_RESCHEDULE_TASK: 'Tarea recalendarizada',
			DO_ACTION_ASSIGN_AGENT_TASK: 'Agente asignado',
			DO_ACTION_ASSIGN_TEAM_TASK: 'Equipo asignado',
			DO_ACTION_ASSIGN_PARTNER_TASK: 'Asignado a socio',
			DO_ACTION_CANCEL_TASK: 'Tarea Cancelada',
			DO_ACTION_CANCEL_PARTNER_TASK: 'Candelado por Socio',
			DO_ACTION_RATE_TASK: 'Tarea calificada',
			DO_ACTION_RESTART_TASK: 'Tarea reiniciada',
			DO_ACTION_TRASH_TASK: 'Tarea deshabilidata',
			DO_ACTION_DELETE_TASK: 'Tarea Eliminada',
			DO_ACTION_CANCEL_TRASH_TASK: 'Tarea habilitada',
			DO_START_TASK_AGENT: 'Agente inició Tarea',
			DO_ARRIVE_TASK_AGENT: 'Agente ha llegado',
			DO_ON_WAY_TASK_AGENT: 'Agente en camino',
			DO_REJECT_TASK_AGENT: 'Agente rechazó Tarea',
			DO_FINISH_TASK_AGENT: 'Agente terminó Tarea',
			DO_CANCEL_TASK_AGENT: 'Agente canceló Tarea',
			DO_ACCEPT_TASK_AGENT: 'Agente aceptó Tarea',
			DO_ADD_COMMENT: 'Agente agregó comentario',
			DO_ACTION_ASSIGN_GROUP: 'Grupo asignado',
			DO_ACTION_REFUSE_GROUP:'Tarea desvinculada del grupo',
			DO_ACTION_ASSIGN_FORM: 'Formulario asignado a la tarea',
			DO_ACTION_ASSIGN_ASSET: 'Activo asignado a la tarea',
			DO_ACTION_ASSIGN_INVENTORY: 'Inventario asignado a la tarea',
			DO_ACTION_PAUSED_TASK: 'Tarea pausada ',
			DO_ACTION_AUTOASSIGNING_STARTED: 'Inicio Auto asignación',
			DO_ACTION_AUTOASSIGNING_FAILED: 'Auto asignación fallida',
			DO_ACTION_UNLINK_TASK: 'Tarea desvinculada de la ruta',
			DO_ACTION_RESET_EXECUTION_TASK: 'Ejecución reiniciada',
			DO_ACTION_RESET_CANCELED_TASK: 'Tarea reiniciada',
			ADD_TASK_TO_ROUTE: 'Tarea agregada a ruta',
			DO_ACTION_REMOVE_TASK_FROM_ROUTE: 'Tarea eliminada de una ruta',
			DO_ACTION_ADD_TASK_TO_ROUTE: 'Tarea agregada a ruta',
			DO_ACTION_UPDATED_FORM: 'Formulario Guardado',
			DO_ACTION_UPDATED_ASSET: 'Modificó activo',
			DO_ACTION_UPDATED_INVENTORY: 'Modificó inventario',
			DO_TAKE_TASK_ON_GROUP:'Tarea tomada por agente',

			DO_ACTION_UPLOAD_TASK_DOCUMENT: 'Documento adjuntado a tarea',
			DO_ACTION_DELETE_TASK_DOCUMENT: 'Documento removido de la tarea',
			DO_ACTION_UPDATE_TASK_DOCUMENT: 'Documento actualizado',

			DO_ACTION_UPLOAD_TASK_LINK_DOCUMENT: 'Link adjuntado a la tarea',
			DO_ACTION_DELETE_TASK_LINK__DOCUMENT: 'Link removido de la tarea',
			DO_ACTION_UPDATE_TASK_LINK__DOCUMENT: 'Link de tarea actualizado',

			DO_ACTION_FINISH_FORM:'Formulario Terminado',
			DO_ACTION_REVIEW_FORM: 'Formulario enviado a revisión',
			DO_ACTION_APPROVED_FORM:  'Formulario aprobado',

			DO_ACTION_UPLOAD_TASK_PDF_FORM: 'PDF de formulario aprobado',
			DO_ACTION_UPDATE_TASK_PDF_FORM: 'PDF de formulario aprobado actualizado',
			DO_ACTION_ADMIN_MODIFY_TASK: 'Administrador modificó estado de la tarea',
			DO_ACTION_MANAGER_MODIFY_TASK: 'Gerente modificó estado de la tarea',
			DO_ACTION_SEND_FORM_BY_MAIL:'Agente envío formularios por correo',

			CREATED: 'Creada',
			ON_WAY: 'Agente en camino',
			ARRIVE: 'Agente llego a destino',
			EXECUTING: 'Grupo de Tareas en Proceso',
			PAUSE: 'Pausada',
			FINISH: 'Finalizada',
			CANCELED: 'Cancelada',
			ON_TRASH: 'Deshabilitada',

			finish: 'Terminar',
			continue: 'Continuar',
			back: 'Regresar',
			documentation: 'Documentación',
			businessIntelligence:"BI",

			// TASK INFO
			numTask: 'Número de Tarea',

			disabled: 'desactivados',

			deleteCompanies: 'Borrar compañía(s)',
			deactivateCompanies: 'Desactivar compañía(s)',
			enableCompanies: 'Habilitar compañía(s)',

			disabledBranches: 'Desactivar Sucursal(es)',
			deleteBranches: 'Borrar sucursal(es)',
			enableBranches: 'Habilitar sucursal(es)',
			enableTask: 'Habilitar tarea(s)',
			duplicateTask: 'Duplicar tarea',

			deleteClients: 'Borrar cliente(s)',
			deactivateClients: 'Desactivar client(s)',
			enableClients: 'Habilitar cliente(s)',

			deleteManagers: 'Borrar gerente(s)',
			deactivateManagers: 'Desactivar gerente(s)',
			enableManagers: 'Habilitar gerente(s)',

			deleteTeams: 'Borrar equipo(s)',
			deactivateTeams: 'Desactivar equipos(s)',
			enableTeams: 'Habilitar equipo(s)',

			assistedFormError: 'Formato PDF no compatible, inténtalo nuevamente.',
			assistedFormSuccess:'El Documento se ha digitalizado. Nuestra inteligencia artificial ha creado para tí un formulario y template de Excel con la estructura de preguntas sugerida. Por favor revisa dicha estructura y mapeo en el template. Puedes ajustar con los diferentes tipos de pregunta que Fielder te ofrece de acuerdo a tus necesidades puntuales.',

			cancelPartnership: 'Cancelar Asociación',

			deactivateAdministrators: 'Desactivar administrador(es)',
			deleteAdministrators: 'Eliminar administrador(es)',
			enableAdministrators: 'Habilitar administrator(es)',

			notifications: 'Notificaciones',
			recentNotifications: 'Notificaciones Recientes',
			olderNotifications: 'Antiguas Notificaciones',
			addUser: 'Agregar usuario',
			news: 'Noticias',
			functionalitiesImprovement: 'Mejoras en funcionalidades',
			newFeatures: 'Nuevas características',
			share: 'Compartir',
			myProfile: 'Mi perfil',
			changePassword: 'Cambiar contraseña',
			support: 'Soporte',
			logOut: 'Salir',

			showOnlyDeleted: 'Ver desactivados',
			showOnlyDisabled: 'Ver desactivados',

			showTaskByMail: 'Mostrar tareas creadas por correo',

			questionDeactivateCompany: '¿Estás seguro que deseas desactivar la(s) compañía(s)?',
			questionDeletedCompanies: '¿Estás seguro que deseas eliminar la(s) compañía(s)?',
			questionRestoreCompanies: '¿Estás seguro que deseas restaurar la(s) compañía(s)?',

			questionDeactivateBranches: '¿Estás seguro que deseas desactivar la(s) sucursal(es)?',
			questionRestoreBranches: '¿Estás seguro que deseas restaurar la(s) sucursal(es)?',
			questionDeletedBranches: '¿Estás seguro que deseas eliminar la(s) sucursal(es)?',

			questionDeactivateClients: '¿Estás seguro que deseas desactivar el/los cliente(s)?',
			questionDeletedClients: '¿Estás seguro que deseas eliminar el/los cliente(s)?',
			questionRestoreClients: '¿Estás seguro que deseas restaurar el/los cliente(s)?',

			questionDeactivateAdministrator: '¿Estás seguro que deseas desactivar el/los adminitrador(es)?',
			questionDeletedAdministrator: '¿Estás seguro que deseas eliminar el/los adminitrador(es)?',
			questionRestoreAdministrator: '¿Estás seguro que deseas restaurar el/los administrador(es)?',

			questionDeactivateManager: '¿Estás seguro que deseas desactivar el/los gerente(s)?',
			questionDeletedManager: '¿Estás seguro que deseas eliminar el/los gerente(s)?',
			questionRestoreManager: '¿Estás seguro que deseas restaurar el/los gerente(s)?',

			questionAcceptInvitations: '¿Estás seguro de aceptar la(s) invitacion(es)?',
			questionRefuseInvitations: '¿Estás seguro de rechazar la(s) invitacion(es)?',

			questionCancelPartnership: '¿Estás seguro que quieres cancelar la asociación(es)?',

			questionDeactivateTeams: '¿Estás seguro que deseas desactivar el/los equipo(s)?',
			questionDeletedTeams: '¿Estás seguro que deseas eliminar equipo(s)?',
			questionRestoreTeams: '¿Estás seguro que deseas restaurar equipo(s)?',

			idUser: 'Id',
			idUserHeader:'Cliente (id Fielder)',
			mobileNumber: 'Teléfono móvil',
			jobPosition: 'Puesto',
			showTasks: 'Desplegar tareas',
			detailCompany: 'Detalle de compañía',
			logoCompany: 'Logo de la compañía',
			detailClient: 'Detalle del cliente',
			detailAdministrator: 'Detalle del administrador',
			detailManager: 'Detalle del gerente',
			detailBranch: 'Detalle de sucursal',
			editCompany: 'Editar compañía',
			addCompanySubtitle: 'Agrega una nueva compañía a tu operación',
			editCompanySubtitle: 'Ve o actualiza la información de la compañía',
			editAdministrator: 'Editar administrador',
			editManager: 'Editar gerente',
			editClient: 'Editar cliente',
			editBranch: 'Editar sucursal',
			editAgent: 'Editar agente',
			save: 'Guardar',
			saveFile: 'Guardar Archivo',
			saveFilter: 'Guardar filtro',
			removeFilter: 'Eliminar filtro',
			loadFilter: 'Cargar filtro',
			loadedFilter: 'Filtro cargado con exito',
			savedFilter: 'Filtro salvado con exito',
			removedFilter: 'Filtro removido con éxito',
			saveFiles: 'Guardar Archivos',
			deleteFile: 'Eliminar Archivo',
			removeTemplateFile: '¿Está seguro que desea eliminar el archivo de la plantilla?',
			dropFiles: 'Deposite los archivos aquí ...',
			importFileDetails: 'Detalles de importación',
			toUploadToYourAccount: 'Para guardar en su cuenta',
			uploadAssetInventoryTemplate:
				'Cargue un archivo para usarlo como plantilla al exportar detalles internos de la tarea',
			unsupportedFileType: 'Tipo de archivo no admitido, cargue un tipo admitido',
			activateAccountEmail: 'Activar cuenta de correo',
			print: 'Impresión',
			getInto: 'Ingresa',
			signUp: 'Regístrate',
			disabledUser: 'Desactivar usuario',
			activateUser: 'Activar usuario',
			resendPassword: 'Re-enviar Contraseña',
			recoverPassword: 'Recuperar Contraseña',
			recoverPasswordBtn: 'Recuperar',
			recoverPasswordSuccessText: 'Te enviaremos tu contraseña por email.',
			recoverPasswordUserNotFound: 'E-mail no encontrado, por favor verifica tu e-mail.',
			errorGralFail: 'Surgió un error, intente más tarde',
			errorGroupWasReassigned:'El grupo fue reasignado',
			errorRouteTaskNull:
				'La ruta no pudo ser reagendada porque una de sus tareas no tiene fecha de ejecución. Por favor de reagendarla e intentar nuevamente.',
			sameCompanyHint: 'La compañía padre no puede ser la misma',
			cancelServiceByPartner: 'Servicios cancelados por partner',
			idParentCompany: 'Id Compañía Padre',
			address: 'Dirección',
			addressExcel: 'Dirección de llegada a la localidad',
			latitude: 'Latitud',
			longitude: 'Longitud',
			attentionTimeFrom: 'Tiempo de atención desde',
			attentionTimeTo: 'Tiempo de atención hasta',
			linkedToBranch: 'Ligado a sucursal',

			workScheduleFrom: 'Horario de trabajo desde',
			workScheduleTo: 'Horario de trabajo hasta',
			workSchedule: 'Horario',
			schedule: 'Fecha/Hora',
			type: 'Tipo',
			officePhone: 'Teléfono oficina',
			mobilePhone: 'Teléfono móvil',
			administrative: 'Administrativo',
			executive: 'Ejecutivo',
			technical: 'Técnico',
			trade: 'Comercial',

			hourValidation: '¡La primera fecha debe de ser menor a la segunda!',
			userNameValidation: 'El nombre de usuario ya existe',
			workingHours: 'Horario de trabajo',
			rangeOfAttentionTime: 'Horario de Atención de Servicio',
			download: 'Descargar',
			downloadTask: 'Descargar tareas',

			// TaskType
			taskType: 'Tipo de Tarea',
			tasktypeMinus1: 'Remota',
			tasktipeMail: 'Por correo',
			tasktype0: 'Volumen',
			tasktype1: 'Recoger',
			tasktype2: 'Servicio',
			tasktype3: 'Entrega',
			tasktype4: 'Tarea en Grupo',
			volume: 'Volumen',
			remote: 'Remota',
			pickup: 'Recoger',
			workforce: 'Servicio',
			delivery: 'Entrega',
			grouptask: 'Tarea en Grupo',
			tasktupeSimple: 'En grupo',
			tasktypeAll: 'Todas',
			unassigned: 'Sin asignar',
			days: 'días',
			hours: 'horas',
			minutes: 'minutos',
			seconds: 'segundos',
			hour: 'hora',
			minute: 'minuto',
			second: 'segundo',
			reorderByModifyStatus: 'Organizar por modificación de estatus',
			reorderBySignature:'Organizar por firma faltante',
			activity_type_0: 'Ruta creada',
			activity_type_1: 'Agente asignado',
			activity_type_2: 'Ruta aceptada',
			activity_type_3: 'Ruta ejecutada',
			activity_type_4: 'Ruta cancelada',
			activity_type_5: 'Ruta finalizada',
			activity_type_6: 'Ruta rechazada',
			activity_type_7: 'Ruta reorganizada',
			activity_type_8: 'Emergencia creada',
			activity_type_9: 'Ruta reagendada',
			activity_type_10: 'Tarea eliminada',
			activity_type_11: 'Tarea añadida',
			label_position_add_task: 'Posición de la tarea',
			plus: ' Mas ... ',
			// Configuration

			configIdAccount: 'Número de Cuenta',
			accountStatus: 'Estatus',
			action: 'Acción',
			configBusinessName: 'Razón Social de la Empresa',
			configPhone: 'Teléfono de la Empresa',
			configAddress: 'Dirección de la Empresa',
			configRFC: 'RFC de la Empresa',
			configUploadLogo: 'Subir Logo',
			configAccount: 'Cuenta Fielder',
			configAccess: 'Permisos',
			mailSettings: 'Configuración de correo',

			permissionAgentAllwaysExecute: 'Ejecutar tareas en diferente fecha',
			permissionAgentCreateTasks: 'Crear tareas',
			permissionAgentDuplicateTasks: 'Crear subtareas',
			permissionAgentChangeTypeTasks: 'Permitir al agente cambiar tipo de tarea',
			permissionAgentRescheduleTasks: 'Permitir al agente reagendar tareas',
			permissionAccessClient: 'Permitir acceso a Clientes',
			permissionAccessClientImport: 'Permitir importar tareas',
			permissionAccessClientExport: 'Permitir exportar tareas',
			permissionManagerToCreate: 'Permitir a Gerentes crear tareas',
			permissionManagerAccessToAnalytics: 'Permitir a Gerentes ver analíticos',
			permissionManagerAccessToRoute: 'Permitir a Gerentes acceder al módulo de Optimización de Rutas',
			permissionManagerAccessToResource: 'Permitir a Gerentes acceder al módulo de Asignación de recursos',
			permissionManagerAccessToIsochronous: 'Permitir a Gerentes acceder al módulo de Isócronas',
			permissionManagerAccessToDowloadExcel: 'Permitir que los Gerentes puedan exportar tareas',
			permissionManagerAccessToImportExcel: 'Permitir a los Gerentes importar tareas',
			permissionManagerToModifyTaskStatus: 'Permitir a Gerentes modificar estado de las tareas',
			permissionManagerToDownloadFormExcelTaskDetail: 'Permitir a Gerentes descarguen el formulario de Excel en el detalle de la tarea',
			permissionManagerToEditFormTaskDetail: 'Permitir al gerente editar formulario en detalle de tarea',
			configAllowManagerToSeeTaskByMail: 'Permitir a gerente ver tareas por mail',
			configAllowManagerToGeneratePdf:'Permitir a Gerentes convertir el formulario a PDF en el detalle de la tarea',
      		configAllowManagerToApproveForms:'Permitir a Gerentes aprobar formularios',
			configAllowManagerToGenerateAutoAssignTasks:'Permitir a Gerentes generar la Auto-asignación de tareas',
			configAllowManagerToGenerateAndDownloadForms:'Permitir a Gerentes generar y descargar informes de formularios',
			configAllowManagerToGenerateAndDownloadAssets:'Permitir a Gerentes generar y descargar informes de activos',
			configAllowManagerToGenerateAndDownloadInventories:'Permitir a Gerentes generar y descargar informes de inventarios',

      		permissionAdminToModifyTaskStatus: 'Permitir a Administradores modificar estado de las tareas',
			permissionAdminAccessToAnalytics: 'Permitir a Administradores ver analíticos',
			permissionAdminToViewTaskToCash: 'Permitir a Administradores ver Tareas por Cobrar',
			permissionAdminToReceiveEmailWeeklyAccountSummary: 'Permitir que los administradores reciban un correo electrónico semanal con un resumen de la cuenta',
			size: 'Tamaño',

			modifyTaskStatus: 'Modificar estado de la tarea',

			addAdministrator: 'Agregar administrador',
			editTaskGroup: 'Editar Grupo de Tareas',
			taskGroup: 'Grupo de Tareas',
			unlinkTaskGroup: 'Desvincular tarea(s)',
			taskGroupUpperCase: 'Grupos de tareas',
			downloadTaskGroupImages: 'Descarga las imágenes de las tareas del grupo',
			deletedTaskGroup: 'Eliminar grupo de tareas',
			confirmDeleteTaskGroup: '¿Estás seguro que deseas eliminar este grupo de tareas?',
			noGroupTaskImages: 'Este grupo de tareas no tiene ninguna imágen almacenada',

			detailTaskGroup: 'Detalle de Grupo de Tareas',
			imageTaskFile: 'imagenes-del-grupo-de-tareas',
			upperCaseAdministrators: 'ADMINISTRADORES',
			upperCaseManagers: 'GERENTES',
			selectTeamZone: 'Selecciona equipo/zona',
			selectTeamZoneApi: 'Selecciona equipo/zona (Obligatorio)',

			map: 'MAPA',
			dashboard: 'DASHBOARD',
			statusTodayDashboard: 'ESTATUS DE TAREAS / HOY',
			total: 'Total',
			inProgress: 'En progreso',
			finished: 'Finalizadas',
			rejected: 'Sin aceptación',
			totalAgents: 'Agentes Totales',
			agentsAnalytics: 'AGENTES',
			executeServices: 'TAREAS TERMINADAS',
			tasksFinished: 'Tareas finalizadas',
			pieGraph: 'Mostrar gráfica de pastel',
			normalGraph: 'Mostrar gráfica normal',
			onlineDashboard: 'Conectado',
			offlineDashboard: 'Desconectado',
			online: 'Conectados',
			offline: 'Desconectados',
			messageAgentDashboard: 'The agent has not updated their information or logged into the application',
			customerSatisfaction: 'SATISFACCIÓN DE CLIENTES',
			taskPlan: 'PLAN DE TAREAS',
			taskPlanAlert:
				'Has excedido el plan de tareas mensual contratado. Las tareas excedentes serán consideradas en tu siguiente factura.',
			completedTasks: 'Tareas realizadas',
			remainingTasks: 'Tareas restantes',
			totalPlan: 'Plan total',
			filterByStatusModification: 'Filtrar por modificación de estado',
			showTableColumns: 'Mostrar columnas de la tabla',

			taskToCash: 'Tareas por cobrar',
			taskToCashReportFilename: 'reporteTareasPorCobrar',
			moreBranchTooltip: 'más sucursales',
			moreTaskTypeTooltip: 'más tipos de tarea',
			taskQuantity: 'Cantidad de tareas',
			amountToCash: 'Monto a cobrar',
			currency: 'Moneda',
			progressOfTheProject: 'Progreso del proyecto',
			contributionPercentage: 'Porcentage de contribución',
			pricePerTask: 'Precio por tarea',
			generalTaskStatus: 'Estado general de las tareas',
		
			restOfTasksWeight: 'Resto de las tareas',

			summaryOfTask: 'Resumen de tareas',
			summaryTaskSla: 'Tareas con SLA vencido o próximo a vencer el día de hoy',
			summaryOfAgent: 'Resumen de Agentes',
			unassignedAgent: 'Agente no asignado',
			idFielder: 'ID Fielder',
			customId: 'ID Personalizado',
			idCustomGroup: 'ID Externo',
			tasksCompleted: 'TAREAS FINALIZADAS',
			taskCreated: 'Tareas creadas',
			taskFinished: 'Tareas completadas',
			completed: 'Completado',
			information: 'Información',
			countTask: 'Número',
			uppercasePartners: 'SOCIOS',
			invitations: 'Invitaciones',
			myPartners: 'Socios que integré',
			iAmPartner: 'Socios que me integraron',
			integratedByMe: 'Integrado por mí',
			newAgent: 'Nuevo agente',
			newClient: 'Nuevo cliente',
			newManager: 'Nuevo gerente',
			newBranch: 'Nueva sucursal',
			newAdministrator: 'Nuevo administrador',
			updateInvitation: 'Actualizar invitación',
			updateInvitationSuccess: 'Invitación actualizada correctamente',
			updatePartnerSuccess: 'Socio actualizado correctamente',
			acceptInvitation: 'Aceptar invitación',
			acceptInvitations: 'Aceptar invitaciones',
			refuseInvitation: 'Rechazar invitación',
			validEmail: 'Email valido email',
			invalidEmail: 'Email no valido',
			sendEmail: 'Correo enviado',
			activatedUser: 'Usuario activado',
			deactivatedUser: 'Usuario desactivado',
			statusInvitation: 'Estatus',
			manager: 'Gerente',
			teamName: 'Nombre del equipo',
			selectManager: 'Selecciona un gerente',
			addAnAgent: 'Agrega agentes',
			administrator: 'Administrador',
			superAdmin: 'Super Administrador',
			superAdmins: 'Super Administradores',
			periodTime: 'Selecciona periodo de planeación',
			messagMoreTasksSelected: 'Seleccionaste más de una tarea',

			messageYouCanOnlyDuplicateOwnTasks:'Solo pueden duplicarse tareas que hayan sido creadas en tu cuenta',
			messageDuplicateTasksStatusError: 'Sólo se pueden duplicar tareas terminadas y pausadas',
			messageDuplicateTasksBranchClientError: 'Sólo se pueden duplicar tareas de la misma sucursal o cliente',
			messagParentTasksError: 'La tarea seleccionada no tiene tarea padre',
			messagDuplicateTasksLimitError: 'Número máximo de tareas a duplicar es 50',
			messagNoGroup:
				'Esta tarea no se encuentra en un Grupo de Tareas',
			messageThisTaskIsNotInATaskGroup:"This task is not in a Task Group",
			noneTasks: 'Sin tareas',
			noTags: 'Sin tags',
			noneEmergencies: 'Sin emergencias',
			routeStatus: 'Estatus',
			totalShipment: 'Carga total',
			programDate: 'Fecha programada de la tarea',
			programHour: 'Hora programada de la tarea',
			startDateExcel: 'Fecha de inicio de la tarea',
			startHourExcel: 'Hora de inicio de la tarea',
			executionFormattedDate: 'Fecha de Ejecución',
			executionFormattedHour: 'Hora de Ejecución',
			arriveFormattedDate: 'Fecha de llegada',
			arriveFormattedHour: 'Hora de llegada',
			startedFormattedDate: 'Fecha de inicio',
			startedFormattedHour: 'Hora de inicio',
			finishedFormattedDate: 'Fecha de termino',
			originalDates: 'Período de ejecución original',
			emergencies: '# Emergencias',
			incidences: 'Incidencias',
			totalTasks: 'Total de Tareas',
			unlinkTaskQuestion: '¿Deseas desvincular las siguientes tareas de tu grupo de tareas?',
			errorTasksForRouteDate: 'Las tareas deben de tener el mismo dia de ejecución',
			errorTasksForRouteRoute:
				'Una o más tareas ya se encuentran en una ruta, por favor valida la información e intenta nuevamente',
			errorTasksForRouteGroup:
				'Una o más tareas pertenecen a un Grupo de Tareas, por favor valida la información e intenta nuevamente',
			errorCreateTask: 'Hubo un error en la creación de la tarea',
			errorDateAnalytics: 'La fecha de consulta inical debe ser menor a la última',
			errorTasksForRouteStatus: 'Las tareas deben estar en los estatus ',

			taskStatus_0: 'Gestionando',
			taskStatus_1: 'Cancelado',
			taskStatus_2: 'Terminado',
			taskStatus_3: 'Esperando Agente',
			taskStatus_4: 'En Proceso',
			taskStatus_5: 'Ejecutando',
			taskStatus_6: 'Agente en camino',
			taskStatus_Agent: 'Agente en ruta',
			taskStatus_7: 'Cancelado por Socio',
			taskStatus_8: 'Asignado a Socio',
			taskStatus_8_1: 'Asignado por Socio',
			taskStatus_9: 'Asignado a Equipo',
			taskStatus_10: 'Ha llegado',
			taskStatus_11: 'Eliminado',
			taskStatus_12: 'Auto asignando',
			taskStatus_13: 'Auto asignado',
			taskStatus_14: 'Auto asignación falló',
			taskStatus_15: 'Pausa',
			taskStatus_16: 'Revisión',
			taskStatus_17: 'Rechazada',

			taskGroup_0: 'Creado',
			taskGroup_1: 'Agente en camino',
			taskGroup_2: 'Ha llegado',
			taskGroup_3: 'Ejecutando',
			taskGroup_4: 'Pausa',
			taskGroup_5: 'Terminado',
			taskGroup_6: 'Cancelado',
			taskGroup_7: 'Deshabilitado',

			grandTotal: 'Gran total',

			linkFile: 'Adjuntar archivo',
			linkFiles: 'Adjuntar archivos',
			fileSizeExceeded:
				'Se superó el tamaño permitido de archivos por tarea, usa un link o adjunta archivos más pesados desde el detalle de tarea.',
			maxSizeFileTask: '(Tamaño total máximo 4MB)',

			review: 'Revisión',
			wrongImageSize: 'Tamaño de la imagen incorrecto',

			formatDay: 'días-hh:mm:ss',
			or: ' o ',
			and: ' y ',
			validateAgentsAndPriority:
				'Valida las prioridades y asegurate de agregar los agentes y que contegan un punto de partida',
			validateTaskTypes: 'No se puede generar Rutas para Tareas de tipo Remota',
			errorCreatedRoute:
				'No se pudieron crear las rutas, por favor valida la información de las tareas e intentalo más tarde',
			successCreatedRoutes: '{0} rutas se crearon con éxito ',
			successCreatedRoute: 'Una ruta se creó con éxito ',
			detailTeam: 'Detalle de equipo',
			editTeam: 'Editar equipo',
			countWaitingForAgent: 'Tareas esperando agente',
			anotherJob: 'Otro puesto',
			meetFielder: 'Conoce Fielder',
			shareText: 'Invita a otras empresas a colaborar en tu ecosistema utilizando Fielder',
			errorAcceptMyInvitation: 'No puedes aceptar tus propias invitaciones',
			addedCorrectly: '¡Agregado correctamente!',
			savedCorrectly: 'Cambios guardados correctamente',
			groupTasksAreBeingAssigned: 'Las tareas del grupo se están asignando',
			groupTasksAreBeingUnlinked:'La tarea fue desvinculada del Grupo',
			savedCorrectlyReschedule: 'Tareas reagendadas con éxito. Numero total:',
			accountUpdateSavedCorrectly:
				'Cambios guardados correctamente \n *Recuerda la actualización del logo se verá reflejada en breve, agradecemos su paciencia',
			emailAccountCreated: 'Cuenta de correo activada correctamente',
			emailAccountCreatedError: 'La cuenta de correo no pudo ser activada, intentelo mas tarde',
			cancelSuccessfully: 'Tarea cancelada correctamente',
			rateSuccessfully: 'Tarea calificada correctamente',
			cancelError:
				'No se puede cancelar la tarea, verifique la información de las tareas e intente nuevamente más tarde',
			rateError:
				'No se puede calificar la tarea, verifique la información de las tarea y vuelva a intentarlo más tarde',
			minimunCharacters: 'caracteres mínimos: {0}',

			administratorsRestoredCorrectly: '¡Administrador(es) restaurado(s)  correctamente!',
			administratorsDeletedCorrectly: '¡Administrador(es) eliminado(s) correctamente!',
			administratorsDisabledCorrectly: '¡Administrador(es) desactivado(s) correctamente!',

			clientRestoredCorrectly: '¡Cliente(s) restaurado(s)  correctamente!',
			clientDeletedCorrectly: '¡Cliente(s) eliminado(s) correctamente!',
			clientDisabledCorrectly: '¡Cliente(s) desactivado(s) correctamente!',

			branchDisabledCorrectly: '¡Sucursal(es) desactivada(s) correctamente!',
			branchRestoredCorrectly: '¡Sucursal(es) restaurada(s)  correctamente!',
			branchDeletedCorrectly: '¡Sucursal(es) eliminada(s) correctamente!',

			EXIST_ASSOCIATE_BY_TASK: 'El elemento no puedo ser eliminado, porque esta asociado a una tarea existente', // 301
			existAssociateDeleteClientsByTask: 'Cliente(s):  {0}  no eliminado(s) porque se encontraron tareas asociadas', //301
			existAssociateBySystemClient: 'Cliente(s):  {0}  no eliminado(s), porque existen varias dependecias de negocio', // 303
			unknownErrorDeleteClients: 'Cliente(s):  {0}  no eliminado(s) por un error no administrado', // 304
			existAssociateDeleteManagersByTask: 'Gerente(s):  {0}  no eliminado(s), porque se encontraron tareas asociadas', //301
			existAssociateDeleteManagersByTeam: 'Gerente(s):  {0}  no eliminado(s), porque se encontraron equipos asociados', // 302
			existAssociateBySystemManager: 'Gerente(s):  {0} no eliminado(s), porque existen varias dependecias de negocio', // 303
			unknownErrorDeleteManagers: 'Gerente(s):  {0}  no eliminado(s), por un error no administrado', // 304
			EXIST_ASSOCIATE_BY_TEAM: 'El elemento no puedo ser eliminado, porque esta asociado a un equipo existente', // 302
			EXIST_ASSOCIATE_BY_SYSTEM: 'El elemento no puedo ser eliminado, porque esta asociado a varias dependencias de negocio', // 303
			existAssociateBySystemAdmin: 'Administrador(es):  {0}  no eliminado(s), porque existen varias dependecias de negocio', // 303
			UNKNOWN_ERROR_DELETE_USER: 'Error desconocido al tratar de eliminar el usuario', // 304
			unknown_error_delete_admins: 'Administrador(es):  {0}  no eliminado(s), por un error no administrado', // 304
			unknown_error_delete_agents: 'Agente(s):  {0}  no eliminado(s) por un error no administrado', // 304
			CONFLICT_EMERGENCIES: 'La ruta tiene emergencias', // 334
			CONFLICT_ROUTE: 'La ruta esta finalizada o en ejecuciòn', // 335
			CONFLICT_SERVICE_ON_ROUTE: 'Algunas tareas de la ruta no pueden ser eliminadas', // 336
			EXIST_CLIENT_ASSOCIATE: 'El elemento no puedo ser eliminado, porque a que existe una asociacion con un cliente.', // 351
			EXIST_BRANCHES_BY_COMPANY:
				'El elemento no puedo ser eliminado, porque existen sucursales asociadas a la compañía', // 400
			EXIST_SUBCOMPANIES_BY_COMPANY: 'El elemento no puedo ser eliminado, porque existen subcompañías asociadas', // 401
			EXIST_ASSOCIATE_BY_TASK_AGENT: 'El elemento no puedo ser eliminado, porque existen tareas asociadas al Agente', // 450
			existAssociateByTaskAgent: 'Agente(s):  {0}  no eliminado(s), porque existen tareas asociadas',
			EXIST_ASSOCIATE_BY_SYSTEM_AGENT:
				'El elemento no puedo ser eliminado, porque existen varias dependecias de negocio', // 451
			existAssociateBySystemAgent: 'Agente(s):  {0}  no eliminado(s), porque existen varias dependecias de negocio',
			UNKNOWN_ERROR_DELETE_AGENT: 'Error desconocido al tratar de eliminar agente', // 452

			EXIST_ASSOCIATE_BY_AGENT: 'El elemento no puede ser eliminado, porque esta asociado a un agente', // 500;
			EXIST_ASSOCIATE_BY_MANAGER: 'El elemento no pude ser eliminado, porque esta asociado a uno o más gerentes', // 501;
			UNKNOWN_ERROR_DELETE_TEAM: 'Erroro desconocido al intentar borrar el equipo', // 502;
			unknownErrorDeleteTeams: 'Equipo(s):  {0}  no eliminado(s), por un error no administrado', // 502
			permissionMissignDeleteTeams: 'Equipo(s):  {0}  no eliminado(s), porque no tiene permisos para esta acción.', // 990

			FAIL_UPDATE_GROUP_TASKS:
				'Sucedio algo inesperado al intentar deshabilitar el grupo de tareas. Inténtelo de nuevo.',
			FAIL_DELETE_TASKS: 'El grupo de tareas no se puede deshabilitar porque está asociada con una tarea existente',
			WRONG_DELETE_STATUS:
				'El grupo de tareas no puede ser deshabilitado. Solo grupos con estatus creado y cancelado pueden ser deshabilitados',
			GROUP_NOT_FOUND: 'El grupo de tareas no se puede deshabilitar porque no se encontró.',

			failSendInvitation: 'Algo inesperado sucedio al intentar enviar la invitación, inténtalo de nuevo',
			idTeam: 'Id',
			idTeamHeader:'Equipo (Id Team)',
			companyRestoredCorrectly: '¡Compañía(s) restaurada(s)  correctamente!',
			companyDeletedCorrectly: '¡Compañía(s) eliminada(s) correctamente!',
			companyDisabledCorrectly: '¡Compañía(s) desactivada(s) correctamente!',

			selectGlobalAccountClient: 'Selecciona una cuenta para crear una tarea o un ticket:',
			teamRestoredCorrectly: '¡Equipo(s) restaurado(s)  correctamente!',
			teamDeletedCorrectly: '¡Equipo(s) eliminado(s) correctamente!',
			teamDisabledCorrectly: '¡Equipo(s) desactivado(s) correctamente!',
			cancelPartnershipSuccess: 'Asociación cancelada con éxito',
			acceptInvitationSuccess: 'Invitación aceptada con éxito',
			rejectedInvitationSuccess: 'Invitación rechazada con éxito',

			teamsAndAgents: 'Equipos y Agentes',

			// Analytics
			analyticsUpperCase: 'ANALÍTICOS',
			phoneNumberInput: 'Introduce el número telefónico',

			/// GLOBAL SEARCH ///
			teamsAssigned: 'Sin equipos asignados',
			clientAddress: 'Sin dirección',
			photoMessagePermission:
				'Recuerda que las fotos sin información fueron subidas de la galeria o el agente no ha aceptado los permisos para ser geolocalizado.',
			search: 'Busqueda',
			filterBy: 'Filtrar por',
			formFilter:'Filtro de formulario',
			advancedFilter: 'Filtro avanzado',
			filterByDate: 'Filtrar por fecha',
			all: 'Todos',

			goTeams: 'Ir a equipos',

			searchFormsByTasks: 'Búsqueda de formularios por tareas',
			emailTask: 'Tareas por correo',
			disabledTask: 'Tareas desactivadas',

			groups: 'Grupos de Tareas',
			groupUpperCase: 'GRUPOS',
			routes: 'Rutas',
			companies: 'Empresas',
			resultsFound: 'resultados encontrados para',
			results: 'Resultados',
			fullName: 'Nombre y Apellido',
			phoneNumber: 'Teléfono móvil',
			idManager: 'Id Gerente',
			groupName: 'Nombre del Grupo',

			teamManager: 'Gerente del Equipo',
			tasksNumber: 'Número de Tareas',
			contractNumber: 'Número de contrato',
			myPartner: 'Socio que integré',
			iPartner: 'Socio que me integró',

			// Routes
			incidenceCount: 'Incidencia #',
			incidenceAgent: 'Incidencias del agente ',
			// Import users
			importUsersSpecification:
				'* Verifica que los usuarios a importar no contenga comas (,).El nombre de usuario,correo,nombre, apellido, código de país y teléfono celular son obligatorios.',
			row: 'Fila',
			errorType: 'Tipo de error',
			countryCode: 'Código de país',
			importUsersSubject: 'Fielder Resultados de importación de usuarios CSV',
			importBranchSubject: 'Fielder Resultados de importación de sucursales CSV',
			importCompanySubject: 'Fielder Resultados de importación de compañías CSV',
			importAssetsSubject: 'Fielder Resultados de importación de activos CSV',
			importInventorySubject: 'Fielder Resultados de importación de inventarios CSV',
			importMessage: 'Tu proceso de importación ha finalizado, recibirás un correo con los resultados',
			updateMessage:'El proceso de actualización se está llevando acabo',
			importIsRequired: ' es requerido',
			if: ' si ',
			notExists: ' no existe ',

			oneCell: ' Una celda de ',

			// Error codes
			errorCode301: 'Campos requeridos incorrectos',
			errorCode407: 'El formato de atencion es incorrecto o el tiempo de inicio es mayor al tiempo final',
			errorCode300: 'Usuario duplicado',
			errorCode350: 'Sucursal ya existe',
			errorCode352: 'La compañía no existe o el id personalizado es incorrecto',
			errorCode353: 'Id de la Compañía duplicado',
			errorCode500: 'Información del usuario incorrecta',
			errorCode1001: 'Archivo .csv incorrecto, por favor descargar el templete correcto',
			errorCode520: 'Información del usuario incorrecta',
			errorCode710: 'Sucursal y Categoría son campos requeridos',
			errorCode711: 'Categoría campo requerido',
			errorCode712: 'Categoría no tiene Template definido',
			errorCode713: 'Categoría no tiene Template definido',
			errorCode714: 'Categoría no encontrada',
			errorCode715: 'Sucursal no encontrada',
			errorCode716: 'El archivo está vacío',
			errorCode960: 'Usuario duplicado',
			errorCode961: 'Error al crear usuario',


			errorCode310:'El nombre de usuario excede el limite de caracteres permitido (45 caracteres)',
			errorCode311:'El correo excede el limite de caracteres permitido (75 caracteres)',
			errorCode312:'El nombre excede el limite de caracteres permitido (150 caracteres)',
			errorCode313:'El apellido excede el limite de caracteres permitido (150 caracteres)',
			errorCode314:'El numero de telefono excede el limite de caracteres permitido (45 caracteres)',
			errorCode315:'El numero de telefono movil excede el limite de caracteres permitido (45 caracteres)',
			errorCode316:'El puesto de trabajo excede el limite de caracteres permitido (80 caracteres)',

			createUser: 'Crear usuario',
			createGlobalAccount: 'Crear cuenta global',
			errorFormatXslxHtml: 'Formato incorrecto del archivo. Por favor sube un archivo .xlsx o .html',

			timeOfResponse: 'TIEMPO DE RESPUESTA',
			timeOfSolution: 'TIEMPO DE SOLUCIÓN',
			solutionResponse: 'SOLUCIÓN / RESPUESTA',
			serviceStatus: 'ESTATUS DE TAREAS',
			serviceStatusLowerCase: 'Estatus de Tareas',
			executionTimeInitialDateUpperCase: 'TIEMPO DE EJECUCIÓN / FECHA DE INICIO',
			taskGroupStatus: 'ESTATUS GRUPO DE TAREAS',
			taskGroupStatusLowerCase: 'Status',
			timeOfResponseLowerCase: 'Tiempo de respuesta',
			timeOfSolutionLowerCase: 'Tiempo de solución',
			executionTime: 'Tiempo de ejecución',
			startDate: 'Fecha de Inicio',
			solutionResponseLowerCase: 'Solución / Respuesta',

			executionTimeInitialDate: 'Promedio entre Tiempo de ejecución / Tiempo de inicio agente',
			puntuality: 'Puntualidad',
			customTaskType: 'Tipo de tarea personalizada',
			customTaskTypeName: 'Tarea personalizada',
			serviceStatusTaskGroupLowerCase: 'Estatus grupo de tareas',
			dashboardAnalitycs: 'Dashboard Analíticos Generales',
			dashboardAnalitycsTask: 'Dashboard Tareas',
			dashboardAnalitycsGroup: 'Dashboard Grupo de Tareas',
			dashboardAnalitycsAgent: 'Dashboard Agentes',
			dashboardAnalitycsClient: 'Dashboard Clientes',
			dashboardAnalitycsBranch: 'Dashboard Sucursales',
			dashboardAnalitycsCompany: 'Dashboard Compañías',
			dashboardAnalitycsPartner: 'Dashboard Socios que integré',
			dashboardAnalitycsIPartner: 'Dashboard Socios que me integraron',

			executedServices: 'SERVICIOS EJECUTADOS',
			solutionExecutedServices: 'TIEMPO DE SOLUCIÓN / SERVICIOS EJECUTADOS',
			offLineTime: 'FUERA DE SERVICIO',
			offlineExecution:'Ejecución offline',

			offCause_1: 'Enfermedad',
			offCause_2: 'Vacaciones',
			offCause_3: 'Permiso',
			offCause_4: 'Incapacidad',
			offCause_5: 'Fin de Jornada',

			executedServicesLowerCase: 'Tareas terminadas',
			solutionExecutedServicesLowerCase: 'Tiempo de solución / Servicios ejecutados',
			offLineTimeLowerCase: 'Fuera de servicio',
			canceledServices: 'Servicios Cancelados',
			inProgressServices: 'Servicios en Progreso',
			executingServices: 'Servicios en Ejecución',

			branchesDeletedSuccessfullyText: 'Sucursales eliminadas correctamente',
			deletedSuccessfullyText: 'Eliminado correctamente',
			linkedTextBranch: 'No se pudieron eliminar por que estan vinculadas a una compañía, tarea o cliente.',
			linkedTextCompany: 'No se pudieron eliminar por que estan vinculadas a una sucursal',

			linkedTextClient: 'No se pudieron eliminar por que existen dependencias',
			linkedTextTeam: 'No se pudieron eliminar por que existen dependencias',
			linkedTextManager: 'No se pudieron eliminar por que existen dependencias',
			linkedTextAdministratador: 'No se pudieron eliminar por que existen dependencias',
			linkedTextAgent: 'No se pudieron eliminar por que existen dependencias',

			note: 'Nota: para más informacion ver el icono',
			questionWhatToSee: '¿Qué quieres mostrar?',
			addAction: 'Añadir acción',
			writeYourSMSEsText: 'Escribe tu sms en español aquí',
			writeYourSMSEnText: 'Escribe tu sms en inglés aquí',
			writeYourSMSPtText: 'Escribe tu sms en portugués aquí',
			ln_es_MX: 'Español',
			ln_en_US: 'Inglés',
			ln_pt_BR: 'Portugués',
			variables: 'Vars.',
			sms_first_part_text_0: 'Cuando el agente',
			sms_first_part_text_1: 'Cuando el agente',
			sms_first_part_text_2: 'Cuando el agente',
			sms_second_part_text_2: 'la tarea, enviar',
			sms_second_part_text_1: 'a la ubicación de la tarea, enviar',
			sms_second_part_text_0: 'a la tarea, enviar',
			sms_target: 'al cliente.',
			sms_action_type_2: 'inicie',
			sms_action_type_1: 'llegue',
			sms_action_type_0: 'vaya en camino',
			sms_vars_date: 'Fecha',

			instructions: 'Instrucciones',
			instructionsText:
				'Para poder crear un servicio por medio del correo es necesario respetar la siguiente nomenglatura:',
			folioInstructions: '*El campo de folio puede ser dejado vacio pero no omitido',
			forBranches: '-Para sucursales:',
			forClients: '-Para clientes',
			emailTextBranchPart1: '{{SucursalID}}6793',
			emailTextBranchPart2: '{{Descripción}}Tarea creada por email a una sucursal',
			emailTextClientPart1: '{{Folio}}Texto de Ejemplo',
			emailTextClientPart2: '{{ClienteID}}10447',
			emailTextClientPart3: '{{Descripción}}Tarea creada por email a un cliente',
			contactSupportTitle: 'CONTACTO SOPORTE TÉCNICO FIELDER: CONTACTO@APPFIELDER.COM',
			helpWhatIsFielder: '1. ¿Qué es fielder?',
			helpWhatIsFielderAnswer:
				'Fielder es una plataforma de gestión de tareas que permite gestionar las tareas asignadas a tu personal, monitoreando en tiempo real el estatus de las tareas y tu fuerza de trabajo en campo, brindando a tus clientes una experiencia diferente en la solicitud, entrega y calidad de tus servicios.',
			helpTypeOfUserQuestion: '2. ¿Qué tipo de usuarios existen en Fielder?',
			helpTypeOfUserAnswer:
				'Existen 5 tipos de usuarios: Superadministrador, Administrador, Agente, Gerente y Cliente. El superadministrador es el usuario que adquiere fielder, y tanto él como el usuario administrador tienen acceso a todas las funcionalidades. El usuario Agente es el personal que está en campo y tiene acceso a la APP móvil de agente. El usuario Gerente es el que administra los equipos y a los agentes que se les asigna al equipo. El usuario Cliente son los clientes que se dan de alta y pueden crear solicitudes de servicios.',
			helpCreateTaks: '3. ¿Cómo doy de alta una Tarea?',
			helpCreateTaksAnswer:
				'Podrás crear tareas dando click en el símbolo "+" que aparece en las diferentes pantallas de la plataforma.',
			helpAdress: '4. ¿Es importante la dirección al crear una tarea?',
			helpAdressAnswer: 'Sí, por lo que siempre que genéres una tarea, valida que el Pin de Fielder esté en el mapa.',
			helpStatusTask: '5. ¿En dónde veo el estatus de una tarea?',
			helpStatusTaskAnswer:
				'En la sección de Mi Operación visualizarás todas las tareas y podrás seleccionar de una en una para revisar el detalle.',
			helpReschedule: '6. ¿Puedo recalendarizar un servicio?',
			helpRescheduleAnswer:
				'Sí, cuaquier tarea puede ser recalendarizada. Selecciona la tarea y en el menú de acciones que se encuentran debajo del mapa selecciona la opción “Detalle de Tarea”',
			helpAgentClient: '7. ¿Qué pasa si lo mails no están llegando a mis agentes o a mis clientes?',
			helpAgentClientAnswer:
				'Pide a los usuarios que revisen su "SPAM", si definitivamente no recibieron los correos porfavor envía un correo a soporte@appfielder.com',
			helpExecuteApplication: '8. ¿Qué tareas puedo ejecutar en la aplicación?',
			helpExecuteApplicationAnswer:
				'Puedes ejecutar cualquier tarea que se ejecute en campo, entre las que destacan entregas (delivery), recolecciones (pick ups) o servicios en general (i.e. mantenimientos, instalaciones o cualquier actividad que se realice en sitio).',
			helpGeolocalization: '9. ¿Cómo geolocalizo a mis agentes?',
			helpGeolocalizationAnswer:
				'En el Dashboard y en el Mapa de Mi Operación podrás visualizar la geolocalización de tus agentes.',
			helpClientRate: '10. ¿Cómo me puede calificar la tarea mi cliente?',
			helpClienRateAnswer:
				'Desde la aplicación móvil, el cliente puede seleccionar el servicio al dar click en "calificar". Desde la vista web, tu cliente tendrá que entrar, ir al menú tareas y dar click en el botón de acciones seleccionando "calificar" agregando sus comentarios.',
			helpTaskDay: '11. ¿Cómo veo cuántas tareas tengo en el día?',
			helpTaskDayAnswer:
				'En la sección de Mi Operación podrás visualizar las tareas que te han asignado tus clientes en el día actual y las que tú has creado, mostrándote el estatus en tiempo real.',
			helpTechnicalRequirements: '12. ¿Qué necesito para utilizar la aplicación?',
			helpTechnicalRequirementsAnswer:
				'En nuestra página web podrás encontrar los querimientos técnicos mínimos para el mejor performance de la app.',

			taskDifference: 'Diferencia de tareas',
			lastMonthTask: 'Tareas del mes pasado',
			tasksOfTheMonth: 'Tareas del mes',

			// Billing
			billing: 'Facturación',
			billingUpperCase: 'FACTURACIÓN',
			billingSummary: 'RESUMEN DE FACTURACIÓN',
			billingDynamicAllocationTask: 'Tareas utilizadas en Asignación Dinámica',
			billingRouteOptimizationTask: 'Tareas en ruta',
			billingResurceAllocationTask: 'Tareas en Asignación  de recursos',
			billingFormsTask: 'Formularios en tareas',
			billingTasksInGroups: 'Tareas en Grupo',
			billingAssetsInTasks: 'Activos asignados a una tarea',
			billingInventoriesInTasks: 'Inventarios asignados a una tarea',
			billingInternalUpperCase: 'FACTURACION INTERNA',
			billingHistory: 'Historial',
			billingHistoryUpperCase: 'HISTORIAL DE FACTURACIONES PREVIAS',
			billlingView: 'VER FACTURACIÓN',
			billingTotal: 'TOTAL',
			billingConsultingDate: 'Fecha de Consulta',
			billingCutoffDate: 'Consumo estimado al día',
			billingMonthDate: 'Consumo al cierre',
			disableAccount: 'Deshabilitar Cuenta',
			enableAccount: 'Habilitar Cuenta',
			activeAccount: 'Cuenta activa',
			accountDeactivated: 'Cuenta desactivada',
			configAlertPaymentAccount: 'Configurar Alerta de Pago',
			descAlertPayment: 'Configurar  los días que restan para efectuar el pago',
			daysToPay: 'Días para pagar',
			hideAlertPaymentAccount: 'Quitar Alerta de Pago',
			billingShowAlertAccountSuccess: 'Se ha habilitado alerta de No Pago correctamente',
			billingHideAlertAccountSuccess: 'Se ha eliminado alerta de No Pago correctamente',
			day: 'día',

			billingAlertNotPaid1: 'Hubo un problema con tu pago, cuentas con ',
			billingAlertNotPaid2: ' para regularizarte. Evita la suspensión de tu cuenta.',
			disableAccountSuccess: 'Cuenta deshabilitada correctamente',
			enableAccountSuccess: 'Cuenta habilitada correctamente',
			taskPackage: 'Paquete de tareas',
			summary: 'Resumen',
			monthSummary: 'Resumen del mes',
			monthSummaryTitleUpperCase: 'RESUMEN TOTAL DE OPERACIONES',
			paymentType: 'Tipo de pago',
			billingPrepaid: 'Pago anticipado',
			billingOnDemand: 'Bajo demanda',

			agentLocalization: 'Localización de agente',
			gelocalizationLevel0: 'Sin Geolocalización',
			gelocalizationLevel1: 'Con Geolocalización Moderada',
			gelocalizationLevel2: 'Con Geolocalización Alta',

			period: 'Periodo',
			exchangeType: 'Tipo de cambio',
			billingMonthly: 'Mensual',
			billingBimonthly: 'Bimestral',
			billingQuarterly: 'Trimestral',
			billingBiannual: 'Semestral',
			billingAnnual: 'Anual',
			billingBasicPlan: 'Fielder Básico',
			billingOptimizedPlan: 'Fielder Optimizado',
			billingTaskPrice: 'Precio por tarea',
			billingTaskAdditionalPrice: 'Precio por tarea adicional',
			billingRequestPrice: 'Precio por petición',
			billingRequestAdditionalPrice: 'Precio por petición adicional',
			billingPricePerReorganization: 'Precio por reorganización de ruta',
			billingPricePerAdditionalReorganization: 'Precio adicional por reorganización de ruta',
			billingPricePerIsochronous: 'Precio por isócrona',
			billingPricePerForm: 'Precio por template de formulario',
			billingPricePerGroup: 'Precio por Grupo de Tareas',
			numberOfTasks: 'Número de tareas',
			numberOfSkills: 'Número de habilidades',
			billingPricePerSkill: 'Precio por habilidad',
			billingSkillAdditionalPrice: 'Precio adicional por habilidad',
			pricePerPackage: 'Precio por paquete',
			concept: 'Concepto',
			quantity: 'Cantidad',
			unitPrice: 'Precio unitario',
			subtotal: 'Subtotal',
			additionalTaskCreated: 'Tareas adicionales creadas',
			executions: 'Ejecuciones',
			additionalExecutions: 'Ejecuciones adicionales',
			reorganizeRoutes: 'Reorganización de rutas',
			additionalReorganizeRoutes: 'Reorganización de rutas adicionales',
			routeTasks: 'Tareas en ruta',
			additionalRouteTasks: 'Tareas adicionales en ruta',
			totalBalanceToPaid: 'Saldo total a pagar',
			billingTermsAndConditions1:
				'Los Términos y Condiciones aplicables son los contenidos en el contrato vigente firmado entre las “partes”.',
			billingTermsAndConditions2: 'Cualquier duda o aclaración por favor enviar a correo management@smartstc.com ',
			actions: 'Acciones',
			consulting: 'Consultoría',
			storage: 'Almacenamiento',
			billingManagementControl: 'Gestión y Control',
			billingPlanningOptimization: 'Planeación y Optimización',
			optimizationSideBar: 'Optimización',
			plannngSideBar: 'Planeación',
			billingAutomation: 'Automatización',
			billingComplement: 'Servicios adicionales',
			of: ' de ',

			// InternalAdmin
			accounts: 'Cuentas',
			noRecordsLoginText: 'No hay registros de inicio en la cuenta seleccionada',
			noRecordsAgentText: 'No hay registros de agentes asignados en la cuenta seleccionada',
			noRecordsRouteText: 'No hay registros de ruta en la cuenta seleccionada',
			totalNumberOfAdministrators: 'Número total de administradores',
			totalNumberOfAdministratorsViewer: 'Número total de visualizadores de administradores',
			totalNumberOfManagers: 'Número total de gerentes',
			totalNumberOfClients: 'Número total de clientes',
			totalNumberOfCompanies: 'Número total de compañías',
			totalNumberOfBranches: 'Número total de sucursales',
			totalNumberOfTeams: 'Número total de equipos',
			totalNumberOfAccountPartner: 'Número total de socios',
			totalNumberOfAgents: 'Número total de agentes',
			totalUsersInFielder: 'Usuarios totales en fielder',
			totalUsers: 'Usuarios totales',
			noUsers: 'Sin usuarios',
			taskComparison: 'Comparación de tareas',

			lastLogin: 'Último acceso',
			numberOfRoutes: 'Número de rutas',
			numberOfAssignedAgents: 'Número de agentes asignados',
			noLastLogin: 'Sin registros de último inicio de sesión',
			records: 'Registros',
			addGlobalUser: 'Agregar usuaria global',
			globalUser: 'Usuario global',
			loginsRecords: 'Registros de inicios de sesión',
			routesRecords: 'Registros de ruta',
			agentsRecords: 'Registros de agentes',
			accountsUpperCase: 'CUENTAS',
			numberOfRequest: 'Número de Peticiones',
			numberOfReorganization: 'Número de reorganizaciones de ruta',
			numberOfForms: 'Número de formulários',
			taskWithForms: 'Tareas con formularios',
			additionalTaskWithForms: 'Tareas con formularios adicionales',
			numberOfGroups: 'Número de grupos de tareas',
			taskInGroups: 'Tareas en Grupo',
			additionalTaskInGroups: 'Tareas adicionales en Grupo',
			taskInDynamicAllocation: 'Tareas en asignación dinámica',
			additionalTaskInDynamicAllocation: 'Tareas adicionales en asignación dinámica',
			taskWithAssets: 'Tareas con activos',
			additionalTaskWithAssets: 'Tareas adicionales con activos',
			taskWithInventories: 'Tareas con inventarios',
			additionalTaskWithInventories: 'Tareas adicionales con inventarios',
			welcome: 'Bienvenido ',


			//Api User
			loginApi: 'Login',
			createCompanyApi: 'Create Company',
			updateCompanyApi: 'Update Company',
			getTotalCompaniesApi: 'Get total companies',
			getCompaniesApi:'Get Companies',
			createBranchApi: 'Create Branch',
			updateBranchApi: 'Update Branch',
			getTotalBranchesApi: 'Get Total Branches',
			getBranchesApi: 'Get Branches',
			createAgentApi: 'Create Agent',
			updateAgentApi: 'Update Agent',
			getTotalAgentsApi: 'Get total Agents',
			getAgentsApi: 'Get Agents',
			createTaskApi: 'Create Task',
			getTaskApi: 'Get Task',
			getTasksByDateApi: 'Get Tasks Ids By Date',
			getTaskActivityApi: 'Get Task Activity',
			getRescheduleTaskApi: 'Reschedule Task',
			getCancelTaskApi: 'Cancel Task',
			getPauseTaskApi: 'Pause Task',
			getReasingPartnerApi: 'Reasing Partner',
			getCancelTaskPartnerApi: 'Cancel Task Partner',
			getAssignTaskApi: 'Assign Task',
			getAssignInventoryAssetToTaskApi: 'Assign Inventory/Asset To Task',
			getApproveTaskFormsApi: 'Approve Task Forms',
			getUpdateTaskApi: 'Update Task',
			getDuplicateTaskApi: 'Duplicate Task',
			getCreateTaskGroupApi: 'Create Task Group',
			getUpdateTaskGroupApi: 'Update Task Group',
			getAssignTaskGroupApi: 'Assign Task Group',
			getDetailTaskGroupApi: 'Detail Task Group',
			getTaskGroupNameAndIdCustomApi: 'Get Task Group Name and Id Custom',
			getUnlinkTaskFromTaskGroupApi: 'Unlink Task From Task Group',
			getClientsApi: 'Get Clients',
			getTotalClientsApi: 'Get Total Clients',
			getCreateClientApi: 'Create Client',
			getUpdateClientApi: 'Update Client',
			getTeamAgentsApi: 'Get Team Agents',
			getTaskTypesApi: 'Get Task Types',
			getCreateTaskTypeApi: 'Create Task Type',
			getUpdateTaskTypeApi: 'Update Task Type',
			getTaskPrioritiesApi: 'Get Task Priorities',
			getCustomStatusAssetsApi: 'Get Custom Status (Assets)',
			getCategoriesAssetsApi: 'Get Categories',
			getCategoryByIdAssetsApi: 'Get Category by Id',
			getTotalAssetsByCategoryApi: 'Get total Assets by Category',
			getAssetsApi: 'Get Assets',
			getAssetByIdApi: 'Get Asset by Id',
			getCreateAssetApi: 'Create Asset',
			getUpdateAssetApi: 'Update Asset',
			getDisableAssetApi: 'Disable Asset',
			getDeleteAssetApi: 'Delete Asset',
			getEnableAssetApi: 'Enable Asset',
			getCustomStatusInventoryApi: 'Get Custom Status (Inventory)',
			getCategoriesInventoryApi: 'Get Categories',
			getCategoryByIdInventoryApi: 'Get Category by Id',
			getTotalInventoryByCategoryApi: 'Get total Inventory by Category',
			getInventoriesApi: 'Get Inventories',
			getInventoryByIdApi: 'Get Inventory by Id',
			getCreateInventoryApi: 'Create Inventory',
			getUpdateInventoryApi: 'Update Inventory',
			getDeleteInventoryApi: 'Delete Inventory',
			getDisableInventoryApi: 'Disable Inventory',
			getEnableInventoryApi: 'Enable Inventory',
			getFormsFromTaskApi: 'Get Forms From Task',
			getUpdateFormTaskApi: 'Update Form Task',

			apiUserActivity_0:'Usuario API creado',
			apiUserActivity_1:'Usuario API editado',
			apiUserActivity_2:'API de usuario activada',
			apiUserActivity_3:'API de usuario deshabilitada',

			//Taks Files Massive Download
			massiveDownload: 'Descargas Masivas',
			massiveDownloadComponentTitle: 'Descarga masiva de Formularios',
			massiveDownloadDescription: 'Descarga de forma masiva los formularios contenidos en una Compañía',
			massiveDownloadCompanyId: 'ID de Compañía:',
			massiveDownloadBranchId: 'ID de Sucursal:',
			massiveDownloadZipButton: 'Enviar información',
			massiveDownloadCompanyPlaceholder: 'Ingrese el ID de la companía',
			massiveDownloadBranchPlaceholder: 'Ingrese el ID de la sucursal',
			requiredIdAccount: 'El campo ID de Compañía es requerido',
			massiveDownloadSuccess: 'Enviaremos a tu correo electrónico un link de descarga',
			massiveDownloadError: 'Ocurrió un error al intentar generar el archivo.',
			massiveDownloadIdMustBeNumeric: 'El valor de ID ingresado debe ser un número',
			massiveDownloadMandatoryDates: 'Se debe seleccionar un rango de fechas',
			massiveDownloadStatusCheckError: 'No fue posible verificar si ya hay una descarga en progreso',
			massiveDownloadStatusAlreadyInProgress: 'Ya hay una descarga en progreso',

			//Maya Marketplace
			mayaManagerMarketplace: 'Maya Asistente Virtual para tu operación',
			mayaTechnicianMarketplace: 'Maya Asistente Virtual para técnicos',
			mayaClientMarketplace: 'Maya Asistente Virtual para clientes finales',
		},
		pt_BR: {
			location: 'Localização',
			selectView: 'Selecione a vista',
			filter: 'Filtro',
			filterName: 'Nome do filtro',
			viewMoreFilters: 'Ver mais filtros',
			noTasks: 'Sem Tarefas',
			away: 'Ausente',
			agentsInfo: 'Informação dos agentes',
			noTeams: 'Sem Equipes',
			noTeam: 'Sem Equipe',
			noCompany: 'Sem Empresa',
			filterAgents: 'Filtrar Agentes',
			agentTaskNumberFilter: 'Número da tarefa do agente',
			agentStatusFilter: 'Status do agente',
			agentsStatus: 'Status dos Agentes',
			agentTeams: 'Equipes do Agente',
			agentDetails: 'Detalhe do agente',
			allStatus: 'Todos os Status',
			task: 'Tarefa',
			tasks: 'Tarefas',
			couldNotFilterAgentsCommandCenter: 'Não foi possível filtrar agentes, tente novamente mais tarde',
			couldNotFilterTasksCommandCenter: 'Não foi possível filtrar tarefas, tente novamente mais tarde',
			noAgentsFound: 'Nenhum agente encontrado',
			noTasksFound: 'Nenhuma tarefa encontrada',
			tryWithOtherFilter: 'Por favor, tente novamente com outro filtro',
			tasksInfo: 'Informações sobre tarefas',
			viewAgent: 'Ver Agente',
			today: 'Hoje',
			notShowingDisabledAgents: 'Não mostrando agentes desativados',
			lastLocationUpdate: 'Última Atualização de Localização',
			lastIdsCustomUsed:'Últimos IDs personalizados usados',
			workload: 'Carga de trabalho',
			taskInProgress: 'Tarefa em progresso',
			viewAll: 'Ver tudo',
			showAgentTasks: 'Mostrar Tarefas do Agente',
			clickRecenterMap: 'Haga clic para volver a centrar el mapa',
			assignTasksWithinTimeFrame: 'Atribuir tarefas ao agente dentro do prazo',
			viewAgentsByStatus: 'Ver agentes por status',
			viewAgentsByTaskNumber: 'Ver agentes por contagem de tarefas',
			taskFilterSearchLabel: 'Pesquisar por Id de tarefa ou fólio',
			totalMissingTasks: 'Tarefas Restantes',
			noLocation: 'Sem localização',
			noTaskInProgress: 'Nenhuma tarefa em progresso',
			assignTasks: 'Atribuir tarefas',
			addTasks: 'Adicionar Tarefas',
			tasksToAssignToAgent: 'Tarefas para atribuir ao agente',
			noClient: 'No Cliente',
			moving: 'Movendo',
			showUnattendedTasks: 'Mostrar animação para tarefas com vencimento de SLA próximo',
			showUpdatesInRealTime: 'Mostrar atualizações em tempo real',
			loaded: 'Carregados',
			newUpdates: 'Novas atualizações',
			noOptions: 'Sem opções',
			agentsSelectedHaveNoLocation: "Os agentes selecionados não possuem localização registrada",
			by: 'por',
			ids:'Ids',

			hello: "Olá",
			moreInformation:'Mais informações',
			enterCaseNumber: 'Insira o número do caso',
			howCanIHelpYou: "Olá! Sou a Maya, sua assistente de I.A. generativa da Fielder",
			writeYourMessageHere: "Pergunte-me sobre Fielder",
			sorryIcantHelp:"Desculpe, não posso responder a essa pergunta. Você pode entrar em contato com nossa equipe de suporte por e-mail para obter mais assistência.",
			forMoreInformation:"Para mais informações, recomendo ler a documentação",
			documentationLink: "link da documentação:",
			createTaskDocumentation:"Criar Tarefa",
			taskCreationDetailDocumantation:"Detalle de creación tarea",
			// global account
			usersIds: 'Ids de usuários',
			example: 'Exemplo',
			send: 'Enviar',
			topic: 'Tema',
			mailIntegrationNoActivated: 'Correio não ativado',
			mailIntegrationConfig: 'O domínio está sendo configurado',
			relations: 'Relações',
			clientGlobal: 'Fielder Client Global Account',
			linkedAccount: 'Conta ligada',
			noLinkedAccount: 'Sem contas vinculadas',
			linkedUsers: 'Usuários vinculados',
			noLinkedUsers: 'Sem usuários vinculados',
			globalAccountInfo: 'Informação da conta',
			globalUserInfo: 'Informação do usuário',
			adminGlobal: 'Fielder Root Global Account',
			addUserToGlobalAccount: 'Adicionar usuário à conta global',
			editGlobalAccount: 'Mostrar conta global',
			idGlobalAccount: 'Id Conta Global',
			linkGlobalAccount: 'Vincular conta global a conta',
			domain: 'Domínio',
			assignDomain: 'Atribuir domínio',
			assingAccount: 'Atribuir conta',
			globalUserNormalName: 'Nome',
			globalUseridUser: 'ID de usuário',
			globalAccount: 'Conta global',
			domainCreatedSuccessfully: 'Domínio vinculado com sucesso',
			accountCreatedSuccessfully: 'Conta criada com sucesso',
			accountLinkedSuccessfully: 'Conta ligada com sucesso',
			userAddedSuccessfully: 'Usuário vinculado com sucesso',
			globalDashboardError: 'Algo inesperado aconteceu, tente novamente',
			domainCreatedError: 'Ocorreu um problema ao criar o domínio, tente novamente mais tarde.',
			accountErrorReltation: 'A conta já tem esse relacionamento',
			accountCreatedError: 'Ocorreu um problema ao criar a conta, tente novamente mais tarde.',
			assingAccountCreatedError: 'Ocorreu um problema ao atribuir a conta à conta global. Por favor, tente novamente.',
			addUsertoGlobalAccountCreatedError:
				'Ocorreu um problema ao atribuir o usuário à conta global. Por favor, tente novamente.',
			accountName: 'Nome da conta',
			contactName: 'Nome de contato',
			contactEmail: 'Email de contato',
			ERROR_404: 'ERROR 404',
			pageNoAvailable: 'Página não acessível',
			textNotAvailable:
				'Esta página não está disponível para seu tipo de usuário ou não está visível para sua função. Entre em contato com seu administrador para obter mais informações.',
			textNotFound404: 'Nenhuma página encontrada semelhante ao que você está procurando.',
			viewOperation: 'Ver Operação',
			invalidCredentialsForViewOperation:
				'Desculpe, você não está habilitado para ver a operação desta conta, por favor entre em contato com a equipe Fielder para ativar a visualização da conta.',
			pending: 'Pendente',
			formFilterInfo: 'Pesquise pelo nome do formulário ou qualquer outro texto',
			userFilterTo: 'Use o filtro avançado para fazer pesquisas mais específicas para uma ou mais tarefas.',
			noResultsFound: 'Nenhum resultado encontrado',

			dynamicAllocation: 'Atribuição Dinâmica',
			dynamicAllocationConfirmData: 'Deseja executar a alocação dinâmica?',
			dynamicAllocationSuccessMessage:
				'Seu processo de alocação dinâmica está sendo executado, você receberá um email com os resultados',
			dynamicAllocationErrorMessage: 'Seu processo de alocação dinâmica não foi executado, tente novamente.',
			dynamicAllocationDescription:
				'Crie rotas e edite a ordem, o agente atribuído e a programação dinamicamente. Essa funcionalidade é excelente se sua operação é flexível e muda rapidamente o tempo todo.',
			showWarninigs: 'Ver conflito',
			warningscomment:
				'O cronograma estabelecido para executar a Atribuição Dinâmica não abrange alguns períodos de execução (agendamento) de algumas tarefas que você deseja atribuir, Deseja gerar a alocação dinâmica? Ou você quer ver as tarefas em conflito?',
			warningAddress: 'A alteração do endereço gerará uma solicitação para atualizar a localização da Filial/Cliente',
			warningTaskType: 'Alguns tipos de tarefas têm restrições com algumas empresas e / ou filiais',
			generateRoutesByRange: 'Gerar rotas apenas entre as datas dos intervalos selecionados?',
			generateRoutesWithTheFarthest: 'Gere rotas com a tarefa mais distante',
			generateRoutesWithTheFarthestInfo: 'As tarefas devem ter a mesma prioridade e ter o mesmo período de datas',
			generateRoutesWithTheFarthestROInfo: 'A rota só terá a distância como uma variável',
			generateRouteWithLastLocation: 'Pegue a última localização para a próxima rota',
			generateRouteWithLastLocationInfo:
				'Ao gerar mais de uma rota com o mesmo Agente, o ponto de partida será a localização do a última tarefa',
			enableExpensesRoutes: 'Ativar despesas?',
			generate: 'Executar',
			noDate: 'Sem data',
			noData: 'Sem informação',
			analitycsMessageDatePart1: 'Você não possui informações suficientes entre ',
			analitycsMessageDatePart2: ', Por favor, tente novamente',
			changeParameterAnalitycs: 'Precisa alterar os parâmetros para gerar o gráfico',
			noImages: 'Não há imagens nesta tarefa',
			noServiceFile: 'Tarefa sem arquivos',
			loading: 'Carregando...',
			noDataTeamActive: 'Você não tem equipes ativadas nesta seção. Crie uma nova equipe.',
			noDataTeamDisabled: 'Você não tem equipe desativada nesta seção.',
			noDataUserActive: 'Você não tem usuários ativados nesta seção. Crie um novo usuário.',
			noMyPartners: 'Você ainda não tem parceiros de negócios, adicione e amplie sua rede de negócios com o fielder.',
			noIamNotPartner: 'Você ainda não foi integrado por um parceiro',
			noInvitations: 'Você não tem convites, adiciona parceiros e amplia sua rede de negócios com o fielder',

			noDataUserDisable: 'Você não possui usuários desativados nesta seção.',
			noDataCompanyActive: 'Você não possui empresas ativadas nesta seção. Crie uma nova empresa.',
			noDataCompanyDisable: 'Você não possui empresas desativadas nesta seção.',

			noDataBranchActive: 'Você não tem filiais ativadas nesta seção. Crie uma nova filial.',
			noDataBranchToUpdate: 'Não há ramificação para atualizar',
			noDataBranchDisable: 'Você não possui filiais desativadas nesta seção.',
			viewRoutes: 'Ver Rotas',
			noDataFilter: 'Não há dados que atendam a esse critério de pesquisa.',
			appearance: 'Aparência: ',
			considerDateRange: 'Considere o período',
			iconography: 'Iconografia',
			withoutIdCustomGroup: 'Sem identificação personalizada',
			numberOfTask: 'Número da tarefa',
			numberOfAgents: 'Número de agentes',
			editPhoto: 'Editar foto',
			edit: 'Editar',
			show: 'Mostrar',
			hide: 'Ocultar',
			more: 'mais',
			showMap: 'Mostrar mapa',
			showMore: 'Mostre mais',
			showTable: 'Mostrar tabela',
			menu: 'Menu Principal',

			element: 'Elemento',
			language: 'Idioma',
			portugues: 'Português',
			english: 'Inglês',
			spanish: 'Espanhol',
			login: 'Conecte-se',
			user: 'Usuário',
			tokenName: "Nome do Token",
			tokenTip:"Dica: escolha um nome descritivo para que você saiba que o Token criará solicitações à API Fielder para sua integração com outro sistema.",
			roleType: "Tipo de função",
			apiAdministrator: "Administrador API",
			apiManager: "Gerente API",
			activityLog: "Registro de atividades",

			pass: 'Senha',
			forgotPass: 'Esqueci minha senha',
			login_button: 'Conecte-se',
			sendEmailAction: 'Enviar correio',
			sendEmailError: 'Correio inválido',
			profile: 'Perfil',
			selectAll: 'Selecionar tudo',
			showDetail: 'Ver detalhe',
			uncheckEverything: 'Desmarcar todos',
			newLocationAvailable: 'Nova localização disponível',
			newLocation: 'Nova localização',
			outOfBranchOrClientSchedule: 'Agendamento fora da filial ou do cliente',
			missingFormsToSign:"Faltam formulários para assinar",
			advancedAnalytics: 'Analytics Avançados',
			mailIntegration: 'Integração de Correio',
			advancedAnalyticsDescription:
				'Acesse Gráficos e Analytics avançados sobre sua operação relacionada a KPIs de Clientes, Filiais, Tarefas, Agentes e Parceiros de Negócios.',
			mailIntegrationDescription: 'Criar tarefas através do correio fornecido pela plataforma',

			// Role Management
			administrator: 'Administrador',
			superAdmin: 'Super Administrador',
			superAdmins: 'Super Administradores',
			roleManagementDescription: 'Aplicativo para gerar permissões e funções focadas em suas contas.',
			roleManagement: 'Administração de Funções',
			permissions: 'Permissões',
			roles: 'Papéis/Funções',
			role: ' Papel/Função',
			default: 'Padrão',
			custom: 'Personalizado',
			specific: 'Específico',
			webAndManagement: 'Web e Management',
			noPermissionsFound: 'Nenhuma permissão encontrada',
			noRolesFound: 'Nenhum papel foi encontrado',
			noNotificationsFound: 'Nenhuma notificação encontrada',
			newPermission: 'Nova permissão',
			newRole: 'Novo papel',
			newNotification: 'Nova notificação',
			editRole: 'Editar Papel/Função',
			editPermission: 'Editar permissão',
			editNotification: 'Editar notificação',
			addPermissions: 'Adicionar Permissões',
			addNotifications: 'Adicionar Notificações',
			deleteConfirmationNotification: 'Tem certeza de que deseja excluir a(s) notificação(ões)?',
			errorTryingToDeleteNotifications: 'Erro ao tentar excluir uma notificação',
			someNotificationHasRole: 'Pelo menos uma notificação selecionada está presente em uma ou mais funções',
			addRole: 'Adicionar Papel/Função',
			permissionsAdded: 'Permissões Adicionadas',
			notificationsAdded: 'Notificações Adicionadas',
			roleSearch: 'Procurar...',
			permissionActive: 'Ativo',
			basic: 'Básico',
			roleModules: 'Módulos',
			general: 'Geral',
			deselect: 'Desmarcar',
			accountConfiguration: 'Configuração da conta',
			roleDashboard: 'Dashboard',
			viewDeactivates: 'Visualizar desativado',
			deactivate: 'Desativar',
			activate: 'Ativar',
			roleImport: 'Importar',
			roleExport: 'Exportar',
			deletePartnership: 'Excluir parceria',
			roleAdd: 'Adicionar',
			assignTeam: 'Atribuição de equipes',
			assignPartner: 'Atribuição de parceiros',
			assignAsset: 'Atribuição de ativos',
			assignInventory: 'Atribuição de inventários',
			assignForm: 'Atribuição de formulários',
			restartRoutes: 'Reiniciar rotas',
			rescheduleRoutes: 'Reagendar rotas',
			addTaskByFolio: 'Adicionar tarefa por fólio',
			deleteTaskFromRoute: 'Excluir tarefa da rota',
			exportTasks: 'Exportação de tarefas',
			generateAssignments: 'Gerar atribuições',
			generateOptimizations: 'Gerar otimizações',
      editAutoAssignTemplate: 'Editar Modelo Auto-Atribuição',
			viewTemplates: 'Visualizar modelos',
			createTemplates: 'Criar modelos',
			editTemplates: 'Editar modelos',
			deleteTemplates: 'Excluir modelos',
			autoAssignTemplateDeleted: 'Este modelo de auto-atribuição foi excluído',
			autoAssignTemplateIsInactive: 'O modelo de auto-atribuição está inativo',
      msgAutoAssignNotAllowHasGroupRoute: 'Tarefa(s) com grupo(s) ou rota(s) não podem ser Auto-Atribuição',
      msgAutoAssignNotAllowTaskStatus: 'A(s) tarefa(s) no status atual não pode(m) ser Auto-Atribuição',
      msgAutoAssignNotAllowClientUsers: 'Os clientes não estão autorizados a realizar a Auto-Atribuição',
      msgAutoAssignNotAllowedPermissions: 'Você não tem as permissões necessárias para realizar a Auto-Atribuição',
      msgAutoAssignInitiateProcess: 'Processo de Auto-Atribuição iniciado...',
      msgNoTasksWereAutoAssigned: 'As tarefas não puderam ser atribuídas automaticamente. Verifique o status das tarefas antes da Auto-Atribuição',
			web: 'Web',
			pushNotification: 'Push Notification',
			startPath: 'Começar caminho',
			toArrive: 'Chegar',
			toStart: 'Começar',
			toPause: 'Pausa',
			toFinish: 'Terminar',
			toReject: 'Rejeitar',
			createComment: 'Criar comentário',
			reasonForChange: 'Razão para mudança',
			selectAStatus: 'Selecione um estado',
			createEmergencies: 'Criar emergências',
			iAmHere: 'Estou aqui',
			messageMultiSelect: 'Para selecionar uma subtarefa, cancele sua tarefa pai',
			messageSelectParner: 'Selecione um parceiro',
			messageSelectCompany: 'Por favor selecione uma empresa',
			messageSelectTypeTask: 'Selecione um tipo de tarefa',
			startTask: 'Iniciar tarefa',
			restartTasks: 'Reiniciar tarefas',
			addTasksToGroup: 'Adicionar tarefas ao grupo',
			enableNotifications: 'Ativar notificações em',
			sendNotifications: 'Envie notificações para o usuário quando',
			selectMoreOptions: 'Você pode selecionar uma ou mais opções',
			permissionCreatedSuccessfully: 'Permissão criada com sucesso',
			permissionUpdatedSuccessfully: 'Permissão modificada com sucesso',
			notificationCreatedSuccessfully: 'Notificação criada com sucesso',
			notificationUpdatedSuccessfully: 'Notificação modificada com sucesso',
			roleCreatedSuccessfully: 'Papel/Função criada com sucesso',
			roleUpdatedSuccessfully: 'Papel/Função modificada com sucesso',
			roleSearchBy: 'Pesquisa por empresa, cliente e filial',
			rolePartner: 'Empresas parceiras, filiais e clientes',
			selectedEntities: 'Empresas, filiais e clientes selecionados',
			selectedPermissions: 'Permissões selecionadas',
			selectedNotifications: 'Notificações selecionadas',
			dataSheet: 'Ficha Técnica',
			userList: 'Lista de usuários',
			userDescription: 'Selecione qualquer usuário para ver sua ficha técnica',
			usersWithoutRole: 'Usuários sem papel/função',
			usersWithRole: 'Usuários que têm um papel/função',
			assignRole: 'Atribuir Função',
			userCreatedSuccessfully: 'As funções selecionadas foram atribuídas ao usuário com sucesso',
			userUpdatedSuccessfully: 'As funções de usuário selecionadas foram atualizadas com sucesso',
			editUser: 'Editar usuário',
			congratAgent: 'Parabenizar agente',
			defaultDuplicationNameLabel: 'Deixe em branco para o nome padrão: ',
			copy: 'cópia',
			deleteRoleLabel: 'Tem certeza de que deseja excluir a função?',
			deleteRoleWarning: 'Uma vez excluído, ele não pode ser recuperado',
			deletePermissionLabel: 'Tem certeza de que deseja excluir a permissão?',
			deletePermissionWarning: 'Uma vez excluído, ele não pode ser recuperado',
			error: 'Error',
			roleDeletionErrorUserAssigned:
				'Não foi possível remover a função, verifique se a função não está atribuída a nenhum usuário',
			permissionDeletionErrorRoleAssigned:
				'Não foi possível remover a permissão, verifique se a permissão está atribuída a nenhum função',

			permission_USERS_tasks_0: 'Visualizar',
			permission_USERS_tasks_1: 'Editar',
			permission_USERS_tasks_2: 'Padronizar novamente',
			permission_USERS_tasks_3: 'Cancelar',
			permission_USERS_tasks_4: 'Atribuição de equipes',
			permission_USERS_tasks_5: 'Asignación de agentes',
			permission_USERS_tasks_6: 'Atribuição de parceiros',
			permission_USERS_tasks_7: 'Atribuição de ativos',
			permission_USERS_tasks_8: 'Atribuição de inventários',
			permission_USERS_tasks_9: 'Atribuição de formulários',
			permission_USERS_tasks_10: 'Importar',
			permission_USERS_tasks_11: 'Exportar',
			permission_USERS_tasks_12: 'Criar',

			permission_CLIENT_USERS_tasks_0: 'Visualizar',
			permission_CLIENT_USERS_tasks_1: 'Editar',
			permission_CLIENT_USERS_tasks_2: 'Padronizar novamente',
			permission_CLIENT_USERS_tasks_3: 'Cancelar',
			permission_CLIENT_USERS_tasks_12: 'Criar',

			doNotApply: 'Não se aplica',
			formTaskStatusPermission: 'A partir de que momento o agente deve preencher o formulário',

			formTaskStatusPermission0: 'Em qualquer momento',
			formTaskStatusPermission1: 'Quando a tarefa é aceita',
			formTaskStatusPermission2: 'Quando está a caminho',
			formTaskStatusPermission3: 'Quando o agente chegou',
			formTaskStatusPermission4: 'Quando a tarefa começa',

			permission_AGENT_tasks_0: 'Rejeitar',
			permission_AGENT_tasks_1: 'Cancelar',
			permission_AGENT_tasks_2: 'Upload fotos',
			permission_AGENT_tasks_3: 'Pausa',
			permission_AGENT_tasks_4: 'Criar comentários',
			permission_AGENT_tasks_5: 'Criar tarefa',
			permission_AGENT_tasks_6: 'Alterar tipo de tarefa',
			permission_AGENT_tasks_7: 'Criar subtarefa',

			permission_USERS_accountconfiguration_0: 'Visualizar',
			permission_USERS_accountconfiguration_1: 'Editar',

			permission_USERS_administrators_0: 'Criar',
			permission_USERS_administrators_1: 'Visualizar',
			permission_USERS_administrators_2: 'Editar',
			permission_USERS_administrators_3: 'Visualizar desativados',
			permission_USERS_administrators_4: 'Desativar',
			permission_USERS_administrators_5: 'Remover',
			permission_USERS_administrators_6: 'Importar',
			permission_USERS_administrators_7: 'Exportar',

			permission_USERS_agents_0: 'Criar',
			permission_USERS_agents_1: 'Visualizar',
			permission_USERS_agents_2: 'Editar',
			permission_USERS_agents_3: 'Visualizar desativados',
			permission_USERS_agents_4: 'Desativar',
			permission_USERS_agents_5: 'Remover',
			permission_USERS_agents_6: 'Importar',
			permission_USERS_agents_7: 'Exportar',
			permission_USERS_agents_8: 'Parabenizar agentes',

			permission_USERS_clients_0: 'Criar',
			permission_USERS_clients_1: 'Visualizar',
			permission_USERS_clients_2: 'Editar',
			permission_USERS_clients_3: 'Visualizar desativados',
			permission_USERS_clients_4: 'Desativar',
			permission_USERS_clients_5: 'Remover',
			permission_USERS_clients_6: 'Importar',
			permission_USERS_clients_7: 'Exportar',

			permission_AGENT_clients_0: 'Visualizar',
			permission_AGENT_clients_1: 'Criar',

			permission_USERS_companies_0: 'Criar',
			permission_USERS_companies_1: 'Visualizar',
			permission_USERS_companies_2: 'Editar',
			permission_USERS_companies_3: 'Visualizar desativados',
			permission_USERS_companies_4: 'Desativar',
			permission_USERS_companies_5: 'Remover',
			permission_USERS_companies_6: 'Importar',
			permission_USERS_companies_7: 'Exportar',

			permission_USERS_managers_0: 'Criar',
			permission_USERS_managers_1: 'Visualizar',
			permission_USERS_managers_2: 'Editar',
			permission_USERS_managers_3: 'Visualizar desativados',
			permission_USERS_managers_4: 'Desativar',
			permission_USERS_managers_5: 'Remover',
			permission_USERS_managers_6: 'Importar',
			permission_USERS_managers_7: 'Exportar',

			permission_USERS_partners_0: 'Adicionar',
			permission_USERS_partners_1: 'Visualizar',
			permission_USERS_partners_2: 'Excluir parceria',
			permission_USERS_partners_3: 'Aceitar convites',

			permission_USERS_teams_0: 'Visualizar',
			permission_USERS_teams_1: 'Editar',
			permission_USERS_teams_2: 'Desativar',
			permission_USERS_teams_3: 'Remover',
			permission_USERS_teams_4: 'Visualizar desativados',
			permission_USERS_teams_5: 'Criar',

			permission_USERS_branches_0: 'Criar',
			permission_USERS_branches_1: 'Visualizar',
			permission_USERS_branches_2: 'Editar',
			permission_USERS_branches_3: 'Visualizar desativados',
			permission_USERS_branches_4: 'Desativar',
			permission_USERS_branches_5: 'Remover',
			permission_USERS_branches_6: 'Importar',
			permission_USERS_branches_7: 'Exportar',

			permission_AGENT_branches_0: 'Visualizar',
			permission_AGENT_branches_1: 'Criar',

			permission_USERS_analytics_0: 'Visualizar',

			permission_USERS_assets_0: 'Visualizar',
			permission_USERS_assets_1: 'Editar categoria',
			permission_USERS_assets_2: 'Editar ativo',
			permission_USERS_assets_3: 'Importar e exportar ativos',

			permission_AGENT_assets_0: 'Visualizar',
			permission_AGENT_assets_1: 'Editar',

			permission_USERS_autoassign_0: 'Visualizar modelos',
			permission_USERS_autoassign_1: 'Editar modelos',
			permission_USERS_autoassign_2: 'Criar modelos',
			permission_USERS_autoassign_3: 'Remover modelos',

			permission_USERS_billing_0: 'Visualizar',

			permission_USERS_calendar_0: 'Visualizar',

			permission_CLIENT_USERS_calendar_0: 'Visualizar',

			permission_USERS_dashboard_0: 'Visualizar',

			permission_CLIENT_USERS_dashboard_0: 'Visualizar',

			permission_USERS_dynamicallocation_0: 'Gerar atribuições',

			permission_USERS_forms_0: 'Visualizar modelos',
			permission_USERS_forms_1: 'Editar modelos',
			permission_USERS_forms_2: 'Criar modelos',
			permission_USERS_forms_3: 'Remover modelos',
			permission_USERS_forms_4: 'Editar formulário de tarefa',
			permission_USERS_forms_5: 'Excluir formulário de tarefa',
			permission_USERS_forms_6: 'Baixar formulário de tarefa',
			permission_USERS_forms_7: 'Importar modelo',

			permission_CLIENT_USERS_forms_0: 'Visualizar',
			permission_CLIENT_USERS_forms_4: 'Editar formulário de tarefa',

			permission_AGENT_forms_0: 'Visualizar',
			permission_AGENT_forms_1: 'Editar',

			permission_USERS_grouptasks_0: 'Criar',
			permission_USERS_grouptasks_1: 'Visualizar',
			permission_USERS_grouptasks_2: 'Adicionar tarefas ao grupo',
			permission_USERS_grouptasks_3: 'Desvincular tarefas',
			permission_USERS_grouptasks_4: 'Editar',
			permission_USERS_grouptasks_5: 'Baixar imagens',
			permission_USERS_grouptasks_6: 'Exportação de tarefas',
			permission_USERS_grouptasks_7: 'Remover',

			permission_AGENT_grouptasks_0: 'Visualizar',
			permission_AGENT_grouptasks_1: 'Cancelar',
			permission_AGENT_grouptasks_2: 'Pausa',
			permission_AGENT_grouptasks_3: 'Executar',

			permission_USERS_inventory_0: 'Visualizar',
			permission_USERS_inventory_1: 'Editar categoria',
			permission_USERS_inventory_2: 'Editar inventário',
			permission_USERS_inventory_3: 'Importar e exportar inventários',

			permission_AGENT_inventory_0: 'Visualizar',
			permission_AGENT_inventory_1: 'Editar',

			permission_USERS_isochronoes_0: 'Visualizar',
			permission_USERS_isochronoes_1: 'Editar',
			permission_USERS_isochronoes_2: 'Remover',
			permission_USERS_isochronoes_3: 'Criar',

			permission_USERS_resourceallocation_0: 'Gerar atribuições',

			permission_USERS_routeoptimization_0: 'Gerar otimizações',

			permission_USERS_routes_0: 'Visualizar',
			permission_USERS_routes_1: 'Editar',
			permission_USERS_routes_2: 'Cancelar',
			permission_USERS_routes_3: 'Reiniciar tarefas da rota',
			permission_USERS_routes_4: 'Reiniciar tarefas canceladas',
			permission_USERS_routes_5: 'Reiniciar rotas',
			permission_USERS_routes_6: 'Reagendar rotas',
			permission_USERS_routes_7: 'Rearrange route',
			permission_USERS_routes_8: 'Adicionar tarefa por fólio',
			permission_USERS_routes_9: 'Excluir tarefa da rota',
			permission_USERS_routes_10: 'Reatribuir agente',

			permission_AGENT_routes_0: 'Visualizar',
			permission_AGENT_routes_1: 'Cancelar',
			permission_AGENT_routes_2: 'Rejeitar',
			permission_AGENT_routes_3: 'Criar emergências',

			permission_USERS_skills_0: 'Visualizar',
			permission_USERS_skills_1: 'Remover',
			permission_USERS_skills_2: 'Criar',

			permission_USERS_sms_0: 'Visualizar',
			permission_USERS_sms_1: 'Editar',

			permission_USERS_tasktypepreferences_0: 'Visualizar',
			permission_USERS_tasktypepreferences_1: 'Editar',

			permission_CLIENT_USERS_tasktypepreferences_0: 'Visualizar',

			mobile: 'Móvel',
			deselectAllNotificationsTooltip: 'Desmarque todas as notificações neste grupo',
			selectAllNotificationsTooltip: 'Redefina as configurações padrão, selecione todas as notificações de grupo',

			usersNotifications: 'Notificações de usuários',
			myNotifications: 'Minhas notificações',
			configureYourNotifications: 'Configure Suas notificações',
			configureNotificationsDescription:
				'Selecione como você gostaria de receber as notificações para as seguintes atividades',
			configureUsersNotifications: 'Configure as notificações para os usuários em sua conta',
			configureUsersNotificationsDescription:
				'Selecione quais notificações você gostaria que cada tipo de usuário recebesse',
			smsNotificationExtraCost: 'Ativar SMS resultaria em custo adicional para notificações',

			accepted: 'Aceitaram',
			withoutAccepting: 'Sem aceitar',

			notificationAction_0: 'Tarefa criada',
			notificationAction_65: 'Tarefa criada pelo cliente',
			notificationAction_66: 'Tarefa criada por e-mail',
      notificationAction_67: 'A tarefa autoalocada não é para hoje',
			notificationAction_1: 'Tarefa atribuída a um agente',
			notificationAction_14: 'Tarefa atribuída a um parceiro',
			notificationAction_48: 'Tarefa reatribuída ao parceiro',
			notificationAction_62: 'Tarefa atribuída ao grupo de tarefas',
			notificationAction_32: 'Tarefa atribuída a uma equipe',
			notificationAction_33: 'Formulário atribuído à tarefa',
			notificationAction_35: 'Inventário atribuído à tarefa',
			notificationAction_34: 'Ativo atribuído à tarefa',
			notificationAction_2: 'Tarefa aceita pelo agente',
			notificationAction_5: 'Tarefa rejeitada pelo agente',
			notificationAction_3: 'Tarefa iniciada',
			notificationAction_24: 'Agente chegou ao local da tarefa',
			notificationAction_7: 'Tarefa cancelada',
			notificationAction_51: 'Agente a caminho',
			notificationAction_53: 'Tarefa Concluída',
			notificationAction_10: 'Agendamento de tarefas alterado',
			notificationAction_13: 'Tarefa cancelada pelo parceiro',
			notificationAction_20: 'Local da tarefa alterado',
			notificationAction_55: 'A tarefa foi editada',
			notificationAction_54: 'Um comentário foi adicionado à Tarefa',
			notificationAction_40: 'A Tarefa foi Reiniciada',
			notificationAction_80: 'Formulário não aprovado',
			notificationAction_81: 'Formulário aprovado',

			notificationAction_23: 'Rota atribuída a um agente',
			notificationAction_57: 'Tarefas de rota reiniciadas',
			notificationAction_15: 'Rota aceita',
			notificationAction_16: 'Rota rejeitada',
			notificationAction_17: 'Rota concluída',
			notificationAction_37: 'Programação da rota alterada',
			notificationAction_38: 'Rota foi reiniciada',
			notificationAction_18: 'Rota cancelada',
			notificationAction_9: 'Rota iniciada',
			notificationAction_21: 'Rota excluída',
			notificationAction_25: 'Tarefa adicionada à rota',
			notificationAction_56: 'Rota atualizada',
			notificationAction_22: 'Tarefa excluída da rota',
			notificationAction_58: 'Incidente relatado na rota',
			notificationAction_59: 'Reiniciar tarefas canceladas da Rota ',

			notificationAction_27: 'Agente atribuído ao grupo de tarefas',
			notificationAction_60: 'Grupo de tarefas cancelado',
			notificationAction_61: 'Grupo de tarefas pausado',
			notificationAction_62: 'Tarefa adicionada ao grupo de tarefas',
			notificationAction_63: 'Tarefa excluída do Grupo de Tarefas',
			notificationAction_64: 'Grupo de tarefas atualizado',

			notificationAction_11: 'Convite para Parceiro',
			notificationAction_12: 'Parceria excluída',

			botName:'Assistente Virtual',

			// isochronoes
			area: 'Área',
			originPoint: 'Ponto de origem',
			none_agents: 'Sem agentes',
			noneteam: 'Sem equipes',
			circle: 'Círculo',
			polygon: 'Polígono',
			errorMessageIsoDates:
				'Informações incorretas dos dados / Verifique se a primeira data da tarefa deve ser anterior à segunda data da tarefa',
			clear: 'Limpar',
			loginInvalidUserPassword: 'Nome de usuário e / ou senha inválidos',
			loginDisabledAccount: 'Conta desativada, entre em contato com o administrador',
			loginDisabledUser: 'Usuário desativado, entre em contato com o administrador para obter mais informações',
			loginAgentAccesMessage:
				'Como agente, você não tem acesso ao aplicativo da web, faça o download do aplicativo móvel do agente para entrar.',
			loginAgentAccessDenied: 'O agente não tem acesso ao Fielder Web',
			showIsochronous: 'Mostrar isócronas',
			showAgentsStartLocation: 'Mostrar ponto de partida dos agentes',
			isoType: 'Type',
      editIsochronous: 'Editar Isócrono',
			addIsochronous: 'Adicionar Isócrono',
			searchLocation: 'Encontrar localização',
			accountDoesNotHaveIsochronous: 'Você não tem isócronas ativas nesta secao. Crie uma nova.',
			confirmDeleteIscronoes: 'Tem certeza de que deseja eliminar isócrono?',
			noneIsochronoes: 'Você ainda não tem isócronos criados',
			selectIsochronous: 'Selecione um ou mais isócronos',

			// Form Validation Errors
			requiredName: 'Nome é obrigatório',
			isRequired: 'é obrigatório',
			requiredName: 'Nome de isócrona obrigatório',
			requiredAddress: 'Endereço necessário',

			gatheringLocationInformation: 'Reunindo informações de localização...',
			creatingIsochronousArea: 'Criando área isócrona...',
			assigningAgentsTeams: 'Atribuindo agentes e equipes...',
			preparingInformation: 'Preparando informações...',
			generatingNewIsochronousData: 'Gerando novos dados isócronos...',
			updatingAccountData: 'Atualizando dados da conta...',
			savingInformation: 'Salvando informações...',

			// Resource allocation
			allocateResources: 'Alocar recursos',
			resourceAllocation: 'Atribuição de recursos',
			errorEnsureInformationCorrect: 'Verifique se as informações estão corretas e tente novamente',
			resourceAllocationSuccess:
				'O processo de alocação de recursos foi criado com sucesso. Você receberá um email com o resultado do processo em um momento',

			// ResourceAllocation and Dynamic Allocation variables
			routeTaskVariables: 'Variáveis ​​de rota',
			routeTaskAddVariable: 'Adicionar variável',

			// Myoperation
			downloadTaskFileName: 'fieldertasks',

			downloadManagerFileName: 'fielderManagers.csv',
			downloadDisableManagerFileName: 'fielderDisableManagers.csv',

			downloadAdministratorFileName: 'fielderAdministrators.csv',
			downloadDisableAdministratorFileName: 'fielderDisableAdministrators.csv',

			downloadClientsFileName: 'fielderClients.csv',
			downloadDisableClientsFileName: 'fielderDisableClients.csv',

			downloadCompaniesFileName: 'fielderCompanies.csv',
			downloadDisableCompaniesFileName: 'fielderDisableCompanies.csv',

			downloadBranchesFileName: 'fielderBranches.csv',
			downloadDisableBranchesFileName: 'fielderDisableBranches.csv',

			downloadAgentsFileName: 'fielderAgents.csv',
			downloadDisableAgentsFileName: 'fielderDisableAgents.csv',

			generateZip: 'Gerar zip',
			modulesZipSuffix: 'modulos.zip',

			statusModifiedByUser: 'Status modificado pelo usuário',
			theTaskWasNotFound: 'A tarefa não foi encontrada',
			notFound:'Nao encontrado',
			taskStatusNotAllowed: 'Status da tarefa não permitido',
			canceledTask: 'Tarefa cancelada',
			taskIsInactive: 'A tarefa está inativa',
			tryAgainUpdateStatus: 'Não foi possível atualizar o status da tarefa, tente novamente',
      tryAgainAutoAssignTasks: 'Não foi possível atribuir a tarefa automaticamente. Tente novamente',
			idTaskName:'ID de Tarefa',
			idTask: 'ID Fielder',
			idCustom: 'ID Personalizado',
			idCustomBranch: 'ID da Filial Personalizada',
			branchNameText: 'Nome da Filial',
			companyIdCustom: 'ID Personalizado da Empresa',
			companyIdAndName:'Id-Nome da Empresa',
			companyName:'Nome da Empresa',
			parentCompanyNameText: 'Nome da empresa-mãe',
			addressCompany:'Endereço da companhia',

			idParentTask: 'ID Tarefa Pai',
			parentTask: 'Tarefa Pai',
			parentTaskType: 'Tipo de Tarefa Pai',
			deleteTaskTooltip: 'Excluir tarefa(s)',
			disableTaskTooltip: 'Desativar Tarefa',
			deleteRouteTooltip: 'Excluir Rota(s)',
			deleteGroupTaskTooltip: 'Excluir Grupo(s) de tarefas',
			taskRouteReturnedNoResults: 'Rota de tarefa retida sem resultados',
			taskRouteAvailableForTaskStatus: 'Rotas de tarefas disponíveis apenas para tarefas com status A caminho',
			document: 'Documento',
			documents: 'Documentos',
			folio: 'Fólio',
			caseNumber:'Número do caso',
			noFolioFolder: 'Sem fólio',
			noFolio: 'Sem fólio',
			noForm: 'Nenhum formulário selecionado',
			noWorkingHourFrom: 'Sem tempo de entradae',
			noWorkingHourTo: 'Sem hora de partida',
			group: 'Grupo',
			statusGroup: 'Status do grupo',
			groupTask: 'Grupo de tarefas',
			unknownCategory: 'Outros',
			module: 'Módulo',
			severity: 'Severidade',
			status: 'Status ',
			notAvailable: 'Não disponível',
			tags: 'Tags',
			tag: 'Tag',
			agentStartLocation: 'Direção de execução',
			agentStartLocationExcel: 'Endereço de início da tarefa',
			routeTimeTraveled: 'Tempo estimado de viagem da rota',
			pauseFormattedHour: 'Tempo de pausa da tarefa',
			pauseFormattedDate: 'Data de pausa da tarefa',
			pauseResumeFormattedHour: 'Hora de retomada da tarefa pausada',
			pauseResumeFormattedDate: 'Data de retomada da tarefa pausada',

			countPause: 'Número de pausas',
			countResume: 'Número de retomada da tarefa',

			routeDistanceTraveled: 'Distância estimada da rota',
			agentEndLocation: 'Endereço de conclusão',
			agentEndLocationExcel: 'Endereço de conclusão da tarefa',
			name: 'Nome',
			names:'nomes',
			lastConnection: 'Última conexão',
			lastDataObtained: 'Últimos dados obtidos',
			lastName: 'Sobrenome',
			idCountryCode: 'ID do país',
			selectedElements: 'Itens selecionados',
			branchName: 'Filial',
			idCustomBranchDuplicated: 'Id personalizado ou nome duplicado',
			idBranches: 'Id Filiais',
			noBranchLink: 'Não está ligado a uma filial',
			country: 'País',
			accountByCountry: 'Conta por país',
			alreadySenInvitacion: 'Você já enviou um convite para este e-mail',
			partnertNotFound: 'O parceiro que você está tentando convidar não existe ou o e-mail está errado',
			pendentingInvitation: 'Convite pendente',
			unnamed: 'sem nome',

			agent: 'Agente',
			startWay: 'Data agendada',
			startTime: 'Data agendada',
			onWayDateExcel: 'Data de início da viagem',
			onWayHourExcel: 'Hora de inicio de viagem',
			onWayDate: 'A caminho da data',
			endDateExcel: 'Data de Conclusão da Tarefa',
			endHourExcel: 'Hora de Fim da Tarefa',
			endAgent: 'Data de término',
			endHour: 'Hora de término',
			score: 'Pontuação',
			timeZone: 'Fuso horário',
			rescheduledDate: 'Data reagendada',
			rescheduledHour: 'Hora reagendada',
			rescheduledComment: 'Comentar ao reagendar a tarefa',
			rescheduledCommentDate: 'Data do comentário ao reagendar a tarefa',
			rescheduleUserName:'Usuário que reagendou',
			cancelReason: 'Motivo de cancelamento',
			taskActivities: 'Atividades de tarefa',
			reason: 'Motivo',
			cancelBy: 'Cancelado por',
			cancelComment: 'Comentários de cancelamento',
			taskPauseComment: 'Comentários de pausa de tarefa',
			taskRejectionDate: 'Data de rejeição da tarefa',
			taskRejectTime: 'Tempo de rejeição da tarefa',
			taskRejectDependency: 'Dependência de rejeição de tarefas',
			taskRejectionReason: 'Motivo da rejeição da tarefa',
			taskRejectionComment: 'Comentários de Rejeição de Tarefa',

			mobileInformation: 'Informação móvel',
			locationPrecisePermission: 'Permissão de localização precisa',
			locationApproximatePermission: 'Permissão de localização aproximada',
			locationBackgroundPermission: 'Permissão de localização em segundo plano',

			taskGroupNotification:
				'Apenas os Grupos de Tarefas com status "Criado" são mostrados, se o Grupo estiver em outro status, crie um Novo Grupo ou atribua a tarefa individualmente',

			searchByTask:'Pesquisar por tarefa',
			searchByForm:'Pesquisar por formulário',
			userCancelComment: 'Comentários de cancelamento do usuário',
			executionMode: 'Modo de execução: SEM rede',
			kmsDistance: 'Distância estimada percorrida em kms',
			messageForm: 'Falta a data final',
			messageTo: 'Falta a data de início',
			messagePrevious: 'A data de início deve ser anterior à data de término',

			messageFieldEmpty: 'O campo de pesquisa está vazio',
			messageGeneralSearch: 'Busca geral',
			disableAvacedSearch: 'Remova a pesquisa avançada para usar a pesquisa geral',
			messageEmptyTasks: 'Você deve selecionar uma tarefa',
			messageEmptyRoutes: 'Você deve selecionar uma rota',
			messageEmptyGroupTasks: 'Nenhum grupo de tarefas selecionado',
			messageTaskGroupDeletePartner:'Você selecionou um ou mais grupos atribuídos por um parceiro e não pode excluí-los',
			messageNoMoreThanTwoTask: 'Você deve selecionar pelo menos 2 tarefas para criar um grupo de tarefas',
			messageNoMoreThanTwoTaskRoutes: 'Você deve selecionar pelo menos 2 tarefas para gerar rotas',
			messageMusntDelete: 'Você não deve excluir estas tarefas',
			messageCantRestore: 'Esta tarefa não pode ser recuperada. Tente novamente mais tarde',
			messageTaskDeleteError: 'Você não pode eliminar essas tarefas porque elas são atribuídas a outros recursos.',
			messageTaskDisableError:
				'Você não pode excluir essas tarefas. Somente as tarefas de status gerenciando e aguardando agente podem ser excluídas.',
			messageTaskWithRoute: 'Você não pode excluir esta tarefa porque está vinculada a uma rota',
			messageTaskDeleteSuccess: 'Tarefa(s) excluída(s) com sucesso',
			messageTaskDisableSucess: 'Tarefa(s) desativada(s) com sucesso',
			messageConfirmDeleteTasks: 'Tem certeza de que deseja excluir essas tarefa(s)?',
			messageConfirmRestoreTasks: 'Tem certeza de que deseja restaurar essas tarefa(s)?',
			messageConfirmDeleteRoutes: 'Tem certeza de que deseja excluir essas rota(s)?',
			messageConfirmDeleteGroupTasks: 'Tem certeza de que deseja excluir esses grupo(s) de tarefa(s)?',
			messageConfirmaDisableTask: 'Tem certeza de que deseja desativar esta tarefa(s)?',
			messageConfirmaCancelTask: 'Por que você deseja cancelar sua tarefa?',
			cancelInformation:'*Lembre-se que as tarefas canceladas pelos administradores e/ou gerentes serão totalmente canceladas e você terá que criar uma nova tarefa. Tarefas canceladas por Agentes e/ou parceiros poderão ser reatribuídas.',
			messageMoreTasksSelected: 'Por favor, selecione apenas uma tarefa',
			messageMusntReschedule: 'Uma tarefa cancelada não pode ser reagendada',
			messageErrorReschedule:
				'Você não pode reagendar uma tarefa com o status Executando, A caminho, Chegando, Cancelado, Finalizado ou Cancelado por um parceiro',
			messageErrorRescheduleClient:
				'Você não pode reagendar uma tarefa com o status Executando, A caminho, Chegando, Cancelado ou Finalizado',

			messageErrorRescheduleGpoTask:
				'A tarefa está em um Grupo de Tarefas, para realizar uma ação sobre ela você deve primeiro desvinculá-la do grupo',
			messageAutoassignError:
				'Você não pode atribuir um agente a uma tarefa enquanto o processo de auto-atribuição está em execução ou o status é diferente de "Gerenciando", "Cancelado", "Aguardando agente", "Atribuído à equipe", "Cancelado pelo parceiro" ou se a tarefa foi cancelada por um cliente ou administrador',
			messageAssignAgentPartnersError: 'Incapaz de atribuir um agente a uma tarefa atribuída a um parceiro',

			messageAssignAgentPartnersCancelError:
				'Não é possível atribuir um agente a uma tarefa cancelada se você não for o proprietário e tiver uma relação de parceria',

			messageErrorAssignTeamPopUp:
				'Você não pode atribuir uma equipe a uma tarefa com um status diferente de "Gerenciando" ou "Atribuído à equipe", "Cancelado", "Cancelado pelo parceiro"  ou se a tarefa foi cancelada por um cliente ou administrador',
			messageRateFinishedTaskOnly: 'Não foi possível avaliar uma tarefa com um status diferente de "Concluído"',
			messageRatedAlready: 'A tarefa já foi classificada',
			messageAssingAgentPartnersError: 'Você não pode atribuir um agente às tarefas de um parceiro',

			messageErrorGroupTaskWitTask:'Você não pode criar um grupo de tarefas se já selecionou uma tarefa com um grupo atribuído a ela, recomendamos primeiro desvincular a tarefa do grupo ou criar um novo grupo na guia grupo de tarefas.',

			messageErrorAssignGroupTaskWitTask:'Você não pode atribuir um grupo a uma tarefa que já possui um, primeiro desvincule a tarefa do grupo.',

			messageErrorGroupTaskStatus:
				'Você não pode adicionar um grupo de tarefas a uma tarefa com status "Concluido", "Executando", "Iniciar rota" ou "Chegou", verifique as informações e tente novamente',

			messageErrorGroupTaskParner:
				'Você não pode criar um grupo de tarefas para uma tarefa cancelada ou pausada  por você ou seu parceiro se você não for o proprietário da tarefa',

			messageErrorCancelTaks: 'Você não pode cancelar tarefa(s) com o status "Concluído" ou "Cancelado"',
			messageErrorCancelTaksOwner:
				'Você não pode cancelar tarefa(s) com o status "Concluído" ou "Cancelado" ou "Cancelado por um parceiro"',

			messageErrorGroupTaskPartner:
				'Você não pode atribuir um grupo de tarefas a uma tarefa cancelada ou pausada por você ou seu parceiro se você não for o proprietário da tarefa',

			messageErrorAssignPartner:
				'Você não pode atribuir um parceiro a uma tarefa com um status diferente de "Gerenciando". Se desejar alterar o parceiro atribuído à tarefa, utilize a função "reatribuir parceiro". ',
			messageErrorUserReasingPartner: 'Use a função "reatribuir parceiro" no menu de ações',
			messageErrorUserReasingPartnerCancelClient:
				'Você não pode atribuir uma tarefa a um parceiro se o cliente ou administrador a cancela anteriormente e é o proprietário dela',

			messageErrorReAssignPartner:
				'Você não pode reatribuir um parceiro para uma tarefa que está em execução ou concluída',
			messageErrorReAssignPartnerNoPartner:
				'Você não pode reatribuir uma tarefa a um parceiro se não tiver sido atribuída anteriormente a um',
			messageErrorReAssignPartnerExecutor:
				'Você não pode reatribuir um parceiro a uma tarefa quando você é o último parceiro atribuído a ela',
			selectOnePartner: 'Você deve selecionar pelo menos um parceiro',
			setOneReason: 'Você deve escrever um motivo',
			setCategory: 'Você deve selecionar uma categoria',
			fail: 'Não atendeu à condição',
			reassignedByMe: 'Mostrar tarefas atribuídas por mim',
			reassignedByOther: 'Mostrar tarefas reatribuídas por outros parceiros',
			taskReassignedInfo: 'Painel de tarefas totais reatribuídas',
			messageErrorAssignAsset: 'Você não pode atribuir um ativo a tarefas com diferentes filiais',
			messageErrorAssignAssetwithNoBranch: 'Você não pode atribuir um ativo a tarefas sem filial',
			messageErrorAssignAssetPartner:"Você não pode atribuir um ativo a uma tarefa atribuída por um parceiro de negócios",
			messageErrorAssignAssetWrongStatus:
				'Você não pode atribuir um ativo a uma tarefa com status cancelado ou concluído',
			messageErrorAssignFormWrongStatus:
				'Você não pode atribuir um formulário a uma tarefa com status cancelado ou concluído',
			messageErrorAssignInventaryPartner:"Você não pode atribuir um inventário a uma tarefa atribuída por um parceiro",
			messageErrorAssignInventoryWrongStatus:
				'Você não pode atribuir inventário a uma tarefa com status cancelado ou concluído',
			messageErrorDeletedTask: 'Não foi possível excluir esta (s) foto (s)',
			messageConfirmationAssignAgent: 'Tem certeza de que deseja atribuir um agente "fora de serviço"?',
			messageAssignAgentSuccessfully: 'O agente foi atribuído com sucesso',
			messageAssignTeamSuccessfully: 'A equipe foi designada com sucesso',
			messageErrorAssignTeam: 'Não foi possível atribuir a equipe, tente novamente',

			tasksUpperCase: 'TAREFAS',
			taskUpperCase: 'TAREFA',
			messageErrorAssignAgent: 'O agente não foi designado, tente novamente',

			newTasks: 'Novas tarefas',
			newTaskGroups:'Novos grupos de tarefas',
			showUpdates: 'Mostrar atualizações',
			showTaskGroupUpdates:'Mostrar atualizações do grupo de tarefas',
			taskStatusAnalitycs: 'ESTADO DAS TAREFAS',
			statusAnalitycs: 'ESTADO',
			titleIconCalendar: 'Reagendar',
			titleIconDetail: 'Detalhe',
			titleIconGroup: 'Crie um grupo de tarefas',
			titleIconEditGroup: 'Detalhe do grupo de tarefas',
			titleIconEditGroupAction: 'Detalhe Grupo',

			updateFormInTaskByCsv:'Atualizar formulário na tarefa por CSV',
			titleIconAssignGroup: 'Atribuir um grupo de tarefas',
			titleIconAssignGroupAction: 'Atribuir Grupo',
			route: 'Rota',
			assignResources: 'Atribuir Recursos',
			updateTemplateForm:'Modelo para atualização de tarefas',
			import: 'Importar dados',
			export: 'Exportar dados',
			updateTaskForms:'Atualizar formulários de tarefas',
			updateTasks:'Atualizar tarefas',
			importTask: 'Importar tarefas',
			exportTask: 'Exportar tarefas',
			selectOptions: 'Selecione os campos que deseja exportar',
			tasksRelatedReason: 'Tarefas relacionadas com a razão',
			exportCalendar: 'Exportar calendário',
			exportError: 'Não há dados para exportar',
			exportMessage: 'Estamos processando e baixando seu arquivo ...',
			loadingTextAssitedForm:'Criando modelo. Esta ação pode demorar alguns minutos.',
			getTaskFromGroup:'Estamos trazendo suas tarefas de grupo',
			uploadFileMessage: 'Estamos processando e enviando seu arquivo ...',

			obtainTaskMessage: 'Estamos obtendo as informações de suas tarefas',
			obtainFormMessage: 'Estamos gerando as formas de suas tarefas',
			obtainFormImageMessage: 'Estamos recebendo as imagens dos formulários. Por favor, espere.',
			exportingModules: 'Estamos obtendo as informações de suas módulos. Por favor, espere',
			noFormsToDownload: 'Não havia formulários com um modelo html ou xlsx',
			noFormsToDownloadImages: 'Não havia formulários contendo imagens',
			importMessageProcess: 'Estamos processando e fazendo upload do seu arquivo ...',
			maxImportMessage:'A quantidade máxima para importar tarefas através do Excel é de 200 tarefas por importação. Para importar um volume maior, utilize a API Fielder',
			maxUpdateMessage:'A quantidade máxima para atualizar tarefas através do Excel é de 200 tarefas por atualização. Para atualizar um volume maior, utilize a API Fielder',
			updateForms: 'Atualizar formulários',
			downloadFormTemplateMessage1: '1. Clique ',
			downloadFormTemplateMessage11: ' aqui ',
			downloadFormTemplateMessage12:' para acessar o Módulo Formulários',
			downloadFormTemplateMessage2: '2. Clique no nome do formulário que deseja atualizar',
			downloadFormTemplateMessage3: '3. Dentro dos detalhes do formulário, baixe o modelo',
			downloadFormTemplateMessage4: '4. Carregue seu modelo editado com as atualizações feitas no seguinte botão "Atualizar Formulário".',
			
			downloadFormTemplateManagerMessage1: '1. Clique nos detalhes da tarefa',
			downloadFormTemplateManagerMessage2: '2. Entre na guia de funcionalidade e abra a seção de formulários',
			downloadFormTemplateManagerMessage3: '3. Selecione o formulário desejado',
			downloadFormTemplateManagerMessage4: '4. Baixe o modelo usando o botão superior direito',

			from: 'De',
			to: 'Para',
			outOf: 'de',
			executeReport: 'Executar',
			offDuty: 'Fora de serviço',
			importTaskSuccess:
				'Seu processo de importação de tarefas foi criado com sucesso; em breve você receberá um email com os resultados.  ',
			importTaskError:
				'Desculpe, seu processo de importação de tarefas não teve êxito. Revise as informações no seu arquivo .csv e tente novamente. ',
			importTaskExceeds: 'O número de tarefas excede o máximo permitido (500 tarefas)',
			importTaskNotLastCVS:
				'O número de colunas no formato não corresponde à sua versão mais recente. Faça o download do formato e tente novamente.',
			downloadTaskTemplate: 'Faça o download do modelo .csv de importação de tarefas',
			downloadUpdateTaskTemplate:'Faça o download do modelo .csv de atualização de tarefas',
			downloadUserTemplate: 'Faça o download do modelo .csv do arquivo de importação do usuário',
			downloadBranchTemplate: 'Baixe o modelo .csv do arquivo de importação de filial',
			downloadCompanyTemplate:'Baixe o modelo .csv do arquivo de importação da companhia',
			createTaskShownClientWarning:
				'Lembre-se de que apenas os clientes que não estão vinculados a uma filial aparecem',
			rememberCsvFile: 'Lembre-se de que você só pode enviar um arquivo .csv',
			useLastCsvTemplate: 'Verifique se você está usando a última versão do modelo / arquivo',
			importBranchNote: 'Se você deseja atualizar as informações por meio da importação, marque a última caixa com um x',
			useCorrectCsvUpdateTemplate: 'Certifique-se de ter o csv correto para atualizar o formulário dentro da tarefa',
			cellsLeftBlankInfo: 'As células em branco serão ignoradas e as informações dessa pergunta não serão atualizadas',
			useCorrectCsvTemplate: 'Certifique-se de que está usando o modelo csv correto para esta categoria',
			cancelRoute: 'Cancelar Rota',
			cancelTask: 'Cancel Tarefa',
			rateTask: 'Taxa de Tarefa',
			rate: 'Taxa',
			rateTaskAction: 'Taxa',
			rateTooltipText: 'Selecione de menor para maior o número de estrelas',
			clientComment: 'Comentário do Cliente',
			comment: 'Comentario',
			cancelRouteConfirmation: 'Deseja cancelar as rotas selecionadas?',
			resetRouteTasks: 'Redefinir tarefas de rota',
			resetCanceledTasks: 'Redefinir tarefas canceladas',
			resetRouteTasksConfirmation: 'Deseja reiniciar as rotas selecionadas?',
			resetCanceledTasksConfirmation: 'Deseja reiniciar as tarefas canceladas do seu percurso?',
			resetRouteTasksNotificacion: 'A rota foi redefinida',
			resetCanceledTasksNotification: 'Tarefas de rota canceladas foram reiniciadas',
			restartTaskExecution: 'A tarefa foi reiniciada',
			assetAssigned: 'Ativo atribuído à tarefa',
			templateAssigned: 'Formulário atribuído à tarefa',
			inventoryAssigned: 'Inventário atribuído à tarefa',
			cancelRouteMessage: 'Lembre-se de que, para manter suas tarefas, você deve salvá-las primeiro',
			resetRouteTasksMessage:
				'Esta ação fará com que as tarefas do seu percurso retornem ao estado inicial na seção de tarefas fora do seu percurso e que seu percurso seja eliminado',
			resetCanceledTasksMessage:
				'Esta ação fará com que as tarefas canceladas da sua rota retornem ao estado inicial na seção de tarefas fora da sua rota',
			reschedule: 'Reagendar',
			rescheduleComment: 'Comentário do reagendamento',
			dateOfRescheduleComment:'Data de câmbio',
			rescheduleTask: 'Reagendar tarefa',
			rescheduleRoute: 'Reprogramar rota',
			routeRescheduled: 'A Rota foi reprogramada',
			restartRoute: 'Reiniciar Rota',
			restart: 'Reiniciar',
			routeNumber: 'Número da rota',
			routeName: 'Nome da rota',
			routeCost: 'Custo estimado da rota',
			routeEndPoint: 'Retorno da rota',
			routeStartPointTitle: 'Endereço de início da rota',
			routeEndPointTitle: 'Endereço de retorno da rota',
			routeEndPointDefault: 'Tome a direção da última tarefa da Rota',
			routeExpensesConfig: 'Configuração de despesas de rota',
			agentName: 'Nome do agente',
			selectProgramDate: 'Você deve selecionar uma data do programa',
			selectTasks: 'Você deve selecionar uma ou mais tarefas',
			selectTasksFinish: 'Você não pode desvincular tarefas concluídas',
			messageMoreRoutesSelected: 'Você selecionou mais de uma (1) rota',
			messageCancelRoutes:
				'Você não pode cancelar uma rota com o seguinte status: execução, cancelamento ou conclusão.',
			messageRescheduleRoutes:
				'Você não pode reagendar uma rota que está sendo executada, foi cancelada ou foi finalizada.',
			messageResetRouteTasks: 'Você não pode reiniciar uma rota com o status Executando, Cancelado ou Concluído',
			messageResetCanceledTasks: 'Você só pode redefinir uma rota em estado cancelado',
			messageRestartRoute: 'Você não pode reiniciar uma rota que não foi cancelada ou rejeitada.',
			messageReassignAgent:
				"Você só pode atribuir um agente a uma rota que esteja no status de: 'Aceita', 'Esperando Agente' ou 'Rejeitada'.",
			selectRescheduledDate: 'Selecione uma nova data',
			selectNewAgent: 'Selecione um novo agente',
			messageSelectAgent: 'Você deve selecionar um agente',
			messageUpdateRoute: 'A rota não pode ser atualizada, uma das tarefas mudou seu status',
			messageIdTeams: 'As tarefas devem pertencer á mesma equipe',
			messageNullStartTime: 'Algumas das tarefas não têm uma data de execução; refaça a tarefa e tente novamente.',
			selectRoute: 'Selecione uma rota',
			selectTask: 'Selecione uma tarefa',
			resetedTask: 'Redefinição de Tarefa',
			routeChangedByAgent: 'A operação não pôde ser executada porque o agente modificou a rota',

			// NOTIFICATION
			notificationType0: 'Uma tarefa foi criada',
			notificationType65: 'Uma tarefa foi criada por um cliente',
			notificationType66: 'Uma tarefa foi criada por e-mail',
			notificationType1: 'A tarefa foi atribuída',
			notificationType2: 'A tarefa foi aceita pelo agente',
			notificationType3: 'A tarefa foi iniciada',
			notificationType4: 'A tarefa terminou',
			notificationType5: 'O agente rejeitou a rota',
			notificationType6: 'Notificação',
			notificationType7: 'A tarefa foi cancelada',
			notificationType9: 'O agente iniciou sua rota',
			notificationType10: 'A tarefa foi reagendada',
			notificationType11: 'Novo convite para ser um parceiro ',
			notificationType12: 'se recusou a ser seu parceiro',
			notificationType13: 'A tarefa foi cancelada pelo parceiro',
			notificationType14: 'A tarefa foi atribuída pelo parceiro',
			notificationType15: 'O agente aceitou a rota',
			notificationType16: 'A rota foi rejeitada pelo agente',
			notificationType17: 'A rota foi concluída pelo agente',
			notificationType18: 'A rota foi cancelada',
			notificationType19: 'O agente iniciou sua rota',
			notificationType20: 'O local da tarefa mudou',
			notificationType21: 'A Rota foi excluída',
			notificationType22: 'Tarefa removida da rota',
			notificationType23: 'Rota atribuída a um agente',
			notificationType24: 'O agente chegou',
			notificationType25: 'A tarefa tem a rota com id',
			notificationType26: 'O convite da parceria foi enviado para ',
			notificationType27: 'Grupo de tarefas atribuído',
			notificationType28: 'Erro ao tentar alocar a tarefa automaticamente',
			notificationType29:
				'O processo de auto-atribuição não foi bem-sucedido: nenhum agente disponível para a tarefa foi encontrado ',
			notificationType30:
				'O processo de auto-atribuição não foi bem-sucedido: Não foram encontrados modelos de auto-alocação para participar da tarefa ',
			notificationType31: 'Você recusou uma parceria com',
			notificationType32: 'Tarefa atribuída a uma equipe',
			notificationType33: 'Atribuído ao formulário de tarefa',
			notificationType34: 'Atribuído ao formulário de tarefa',
			notificationType35: 'Inventário Atribuído à Tarefa',
			notificationType36:
				'O processo de auto-atribuição não foi bem-sucedido: Nenhum agente foi encontrado com a habilidade especificada ou isócrono que contém o modelo especificado',
			notificationType37: 'Mudou o horário da Rota',
			notificationType38: 'A rota foi reiniciada',
			notificationType40: 'A tarefa foi reiniciada',
			notificationType41:
				'O processo de auto-atribuição não foi bem-sucedido: Nenhum template de auto-atribuição foi encontrado',
			notificationType42:
				'O processo de auto-atribuição não foi bem-sucedido: O localização da tarefa não está dentro de uma isócrona',
			notificationType43:
				'O processo de auto-atribuição não foi bem-sucedido: nenhum template foi encontrado na isócrona da tarefa',
			notificationType44:
				'O processo de auto-atribuição não foi bem-sucedido: nenhum template foi encontrado para a origem da tarefa',
			notificationType45:
				'O processo de auto-atribuição não foi bem-sucedido: nenhum template foi encontrado para o tipo de tarefa',
			notificationType46: 'O processo de auto-atribuição não foi bem-sucedido: nenhum agente disponível encontrado',
			notificationType47:
				'O processo de auto-atribuição não foi bem-sucedido: nenhum template foi encontrado para a prioridade da tarefa',
			notificationType66: 'O processo de auto-atribuição não foi bem-sucedido: nenhum recurso conectado encontrado para modelo de alocação automática',
			notificationType67: 'O processo de auto-atribuição não foi bem-sucedido: A tarefa não é para hoje',
      notificationType48: 'A tarefa foi redesignada',
			notificationType50:
				'O processo de auto-atribuição não foi bem-sucedido: O intervalo de tempo de atenção da Filial está fora do intervalo de tempo de atenção especificado na tarefa.',
			notificationType51: 'O agente está a caminho da Tarefa',
			notificationType53: 'A Tarefa foi concluída',
			notificationType54: 'New Comment added',
			notificationType55: 'A informação da tarefa foi editada',
			notificationType56: 'As tarefas de Rota foram reorganizadas',
			notificationDefault: 'Notificação de Fielder',
			notificationType57: 'As tarefas de rota foram redefinidas',
			notificationType58: 'Incidente relatado na rota',
			notificationType59: 'As tarefas canceladas da rota foram redefinidas',
			notificationType60: 'Grupo de tarefas foi cancelado',
			notificationType61: 'Grupo de tarefas foi pausado',
			notificationType62: 'Uma tarefa foi adicionada ao grupo',
			notificationType63: 'Uma tarefa foir removida do grupo',
			notificationWhitout: 'Você não tem notificações',

			where: 'Onde',
			orCapitalLetter: 'Ou',
			andCapitalLetter: 'E',
			contain: 'Conter',
			// ChangePassword
			changePasswordTitle: 'Mudar senha',
			password: 'Senha',
			currentPassword: 'Senha atual',
			newPassword: 'Nova senha',
			confirmNewPassword: 'Confirme a nova senha',
			changePasswordButton: 'Mudar senha',
			messageErrorChangePassword1: 'Senha incorreta',
			messageErrorChangePassword2: 'Nova senha não corresponde',
			messageErrorChangePassword3: 'Senha alterada com sucesso',
			messageErrorChangePassword4: 'Ocorreu um erro. Por favor, tente novamente',
			welcomePasswordMessage:
				'Sua senha deve ter no mínimo 8 caracteres e incluir letras minúsculas, letras maiúsculas e números.',

			// TaskDetail
			lastUpdateDate: 'Última data de atualização',
			responsible:'Responsável',
			withoutTeam: 'Sem equipe',
			notAssigned: 'Não atribuído',
			notAssignedSchedule: 'Não há data ou hora atribuída',
			nothingToComment: 'Sem comentários',
			execution: 'Execução',
			photos: 'Fotos',
			activity: 'Atividade',
			functionality: 'Funcionalidade',
			taskDetail: 'Detalhe da tarefa',
			nameOfClient: 'Nome do Cliente',
			nameOfBranch: 'Nome da Filial',
			phoneNumberClient: 'Número de celular do Cliente',
			emailOfClient: 'E-mail do cliente',
			typeTask: 'Tipo de tarefa',
			serviceOrigin: 'Origem do Serviço',
			commentTask: 'Comentário da tarefa',
			descriptionTask: 'Descrição da tarefa',
			rating: 'Classificação',
			editTask: 'Editar Tarefa',
			generatePdf: 'Gerar PDF',
			generateXlsx: 'Gerar XLSX',
			generateXlsxModule: 'Gerar modelo XLSX',
			generatePDFfromXlsx: 'Gerar modelo de PDF',
			generatedPDFfromXlsx: 'Modelo de PDF Gerado',
			buyGeneratePDFfromXlsx: 'Funcionalidade de compra "Generate PDF Template"',
			generatePDFModule: 'Gerar formato PDF Fielder',
			assistedForm: "Crie FORM com I.A.",
			beta:"BETA",
			editGroupTask: 'Editar Grupo de Tarefas',
			scheduledDate: 'Data agendada',
			endDate: 'Data final',
			assignedTeam: 'Equipe designado',
			assignedAgent: 'Agente designado',
			assignedWork: 'Jornada de trabalho',
			phoneAgent: 'Número de celular do agente',
			emailAgent: 'E-mail do agente',
			typeVehicle: 'Tipo de Veículo',
			idVehicle: 'ID do veículo',
			timeTraveled: 'Tempo estimado de viagem',
			distanceTraveled: 'Distância estimada percorrida',
			distance: 'Distância',
			photosGallery: 'Galeria de Fotos',
			history: 'História',
			headerOfTitle: 'Título do Cabeçalho',
			downloadPDF: 'Baixar PDF',
			downloadForms: 'Baixar formulários',
			downloadApprovedForms: "Baixar formulários aprovados",
			approvedForms:"Formulários Aprovados",
			downloadFormsImages: 'Baixar imagens de formulários',
			addressTask: 'Endereço da tarefa',
			timeExecution: 'Tempo de execução',
			until: 'Até',
			commentsAgent: 'Comentários do agente',
			lastAgentComment: 'Último comentário do agente',
			rejectionReason: 'Motivo da Rejeição',
			pausedReason: 'Motivo da pausa',
			taskPausedReason: 'Motivo da Tarefa Pausada',
			taskStatusModificationCommentByAdministrator: 'Comentário de modificação do status da tarefa pelo administrador',
			taskStatusModificationCommentByManager: 'Comentário de modificação do status da tarefa pelo gerente',
			taskRejectionComments: 'Comentários de Rejeição de Tarefa',
			taskPauseComments: 'Comentários de pausa de tarefa',

			dateInUTCFormat:'Data no formato UTC',

			commentTitle: 'Comentário',
			commentsUser: 'Comentários de clientes',
			commentUserDate: 'Data de comentário do cliente',
			automationUser: 'Automação de Fielder',

			// Create Group Tasks
			createGroupTaskTitle: 'Criar Grupo de Tarefas',
			createGroupTaskAction: 'Criar Grupo',
			idCustomGroupTask: 'ID Personalizado Grupo de Tarefas',
			executionDate: 'Data de execução',
			creationDate: 'Data de criação',
			disableDate: 'Data de desativação',
			creationDateExcel: 'Data de criação da tarefa',
			creationHourExcel: 'Hora de criação da tarefa',
			executionTimeError: 'A data de execução deve ser a mesma ou posterior a hoje',

			// Asigned group task
			assignedGroupTaskTitle: 'Atribuir grupo de tarefas',
			assign: 'Atribuir',
			reassign: 'Reatribuir',

			// Calendar
			titleCalendar: 'Meu Calendário',
			assignedTask: 'Atribuído à Filial',
			assignedBranch: 'Atribuído ao cliente',
			notAssignedAgent: 'Agente não atribuído',
			assignedToTaskGroup: 'Agente atribuído a um grupo de tarefas',

			// Profile
			profileTitle: 'MEU PERFIL',
			messageErrorEmail: 'E-mail inválido',
			messageErrorPhone: 'Número de telefone inválido',
			messageFailedUpdatePhone: 'Falha ao atualizar o número de telefone',
			messageEmailAlreadyUsed: 'O email já foi usado por outro super administrador',
			messageErrorUpdateProfile: 'Ocorreu um erro. O perfil não foi atualizado',
			messageUpdatedProfile: 'Salvo com sucesso',

			// StatusFormatter
			taskStatusManaging: 'Gerenciando',
			taskStatusWaittingForAgent: 'Aguardando aceitação do agente',
			taskStatusInProgress: 'Em progresso',
			taskStatusExecuting: 'Executando',
			taskStatusFinish: 'Terminado',
			taskStatusCancel: 'Cancelado',
			taskStatusStartRoute: 'Agente a caminho',
			taskStatusCancelByPartner: 'Cancelado pelo parceiro',
			taskStatusAssignedToPartner: 'Atribuído ao parceiro',
			taskStatusAssignedToTeam: 'Atribuído à equipe',
			taskStatusArrived: 'O agente chegou',
			taskStatusDeleted: 'Excluído',
			taskStatusAutoassigning: 'Auto-atribuição em processo',
			taskStatusAutoassigningSuccess: 'Auto-atribuição foi bem sucedida',
			taskStatusAutoassigningFailed: 'A atribuição automática falhou',

			accountReview: 'Análise de conta',
			findAccount: 'Encontre conta',

			firstName: 'Nome',
			idGroupTask: 'Id',
			userName: 'Nome de Usuário',
			age: 'Idade',
			phone: 'Número de telefone',
			company: 'Companhia',
			companyEditToken:'Selecione a(s) Empresa(s)/Projeto(s) (Obrigatório)',
			searchByCompany: 'Pesquisar por companhia',
			searchByBranch: 'Pesquisa por filial',
			companyBranch: 'Companhia',
			email: 'E-mail',
			emailCompany: 'E-mail Corporativo (exemplo johndoe@company.com)',
			emailCompanyConfirmation: 'Confirmação de E-mail Corporativo',
			emailAccount: 'Conta de e-mail',
			confirmEmail: 'Confirm email',
			plate: 'Placa de carro',
			createTaskTitle: 'CRIAR TAREFA',
			createRecurrentTask: 'Tarefas recorrentes estão sendo criadas em segundo plano',
			createVolumenTask: 'Tarefas de volume estão sendo criadas em segundo plano',
			notifications: 'Notificações',
			recentNotifications: 'Notificações recentes',
			olderNotifications: 'Notificações mais antigas',
			addUser: 'Adicionar usuário',
			typeOfTask: 'Tipo de tarefa',
			select: 'Selecione',
			selectedMasc: 'Selecionados',
			client: 'Cliente',
			companyClient: 'Companhia / Cliente',
			changePhoto: 'Mudar foto',
			crop: 'Cortar',
			chooseOtherImage: 'Escolha outra imagem',
			addPhotoToDisplay: 'Adicione uma foto para exibir',
			priorityDefaultDelete:'As prioridades "Alta", "Média" ou "Baixa" não podem ser removidas',
			priorityDeleteError:'Não é possível excluir prioridade(s) ',

			branch: 'Filial',
			branchAnalytics: 'FILIAL',
			amount:"Montante",
			amountOfServices: 'QUANTIDADE DE TAREFAS',
			amountOfServicesLowerCase: 'Quantidade de tarefas',
			amountOfServicesFinishedByAgent: 'Número de agentes que concluíram um serviço',
			amountOfServicesAnalititcsLowerCase: 'Quantidade de tarefas',
			searchBy: 'Pesquisar por',
			placeHolderClient: 'Nome, sobrenome, o email, endereço',
			placeHolderBranch: 'Id personalizado, nome, empresa, endereço',
			placeHolderBranchByIdCompany: 'Pesquise por ID personalizado, nome ou ID da filial',
			placeHolderCompany: 'Id personalizada, nome',
			placeHolderTeam: 'Id, nome',
			nameBranch: 'Nome',
			nameCompany: 'Empresa',
			nameClient: 'Nome',
			lastNameClient: 'Sobrenome',
			cellphoneClient: 'Telefone celular',
			emailClient: 'O email',
			directionClient: 'Endereço',
			description: 'Descrição',
			high: 'Alta',
			medium: 'Média',
			low: 'Baixa',
			severity_1: 'Alta',
			severity_2: 'Média',
			severity_3: 'Baixa',
			editIsochrone: 'Salve isócrona',
			createIsochrone: 'Criar isócrona',
			canOnlyAddRadiusToCircle: 'Você só pode adicionar um raio a uma isócrona circular',
			chooseDate: 'Escolha uma data',
			executionPeriod: 'Período de execução',
			initialDate: 'Data de início',
			toDate: 'A: ',
			finalDate: 'Data final',
			optional: 'Opcional',
			recurrentTask: 'Tarefa recorrente',
			volumeTask: 'Tarefa de volume',
			repeatFrom: 'Repetir',
			repeat: 'Repetir',
			daily: 'Diariamente',
			weekly: 'Semanal',
			monthly: 'Mensalmente',
			annually: 'Anualmente',
			month: 'Mostrar mês',
			week: 'Semana',
			showWeek: 'Semana do show',
			quarterlyBasis: 'Trimestralmente',
			semiannually: 'Semestralmente',
			dailyMonday_Friday: 'Diariamente (Segunda a Sexta)',

			repeatUntil: 'Repetir até',
			numberofTimes: 'Número de vezes',
			objectiveToCover: 'Objetivo de cobrir',
			time: 'vez',
			times: 'vezes',
			team: 'Equipe',
			byDirection: 'Por direção',
			byCoordinates: 'Por coordenadas',
			partner: 'Parceiro',
			taskStatusModifiedDate: 'Data de modificação do status da tarefa',
			taskStatusModifiedHour: 'Tempo de modificação do status da tarefa',
			taskStatusModifiedCommnet: 'Comentário de modificação do status da tarefa',
			taskStatusModifiedByAdministrator: 'Status da tarefa modificado pelo administrador',
			taskStatusModifiedByManager: 'Status da tarefa modificado pelo gerente',

			assignedPartner: 'Parceiro designado',
			didNotFindTasks:
				'As informações pesquisadas não foram encontradas, selecione um período de tempo ou altere seus parâmetros de pesquisa.',
			partnerWarningAssing: '*Você só pode atribuir tarefas a parceiros com contas ativadas',
			assignTo: 'Atribuir a',
			assignedTo: 'Atribuído a',
			assignedBy: 'Assinado por',
			doNotAssign: 'Não atribua',
			onlyAgentsWitouthTeamMessage:'Somente agentes que não estão em uma equipe são mostrados',

			requiredFields: 'Campos obrigatórios',
			createTask: 'Criar tarefa',
			viewTask: 'Ver tarefa',
			apiUrl: 'Api Url',
			id: 'Id',
			idUserConfig: 'Id do usuário',
			idRoute: 'Id',
			idTaskGroup: 'Id',
			capitalLettersID: 'ID',
			createdDate: 'Data de criação',
			view: 'Visualizar',
			originType: 'Tipo de origem',
			originId: 'ID de origem',
			form: 'Formulário',
			companiesUpperCase: 'COMPANHIA',
			desktop: 'Dashboard',
			myOperation: 'Minha operação',
			commandCenter: 'Centro de Comando',
			calendar: 'Calendário',
			agentsDashboard: 'Agentes_Dashboard',
			taskCompanyDashboard: 'Tarefa Da Empresa Dashboard',
			taskCompanyDashboardSla: 'Tarefa com SLA',
			agents: 'Agentes',
			managers: 'Gerentes',
			administrators: 'Administradores',
			partners: 'Parceiros',
			clients: 'Clientes',
			uppercaseBranches: 'FILIAIS',
			uppercaseCompanies: 'EMPRESAS',
			uppercaseClients: 'CLIENTES',
			typeOfService: 'TIPO DE SERVIÇO',
			typeOfServiceAnalitycs: 'TIPO DE TAREFA',
			typeOfServiceLowerCase: 'Tipo de tarefa',
			branches: 'Filiais',
			activeBranches: 'Filiais ativas',
			inactiveBranches: 'Filiais inativas',
			uppercaseTeams: 'EQUIPES',
			companiesLowerCase: 'Empresas',
			teams: 'Equipes',
			agentTeamList:'Equipe',
			analytics: 'Analítica',
			isochronous: 'Isócrona',
			marketPlace: 'Marketplace',
			account: 'Conta',
			globalDashboard: 'Dashboard Global',
			configuration: 'Configurações',
			help: 'Ajuda',
			users: 'Usuários',
			optinalField: 'Campo opcional',
			addClient: 'Adicionar cliente',
			addBranch: 'Adicionar filial',
			addBranchAndCreateTask: 'Criar ramo com tarefa',
			addTeam: 'Adicionar equipe',
			addPartner: 'Adicionar parceiro',
			addCompany: 'Adicionar Empresa',
			addManager: 'Adicionar gerente',
			noManager: 'Nenhum gerente',
			legalAddress: 'Direção Fiscal',
			showBranchesToUpdateLocation: 'Ver atualização de local pendente',
			businessName: 'Empresa',
			parentCompanyName: 'Empresa-mãe',
			workingHoursFrom: 'Hora de trabalho de',
			workingHoursTo: 'Hora de trabalho para',
			parentCompanyId: 'ID da Empresa-mãe',
			selectParentCompany: 'Selecione uma empresa-mãe',
			selectPlaceHolder: 'Selecione um',
			selectAgentsPlaceHolder: 'Selecionar agentes',
			idCompany: 'Id Empresa',
			idBranch: 'Id Filial',
			branchLogs: 'Histórico de Filial',
			createBranchLog: 'Filial Criada',
			createBranch: 'Criar Filial',
			editBranch: 'Editar Filial',
			editBranchLog: 'Informação de Filial Editada',
			updateLocationBranchLog: 'Localização da Filial atualizada',
			disabledBranchLog: 'Filial desabilitada',
			enabledBranchLog: 'Filial habilitada',
			apiUpdateBranchLog: 'Filial atualizada pela API',
			createBranchAPILog: 'Filial criada pela API',
			createBranchMobileLog: 'Filial criada através de dispositivo móvel',
			importedBranchLog: 'Filial importada',
			versionUpdatesHilighted: 'As atualizações da versão anterior são destacadas em uma cor diferente',
			updateLocationRequestLog: 'Solicitação de atualização de localização',
			deniedLocationRequestLog: 'Atualização de localização negada',
			updated: 'Atualizada',
			branchLocation: 'Localização da filial',
			placeHolderAddDirection: 'Escreva um endereço',
			placeHolderCancelComment: 'Escreva um comentário...',
			addCustomer: 'Adicionar um cliente',
			data: 'Dados',
			header: 'Cabeçalho',
			text: 'Texto',
			fields: 'Campos',
			inventoryAsset: 'Inventário',
			categories: 'Categorias',
			numberID: 'Número de identidade',
			brand: 'Marca',
			title: 'Título',
			modules: 'MÓDULOS',
			functionalitiesUpperCase: 'FUNCIONALIDADES',
			placeHolderForm: 'Nome do formulário',
			continue: 'Continuar',
			add: 'Adicionar',
			functionalities: 'Funcionalidades',
			iconTaskInformation: 'Funcionalidades',
			teamNames: 'Equipes',

			placeHolderInventory: 'Id, nome, marca, modelo ou número de série',
			formUpdateWarning: "Todas as alterações em seus formulários serão refletidas na próxima tarefa criada, realize os testes necessários para que atenda aos seus objetivos. Saiba mais na documentação do Fielder",
			choose: 'Escolher',
			yes: 'Sim',
			no: 'Não',
			accept: 'Aceitar',
			cancel: 'Cancelar',
			showTasks: 'Mostrar tarefas',
			return: 'Retornar',
			delete: 'Excluir',
			close: 'Fechar',
			idAgent: 'Id Agente',
			fillTheRequiredFields: 'Preencha os campos obrigatórios',
			sameCompanyParentError: 'Você não pode selecionar a mesma empresa que a empresa controladora',
			// Marketplace
			marketplace: 'Marketplace',
			taskTypeMkt: 'Personalização de Tarefas',

			pdfAprovedMessage: 'O documento foi aprovado com sucesso',
			pdfApprovedMessageError :'O formulário não pôde ser gerado corretamente, tente novamente',
			pdfCancelMessagePart1: 'O documento não foi aprovado. Edite o formulário clicando no ícone de edição',

			marketplaceGraphTitle: 'Status da sua operação',
			marketplaceYouAreHere: 'Tu estás aqui',
			marketplaceGraphDescription:
				'Você fez suas operações funcionarem com poucos cliques para dispersar suas atividades.',
			connected: 'Conectado',
			connect: 'Conectar',
			buy: 'Comprar',
			administration: 'Administração',
			manage: 'Gerir',
			rol: 'Role',
			rules: 'Regras',
			rule: 'Regra',
			inventoryDescription:
				'Configure e gerencie peças, peças sobressalentes, pacotes, qualquer entrada no processo de suas tarefas no campo',
			formsDescription:
				'Configure formulários de estilos diferentes e inclua-os em diferentes fases do processo de suas tarefas',
			groupTasksDescription:
				'Gerencie um grande volume de atividades no mesmo local, com um ou vários agentes e fácil administração por meio de tags.',
			supportDescription: 'Adquira melhores níveis de atenção do equipe Fielder.',
			consultingDescription:
				'Você precisa de suporte para digitalizar ou integrar algum processo? Nossos especialistas estão prontos para ajudá-lo.',
			storageDescription:
				'Determine o volume de informações necessárias em seu processo (imagens, solicitações, logs, bancos de dados, etc ...)',
			taskTypeDescription:
				'Personalize o nome do serviço ou atividade que seus agentes executarão de acordo com o seu catálogo de serviços reais.',
			assetManagementDescription:
				'Configurar, excluir e gerenciar ativos em campo para gerenciamento do ciclo de vida e identificação de problemas',
			autoassignDescription: 'Atribua tarefas aos seus agentes, parceiros ou equipes de trabalho',
			resourceAllocationDescription: 'Distribua e atribua seus agentes da maneira mais ideal para reduzir custos',
			isochronousDescription:
				'Crie cercas geográficas para garantir o nível de serviço ou cobertura necessário para fornecer a seus clientes',
			skillsDescription: 'Gerencie as habilidades de seus recursos para executar os tipos correspondentes de tarefas ',
			apiDescription: 'Conecte todos os seus sistemas com o Fielder ',
			routeOptimizationDescription:
				'Crie as rotas mais ideais para seus agentes com base nos KPIs que mais afetam sua operação',
			expensesDescription: 'Configure os parâmetros de despesas da sua rota',
			isochroneDescription: 'Defina seus intervalos de cobertura e crie "mapas de calor"',
			functionalityConfirm: 'Deseja adquirir esta funcionalidade?',
			functionalityRequestDescription:
				'Essa funcionalidade possui um valor extra. Entraremos em contato para validar sua solicitação.',
			smsDescription: "Configure seus SMS's de acordo com as regras necessárias em seus negócios.",

			sms: 'SMS',
			// Route Preferences
			routePreferences: 'Preferências de Rota',
			availableTasks: 'Tarefas disponíveis',
			expireOn: 'Expira em',

			// Route Optimization
			optimizeRoute: 'Otimizar rota',
			routeOptimization: 'Otimização de rotas',
			commandCenterDescription:
				'Monitore as variáveis ​​de interesse em tempo real para tomar decisões imediatas e otimizar a execução de sua operação com base no cumprimento de seus objetivos diários.',

			noAgentLocation: 'Sem localização do agente',
			expenses: 'Despesas',
			addExpenses: 'Adicionar despesas',
			enableExpensesRoutesInfo: 'Aplicar despesas à rota',
			expensesAverage: 'Despesas médias por ROUTE',
			expensesFood: 'Alimento',
			expensesSleeping: 'Hospedagem',
			expensesKm: 'Porcentagem a adicionar ao custo estimado de milhagem (entre 1 e 100)',
			// Api
			api: 'API',
			webHook: 'Web Hook',
			apiUsers: 'Usuários da API',
			addApiUser: 'Criar Token de API',
			editApiUser: 'Editar Token de API',
			// Forms
			createForm: 'Criar formulário',
			finishedForms: 'Formulários Preenchidos',

			forms: 'Formulários',
			pdfConverterTitle: 'Crie e converta templates de XLSX para PDF',
			pdfConverterDescription: 'Converta e crie PDF de XLSX',
			formsImages: 'Forma imagens',
			images: 'Imagens',
			templateName: 'Nome do Modelo',
			headerTitle: 'Título do cabeçalho',
			addQuestion: 'Añadir pregunta',
			allowEditableFormLabel: 'Deseja que o formulário seja editável após a assinatura do cliente?',
			onlyOneEditableLabel: 'Só pode haver uma assinatura com esta condição selecionada selecionada',
			option: 'Opção',
			multipleChoice: 'Múltipla escolha',
			checkbox: 'Caixa de verificação',
			radioButton: 'Botão de múltipla escolha',
			radius: 'Raio (metros)',
			textFieldQuestion: 'Campo de texto',
			numericRating: 'Pontuação Numérica',
			binaryRating: 'Pontuação Binária',
			starsRating: 'Pontuação por Estrelas',
			eSignature: 'Assinatura Eletrônica',
			photograph: 'Fotografia',
			qrCode: 'Código QR',
			barcode: 'Código de barras',
			dateHour: 'Data e hora',
			textFile: 'Arquivo de texto',
			catalogue: 'Catálogo',
			footer: 'Rodapé',
			noFormsFound: 'Nenhum formulário foi encontrado em sua conta',
			detailForm: 'Detalhes do formulário',
			editForm: 'Editar formulário',
			uploadTemplateForm: 'Carregar modelo',
			templateHistory: 'Histórico de modelos',
			assistedTemplateText:'Selecione um arquivo no formato pdf para que o assistente do Fielder possa criar seu formulário. O assistente do Fielder pode ajudá-lo com documentos de máximo 5 MB.',
			uploadTemplateFormSuccess: 'Modelo enviado com sucesso',
			deleteForm: 'Excluir formulário',
			deleteConfirmationForm: 'Tem certeza de que deseja excluir o formulário?',
			selectOneFormDelete: 'Selecione pelo menos um formulário para excluir',
			startRange: 'Número inicial',
			endRange: 'Número final',
			characterNumber: 'Número máximo de caracteres',
			chooseDateFormat: 'Escolha o formato da data',
			dateFormatDesc:
				'DD: Dia do Mês, MM: Número do Mês, MMMM: Nome do Mês, YY: Ano com 2 dígitos, YYYY: Ano de 4 dígitos, HH: Horas, mm: Minutos',
			allowAgentEdit: 'Permitir que o agente edite o campo',
			stars: 'Estrela (s)',
			chars150: '150 caracteres',
			chars200: '200 caracteres',
			chars250: '250 caracteres',
			questions: 'Perguntas',
			messagMoreTasksSelected: 'Você selecionou mais de uma tarefa',

			messageYouCanOnlyDuplicateOwnTasks:'Solo pueden duplicarse tareas que hayan sido creadas en tu cuenta',
			messageDuplicateTasksStatusError: 'Apenas tarefas concluídas e pausadas podem ser duplicadas',
			messageDuplicateTasksBranchClientError: 'Você só pode duplicar tarefas da mesma filial ou cliente',
			messagParentTasksError: 'Tarefa selecionada não possui tarefa pai',
			messagDuplicateTasksLimitError: 'O número máximo de tarefas a duplicar é 50',
			messagNoGroup:
				'Esta tarefa não está em um Grupo de Tarefas',
			messageThisTaskIsNotInATaskGroup:"This task is not in a Task Group",
			date: 'Data',
			updateDate:'Data da última atualização',
			formClientNameLabel: {
				client: 'Nome do Cliente',
				branch: 'Nome do Filial',
			},
			formIdCustomLabel: {
				client: 'ID do Cliente',
				branch: 'ID personalizado da Filial',
			},
			downloadImages: 'Download de Imagens',
			selectedForms: 'Formulários selecionados',
			autoSelectedFormsAdvice:
				'Atribuímos automaticamente a você alguns formulários de acordo com a necessidade da sua tarefa',
			assignForms: 'Atribuir Formulários',
			formList: 'Lista de Formulários',
			currentlyAssignedForms: 'Formulários atualmente atribuídos',
			restoreFormsToDefault: 'Restaurar formulários para o padrão',
			formLimitReached: 'Limite de formulários atingido',
			formTableWasNotCreated:
				'A tarefa a seguir não pôde atribuir o formulário porque a conta de origem não possui esse módulo ativado',
			enableGalleryPhotoForms: 'Permitir que o agente faça upload de fotos da galeria',
			enableMetadata: 'Mostrar geolocalização da foto no relatório',
			hideFieldToAgent: 'Ocultar para agente',
			doNotAllowAgentToEdit: 'Não permitir que o agente edite',

			restrictValue:'Restringir valor',
			alphanumericValue: 'Valor alfanumérico',
			numericValue: 'Valor numérico',
			stringValue: 'Valor da sequência de texto',
		
			alphanumericValue: 'Valor alfanumérico',
			formsTemplateSaved: 'Formulário foi salvo com sucesso',
			chooseFile: 'Escolher arquivo',
			selectOrDragAndDrop: 'Arrastre algum arquivo aqui, ou clique para selecionar o arquivo',
			clickToSelectFile: 'Clique para selecionar o arquivo',
			selectImageOrDragAndDrop: 'Arrastre alguma imagem aqui, ou clique para selecionar uma imagem',
			clickToSelectImage: 'Clique para selecionar uma imagem',
			uploadXlsxOrHtmlTemplate: 'Faça o upload de um modelo com extensão .xlsx ou .html',
			formTemplateHistory: 'Histórico do modelo de formulário',
			createFormTemplateAssisted:'Formulário Assistido',
			questionNotation: 'Notação para perguntas',
			assetInventoryQuestionInfo: `Para campos de ativos/inventário personalizados, adicione F antes do id, para perguntas do agente, adicione A (Exemplo: campo com id 1 => {{F1}} pergunta do agente com id 3 => {{A3}})`,
			taskInfoNotation: 'Notação para informações da tarefa',
			taskAddress: 'Endereço da Tarefa',
			taskDate: 'Data da tarefa',
			taskBranchId: 'ID da filial',
			taskBranchIdCustom: 'Id personalizado da filial',
			taskCompanyId: 'ID da empresa',
			taskCompanyName: 'Company Name',
			optionNumber: 'Número da opção',
			onlyXlsxTemplate: 'Somente para o modelo .xlsx',
			allLines: 'Todas as linhas',
			widthInPixels: 'Mudança de largura em px',
			heightInPixels: 'Mudança de altura em px',
			linesPerCell: 'Linhas por célula',
			notAllFormsDeleted: 'Não foi possível excluir todos os formulários',
			deleteInactiveForms: 'Você só pode excluir formulários inativos',
			deleteFormImageConfirmation:
				'Tem certeza de que deseja excluir a imagem? Uma vez excluída, ela não pode ser recuperada',
			deleteFormSignaturesConfirmation:'Confirmar exclusão de assinaturas',
			deleteFormSignaturesExtraMessage:'As boas práticas sugerem a remoção de ambas as assinaturas após qualquer modificação feita',
			reactivateForm: 'Reativar Formulário',
			reactivateFormWarning: 'Se você reativar o formulário, toda a Assinatura Electrônica será removida',
			removeForm: 'Remover Formulário',
			remove: 'Remover',
			removeFormWarning: 'Se você remover o formulário da tarefa, todas as informações serão perdidas',
			duplicate: 'Duplicar',
			duplicateTaskWithUpdates: 'Incluir mudanças',
			duplicateTaskAddAutomatedForms: 'Incluir formulários atribuídos automaticamente',
			showQuestionToPartner: 'Mostrar campo ao parceiro',
			hideQuestionToPartner: 'Ocultar campo ao parceiro',
			partnersInheritableForm: 'Permitir que o formulário seja herdado dos parceiros',
			partnersEditableForm: 'Permitir que os parceiros editem o formulário',
			partnerSettings: 'Configurações do parceiro',
			formsReport:'Relatório de formulários',
			generateFormsReport: 'Gerar Relatório de formulários',
			none:'Nenhum',
			generateFormsReportDescription:
				'Selecione um ou mais formulários e as perguntas do referido formulário para adicioná-los ao relatório',
			selectMoreThanOneForm: 'Adicione mais de um formulário para concluir a solicitação',
			tryAgainLater: 'Tente mais tarde',
			noTaskHasForms: 'Nenhuma tarefa encontrada com os formulários selecionados',
			noTaskHasInventories: 'Nenhuma tarefa foi encontrada com os inventários selecionados',
			noTaskAssets: 'Nenhuma tarefa foi encontrada com os ativos selecionados',
			noTaskSelected: 'Nenhuma tarefa no período selecionado',
			hideToClient: 'Ocultar para o Cliente',
			hideToClientInfo: 'Ocultar perfil Cliente final',
			allowUpdatesViaWebhook:'Permitir atualizações via webhook (opcional)',
			showToClient: 'Mostrar para o Cliente',
			enableClientEditing: 'Ativar a edição do cliente',
			reasonForNotApproved:'Motivo da não aprovação',
			approveQuestion: 'Aprovar pergunta',
			approve: 'Aprovar',
			approveAllQuestions: 'Aprovar todas as perguntas',
			disapproveAllQuestions: 'Desaprovar todas as perguntas',
			viewForm: 'Visualizar o formulário selecionado',
			selected: 'Selecionado',
			addOption: 'Adicionar Opção',
			optionTitle: 'Título da Opção',
			idToMapXlsx: 'Este id é usado para mapear a questão em um arquivo xlsx ao exportar o formulário',
			includeValueColumn: 'Incluir a coluna de valor',
			valueColumnInfo: 'Esta coluna é para dar um valor específico para a opção',
			includeCommentColumn: 'Incluir a coluna de comentário',
			commentColumnInfo: 'Esta coluna é para adicionar qualquer comentário extra à opção',
			value: 'Valor',
			hasNotApprovedForms: 'Formulário(s) não aprovado(s)',
			hasNotApprovedForm: 'Formulário não aprovado',
			AllFormsFinished: 'Formulários finalizados',
			inReviewForms: 'Formulário(s) aguardando revisão',
			inReviewForm: 'Formulário aguardando revisão',
			chooseFormOrigin: 'Escolha a origem do formulário',
			selectPartnerFormOrigin: 'Selecionar fonte de formulário de parceiro',
			source: 'Fonte',
			webhook: 'Webhook URL',
			apiToken: 'Token de API',
			updateByHook:'Atualização por Webhook',
			partnerSpecificOptions: 'Opções específicas de parceiro',
			integratedPartnerInfoViewWarning:
				'O parceiro selecionado foi integrado por esta conta, portanto, a informação mostrada é o que você permitiu que o parceiro visse em sua conta',
			enableFormReview: 'Habilitar revisão de formulário',
			runApprovalViaApi: 'Executar revisão via API',
			formReviewableApiInfo: 'A aprovação de formulários através da API é feita através do webhook',
			hideClientSwitchInfo: 'Quando a chave estiver ativada, este tipo de tarefa não será mostrada ao perfil do cliente final',
			hideClientSwitchInfoPriority: 'Quando a chave estiver ativada, esta prioridade não será mostrada ao perfil do cliente final',
			receiveUpdatesThroughTheWebhook:'Ao fazer uma solicitação através da API você pode receber uma atualização através do webhook',
			taskTypeInfoApplyToAll: 'Este tipo de tarefa será mostrado a todas as empresas, filiais e clientes finais',
			taskPriorityApplyToAll: 'Esta prioridade será mostrada a todas as empresas, filiais e clientes finais',
			taskTypeInfoSpecify: 'Este tipo de tarefa será mostrado apenas para as empresas, filiais e clientes finais especificados',
			taskPrioirtyInfoSpecify: 'Esta prioridade será mostrada apenas para as empresas, filiais e clientes finais especificados',
			userApiApplyToAll: 'Este usuário não receberá um URL de webhook especificado e as atualizações serão recebidas no URL padrão',
			userApiInfoSpecify:'Este usuário receberá apenas atualizações vinculadas à sua empresa e no webhook definido.',
			webhookInfoSpecify:'URL através da qual chegam as atualizações feitas dentro da plataforma',
			formReviewableInfo: 'Permitir que as perguntas do formulário sejam revisadas antes de concluí-lo',
			formDownloadInfo:'Os formulários serão baixados em qualquer status nos formatos Fielder PDF e Excel.',
			formApprovedDownloadInfo:'Os PDFs que serão baixados correspondem aos Formulários preenchidos ou, se for o caso, aos que passaram por processo de aprovação',
			improveDownloadExperienceText:'Para melhorar sua experiência, recomendamos fazer download usando filtros e períodos curtos de tempo. Para grandes volumes de download, recomendamos usar a API Fielder.',
			selectPeriodOfTime: 'Selecione um período de tempo para exportar as informações.',
			
			slaInformation: 'Essas são as tarefas em que o Agente não chegou ao local no horário agendado ou estão pausadas.',
			noPermission: 'Sem permissões',
			partnerCanEdit: 'O parceiro pode editar',
			partnerCanView: 'O parceiro pode visualizar',
			partnerPermissions: 'Permissões de parceiro',
			reviewable: 'Revisável',
			numberFields: 'Número de campos',
			updatedFormsSuccessfully: 'Formulários atualizados com sucesso',
			anyOrigin: 'Qualquer origem',

			idForm: 'Id de formulário',
			textForm:'Palavra-chave',
			// Asset Management
			assetList: 'Lista de Ativos',
			selectedAssets: 'Ativos selecionados',
			downloadAssetFileName: 'assetsFielder.csv',
			downloadCSVTemplate: 'Faça o download do modelo .csv do arquivo de importação',
			downloadCSVTemplateCategory: 'Faça o download do modelo .csv do arquivo de importação para esta categoria',
			model: 'Modelo',
			serialNumber: 'Número de série',
			newCategory: 'Nova categoria',
			editCategory: 'Editar categoria',
			defaultFields: 'Campos de modelo padrão',
			selectDefaultFields: 'Selecione os campos que você deseja que seja necessário',
			idCategory: 'ID da categoria',
			parentCategory: 'Categoria Parental',
			assetManagement: 'Gestão de ativos',
			noAssetsFound: 'Nenhum ativo foi encontrado na categoria selecionada',
			noCategoriesFound: 'Você não tem categorias',
			assetStatus: 'Status',
			active: 'Ativo',
			activeF: 'Ativas',
			activeM: 'Ativos',
			dashboardInactive: 'Inativo',
			inactive: 'Inativo',
			addSubCategory: 'Adicionar subcategoria',
			asset: 'Ativo',
			assets: 'Ativos',
			assetDetail: 'Detalhe do Ativo',
			addField: 'Adicionar campo',
			addAsset: 'Adicionar ativo',
			editAsset: 'Editar ativo',
			newAsset: 'Novo ativo',
			disableAsset: 'Desativar ativo(s)',
			isTheAssetPacked: 'O ativo está em uma categoria específica?',
			addBarcode: 'Adicionar um código de barras',
			productCondition: 'Condição do ativo',
			goodCondition: 'Em boa condição',
			badCondition: 'Em má condição',
			create: 'Criar',
			newTemplate: 'Novo Modelo',
			editTemplate: 'Editar Modelo',
			addTemplate: 'Adicionar Modelo',
			deleteConfirmationAsset: 'Tem certeza de que deseja excluir o Ativo?',
			disableConfirmationAsset: 'Tem certeza de que deseja desativar o Ativo?',
			deleteConfirmationCategory: 'Tem certeza de que deseja excluir a Categoria?',
			changeStatus: 'Alterar Status',
			question: 'Pergunta',
			singleAnswerLabel: 'Esta pergunta só pode ser respondida uma vez',
			agentQuestionLabel: 'Campos de agente',
			selectAtLeastOne: 'Selecione pelo menos um ativo para realizar a operação',
			good: 'Bem',
			bad: 'Mal',
			satisfactory: 'Satisfatório',
			notSatisfactory: 'Não satisfatório',
			assetImportSuccess: 'Ativos importados com sucesso',
			assetImportError: 'Não foi possível importar todos os ativos inseridos',
			errorAssetMustHaveBranch:
				'Você não pode atribuir um ativo á sua tarefa porque ele não está vinculado a uma filial',
			assetCategoryHasSubcategoryOrAsset: 'A categoria contém mais de uma subcategoria ou ativo',
			january: 'Janeiro',
			february: 'Fevereiro',
			march: 'Março',
			april: 'Abril',
			may: 'Maio',
			june: 'Junho',
			july: 'Julho',
			august: 'Agosto',
			september: 'Septembro',
			october: 'Outubro',
			november: 'Novembro',
			december: 'Dezembro',
			noFileFound: 'Nenhum arquivo encontrado',
			viewFileContent: 'Ver o conteúdo do arquivo',
			assetLogs: 'Histórico de ativos',
			assetSavedSuccessfully: 'O ativo foi salvo com sucesso',
			viewAsset: 'Ver o ativo selecionado',
			createTaskWithAsset: 'Salvar e criar tarefa com ativo',
			downloadAssets: 'Baixar Ativos',
			generateAssetReport: 'Gerar relatório de ativos',
			generateAssetReportDescription:
				'Selecione um ou mais activos e as perguntas desse ativo para adicionar ao relatório',
			canOnlyAddOrRemoveFieldsNotEdit:
				'Os campos só podem ser adicionados ou removidos, a funcionalidade de edição está desabilitada',

			// Inventory
			inventory: 'Inventário',
			deleteConfirmationInventory: 'Tem certeza de que deseja excluir o inventário?',
			downloadInventoryFileName: 'inventoriesFielder.csv',
			noInventoryFound: 'Nenhum inventário encontrado nessa categoria',
			addInventory: 'Adicionar inventário',
			newInventory: 'Novo inventário',
			editInventory: 'Editar inventário',
			inventoryLogs: 'Histórico de inventário',
			adminInventory: 'Gestão de Inventário',
			inventories: 'Inventários',
			inventoriesAssociated:'Inventários associados ao ativo',
			linkWithAsset: 'Vincular inventário a um ativo',
			selectedInventories: 'Inventários Selecionados',
			inventoryCategoryHasSubcategoryOrInventory: 'A categoria contém mais de uma subcategoria ou inventário',
			inventorySavedSuccessfully: 'O inventário for salvo com sucesso',
			viewInventory: 'Ver inventário selecionadoo',
			createTaskWithInventory: 'Criar tarefa com inventário',
			downloadInventories: 'Baixar Inventários',
			generateInventoryReport: 'Gerar relatório de inventário',
			generateInventoryReportDescription:
				'Selecione um ou mais inventários e as perguntas desse inventário para adicionar ao relatório',
			inventoryImportSuccess: 'Inventários importados com sucesso',

			// AssetInventory
			customStatus: 'Status personalizado',
			manageCustomStatus: 'Gerenciar Status Personalizados',
			assetAssignedToTaskStatus: 'Status do ativo quando é atribuído a uma tarefa',
			inventoryAssignedToTaskStatus: 'Status do inventário quando é atribuído a uma tarefa',
			noStatus: 'Sem Status',
			toRemoveTheElement: 'Para remover o elemento, selecione-o novamente',
			dontChange: 'Não mude',
			selectStatus: 'Selecione o status',
			statusChangeWhenAssetInTask:
				'O status do ativo mudará automaticamente para a opção selecionada quando for adicionado a uma tarefa',
			statusChangeWhenInventoryInTask:
				'O status do inventário mudará automaticamente para a opção selecionada quando adicionado a uma tarefa',
			statusDisabledForTasks: 'Status desabilitados para tarefas',
			assetsWithSelectedStatusDisabledFromTasks:
				'Os ativos com o status selecionado não poderão ser adicionados às tarefas',
			inventoryWithSelectedStatusDisabledFromTasks:
				'Os inventários com o status selecionado não poderão ser adicionados às tarefas',
			optionToSelect: 'Opção  para selecionar',
			optionsToSelect: 'Opções para selecionar (separadas por "&" )',
			optionsCatalogue: 'Opções para selecionar (id##valor##comentário separated by &)',
			numberInsideRatingValue: 'Valor numérico dentro do intervalo definido na categoria',
			positiveOrNegative: 'Insira 1 para a opção positiva 0 para a opção negativa',
			starsRatingRange: 'Avaliação de 0 a 5',
			notAvailableForUpdate: 'Not available to update',
			notAvailableForImport: 'Não disponível durante a importação',
			insertBarcode: 'Insira o código de barras',
			insertDateInFormat: 'Insira a data no formato DD/MM/AAAA ou DD/MM/AAAA HH:MM',
			insertDateInFormFormat:'Insira a data no formato indicado no cabeçalho csv',
			updateFormInfo: 'Informações para atualizar perguntas do formulário',
			importingInfo: 'Informações para o processo de importação',
			incorrectCsvFormatAssets:
				'Não foi possível importar os ativos: verifique se o seu csv tem o formato correto e não está vazio',
			incorrectCsvFormatInventory:
				'Não foi possível importar inventários: Verifique se o seu csv está no formato correto e não está vazio',
			couldNotImportFollowingAssets: 'Não foi possível importar os seguintes ativos',
			couldNotImportFollowingInventories: 'Não foi possível importar os seguintes inventários',
			showingLatestLogInfo: 'Mostrando as últimas informações do histórico',
			logAction: ['Atualizado', 'Atualizado pelo agente', 'Atualizado pelo admin', 'Ativo atribuído à tarefa' ],
			onlyShowingTheLast10Logs: 'Mostrando apenas os últimos 10 registros',
			thereIsAnUnfinishedService:"Há um serviço inacabado",

			// auto assign
			geolocationLevel: 'Tipo de nível',
			serviceLevel: 'Nível de serviço',
      serviceLevelPersonalized: 'Nível de serviço personalizado',
			autoassignOrigin: 'Origem da auto-atribuição',
			autoassignType: 'Tipo de auto-atribuição',
			isochroneClientErrorMessage:
				'Para auto-atribuição, ao criar um modelo, você não pode criar outro modelo com o mesmo isócrono e a mesma origem',
			deleteTemplateConfirmation: 'Tem certeza de que deseja excluir o(s) modelo(s) selecionado(s)?',
			deleteTemplateTooltip: 'Excluir modelo(s)',
			createTemplate: 'Criar modelo',
			saveTemplate: 'Salvar modelo',
			noTemplatesFound: 'Nenhum modelo encontrado',
			autoassign: 'Atribuição automática',
			autoassignation: 'Auto-atribuição',
			skills: 'Habilidades',
			skillsList: 'Lista de habilidades',
			createSkills: 'Crie novas habilidades',
			responseTimePriority: 'Tempo de resposta por prioridade',
			agentSkills: 'Habilidades do agente',
			addedRepeatedSkill: 'Essa habilidade já existe',
			skillsToAdd: 'Insira uma nova habilidade',
			deleteConfirmationSkill: 'Tem certeza de que deseja excluir a habilidade?',
			unexpectedErrorOcurred: 'Um erro inesperado ocorreu. Por favor, tente novamente',
			createUserError: 'Não foi possível criar o usuário, verifique se as informações estão corretas e tente novamente. (Lembre-se que o limite para nome e sobrenome é de 150 caracteres)',
			createTeamError: 'Não foi possível criar a equipe, tente novamente',
			createCompanyError: 'Não foi possível criar a empresa, tente novamente.',
			createBranchError: 'Não foi possível criar o ramo, tente novamente',
			addAutoassignTemplate: 'Adicionar modelo de atribuição automática',
			viewAutoassignTemplate: 'Ver modelo de atribuição automática',
			templates: 'Modelos',
			likeToAddPartner: 'Deseja atribuir a tarefa a um parceiro?',
			autoassignTo: 'Atribuir automaticamente a',
			createAutoassignTemplate: 'Criar modelo de atribuição automática',
			editAutoassignTemplate: 'Editar modelo de atribuição automática',
			byPriority: 'Cor do marcador por prioridade',
			byStatus: 'Cor do marcador por status',
      personalizedPriorities: 'Prioridades personalizadas',
			priority: 'Prioridade',
			idPriority: 'Id Prioridade',
			lowPriority: 'Baixa',
			medPriority: 'Média',
			highPriority: 'Alta',
			coverage: 'Cobertura',
			viewIsochronous: 'Ver Isócrono',
			viewSkills: 'Ver Habilidades',
			applyToAll: 'Aplicar a todos',
			apply: 'Aplique',
			addFilter: ' + Adicionar filtro',
			selectSpecificAgents: 'Seleccionar agentes específicos',
			selectAgents: 'Seleccionar agentes',
			selectSpecificTeams: 'Seleccionar equipes específicos',
			selectTeams: 'Seleccionar equipes',
			selectSpecificPartners: 'Seleccionar parceiros específicos',
			selectPartners: 'Seleccionar parceiros',
			resources: 'Recursos',
			selectTaskTypes: 'Selecione o tipo de tarefa',
			responseTime: 'Tempo de Resposta',
			immediateResponse: 'Resposta imediata',
			successCounter: 'Veces usado exitosamente',
			useCounter: 'Veces utilizado',
			infoAutoassignType: 'Dependendo do que você gostaria de auto-atribuir',
			autoassignDetail: 'Detalhe da atribuição automática',
			failureReason: 'Motivo del Falha',
			selectResourcesToAutoassign:
				'Selecione os recursos que você gostaria auto-atribuir às tarefas. Arraste o cartão de tipo de recurso para determinar a prioridade durante o processo de atribuição automática',
			dragAndDropForAutoassignPriority: 'Arraste os recursos ativos para atribuir uma ordem de prioridade',
			autoassignTemplateSaved: 'O modelo de atribuição automática foi salvo com sucesso',
			templateDeletedSuccessfully: 'Modelo(s) removido(s) com sucesso',
			configureResponseTimePerPriority:
				'Configurar o tempo de resposta para que o agente esteja no local da tarefa antes do início da tarefa, de acordo com a prioridade',
			configureResponseTimePerPriorityInformation: 'Prazo para garantir que o agente chegue cedo ao local da tarefa',
			color: 'Cor',
			updatedTemplatesSuccessfully: 'Modelos atualizados com sucesso',
			successRate: 'Taxa de sucesso',
			assignBySkills: 'Aplicar por habilidades',
			errorCouldntRetrieveTemplate: 'Não foi possível recuperar o modelo selecionado neste momento. Tente mais tarde',
			errorFieldNotEmpty: 'este campo não deve estar vazio',
			errorMustSelectAtLeastOneOption: 'Você deve selecionar pelo menos uma opção',
			errorMustHaveAtLeastOneActiveResource: 'Você deve ativar pelo menos um recurso',
			errorMustHaveAtLeastOneActivePriority: 'Você deve ativar pelo menos um prioridade',
			templateSavedSuccessfully: 'O modelo foi salvo com sucesso',

			unselectAll: 'Desmarque todos',
			// Status
			executingAutoassign: 'Executando',
			successful: 'Bem sucedido',
			unsuccessful: 'Malsucedido',
			waiting: 'Esperando',
			unknown: 'Desconhecido',

			noAgentsAvailable: 'Nenhum agente disponível foi encontrado para executar tarefa',
			noAgentsWithSkills: 'Nenhum agente foi encontrado com as habilidades necessárias para executar a tarefa',
			availableAgentsInMultipleTeams: 'Os agentes disponíveis foram encontrados em várias equipes',
			agentInMultipleTeams: 'Todos os agentes disponíveis foram encontrados em várias equipes',
			retrying: 'Tentando novamente',

			dashboardPausedTasks: 'Dashboard Tarefas Pausadas',
			dashboardRejectedTasks: 'Dashboard Tarefas Rejeitadas',

			// Task Types - Task Personalizaton
			taskPersonalization: 'Personalização de Tarefas',
			rejectedTaskPersonalization: 'Personalização de Tarefas Rejeitadas',
			pausedTaskPersonalization: 'Personalizando Tarefas Pausadas',
			prioritiesPersonalization:'Personalizando Prioridades',

			addRejectedTask: 'Adicionar motivo para tarefas rejeitadas',
			addPausedTask: 'Adicionar motivo para tarefas pausadas',
			addPriority:'Adicionar Prioridade',
			addTaskType: 'Adicionar tipo de tarefa',
			reasonName: 'Nome do motivo',
			priorityName:'Nome de prioridade',
			addReasonRejectTaskTitle: 'Adicionar motivo para tarefas rejeitadas',
			editReasonRejectTaskTitle: 'Editar motivo da rejeição',
			addReasonPauseTaskTitle: 'Adicionar motivo para tarefas pausadas',
			editReasonPauseTaskTitle: 'Editar motivo da tarefa pausada',
			addPriorityTaskTitle: 'Adicionar Prioridade',
			editPriorityTaskTitle: 'Editar Prioridade',

			hideToAgent: 'Não mostre esse tipo de tarefa ao agente',
			showToAgent: 'Mostre esse tipo de tarefa ao agente',
			showAgentType: 'Mostrar ao agente',
			specifyCompany: 'Especifique a empresa',
			specifyBranch: 'Especifique a filial',
			specifyClient: 'Especifique o cliente',
			editTaskType: 'Editar tipo de tarefa',
			taskTypeName: 'Nome do tipo de tarefa',
			specificTime: 'Especifique o tempo de execução',
			specifyWebhookUrl: 'Insira seu endpoint/url para o webhook (opcional)', 
			apiRequestAllowed: "Solicitações de API permitidas ao usuário:",
			rice: 'Preço',
			selectDependency: 'Selecionar dependência',
			taskPauseDependency: 'Dependência de pausa de tarefa',
			dependency: 'Dependência',
			pausedByFinalClient: 'Pausado pelo Cliente Final',
			pausedByAgent: 'Pausado pelo agente',
			rejectedByFinalClient: 'Rejeitado pelo Cliente Final',
			rejectedByAgent: 'Rejeitado pelo agente',
			selectParent: 'Selecione pai',
			taskTypeConstraintDesc: 'Aplique apenas a (opcional)',
			userApiInfoConstraintDesc: 'Selecione a opção:',
			selectTaskTypeId: 'Seleccione o id do tipo de tarefa',
			taskVariableNumber: 'Número',
			taskVariableTime: 'Tempo',
			taskTypesNotDeleted: 'Não foi possível excluir os seguintes tipos de tarefas: ',
			taskTypesCantBeDeleted: 'Não pode ser excluído',
			taskTypesDeletedWithChildren: 'Tem pelo menos um subtipo',
			taskTypesDeletedWithTasks: 'Alguma tarefa foi criada com este tipo de tarefa',
			taskTypesDeletedSuccess: 'Os seguintes tipos de tarefas foram excluídos com sucesso: ',
			taskTypeAddSuccess: 'Foi salvo com sucesso',
			taskTypeInvalidRouteVariable: "Somente uma variável do tipo 'Número' pode ser selecionada",
			infoTaskTypeRouteVariable:
				'Defina variáveis ​​por tipo de tarefa para obter melhores resultados ao gerar rotas com Alocação de Recursos ou Alocação Dinâmica',
			infoTaskTypeRouteVariableType:
				'Tempo: será adicionado ao tempo total de execução da tarefa; Numérico: será multiplicado pelo tempo de execução da tarefa',
			enableSearch: 'Habilitar la busqueda',

			globalSearchMessage:
				'Os resultados de 3 meses antes e depois da data de pesquisa atual são exibidos. Recomendamos que você use os filtros avançados de Minha Operação para uma busca mais precisa.',

			// support
			messageCreated: 'O tíquete foi criado',
			sendTicket: 'Enviar',
			messageFormatFiles: 'Verifique se os arquivos estão em: .jpg, .png, .gif, .jpeg, .csv, .xls ou .pdf',
			messageNumberFiles: 'Desculpe, você não pode enviar mais de 5 arquivos',
			titleSupport: 'Suporte',
			category: 'Categoria',
			webPlatform: 'Plataforma Web',
			movilApp: 'Aplicativo móvel',
			mailsSMS: 'Correio / SMS',
			subscription: 'Inscrição',
			training: 'Treinamento',
			other: 'Outro',
			updateFileMessage: 'Lembre-se de que, ao atualizar o link ou arquivo, ele é sobrescrito, deseja continuar?',
			updateTemplateMessagePdf:
				'Ao atualizar o arquivo, este substituirá o PDF do template aprovado. Você quer atualizar?',
			externalLink: 'Link externo',
			modifyStatus: 'Modificar status',

			deleteFileMessage: 'Você deseja excluir o arquivo de tarefa?',
			deleteLinkMessage: '¿Deseja remover o link vinculado à tarefa?',

			externalStorage: 'Vá para o armazenamento externo',
			updateLink: 'Atualizar link',
			uploadLink: 'Link de upload',
			linkDocument: 'Link do documento',
			fileName: 'Nome do arquivo',
			link: 'Link',
			uploadFile: 'Subir arquivo',
			uploadFiles: 'Subir arquivos',
			updateFile: 'Atualizar arquivo',
			pressEnterToSearch:'Pressione enter para pesquisar',

			geolocationDesciptionType0:
				'Esta configuração permite a geolocalização (latitude e longitude) dos agentes apenas nas etapas de “início” e “finalização” de suas atividades, os agentes poderão receber tarefas no aplicativo Fielder Agent, sem coletar informações como localização, rotas, ETA. Essa configuração é recomendada em operações não dinâmicas, onde há recursos dedicados, no local, em locais pré-determinados, sendo necessária apenas a colaboração em tempo real com o agente e a coleta de evidências da execução do tarefas atribuídas.',
			geolocationDesciptionType1:
				'Essa configuração permite a geolocalização (latitude e longitude) dos agentes, equilibrando o desempenho da bateria móvel do agente e do plano de dados. O aplicativo Fielder Agent obtém informações de localização entre a cada 30 segundos (aprox. quando há deslocamento do agente) e a cada 5 minutos (aprox. quando não há deslocamento). Em dispositivos com Android 9.0 ou inferior, o aplicativo Fielder Agent em primeiro e segundo plano terá o comportamento mencionado. Nas versões do Android a partir de 10.0, você obtém a localização apenas em primeiro plano. As variáveis ​​podem ser modificadas quando o nível da bateria está baixo no celular. Esta configuração é recomendada quando a operação é dinâmica, existem agentes dispersos atendendo a diferentes tarefas em diferentes locais, e é necessário colaborar em tempo real com o agente e coletar informações sobre locais, rotas, ETA e evidências da execução das tarefas atribuídas.',
			geolocationDesciptionType2:
				'Essa configuração permite a geolocalização (latitude e longitude) dos agentes com alto consumo entre a bateria móvel do agente e o plano de dados. O aplicativo Fielder Agent obtém informações de localização entre 2 minutos e meio (aprox. quando há deslocamento do agente) e a cada 5 segundos (aprox. quando não há deslocamento). Em dispositivos com Android 9.0 ou inferior, o aplicativo Fielder Agent em primeiro e segundo plano terá o comportamento mencionado. Nas versões do Android a partir de 10.0, apenas a localização é obtida em primeiro plano e em segundo plano, desde que o Agente conceda a permissão correspondente no aplicativo. As variáveis ​​podem ser modificadas quando o nível da bateria está baixo no celular. Esta configuração é recomendada quando a operação é extremamente dinâmica e com alta demanda de tarefas, existem agentes dispersos atendendo diferentes tarefas em diferentes locais, sendo necessária a colaboração em tempo real com o agente e usuários finais para fornecer uma experiência "On-Demand”.',

			requiredTaskType: 'Altere o tipo de tarefa diferente de "Por correio" para continuar',
			requiredTeam: 'Selecione uma equipe para continuar',

			createdTaskBy: 'Tarefa criada por',

			DO_ACTION_UNKNOWN: ' ',
			DO_ACTION_CREATE_GROUP: 'Grupo de tarefas criado',
			DO_ACTION_SET_ON_WAY: 'Grupo de tarefas a caminho',
			DO_ACTION_ARRIVE: 'O grupo de tarefas chegou',
			DO_ACTION_STARTED: 'Grupo de tarefas iniciado',
			DO_ACTION_PAUSE: 'Grupo de tarefas pausado',
			DO_ACTION_FINISH: 'Grupo de tarefas concluído',
			DO_ACTION_CANCELED: 'Grupo de tarefas cancelado',
			DO_ACTION_TRASH_GROUP: 'Grupo de tarefas desativado',
			DO_ACTION_DELETE_GROUP: 'Grupo de tarefas excluído',
			DO_ACTION_CANCEL_TRASH_GROUP: 'Grupo de tarefas cancelado',
			DO_ACTION_ASSIGN_AGENT: 'Agente designado',
			DO_ACTION_RESCHEDULE: 'Grupo de tarefas reagendado',
			DO_ACTION_UPDATE_ADDRESS: 'Mudança de endereço',
			DO_ACTION_UPDATE: 'Grupo de tarefas atualizado',
			DO_ACTION_ASSIGN_TASK: 'Tarefa atribuída',
			DO_ACTION_TRASH_GROUP_TASK: 'Tarefa desativada',
			DO_ACTION_UNLINK_TASK_FROM_GROUP: 'Tarefa desvinculada da grupo',
			DO_ACTION_UPDATE_BRANCH:'Filial atualizada',

			DO_ACTION_CREATE_TASK: 'Tarefa Criada',
			DO_ACTION_UPDATE_TASK: 'Tarefa Atualizada',
			DO_ACTION_UPDATE_ADDRESS_TASK: 'Local da Tarefa Atualizado',
			DO_ACTION_RESCHEDULE_TASK: 'Tarefa reagendada',
			DO_ACTION_ASSIGN_AGENT_TASK: 'Atribuído ao agente',
			DO_ACTION_ASSIGN_TEAM_TASK: 'Atribuído à equipe',
			DO_ACTION_ASSIGN_PARTNER_TASK: 'Atribuído ao parceiro',
			DO_ACTION_CANCEL_TASK: 'Tarefa cancelada',
			DO_ACTION_CANCEL_PARTNER_TASK: 'Cancelado pelo parceiro',
			DO_ACTION_RATE_TASK: 'Tarefa classificada',
			DO_ACTION_RESTART_TASK: 'Tarefa reiniciada',
			DO_ACTION_TRASH_TASK: 'Tarefa desativada',
			DO_ACTION_DELETE_TASK: 'Tarefa removida',
			DO_ACTION_CANCEL_TRASH_TASK: 'Tarefa restaurada',
			DO_START_TASK_AGENT: 'Tarefa iniciada pelo agente',
			DO_ARRIVE_TASK_AGENT: 'Agente chegou',
			DO_ON_WAY_TASK_AGENT: 'Agente a caminho',
			DO_REJECT_TASK_AGENT: 'Tarefa rejeitada pelo agente',
			DO_FINISH_TASK_AGENT: 'Tarefa concluída pelo agente',
			DO_CANCEL_TASK_AGENT: 'Tarefa cancelada pelo agente',
			DO_ACCEPT_TASK_AGENT: 'Tarefa aceita pelo agente',
			DO_ADD_COMMENT: 'Comentário criado pelo agente',
			DO_ACTION_ASSIGN_GROUP: 'Grupo de tarefas atribuído',
			DO_ACTION_REFUSE_GROUP:'Tarefa desvinculada da grupo',
			DO_ACTION_PAUSED_TASK: 'Tarefa pausada',
			DO_ACTION_AUTOASSIGNING_STARTED: 'Processo de auto-atribuição iniciado',
			DO_ACTION_AUTOASSIGNING_FAILED: 'O processo de auto-atribuição falhou',
			DO_ACTION_ASSIGN_FORM: 'Formulário atribuído à tarefa',
			DO_ACTION_ASSIGN_ASSET: 'Ativo atribuído à tarefa',
			DO_ACTION_ASSIGN_INVENTORY: 'Inventário atribuído à tarefa',
			DO_ACTION_UNLINK_TASK: 'Tarefa desvinculada da rota',
			DO_ACTION_RESET_EXECUTION_TASK: 'execução reiniciada',
			DO_ACTION_RESET_CANCELED_TASK: 'Tarefa redefinida',
			ADD_TASK_TO_ROUTE: 'Tarefa adicionada à rota',
			DO_ACTION_REMOVE_TASK_FROM_ROUTE: 'Tarefa excluída de uma rota',
			DO_ACTION_ADD_TASK_TO_ROUTE: 'Tarefa adicionada à rota',
			DO_ACTION_UPDATED_FORM: 'Formulário salvo',
			DO_ACTION_UPDATED_ASSET: 'Ativo foi modificado',
			DO_ACTION_UPDATED_INVENTORY: 'Inventário foi modificado',
			DO_TAKE_TASK_ON_GROUP:'Tarefa assumida pelo agente',

			DO_ACTION_UPLOAD_TASK_DOCUMENT: 'Documento anexado à tarefa',
			DO_ACTION_DELETE_TASK_DOCUMENT: 'Documento removido da tarefa',
			DO_ACTION_UPDATE_TASK_DOCUMENT: 'Documento de tarefa atualizado',

			DO_ACTION_UPLOAD_TASK_LINK_DOCUMENT: 'Link anexado à tarefa',
			DO_ACTION_DELETE_TASK_LINK__DOCUMENT: 'Link removido da tarefa',
			DO_ACTION_UPDATE_TASK_LINK__DOCUMENT: 'Link de tarefa atualizado',

			DO_ACTION_FINISH_FORM:'Formulário finalizado',
			DO_ACTION_REVIEW_FORM: 'Formulário enviado para revisão',
			DO_ACTION_APPROVED_FORM:'Formulário aprovado',

			DO_ACTION_UPLOAD_TASK_PDF_FORM: 'PDF do formulário aprovado',
			DO_ACTION_UPDATE_TASK_PDF_FORM: 'PDF do formulário aprovado atualizado',
			DO_ACTION_ADMIN_MODIFY_TASK: 'Status da tarefa modificada pelo administrador',
			DO_ACTION_MANAGER_MODIFY_TASK: 'Status da tarefa modificada pelo gerente',
			DO_ACTION_SEND_FORM_BY_MAIL:'Agente enviou formulários por correio',

			CREATED: 'Criado',
			ON_WAY: 'Agente a caminho',
			ARRIVE: 'Chegou o agente',
			EXECUTING: 'Executando',
			PAUSE: 'Pausada',
			FINISH: 'Acabada',
			CANCELED: 'Cancelada',
			ON_TRASH: 'Desativado',

			finish: 'Concluir',
			continue: 'Continuar',
			back: 'Voltar',
			documentation:'Documentação',
			businessIntelligence:"BI",

			taskType: 'Tipo de tarefa',
			tasktypeMinus1: 'Remoto',
			tasktipeMail: 'Por email',
			tasktype0: 'Tarefas de volume',
			tasktype1: 'Coleção',
			tasktype2: 'Serviço de equipe de campo',
			tasktype3: 'Entrega',
			tasktype4: 'Tarefa em Grupo',
			tasktypeAll: 'Todos eles',
			volume: 'Tarefas de volume',
			remote: 'Remoto',
			pickup: 'Coleção',
			workforce: 'Serviço de equipe de campo',
			delivery: 'Entrega',
			grouptask: 'Tarefa em Grupo',
			tasktupeSimple: 'Em Grupo',
			unassigned: 'Não atribuído',
			days: 'dias',
			hours: 'horas',
			minutes: 'minutos',
			seconds: 'segundos',
			hour: 'hora',
			minute: 'minuto',
			second: 'segundo',
			reorderByModifyStatus: 'Organizar por mudança de status',
			reorderBySignature:'Organizar por falta de assinatura',
			activity_type_0: 'Rota criada',
			activity_type_1: 'Agente atribuído',
			activity_type_2: 'Rota aceita',
			activity_type_3: 'Rota executada',
			activity_type_4: 'Rota cancelada',
			activity_type_5: 'Rota concluída',
			activity_type_6: 'Rota rejeitada',
			activity_type_7: 'Rota reorganizada',
			activity_type_8: 'Incidência criada',
			activity_type_9: 'Rota remarcada',
			activity_type_10: 'Tarefa excluída',
			activity_type_11: 'Tarefa adicionada',
			label_position_add_task: 'Posição da tarefa',
			// TASKINFO
			numTask: 'Número da tarefa',

			disabled: 'desativado',

			deleteCompanies: 'Excluir empresa(s)',
			deactivateCompanies: 'Desativar empresa(s)',
			enableCompanies: 'Ativar empresa(s)',

			deleteBranches: 'Excluir filial / filiais',
			disabledBranches: 'Desativar filial / filiais',
			enableBranches: 'Ativar filial / filiais',
			enableTask: 'Ativar tarefa(s)',
			duplicateTask: 'Tarefa duplicada',

			deleteTeams: 'Excluir equipe(s)',
			deactivateTeams: 'Desativar equipe(s)',
			enableTeams: 'Ativar equipe(s)',

			assistedFormError: 'O formato PDF não é adequado, tente novamente.',
			assistedFormSuccess:'O documento foi digitalizado. Nossa inteligência artificial criou para você um formulário e modelo Excel com a estrutura de perguntas sugerida. Revise esta estrutura e mapeamento no modelo. Você pode ajustar os diferentes tipos de perguntas que o Fielder oferece de acordo com suas necessidades específicas.',

			cancelPartnership: 'Cancelar parceria',
			deleteClients: 'Excluir cliente(s)',
			deactivateClients: 'Desativar cliente(s)',
			enableClients: 'Ativar cliente(s)',

			deactivateAdministrators: 'Desativar administrador(es)',
			deleteAdministrators: 'Excluir administrador(es)',
			enableAdministrators: 'Ativar administrador(es)',

			deleteManagers: 'Excluir gerente(s)',
			enableManagers: 'Ativar gerente(s)',
			deactivateManagers: 'Desativar gerente(s)',

			news: 'Notícias',
			functionalitiesImprovement: 'Melhorias funcionais',
			newFeatures: 'Novas características',
			share: 'Compartilhar',
			myProfile: 'Meu perfil',
			changePassword: 'Mudar senha',
			support: 'Suporte',
			logOut: 'Sair',

			showOnlyDeleted: 'Mostrar itens desativados',
			showOnlyDisabled: 'Mostrar itens desativados',

			showTaskByMail: 'Mostrar tarefas criadas por e-mail',

			questionDeactivateCompany: 'Tem certeza de que deseja desativar a(s) empresa(s)?',
			questionDeletedCompanies: 'Tem certeza de que deseja excluir a(s) empresa(s)?',
			questionRestoreCompanies: 'Tem certeza de que deseja restaurar a(s) empresa(s)?',

			questionDeactivateBranches: 'Tem certeza de que deseja desativar as filiais?',
			questionRestoreBranches: 'Tem certeza de que deseja restaurar as filiais?',
			questionDeletedBranches: 'Tem certeza de que deseja excluir as filiais?',

			questionDeactivateClients: 'Tem certeza de que deseja desativar o(s) cliente(s)?',
			questionDeletedClients: 'Tem certeza de que deseja excluir o(s) cliente(s)?',
			questionRestoreClients: 'Tem certeza de que deseja restaurar o(s) cliente(s)?',

			questionDeactivateAdministrator: 'Tem certeza de que deseja desativar o(s) administrador(es)?',
			questionDeletedAdministrator: 'Tem certeza de que deseja excluir o(s) administrador(es)?',
			questionRestoreAdministrator: 'Tem certeza de que deseja restaurar o(s) administrador(es)?',

			questionDeactivateManager: 'Tem certeza de que deseja desativar o(s) gerente(s)?',
			questionDeletedManager: 'Tem certeza de que deseja excluir o(s) gerente(s)?',
			questionRestoreManager: 'Tem certeza de que deseja restaurar o(s) gerente(s)?',

			questionAcceptInvitations: 'Tem certeza de que deseja aceitar o(s) convite(s)?',
			questionRefuseInvitations: 'Tem certeza de que deseja rejeitar o(s) convite(s)?',

			questionCancelPartnership: 'Tem certeza de que deseja cancelar a(s) parceria(s)?',

			questionDeactivateTeams: 'Tem certeza de que deseja desativar o(s) time(s)?',
			questionDeletedTeams: 'Tem certeza de que deseja excluir o(s) equipe(s)?',
			questionRestoreTeams: 'Tem certeza de que deseja restaurar o(s) equipe(s)?',

			idUser: 'Id',
			idUserHeader:'Cliente (id Fielder)',
			mobileNumber: 'Telefone celular',
			jobPosition: 'Cargo',
			detailCompany: 'Detalhes da Empresa',
			logoCompany: 'Logo da empresa',
			detailClient: 'Detalhe do Cliente',
			detailAdministrator: 'Detalhes do Administrador',
			detailManager: 'Detalhes do Gerente',

			detailBranch: 'Detalhe da Filial',
			editCompany: 'Editar Empresa',
			addCompanySubtitle: 'Adicione uma nova empresa à sua operação',
			editCompanySubtitle: 'Visualizar ou atualizar informações da empresa',
			editClient: 'Editar Cliente',
			editAdministrator: 'Editar administrador',
			editManager: 'Editar Gerente',
			editBranch: 'Edit Filial',
			editAgent: 'Editar Agente',
			save: 'Salve',
			saveFile: 'Salvar Arquivo',
			saveFilter: 'Salvar filtro',
			removeFilter: 'Remover filtro',
			loadFilter: 'Carregar filtro',
			loadedFilter: 'Filtro carregado com sucesso',
			savedFilter: 'Filtro salvo com sucesso',
			removedFilter: 'Filtro removido com sucesso',
			saveFiles: 'Salvar Arquivos',
			deleteFile: 'Deletar arquivo',
			removeTemplateFile: 'Tem certeza que deseja remover este arquivo de modelo?',
			dropFiles: 'Solte os arquivos aqui ...',
			importFileDetails: 'Detalhes de importação',
			toUploadToYourAccount: 'Para salvar na sua conta',
			uploadAssetInventoryTemplate:
				'Carregar um arquivo para usar como modelo ao exportar dentro dos detalhes da tarefa',
			unsupportedFileType: 'Tipo de arquivo não suportado, carregue um tipo suportado',
			activateAccountEmail: 'Ativar conta de email',
			print: 'Impressão',
			plus: ' Mais... ',
			// Configuration
			configIdAccount: 'Id da Conta',
			accountStatus: 'Status',
			action: 'Açao',
			configBusinessName: 'Nome da Empresa',
			configPhone: 'Telefone da Empresa',
			configAddress: 'Endereço da Empresa',
			configRFC: 'Nome Corporativo da Empresa',
			configUploadLogo: 'Carregar logotipo',
			configAccount: 'Conta de Fielder',
			configAccess: 'Permissões',
			mailSettings: 'Configurações de correio',

			permissionAgentAllwaysExecute:
				'Permite executar qualquer tarefa, independentemente da data e hora estabelecidas',
			permissionAgentCreateTasks: 'Criar tarefas',
			permissionAgentDuplicateTasks: 'Criar subtarefas',
			permissionAgentChangeTypeTasks: 'Permitir que o agente altere o tipo de tarefa',
			permissionAgentRescheduleTasks: 'Permitir que o agente reagende tarefas',
			permissionAccessClient: 'Permitir acesso a Clientes',
			permissionAccessClientImport: 'Permitir a importação de tarefas',
			permissionAccessClientExport: 'Permitir a exportação de tarefas',
			permissionManagerToCreate: 'Permitir que os Gerentes criem tarefas',
			permissionManagerAccessToAnalytics: 'Permitir que os Gerentes acessem a Analítica',
			permissionManagerAccessToRoute: 'Permitir que os gerentes acessem a funcionalidade de otimização de rota',
			permissionManagerAccessToResource:
				'Permitir que os gerentes acessem a funcionalidade de Alocação de Recursos',
			permissionManagerAccessToIsochronous: 'Permitir que os gerentes acessem a funcionalidade de Isócronas',
			permissionManagerAccessToDowloadExcel: 'Permitir que gerentes exportem tarefas',
			permissionManagerAccessToImportExcel: 'Permitir que gerentes importem tarefas',
			permissionManagerToModifyTaskStatus: 'Permitir que os gerentes modifiquem o status da tarefa',
			permissionManagerToDownloadFormExcelTaskDetail: 'Permitir que os gerentes baixem o formulário do Excel nos detalhes da tarefa',
			permissionManagerToEditFormTaskDetail: 'Permitir que o gerente edite o formulário nos detalhes da tarefa',
			configAllowManagerToSeeTaskByMail: 'Permitir que o gerente veja as tarefas por e-mail',
			configAllowManagerToGeneratePdf:'Permitir que os gerentes convertam o formulário em PDF nos detalhes da tarefa',
      		configAllowManagerToApproveForms:'Permitir que os gerentes aprovem formulários',
			configAllowManagerToGenerateAutoAssignTasks:'Permitir que os gerentes gerem as tarefas de Auto-atribuição',
			configAllowManagerToGenerateAndDownloadForms:'Permitir que os gerentes gerem e baixem relatórios de formulários',
			configAllowManagerToGenerateAndDownloadAssets:'Permitir que os gerentes gerem e baixem relatórios de ativos',
			configAllowManagerToGenerateAndDownloadInventories:'Permitir que os gerentes gerem e baixem relatórios de inventários',

      		permissionAdminToModifyTaskStatus: 'Permitir que os administradores modifiquem o status da tarefa',
			permissionAdminAccessToAnalytics: 'Permitir que os administradores acessem  a Analítica',
			permissionAdminToViewTaskToCash: 'Permitir que os administradores visualizem as tarefas a ser cobradas',
			permissionAdminToReceiveEmailWeeklyAccountSummary: 'Permitir que os administradores recebam um e-mail semanal de resumo da conta',
			size: 'Tamanho',

			modifyTaskStatus: 'Modificar o status da tarefa',

			// AGENTS
			routeNote: 'Lembre-se de que as tarefas executadas no modo offline não mostram o caminho feito pelo agente..',
			addAgent: 'Adicionar novo Agente',
			inactiveAgents: 'Agentes Inativos',
			activeAgents: 'Agentes Ativos',
			questionDeleteAgents: 'Tem certeza de que deseja excluir o(s) Agente(s)?',
			questionDeactivateAgents: 'Tem certeza de que deseja desativar o(s) Agente(s)?',
			questionRestoreAgents: 'Tem certeza de que deseja restaurar o(s) Agente(s)?',
			restoreAgentTooltip: 'Restaurar Agente(s)',
			deleteAgentTooltip: 'Excluir Agente(s)',
			deactivateAgentTooltip: 'Deactivate Agent(s)',
			emailPasswordSuccess: 'E-mail enviado com sucesso',
			agentEditedCorrectly: '¡Editado corretamente!',
			agentAddedCorrectly: '¡Adicionado corretamente!',
			createAgentPermission: 'O usuário não tem permissão para criar agentes',
			agentDuplicatedUserName: 'O nome de usuário já existe, escolha outro',
			agentRestoredCorrectly: '¡Agente(s) restaurado(s) corretamente!',
			agentDeletedCorrectly: '¡Agente(s) excluído(s) corretamente!',
			agentDisabledCorrectly: '¡Agente(s) desativado(s) corretamente!',

			pleaseSelectDateOneMonth:'Selecione uma data com no máximo um mês',
			congratulate: 'Parabenizar agente',
			congratulationConfirmation: 'Insira um comentário para os agentes',
			congratulationMessage: 'Mensagen de parabéns',
			congratulations: 'Parabéns',
			congratulationsWereSent: 'Os parabéns foram enviados aos agentes',
			assignAgent: 'Atribuir Agente',

			createAgentTitle: 'Criar Agente',
			maxLimitTaskGroupReached: '* O limite máximo é de 30 caracteres',
			maxLimitTaskGroupNameReached:'* O limite máximo é de 128 caracteres',
			userNameFieldHelp: '* O nome do usuário deve ter pelo menos três caracteres',
			nameFieldHelp: '* O nome deve ter pelo menos três caracteres',
			lastNameFieldHelp: '* O sobrenome deve ter pelo menos 3 caracteres',
			emailFieldHelp: '*Entre com um email válido',
			validateSameEmail: 'O e-mail não é o mesmo, digite-o novamente',
			validateCompany:'Por favor, insira um nome de empresa',
			createAgentCompanyNameField: 'Nome da Empresa',
			phoneFieldHelp: '*O campo não pode ficar vazio',
			createAgentWorkingHoursFieldHelp:
				'* O Horário de início não deve ser anterior ou ao mesmo tempo que o Horário de término',
			createAgentVehicleTypeField: 'Tipo de Veículo',
			bicycle: 'Bicicleta',
			motorcycle: 'Motocicleta',
			car: 'Carro',
			bus: 'Ônibus',
			truck: 'Caminhão',
			walking: 'Andando',
			deviceName: 'Nome do dispositivo',
			deviceSystemVersion: 'Versão do sistema do dispositivo',
			fielderVersion: 'Fielder versão',
			battery: 'Bateria',
			licensePlateFieldHelp: '* A matrícula deve ter pelo menos 4 caracteres',
			createAgentStartingPointField: 'Ponto de partida',
			update: 'Atualizar',
			createAgentCreateButtonHelp: '*Campos obrigatórios',
			detailAgentTitle: 'Detalhe do Agente',
			geolocations: 'Geolocalizações',
			getInto: 'Conecte-se',
			signUp: 'Inscrever-se',
			disabledUser: 'Desativar usuário',
			activateUser: 'Ativar usuário',
			resendPassword: 'Reenviar Senha',
			recoverPassword: 'Esqueceu a Senha',
			recoverPasswordBtn: 'Recuperar',
			recoverPasswordSuccessText: 'Enviaremos e-mail com sua senha.',
			recoverPasswordUserNotFound: 'E-mail não encontrado, verifique o seu e-mail.',
			errorGralFail: 'Ocorreu um erro, por favor tente novamente mais tarde',
			errorGroupWasReassigned:'O grupo foi reatribuído',
			errorRouteTaskNull:
				'Não foi possível reagendar a rota porque uma de suas tarefas não tem data de execução. Reagende-o e tente novamente.',
			sameCompanyHint: 'A empresa controladora não pode ser a mesma que a subempresa',
			cancelServiceByPartner: 'Serviços cancelados pelo parceiro',
			idParentCompany: 'Id Empresa-mãe',
			address: 'Endereço',
			addressExcel: 'Endereço de chegada para a localidade',
			latitude: 'Latitude',
			longitude: 'Longitude',
			attentionTimeFrom: 'Tempo de atenção de',
			attentionTimeTo: 'Tempo de atenção até',
			linkedToBranch: 'Vinculado ao filial',

			workScheduleFrom: 'Horário de trabalho de',
			workScheduleTo: 'Horário de trabalho até',
			workSchedule: 'Horário',
			schedule: 'Date/Hora',
			type: 'Tipo',
			officePhone: 'Telefone da Empresa',
			mobilePhone: 'Número de celular',
			administrative: 'Administrativo',
			executive: 'Executivo',
			technical: 'Técnico',
			trade: 'Comercial',

			hourValidation: '¡A primeira data deve ser menor que a segunda!',
			userNameValidation: 'O nome de usuário já existe',
			workingHours: 'Jornada de trabalho',
			rangeOfAttentionTime: 'Horário de atendimento',
			download: 'Download',
			downloadTask: 'Download tarefas',

			// REGISTER
			register: 'Registo',

			region: 'região',
			createRegion: 'Criar região',
			editRegion: 'Editar região',
			deleteRegion: 'Deseja eliminar a região?',
			createRegionSuccessMessage: 'Tu región se creo correctamente',
			createRegionErrorMessage: 'Tu región no se creo correctamente, intentalo más tarde',
			newRegion: 'Nova região',
			specify: 'Especificar',

			industryQuestion: 'Qual é o seu setor?',
			jobDescription: 'O que melhor descreve o seu cargo?',
			jobDescriptionOps: ['Dono do negócio', 'Líder de equipe', 'Freelancer', 'Diretor / Nível C / VP'],

			industryQuestionOps: [
				'Banco e Finanças',
				'Logística / Transporte',
				'Logística / Parcela',
				'Fabricación / Industrial',
				'Serviços de manutenção',
				'Distribuição de produtos / serviços',
				'Alimentos',
				'Construção',
				'Educação',
				'Hospitalidade / Turismo',
				'Serviços de saúde',
				'Serviços públicos',
				'Têxtil / Calçado',
				'Transporte',
				'Animais de estimação',
				'Beleza',
				'Frete e mudanças',
				'Serviços tecnológicos',
				'A Venda à varejo',
				'Outro',
			],
			findUsQuestion: 'Onde você nos achou?',
			findUsQuestionOps: ['Facebook', 'Twitter', 'Linkedin', 'Google', 'Mail', 'Outro'],
			requestTrial: 'Solicite seu teste',

			privacyPolicyTilte: 'Terms of service and Privacy Policy',
			privacyPolicyText1: 'In order to access Fielder Web we need you to accept our Service Terms and Privacy Policy ',
			privacyPolicyCheckbox1: 'I accept and agree with the ',
			privacyPolicyCheckboxLink1: 'Terms of service',
			privacyPolicyText2: ' and ',
			privacyPolicyCheckboxLink2: 'Privacy policy',
			acceptTermsAndConditions: 'Accept the terms and conditions to continue',

			privacyPolicy: 'Política de Privacidade',
			termsAndConditions1: 'Ao criar uma conta no Fielder, você está aceitando o ',
			termsAndConditions2: 'Termos de serviço',
			termsAndConditionsLogin: 'Ao acessar o Fielder, você aceita nossos ',
			registerSuccessText: '¡Registrado com sucesso! Entraremos em contato com você em breve!',
			functionalityAnaliticsDescription:
				'Este é um recurso avançado com custo adicional, entre em contato com o administrador para ativá-lo.',
			registerFailedText: 'Ocorreu um erro, tente novamente',
			registerFailedUserAlreadyExists: ' já tem uma conta no Fielder',
			createAccountUserNameFieldHelp: '* O nome do usuário deve ter pelo menos 8 caracteres',
			pleaseEnterName: '*Por favor insira um nome',
			pleaseEnterLastName: '*Por favor insira um sobrenome',
			pleaseEnterANumberAndCountry: "*Por favor insira um número e país",
			pleaseSelectJobDescription: 'Por favor, selecione uma descrição do trabalho',
			pleaseSelectAnIndustry: 'Por favor, selecione uma indústria',
			pleaseSelectWhereYouFoundUs:'Por favor, selecione onde você nos encontrou',
			theAccountHasNoPreferences: 'A conta não tem preferências',

			addAdministrator: 'Adicionar administrador',
			editTaskGroup: 'Editar grupo de tarefas',
			taskGroup: 'Grupo de Tarefas',
			unlinkTaskGroup: 'Desvincular tarefas',
			taskGroupUpperCase: 'Grupos de tarefas',
			downloadTaskGroupImages: 'Baixar imagens de tarefas do grupo',
			deletedTaskGroup: 'Grupo de tarefas excluído',
			confirmDeleteTaskGroup: 'Tem certeza de que deseja excluir este grupo de tarefas?',
			noGroupTaskImages: 'Este grupo de tarefas não possui nenhuma imagem salva',
			detailTaskGroup: 'Detalhe do grupo de tarefas',
			imageTaskFile: 'group-tasks-images',
			upperCaseAdministrators: 'ADMINISTRADORES',
			upperCaseManagers: 'GERENTES',
			selectTeamZone: 'Selecionar equipe / zona',
			selectTeamZoneApi: 'Selecionar equipe / zona (Obrigatório)',

			map: 'MAPA',
			dashboard: 'DASHBOARD',
			statusTodayDashboard: 'ESTADO DA TAREFA / HOJE',
			total: 'Total',
			inProgress: 'Em progresso',
			finished: 'Terminado',
			rejected: 'Rejeitado',
			totalAgents: 'Total de Agentes',
			agentsAnalytics: 'AGENTES',
			executeServices: 'TAREFAS CONCLUIDAS',
			tasksFinished: 'Tarefas Concluídas',
			pieGraph: 'Mostrar gráfico de setores',
			normalGraph: 'mostrar gráfica normal',
			onlineDashboard: 'Conectado',
			offlineDashboard: 'Desconectado',
			online: 'Conectado',
			offline: 'Desconectado',
			messageAgentDashboard: 'O agente não atualizou suas informações ou fez login no aplicativo',
			customerSatisfaction: 'SATISFAÇÃO DO CLIENTE',
			taskPlan: 'PLANO DE TAREFAS',
			taskPlanAlert:
				'Você excedeu o plano de tarefas mensal contratado. Tarefas em excesso serão consideradas na sua próxima fatura.',
			completedTasks: 'Tarefas concluídas',
			remainingTasks: 'Tarefas restantes',
			totalPlan: 'Plano total',
			filterByStatusModification: 'Filtrar por modificação de status',
			showTableColumns: 'Mostrar colunas da tabela',

			taskToCash: 'Tarefas a ser cobradas',
			taskToCashReportFilename: 'taskToCashReport',
			moreBranchTooltip: 'mais filiais',
			moreTaskTypeTooltip: 'mais tipos de tarefas',
			taskQuantity: 'Quantidade de tarefas',
			amountToCash: 'Valor a ser cobrado',
			currency: 'Moeda',
			progressOfTheProject: 'Progresso do projeto',
			contributionPercentage: 'Porcentagem de contribuição',
			pricePerTask: 'Preço por tarefa',
			generalTaskStatus: 'Status geral da tarefa',
			restOfTasksWeight: 'resto das tarefas',

			summaryOfTask: 'Resumo das Tarefas',
			summaryTaskSla: 'Tarefas com SLA expirado ou próximo a expirar hoje',
			summaryOfAgent: 'Resumo dos Agentes',
			unassignedAgent: 'Agente não atribuído',
			idFielder: 'ID Fielder',
			customId: 'ID Personalizado',
			idCustomGroup: 'ID Personalizado',
			tasksCompleted: 'TAREFAS CONCLUÍDAS',
			taskCreated: 'Tarefa criada',
			taskFinished: 'Tarefas concluídas',
			completed: 'Completado',
			information: 'Informação',
			countTask: 'Número',
			uppercasePartners: 'PARCEIROS',
			invitations: 'Convites',
			myPartners: 'Parceiros que integrei',
			iAmPartner: 'Parceiros que me integraram',
			integratedByMe: 'Integrado por mim',
			newAgent: 'Novo agente',
			newClient: 'Novo cliente',
			newManager: 'Novo gerente',
			newBranch: 'New filial',
			newAdministrator: 'Novo administrador',
			updateInvitation: 'Convite para atualização',
			updateInvitationSuccess: 'Convite atualizado com sucesso',
			updatePartnerSuccess: 'Parceiro atualizado com sucesso',
			acceptInvitation: 'Aceitar convite',
			acceptInvitations: 'Aceitar convites',
			refuseInvitation: 'Rejeitar convite',
			validEmail: 'Email válido',
			invalidEmail: 'E-mail inválido',
			sendEmail: 'Email enviado',
			activatedUser: 'Ativado pelo usuário',
			deactivatedUser: 'Usuário desabilitado',
			statusInvitation: 'Status',
			manager: 'Gerente',
			teamName: 'Nome da equipe',
			selectManager: 'Selecione um gerente',
			addAnAgent: 'Adicionar um agente',

			periodTime: 'Selecione um período',
			noneTasks: 'Sem tarefas',
			noTags: 'Sem tags',
			noneEmergencies: 'Sem incidências',
			routeStatus: 'Status',
			totalShipment: 'Total embarcado',
			programDate: 'Data agendada da tarefa',
			programHour: 'Hora agendada da tarefa',
			startDateExcel: 'Data de início da tarefa',
			startHourExcel: 'Hora de início da tarefa',
			executionFormattedDate: 'Data de execução',
			executionFormattedHour: 'Hora de execução',
			arriveFormattedDate: 'Data de chegada',
			arriveFormattedHour: 'Hora de chegada',
			startedFormattedDate: 'Data de início',
			startedFormattedHour: 'Hora de início',
			finishedFormattedDate: 'Data de término',
			originalDates: 'Período de execução original',
			emergencies: '# Incidências',
			incidences: 'Incidentes',
			totalTasks: 'Total de Tarefas',
			unlinkTaskQuestion: '¿Deseja desvincular as seguintes tarefas do seu grupo de tarefas?',
			addAgents: 'Adicionar agentes',
			addTeams: 'Adicionar equipes',

			errorTasksForRouteDate: 'As tarefas devem ter o mesmo dia de execução ',
			errorTasksForRouteRoute: 'Uma ou mais tarefas já estão em uma rota, verifique as informações e tente novamente',
			errorTasksForRouteGroup:
				'Uma ou mais tarefas pertencem a um grupo de tarefas, valide as informações e tente novamente',
			errorCreateTask: 'Ocorreu um erro ao criar a tarefa',
			errorDateAnalytics: 'A segunda data deve ser maior que a primeira data de exibição',
			errorTasksForRouteStatus: 'As tarefas devem estar no status ',

			taskStatus_0: 'Gerenciando',
			taskStatus_1: 'Cancelado',
			taskStatus_2: 'Terminado',
			taskStatus_3: 'Aguardando agente',
			taskStatus_4: 'Em progresso',
			taskStatus_5: 'Executando',
			taskStatus_6: 'A caminho',
			taskStatus_Agent: 'Agente na rota',
			taskStatus_7: 'Cancelado pelo parceiro',
			taskStatus_8: 'Atribuído ao parceiro',
			taskStatus_8_1: 'Atribuído pelo parceiro',
			taskStatus_9: 'Atribuído à equipe',
			taskStatus_10: 'Chegou',
			taskStatus_11: 'Excluído',
			taskStatus_12: 'Atribuição automática',
			taskStatus_13: 'Atribuído automaticamente',
			taskStatus_14: 'A atribuição automática falhou',
			taskStatus_15: 'Pausada',
			taskStatus_16: 'Reveja',
			taskStatus_17: 'Rejeitada',

			taskGroup_0: 'Criado',
			taskGroup_1: 'A caminho',
			taskGroup_2: 'Chegou',
			taskGroup_3: 'Excluído',
			taskGroup_4: 'Pausado',
			taskGroup_5: 'Terminado',
			taskGroup_6: 'Cancelado',
			taskGroup_7: 'Desativado',

			grandTotal: 'Total geral',

			linkFile: 'Anexar arquivo',
			linkFiles: 'Anexar arquivos',
			fileSizeExceeded:
				'O tamanho permitido de arquivos por tarefa foi excedido, use um link ou anexe arquivos maiores do detalhe da tarefa.',
			maxSizeFileTask: '(Tamanho máximo total 4MB)',

			review: 'Reveja',
			wrongImageSize: 'Tamanho de imagem errado',

			formatDay: 'dias-hh:mm:ss',
			or: ' ou ',
			and: ' e ',
			validateAgentsAndPriority: 'Verifique as propriedades e adicione agentes que tenham um local',
			validateTaskTypes: 'Não é possível gerar rotas para tarefas remotas',
			errorCreatedRoute:
				'Não foi possível criar rotas, verifique as informações das tarefas e tente novamente mais tarde',
			successCreatedRoutes: '{0} rotas foram criadas com sucesso',
			successCreatedRoute: 'Uma rota foi criada com sucesso ',
			detailTeam: 'Detalhe da equipe',
			editTeam: 'Editar equipe',
			countWaitingForAgent: 'Tarefas à espera de agente',
			anotherJob: 'Outro trabalho',
			meetFielder: 'Conheça Fielder',
			shareText: 'Convide outras empresas para colaborar em seu ecossistema usando Fielder',
			errorAcceptMyInvitation: 'Você não pode aceitar seus próprios convites',
			addedCorrectly: '¡Adicionado com sucesso!',
			assignGroupTask:
				'O endereço e a data de execução da tarefa serão alterados para o predefinido no grupo de tarefas',
			savedCorrectly: '¡Salva com sucesso!',
			groupTasksAreBeingAssigned: 'As tarefas de grupo estão sendo atribuídas',
			groupTasksAreBeingUnlinked:'A tarefa foi desvinculada do Grupo',
			savedCorrectlyReschedule: 'Tarefas reagendadas com sucesso. Número total:',
			accountUpdateSavedCorrectly:
				'Salva com sucesso! \n *Lembre-se que a atualização do logotipo será refletida em breve, agradecemos sua paciência',
			emailAccountCreated: 'Conta de email ativada com sucesso',
			emailAccountCreatedError: 'A conta de e-mail não pôde ser ativada, tente novamente mais tarde',
			cancelSuccessfully: 'Tarefa cancelada com sucesso',
			rateSuccessfully: 'Tarefa classificada com sucesso',
			cancelError:
				'Não foi possível cancelar a tarefa, verifique as informações da tarefa e tente novamente mais tarde',
			rateError: 'Não foi possível avaliar a tarefa, verifique as informações da tarefa e tente novamente mais tarde',
			minimunCharacters: 'caracteres mínimos: {0}',

			managerRestoredCorrectly: '¡Gerente(s) restaurado(s) corretamente!',
			managerDeletedCorrectly: '¡Gerente(s) excluído(s) corretamente!',
			managerDisabledCorrectly: '¡Gerente(s) desativado(s) corretamente!',

			administratorsRestoredCorrectly: '¡Administrador(es) restaurado(s) corretamente!',
			administratorsDeletedCorrectly: '¡Administrador(es) excluído(s) corretamente!',
			administratorsDisabledCorrectly: '¡Administrador(es) desativado(s) corretamente!',

			clientRestoredCorrectly: '¡Cliente(s) restaurado(s) corretamente!',
			clientDeletedCorrectly: '¡Cliente(s) excluído(s) corretamente!',
			clientDisabledCorrectly: '¡Cliente(s) desativado(s) corretamente!',

			branchRestoredCorrectly: '¡Filiais restauradas corretamente!',
			branchDeletedCorrectly: '¡Filiais excluídas corretamente!',
			branchDisabledCorrectly: '¡Filiais desativadas corretamente!',

			EXIST_ASSOCIATE_BY_TASK: 'O elemento não pode ser excluído porque está associado a uma tarefa existente', // 301
			existAssociateDeleteClientsByTask: 'Cliente(s):  {0}  não removido(s), porque foram encontradas tarefas associadas', //301
			existAssociateBySystemClient: 'Cliente(s):  {0}  não removido(s), porque existem várias unidades de negócios', // 303
			unknownErrorDeleteClients: 'Cliente(s):  {0}  não removido(s) devido a um erro não gerenciado', // 304
			existAssociateDeleteManagersByTask: 'Gerente(s):  {0}  não removido(s), porque foram encontradas tarefas associadas', //301
			existAssociateDeleteManagersByTeam: 'Gerente(s):  {0}  não removido(s) porque foram encontradas equipes associadas', // 302
			existAssociateBySystemManager: 'Gerente(s):  {0}  não removido(s), porque existem várias unidades de negócios', // 303
			unknownErrorDeleteManagers: 'Gerente(s):  {0}  não removido(s) devido a um erro não gerenciado', // 304
			EXIST_ASSOCIATE_BY_TEAM: 'O elemento não pode ser excluído porque está associado a uma equipe existente', // 302
			EXIST_ASSOCIATE_BY_SYSTEM: 'O elemento não pode ser excluído porque existem várias unidades de negócios', // 303
			existAssociateBySystemAdmin: 'Administrador(es): {0} não removido(s), porque existem várias unidades de negócios', // 303
			UNKNOWN_ERROR_DELETE_USER: 'Erro desconhecido ao tentar excluir o usuário', // 304
			unknown_error_delete_admins: 'Administrador(es):  {0}  não removido(s) devido a um erro não gerenciado', // 304
			unknown_error_delete_agents: 'Agente(s):  {0}  não removido(s) devido a um erro não gerenciado', // 304
			CONFLICT_EMERGENCIES: 'A rota tem emergências', // 334
			CONFLICT_ROUTE: 'A rota está concluída ou em execução', // 335
			CONFLICT_SERVICE_ON_ROUTE: 'Algumas tarefas de rota não podem ser excluídas', // 336
			EXIST_CLIENT_ASSOCIATE: 'O elemento não pode ser excluído porque há uma associação com um cliente', // 351
			EXIST_BRANCHES_BY_COMPANY: 'O elemento não pode ser excluído porque há filiais associadas à empresa', // 400
			EXIST_SUBCOMPANIES_BY_COMPANY: ',O elemento não pode ser excluído porque existem subempresas associadas', // 401
			EXIST_ASSOCIATE_BY_TASK_AGENT: 'O elemento não pode ser excluído porque há tarefas associadas ao agente', // 450
			existAssociateByTaskAgent: 'Agente(s):  {0}  não removido(s) porque há tarefas associadas ao agente com ID: {0}',
			EXIST_ASSOCIATE_BY_SYSTEM_AGENT: 'O elemento não pode ser excluído porque existem várias unidades de negócios', // 451
			existAssociateBySystemAgent: 'Agente(s):  {0}  não removido(s) porque existem várias unidades de negócios',
			UNKNOWN_ERROR_DELETE_AGENT: 'Erro desconhecido ao tentar remover o agente', // 452

			EXIST_ASSOCIATE_BY_AGENT: 'O elemento não pode ser excluído porque existem agentes relacionados à equipe', // 500;
			EXIST_ASSOCIATE_BY_MANAGER: 'O elemento não pode ser excluído porque há gerentes relacionados à equipe', // 501;
			UNKNOWN_ERROR_DELETE_TEAM: 'Erro desconhecido ao tentar excluir a equipe', // 502;
			unknownErrorDeleteTeams: 'Equipo(s):  {0}  não removido(s), devido a um erro não gerenciado', // 502
			permissionMissignDeleteTeams: 'Equipo(s):  não removido(s), porque você não tem permissões para esta ação', // 990

			FAIL_UPDATE_GROUP_TASKS: 'Algo inesperado aconteceu ao tentar desativar o grupo de tarefas. Tente novamente',
			FAIL_DELETE_TASKS: 'A tarefa de grupo não pode ser desativada porque está associada a uma tarefa existente',
			WRONG_DELETE_STATUS:
				'O grupo de tarefas não pode ser desativado. Apenas grupos com status criado e cancelado podem ser desativados.',
			GROUP_NOT_FOUND: 'O grupo de tarefas não pode ser desativado porque não foi encontrado.',

			failSendInvitation: 'Algo não esperado acontece ao tentar enviar o convite, tente novamente',
			idTeam: 'Id',
			idTeamHeader:'Equipe (Id Team)',
			companyRestoredCorrectly: '¡Empresa (s) restaurada (s) corretamente!',
			companyDeletedCorrectly: '¡Empresa (s) excluída corretamente!',
			companyDisabledCorrectly: '¡Empresa (s) desativada (s) corretamente!',

			selectGlobalAccountClient: 'Selecione uma conta para criar uma tarefa ou um ticket:',
			teamRestoredCorrectly: '¡Equipe(s) restaurada(s) corretamente!',
			teamDeletedCorrectly: '¡Equipes excluídas corretamente!',
			teamDisabledCorrectly: 'Team(s) deactivated correctly',
			cancelPartnershipSuccess: 'Equipes desativadas corretamente',
			acceptInvitationSuccess: 'Convite aceito com sucesso',
			rejectedInvitationSuccess: 'Convite rejeitado com sucesso',
			teamsAndAgents: 'Equipes e Agentes',
			// Analytics
			analyticsUpperCase: 'ANALÍTICA',
			incidenceCount: '# incidências ',
			incidenceAgent: 'Incidência de agente',
			/// GLOBAL SEARCH ///
			teamsAssigned: 'Nenhuma equipe designada',
			clientAddress: 'Nenhum endereço',
			photoMessagePermission:
				'Lembre-se que as fotos sem informação foram carregadas da galeria ou o agente não aceitou as permissões para ser geolocalizado.',
			search: 'Procurar',
			filterBy: 'Filtrar por',
			formFilter:'Filtro de formulário',
			advancedFilter: 'Filtro avançado',
			filterByDate: 'Filtrar por data',
			all: 'Tudo',

			goTeams: 'Ir para equipes',

			searchFormsByTasks: 'Pesquise formulários por tarefas',
			emailTask: 'Tarefas por correio',
			disabledTask: 'Tarefas desativadas',

			groups: 'Grupos de Tarefas',
			groupUpperCase: 'GRUPOS',
			routes: 'Rotas',
			companies: 'Empresas',
			resultsFound: 'resultados encontrados para',
			results: 'Resultados',
			fullName: 'Nome  and Sobrenome',
			idManager: 'Gerente Id',
			groupName: 'Nome do grupo',

			teamManager: 'Líder de equipe',
			tasksNumber: 'Número de tarefas',
			contractNumber: 'Número do contrato',
			myPartner: 'Parceiro que integrei',
			iPartner: 'Parceiro que me integrou',

			// Import users
			importUsersSpecification:
				'* Verifique se os usuários a serem importados não contêm vírgulas. O nome de usuário, email, nome, sobrenome, código do país e telefone celular são campos obrigatórios.',
			row: 'Linha',
			errorType: 'Tipo de erro',
			countryCode: 'Código do país',
			importUsersSubject: 'Resultados da importação de CSV de Usuários em Fielder',
			importBranchSubject: 'Resultados da importação de CSV da Filiais em Fielder',
			importAssetsSubject: 'Resultados da importação de CSV de Ativos em Fielder',
			importInventorySubject: 'Resultados da importação de CSV de inventário em Fielder',
			importCompanySubject: 'Resultados da importação de CSV da empresa em Fielder',
			importMessage: 'Seu processo de importação foi concluído. Você receberá um email com os resultados do processo',
			updateMessage:'O processo de atualização está sendo realizado',
			importIsRequired: 'é necessário',
			if: ' e se ',
			notExists: ' não existe ',

			oneCell: ' One cell of ',
			// Error codes
			errorCode301: 'Campos obrigatórios incorretos',
			errorCode407: 'O formato da atenção está incorreto ou a hora de início é maior que a hora de término',
			errorCode300: 'Usuário duplicado',
			errorCode350: 'Filial já existe',
			errorCode352: 'A empresa não existe ou o ID personalizado está incorreto',
			errorCode353: 'Id da empresa duplicado',
			errorCode500: 'Informações incorretas do usuário',
			errorCode1001: 'Formato .csv incorreto. Faça o download do modelo correto',
			errorCode520: 'Informações incorretas do usuário',
			errorCode710: 'Filial e Categoria são campos obrigatórios',
			errorCode711: 'Categoria de campo obrigatória',
			errorCode712: 'A categoria não tem um modelo definido',
			errorCode713: 'A categoria não tem um modelo definido',
			errorCode714: 'Categoria não encontrada',
			errorCode715: 'Filial não encontrada',
			errorCode716: 'O arquivo está vazio',
			errorCode960: 'Usuário duplicado',
			errorCode961: 'Falha ao criar usuário',


			errorCode310:'O nome de usuário excede o limite de caracteres permitido (45 caracteres)',
			errorCode311:'O e-mail excede o limite de caracteres permitido (75 caracteres)',
			errorCode312:'O nome excede o limite de caracteres permitido (150 caracteres)',
			errorCode313:'O sobrenome excede o limite de caracteres permitido (150 caracteres)',
			errorCode314:'O número de telefone excede o limite de caracteres permitido (45 caracteres)',
			errorCode315:'O número do celular excede o limite de caracteres permitido (45 caracteres)',
			errorCode316:'O cargo excede o limite de caracteres permitido (80 caracteres)',


			createUser: 'Criar usuário',
			createGlobalAccount: 'Criar conta global',

			errorFormatXslxHtml: 'Formato de arquivo incorrecto. Faça o upload de um arquivo .xlsx ou .html',

			timeOfResponse: 'TEMPO DE RESPOSTA',
			timeOfSolution: 'TEMPO DE SOLUÇÃO',
			solutionResponse: 'SOLUÇÃO / RESPOSTA',
			serviceStatus: 'ESTADO DA TAREFA',
			serviceStatusLowerCase: 'Estado de Tarefas',
			executionTimeInitialDateUpperCase: 'TEMPO DE EXECUÇÃO / DATA DE INÍCIO',
			taskGroupStatus: 'STATUS DO GRUPO DE TAREFAS',
			taskGroupStatusLowerCase: 'Status',
			timeOfResponseLowerCase: 'Tempo da resposta',
			timeOfSolutionLowerCase: 'Tempo da solução',
			executionTime: 'Tempo de execução',
			startDate: 'Data de início',
			solutionResponseLowerCase: 'Solução / Resposta',

			executionTimeInitialDate: 'Média entre o Tempo de execução / Hora de início do agente',
			puntuality: 'Pontualidade',
			customTaskType: 'Tipo de tarefa personalizada',
			customTaskTypeName: 'Tarefa personalizada',
			serviceStatusTaskGroupLowerCase: 'Status do grupo de tarefas',
			dashboardAnalitycs: 'Dashboard de Análise Geral',
			dashboardAnalitycsTask: 'Dashboard de Tarefas',
			dashboardAnalitycsGroup: 'Dashboard do Grupo de Tarefas',
			dashboardAnalitycsAgent: 'Dashboard de Agentes',
			dashboardAnalitycsClient: 'Dashboard de Clientes',
			dashboardAnalitycsBranch: 'Dashboard do Filiais',
			dashboardAnalitycsCompany: 'Dashboard Empresas',
			dashboardAnalitycsPartner: 'Dashboard Parceiros Que Integrei',
			dashboardAnalitycsIPartner: 'Dashboard Parceiros Que Me Integraram',

			executedServices: 'SERVIÇOS EXECUTADOS',
			solutionExecutedServices: 'TEMPO DE SOLUÇÃO / SERVIÇOS EXECUTADOS',
			offLineTime: 'TEMPO OFFLINE',
			offlineExecution:'Execução off-line',

			offCause_1: 'Doença',
			offCause_2: 'Período de férias',
			offCause_3: 'Permissão',
			offCause_4: 'Licença por Incapacidade',
			offCause_5: 'Fim do dia de trabalho',

			executedServicesLowerCase: 'Serviços finalizados',
			solutionExecutedServicesLowerCase: 'Tempo da solução / serviço executado',
			offLineTimeLowerCase: 'Tempo offline',
			canceledServices: 'Serviços cancelados',
			inProgressServices: 'Serviços em andamento',
			executingServices: 'Executando Serviços',

			deletedSuccessfullyText: 'Deletado com sucesso',
			linkedTextBranch: 'Não foi possível excluir porque há um link para uma empresa, tarefa ou cliente.',
			linkedTextCompany: 'Não foi possível excluir porque há um link para uma Filial',
			linkedTextClient: 'Não foi possível remover porque existem dependências',
			linkedTextTeam: 'Não foi possível remover porque existem dependências',
			linkedTextManager: 'Não foi possível remover porque existem dependências',
			linkedTextAdministratador: 'Não foi possível remover porque existem dependências',
			linkedTextAgent: 'Não foi possível remover porque existem dependências',

			phoneNumberInput: 'Definir número de telefone',
			note: 'Nota: para mais informações, verifique o ícone',
			questionWhatToSee: 'O que você quer ver?',
			addAction: 'Adicionar ação',
			writeYourSMSEsText: 'Escreva seu texto (SMS)  em espanhol aqui',
			writeYourSMSEnText: 'Escreva seu texto (SMS) em inglês aqui',
			writeYourSMSPtText: 'Escreva aqui o seu texto (SMS) em português',
			ln_es_MX: 'Espanhol',
			ln_en_US: 'Inglês',
			ln_pt_BR: 'Português',
			variables: 'variáveis.',
			sms_first_part_text_0: 'Quando agente',
			sms_first_part_text_1: 'Quando agente',
			sms_first_part_text_2: 'Quando agente',
			sms_second_part_text_2: 'a tarefa, envie',
			sms_second_part_text_1: 'para o local, envie',
			sms_second_part_text_0: 'para a tarefa, envie',
			sms_target: 'para o cliente.',
			sms_action_type_2: 'começar',
			sms_action_type_1: 'chegar',
			sms_action_type_0: 'estou a caminho',
			sms_vars_date: 'data',

			instructions: 'Instruções',
			instructionsText: 'Para criar um serviço através do correio, é necessário respeitar a seguinte nomenclatura:',
			folioInstructions: '*O campo folio pode ser deixado vazio, mas não omitido',
			forBranches: '-Para filiais:',
			forClients: '-Para clientes',
			emailTextBranchPart1: '{{FilialID}}6793',
			emailTextBranchPart2: '{{Descrição}}Tarefa criada por e-mail para uma filial',
			emailTextClientPart1: '{{Folio}}Texto de Amostra',
			emailTextClientPart2: '{{ClienteID}}10447',
			emailTextClientPart3: '{{Descrição}}Tarefa criada por e-mail para um cliente',
			contactSupportTitle: 'SUPORTE TÉCNICO PARA O CAMPO DE CONTATO: CONTACTO@APPFIELDER.COM',
			helpWhatIsFielder: '1. O que é Fielder?',
			helpWhatIsFielderAnswer:
				'O Fielder é uma plataforma de gerenciamento de tarefas que permite gerenciar as tarefas atribuídas à sua equipe, monitorando em tempo real o status das tarefas e sua força de trabalho em campo, oferecendo aos seus   clientes uma experiência diferente na solicitação, entrega e qualidade de seus serviços.',
			helpTypeOfUserQuestion: '2. Que tipo de usuários existem no Fielder?',
			helpTypeOfUserAnswer:
				'Existem 5 tipos de usuários: Superadministrador, Administrador, Agente, Gerente e Cliente. O superadministrador é o usuário que adquire o Fidelder, e esse usuário e o usuário Administrador têm acesso total às funcionalidades. O usuário do Agente é a equipe que está no campo e tem acesso ao aplicativo móvel do Agente. O usuário do Gerente é quem gerencia as equipes e os Agentes designados às equipes. O usuário do Cliente é o Cliente que recebe o serviço e pode criar solicitações de serviço.',
			helpCreateTaks: '3. Como posso criar uma tarefa?',
			helpCreateTaksAnswer:
				'Você pode criar tarefas clicando no símbolo "+" que aparece na parte superior das diferentes telas da plataforma.',
			helpAdress: '4. O endereço é algo importante ao criar uma tarefa?',
			helpAdressAnswer: 'Sim, portanto, sempre que você criar uma tarefa, verifique se o Pin do Fielder está no mapa.',
			helpStatusTask: '5. Onde vejo o status de uma tarefa?',
			helpStatusTaskAnswer:
				'Na seção Minha operação, você verá todas as tarefas e pode selecionar uma por uma para revisar os detalhes.',
			helpReschedule: '6. Posso reagendar um serviço?',
			helpRescheduleAnswer:
				'Sim, qualquer tarefa pode ser reagendada. Selecione a tarefa e, no menu de ações abaixo do mapa, selecione a opção "Detalhe da tarefa"',
			helpAgentClient: '7. O que acontece se meus Agentes ou Clientes não estiverem recebendo e-mails do Fielder?',
			helpAgentClientAnswer:
				'Peça aos usuários que verifiquem seu "SPAM". Se eles definitivamente não receberam os emails, envie um email para support@appfielder.com',
			helpExecuteApplication: '8. Quais tarefas posso executar no aplicativo?',
			helpExecuteApplicationAnswer:
				'Você pode executar qualquer tarefa executada em campo, dentre as quais destacam-se entregas, coleções ou serviços em geral (ou seja, manutenção, instalações ou qualquer atividade realizada no local).',
			helpGeolocalization: '9. Como posso geolocalizar meus Agentes?',
			helpGeolocalizationAnswer:
				'No painel e no mapa de minha operação, você poderá visualizar a geolocalização de seus agentes.',
			helpClientRate: '10. Como meu cliente pode avaliar minha tarefa?',
			helpClienRateAnswer:
				'No aplicativo móvel, o cliente pode selecionar o serviço e clicar em &quot;qualificar&quot;. Na visualização na web, seu cliente precisará entrar, ir para o menu de tarefas e clicar nos botões de ação, selecionando "qualificar" e adicionando seus comentários.',
			helpTaskDay: '11. Como vejo quantas tarefas tenho em um dia específico (Hoje)?',
			helpTaskDayAnswer:
				'Na seção Minha Operação, você poderá visualizar as tarefas que seus clientes lhe atribuíram no dia atual e as que você criou, mostrando o status em tempo real.',
			helpTechnicalRequirements: '12. Quais são os requisitos técnicos mínimos para usar o aplicativo?',
			helpTechnicalRequirementsAnswer:
				'Em nosso site, você pode encontrar os requisitos técnicos mínimos para o melhor desempenho do aplicativo.',

			taskDifference: 'Diferença de tarefa',
			lastMonthTask: 'Tarefas do mês passado',
			tasksOfTheMonth: 'Tarefas do mês',

			// Billing
			billing: 'Faturamento',
			billingUpperCase: 'FATURAMIENTO',
			billingSummary: 'RESUMO DE FATURAMIENTO',
			billingDynamicAllocationTask: 'Tarefas usadas na Atribuição Dinâmica',
			billingRouteOptimizationTask: 'Tarefas em rota',
			billingResurceAllocationTask: 'Tarefas em Atribuição de recursos',
			billingFormsTask: 'Formulários em tarefas',
			billingTasksInGroups: 'Tarefas em grupos',
			billingAssetsInTasks: 'Ativos atribuídos a uma tarefa',
			billingInventoriesInTasks: 'Inventários atribuídos a uma tarefa',
			billingInternalUpperCase: 'FATURAMIENTO INTERNO',
			billingHistory: 'Histórico',
			billingHistoryUpperCase: 'HISTÓRICO DE FATURAMENTO ANTERIOR',
			billlingView: 'VER FATURAMIENTO',
			billingTotal: 'TOTAL',
			billingConsultingDate: 'Data da consulta',
			billingCutoffDate: 'Consumo estimado no dia',
			billingMonthDate: 'Consumo no fechamento',
			disableAccount: 'Desativar conta',
			enableAccount: 'Ativar conta',
			activeAccount: 'Conta ativa',
			accountDeactivated: 'Conta desativada',
			configAlertPaymentAccount: 'Configurar alerta de pagamento',
			descAlertPayment: 'Defina os dias restantes para efetuar o pagamento',
			daysToPay: 'Dias a pagar',
			hideAlertPaymentAccount: 'Remover alerta de pagamento',
			billingShowAlertAccountSuccess: 'Nenhum alerta de pagamento ativado com sucesso',
			billingHideAlertAccountSuccess: 'Alerta de não pagamento removido com sucesso',
			day: 'dia',

			billingAlertNotPaid1: 'Houve um problema com seu pagamento, você tem ',
			billingAlertNotPaid2: ' para regularizar. Evite suspender sua conta.',
			disableAccountSuccess: 'Conta desativada com sucesso',
			enableAccountSuccess: 'Conta ativada com sucesso',
			taskPackage: 'Pacote de tarefas',
			summary: 'Resumo',
			monthSummary: 'Resumo do mês',
			monthSummaryTitleUpperCase: 'RESUMO TOTAL DE OPERAÇÕES',
			paymentType: 'Tipo de pagamento',
			billingPrepaid: 'Pagamento antecipado',
			billingOnDemand: 'Sob demanda',

			agentLocalization: 'Localização do agente',
			gelocalizationLevel0: 'Sem geolocalização',
			gelocalizationLevel1: 'Com geolocalização moderada',
			gelocalizationLevel2: 'Com Alta Geolocalização',

			period: 'Período',
			exchangeType: 'Taxa de câmbio',
			billingMonthly: 'Mensalmente',
			billingBimonthly: 'Bimestralmente',
			billingQuarterly: 'Trimestral',
			billingBiannual: 'Semestral',
			billingAnnual: 'Anual',
			billingBasicPlan: 'Fielder Básico',
			billingOptimizedPlan: 'Fielder Otimizada',
			billingTaskPrice: 'Preço por tarefa',
			billingTaskAdditionalPrice: 'Preço por tarefa adicional',
			billingRequestPrice: 'Preço por pedido',
			billingRequestAdditionalPrice: 'Preço por pedido adicional',
			billingPricePerReorganization: 'Preço por reorganização de rota',
			billingPricePerAdditionalReorganization: 'Preço adicional para reorganização de rotas',
			billingPricePerIsochronous: 'Preço por isócrono',
			billingPricePerForm: 'Preço por modelo de formulário',
			billingPricePerGroup: 'Preço por grupo de tarefas',
			numberOfTasks: 'Número de tarefas',
			numberOfSkills: 'Número de habilidades',
			billingPricePerSkill: 'Preço por habilidade',
			billingSkillAdditionalPrice: 'Preço adicional por habilidade',
			pricePerPackage: 'Preço por pacote',
			concept: 'Conceito',
			quantity: 'Quantidade',
			unitPrice: 'Preço unitário',
			subtotal: 'Subtotal',
			additionalTaskCreated: 'Tarefas criadas adicionais',
			executions: 'Execuções',
			additionalExecutions: 'Execuções adicionais',
			reorganizeRoutes: 'Reorganizar rotas',
			additionalReorganizeRoutes: 'Reorganizar rotas adicionais',
			routeTasks: 'Tarefas de rota',
			additionalRouteTasks: 'Tarefas adicionais de rota',
			taskWithAssets: 'Tarefas com ativos',
			additionalTaskWithAssets: 'Tarefas adicionais com ativos',
			taskWithInventories: 'Tarefas com inventários',
			additionalTaskWithInventories: 'Tarefas adicionais com inventários',
			welcome: 'Bem-vindo ',


			//Api User
			loginApi: 'Login',
			createCompanyApi: 'Create Company',
			updateCompanyApi: 'Update Company',
			getTotalCompaniesApi: 'Get total companies',
			getCompaniesApi:'Get Companies',
			createBranchApi: 'Create Branch',
			updateBranchApi: 'Update Branch',
			getTotalBranchesApi: 'Get Total Branches',
			getBranchesApi: 'Get Branches',
			createAgentApi: 'Create Agent',
			updateAgentApi: 'Update Agent',
			getTotalAgentsApi: 'Get total Agents',
			getAgentsApi: 'Get Agents',
			createTaskApi: 'Create Task',
			getTaskApi: 'Get Task',
			getTasksByDateApi: 'Get Tasks Ids By Date',
			getTaskActivityApi: 'Get Task Activity',
			getRescheduleTaskApi: 'Reschedule Task',
			getCancelTaskApi: 'Cancel Task',
			getPauseTaskApi: 'Pause Task',
			getReasingPartnerApi: 'Reasing Partner',
			getCancelTaskPartnerApi: 'Cancel Task Partner',
			getAssignTaskApi: 'Assign Task',
			getAssignInventoryAssetToTaskApi: 'Assign Inventory/Asset To Task',
			getApproveTaskFormsApi: 'Approve Task Forms',
			getUpdateTaskApi: 'Update Task',
			getDuplicateTaskApi: 'Duplicate Task',
			getCreateTaskGroupApi: 'Create Task Group',
			getUpdateTaskGroupApi: 'Update Task Group',
			getAssignTaskGroupApi: 'Assign Task Group',
			getDetailTaskGroupApi: 'Detail Task Group',
			getTaskGroupNameAndIdCustomApi: 'Get Task Group Name and Id Custom',
			getUnlinkTaskFromTaskGroupApi: 'Unlink Task From Task Group',
			getClientsApi: 'Get Clients',
			getTotalClientsApi: 'Get Total Clients',
			getCreateClientApi: 'Create Client',
			getUpdateClientApi: 'Update Client',
			getTeamAgentsApi: 'Get Team Agents',
			getTaskTypesApi: 'Get Task Types',
			getCreateTaskTypeApi: 'Create Task Type',
			getUpdateTaskTypeApi: 'Update Task Type',
			getTaskPrioritiesApi: 'Get Task Priorities',
			getCustomStatusAssetsApi: 'Get Custom Status (Assets)',
			getCategoriesAssetsApi: 'Get Categories',
			getCategoryByIdAssetsApi: 'Get Category by Id',
			getTotalAssetsByCategoryApi: 'Get total Assets by Category',
			getAssetsApi: 'Get Assets',
			getAssetByIdApi: 'Get Asset by Id',
			getCreateAssetApi: 'Create Asset',
			getUpdateAssetApi: 'Update Asset',
			getDisableAssetApi: 'Disable Asset',
			getDeleteAssetApi: 'Delete Asset',
			getEnableAssetApi: 'Enable Asset',
			getCustomStatusInventoryApi: 'Get Custom Status (Inventory)',
			getCategoriesInventoryApi: 'Get Categories',
			getCategoryByIdInventoryApi: 'Get Category by Id',
			getTotalInventoryByCategoryApi: 'Get total Inventory by Category',
			getInventoriesApi: 'Get Inventories',
			getInventoryByIdApi: 'Get Inventory by Id',
			getCreateInventoryApi: 'Create Inventory',
			getUpdateInventoryApi: 'Update Inventory',
			getDeleteInventoryApi: 'Delete Inventory',
			getDisableInventoryApi: 'Disable Inventory',
			getEnableInventoryApi: 'Enable Inventory',
			getFormsFromTaskApi: 'Get Forms From Task',
			getUpdateFormTaskApi: 'Update Form Task',

			apiUserActivity_0:'Usuário da API criado',
			apiUserActivity_1:'Usuário da API editado',
			apiUserActivity_2:'Api do usuário ativada',
			apiUserActivity_3:'Api do usuário desabilitada',


			totalBalanceToPaid: 'Saldo total a ser pago',
			billingTermsAndConditions1:
				'Os Termos e Condições aplicáveis são os contidos no contrato atual assinado entre as “partes”.',
			billingTermsAndConditions2: 'Qualquer dúvida ou esclarecimento, envie para o e-mail management@smartstc.com',
			actions: 'Ações',
			consulting: 'Consultoria',
			storage: 'Armazenamento',
			billingManagementControl: 'Gerenciamento e Controle',
			billingPlanningOptimization: 'Planejamento e Otimização',
			optimizationSideBar: 'Otimização',
			plannngSideBar: 'Planejamento',
			billingAutomation: 'Automação',
			billingComplement: 'Serviços adicionais',
			of: ' do ',

			// InternalAdmin
			accounts: 'Contas',
			noRecordsLoginText: 'Não há registros de login na conta selecionada',
			noRecordsAgentText: 'Não há registros de agentes atribuídos na conta selecionada',
			noRecordsRouteText: 'Não há registros de rota na conta selecionada',
			totalNumberOfAdministrators: 'Número total de administradores',
			totalNumberOfAdministratorsViewer: 'Número total de visualizadores de administradores',
			totalNumberOfManagers: 'Número total de gerentes',
			totalNumberOfClients: 'Número total de clientes',
			totalNumberOfCompanies: 'Número total de empresas',
			totalNumberOfBranches: 'Número total de filiais',
			totalNumberOfTeams: 'Número total de equipes',
			totalNumberOfAccountPartner: 'Número total de parceiros',
			totalNumberOfAgents: 'Número total de agentes',
			totalUsersInFielder: 'Total de usuários no fielder',
			totalUsers: 'Total de usuários',
			noUsers: 'Sem usuários',
			taskComparison: 'Comparação de tarefas',

			lastLogin: 'Último acesso',
			numberOfRoutes: 'Número de rotas',
			numberOfAssignedAgents: 'Número de agentes atribuídos',
			noLastLogin: 'Nenhum último log de login',
			records: 'Registros',
			addGlobalUser: 'Adicionar usuário global',
			globalUser: 'Usuário global',
			loginsRecords: 'Registros de acesso',
			routesRecords: 'Registros de rota',
			agentsRecords: 'Registros de agentes',
			accountsUpperCase: 'CONTAS',
			numberOfRequest: 'Número de pedidos',
			numberOfReorganization: 'Número de reorganizações de rotas',
			numberOfForms: 'Número de formulários',
			taskWithForms: 'Forma tarefas',
			additionalTaskWithForms: 'Tarefas adicionais com formulários',
			numberOfGroups: 'Número de grupos de tarefas',
			taskInGroups: 'Tarefas de grupo',
			additionalTaskInGroups: 'Tarefas adicionais no grupo',
			taskInDynamicAllocation: 'Tarefas na atribuição dinâmica',
			additionalTaskInDynamicAllocation: 'Tarefas adicionais na atribuição dinâmica',

			//Taks Files Massive Download
			massiveDownload: 'Downloads Massivos',
			massiveDownloadComponentTitle: 'Download em massa de formulários',
			massiveDownloadDescription: 'Baixe em massa os formulários contidos em uma Empresa',
			massiveDownloadCompanyId: 'ID da Empresa:',
			massiveDownloadBranchId: 'ID da Filial:',
			massiveDownloadZipButton: 'Envie informação',
			massiveDownloadCompanyPlaceholder: 'Insira o ID da empresa',
			massiveDownloadBranchPlaceholder: 'Insira o ID da filial',
			requiredIdAccount: 'O campo ID da Empresa é obrigatório',
			massiveDownloadSuccess: 'Enviaremos um link de download para seu e-mai',
			massiveDownloadError: 'Ocorreu um erro ao tentar gerar o arquivo.',
			massiveDownloadIdMustBeNumeric: 'O valor do ID inserido deve ser um número.',
			massiveDownloadMandatoryDates: 'Um intervalo de datas deve ser selecionado',
			massiveDownloadStatusCheckError: 'Não foi possível verificar se já existe um download em andamento',
			massiveDownloadStatusAlreadyInProgress: 'Ainda há um download em andamento',

			//Maya Marketplace
			mayaManagerMarketplace: 'Maya Assistente Virtual para sua operação',
			mayaTechnicianMarketplace: 'Maya Assistente Virtual para técnicos de campo',
			mayaClientMarketplace: 'Maya Assistente Virtual para clientes finais',
		},
	};
	return global;
};

export default language;
