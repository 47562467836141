import { AGENT_STATUS } from '@core/constants/agent/agentStatus.constants';

const NOT_APPLY = 'n/a';

export const buildAdministratorsJsonToExcel = (admin) => {
	const excelAdmin = [];
	for (let i = 0; i < admin.length; i++) {
		excelAdmin.push({
			idUser: admin[i].idUser ? admin[i].idUser : NOT_APPLY,
			userName: admin[i].userName ? admin[i].userName : NOT_APPLY,
			email: admin[i].email ? admin[i].email : NOT_APPLY,
			name: admin[i].name ? admin[i].name : NOT_APPLY,
			lastName: admin[i].lastName ? admin[i].lastName : NOT_APPLY,
			idCountryCode: admin[i].idCountryCode ? admin[i].idCountryCode : NOT_APPLY,
			mobileNumber: admin[i].mobileNumber ? admin[i].mobileNumber : NOT_APPLY,
			phoneNumber: admin[i].phoneNumber ? admin[i].phoneNumber : NOT_APPLY,
			jobPosition: admin[i].jobPosition ? admin[i].jobPosition : NOT_APPLY,
		});
	}
	return excelAdmin;
};

export const buildAgentsJsonToExcel = (agents, lan) => {
	const excelAgent = [];
	for (let i = 0; i < agents.length; i++) {
		excelAgent.push({
			idUser: agents[i].idAgent ? agents[i].idAgent : NOT_APPLY,
			userName: agents[i].userName ? agents[i].userName : NOT_APPLY,
			email: agents[i].email ? agents[i].email : NOT_APPLY,
			name: agents[i].name ? agents[i].name : NOT_APPLY,
			lastName: agents[i].lastName ? agents[i].lastName : NOT_APPLY,
			idCountryCode: agents[i].idCountryCode ? agents[i].idCountryCode : NOT_APPLY,
			phone: agents[i].phone ? agents[i].phone : NOT_APPLY,
			licencePlate: agents[i].licencePlate ? agents[i].licencePlate : NOT_APPLY,
			companyName: agents[i].companyName ? agents[i].companyName : NOT_APPLY,
			workScheduleFrom: agents[i].workScheduleFrom ? agents[i].workScheduleFrom : NOT_APPLY,
			workScheduleTo: agents[i].workScheduleTo ? agents[i].workScheduleTo : NOT_APPLY,
			address: agents[i].location ? agents[i].location.address : NOT_APPLY,
			latitude: agents[i].location ? agents[i].location.latitude : NOT_APPLY,
			longitud: agents[i].location ? agents[i].location.longitud : NOT_APPLY,
			teamName: agents[i].teamNames && agents[i].teamNames.length >0 ? agents[i].teamNames.map(team => team.teamName.concat('')) : '',
			companyName: agents[i].companyName ? agents[i].companyName : NOT_APPLY,
			typeVehicle: agents[i].vehicleType ? getVehicleType(agents[i].vehicleType, lan) : NOT_APPLY,
			skills: agents[i].skills.length>0 ? agents[i].skills : NOT_APPLY,
		});
	}
	return excelAgent;
};

const getVehicleType = (typeVehicle, lan) => {
	let agentVehicleType = '';
	switch (Number(typeVehicle)) {
		case 1:
			agentVehicleType = lan.bicycle;
			break;
		case 2:
			agentVehicleType = lan.motorcycle;
			break;
		case 3:
			agentVehicleType = lan.car;
			break;
		case 4:
			agentVehicleType = lan.bus;
			break;
		case 5:
			agentVehicleType = lan.truck;
			break;
		default:
			agentVehicleType = '';
			break;
	}
	return agentVehicleType;
}

export const buildAgentsJsonToExcelDashboard = (agents, lan) =>
	agents.map(({ status, geolocation, idAgent, email, fullName, lastUpdate }) => {
		let statusText;

		if (status.agentStatus === AGENT_STATUS.noData) {
			statusText = lan.noData;
		} else if (status.agentStatus === AGENT_STATUS.inactive) {
			statusText = lan.inactive;

			if (status.offDutyCause) {
				statusText += `: ${lan['offCause_' + status.offDutyCause]}`;
			}
		} else if (status.agentStatus === AGENT_STATUS.online) {
			statusText = lan.onlineDashboard;
		} else if (status.agentStatus === AGENT_STATUS.offline) {
			statusText = lan.offlineDashboard;
		}

		const geolocationText = geolocation ? `${geolocation.latitude}, ${geolocation.longitud}` : lan.noLocation;

		return {
			idAgent: idAgent,
			name: fullName,
			email,
			lastUpdate,
			status: statusText,
			geolocation: geolocationText,
		};
	});

export const buildManagersJsonToExcel = (managers) => {
	const excelManagers = [];
	for (let i = 0; i < managers.length; i++) {
		excelManagers.push({
			idUser: managers[i].idUser ? managers[i].idUser : NOT_APPLY,
			userName: managers[i].userName ? managers[i].userName : NOT_APPLY,
			email: managers[i].email ? managers[i].email : NOT_APPLY,
			name: managers[i].name ? managers[i].name : NOT_APPLY,
			lastName: managers[i].lastName ? managers[i].lastName : NOT_APPLY,
			idCountryCode: managers[i].idCountryCode ? managers[i].idCountryCode : NOT_APPLY,
			mobileNumber: managers[i].mobileNumber ? managers[i].mobileNumber : NOT_APPLY,
			phoneNumber: managers[i].phoneNumber ? managers[i].phoneNumber : NOT_APPLY,
			jobPosition: managers[i].jobPosition ? managers[i].jobPosition : NOT_APPLY,
		});
	}
	return excelManagers;
};

export const buildClientsJsonToExcel = (clients, lan) => {
	const excelClient = [];
	let idBranches = NOT_APPLY;
	let linkedToBranches;
	let address;
	let latitude;
	let longitude;

	for (let i = 0; i < clients.length; i++) {
		if (clients[i].idBranches.length <= 0) {
			linkedToBranches = lan.no;
			idBranches = NOT_APPLY;
			address = clients[i].location ? clients[i].location.address : NOT_APPLY;
			latitude = clients[i].location ? clients[i].location.latitude : NOT_APPLY;
			longitude = clients[i].location ? clients[i].location.longitud : NOT_APPLY;
		} else {
			idBranches = '';
			const arrayIdBranches = clients[i].idBranches;

			for (let x = 0; x < arrayIdBranches.length; x++) {
				idBranches = `${arrayIdBranches[x]},${idBranches}`;
			}
			linkedToBranches = lan.yes;
			address = NOT_APPLY;
			latitude = NOT_APPLY;
			longitude = NOT_APPLY;
		}

		excelClient.push({
			idUser: clients[i].idUser ? clients[i].idUser : NOT_APPLY,
			userName: clients[i].userName ? clients[i].userName : NOT_APPLY,
			email: clients[i].email ? clients[i].email : NOT_APPLY,
			name: clients[i].name ? clients[i].name : NOT_APPLY,
			lastName: clients[i].lastName ? clients[i].lastName : NOT_APPLY,
			idCountryCode: clients[i].idCountryCode ? clients[i].idCountryCode : NOT_APPLY,
			mobileNumber: clients[i].mobileNumber ? clients[i].mobileNumber : NOT_APPLY,
			phoneNumber: clients[i].phoneNumber ? clients[i].phoneNumber : NOT_APPLY,
			linkedToBranches,
			idBranches,
			address,
			latitude,
			longitud: longitude,
			jobPosition: clients[i].jobPosition ? clients[i].jobPosition : NOT_APPLY,
			attentionTimeFrom: clients[i].attentionTimeFrom ? clients[i].attentionTimeFrom : NOT_APPLY,
			attentionTimeTo: clients[i].attentionTimeTo ? clients[i].attentionTimeTo : NOT_APPLY,
		});
	}
	return excelClient;
};

export const buildCompaniesJsonToExcel = (companies) => {
	const excelCompanies = [];
	for (let i = 0; i < companies.length; i++) {
		excelCompanies.push({
			idCompany: companies[i].id ? companies[i].id : NOT_APPLY,
			idCustom: companies[i].idCustom ? companies[i].idCustom : NOT_APPLY,
			name: companies[i].name ? companies[i].name : NOT_APPLY,
			businessName: companies[i].businessName ? companies[i].businessName : NOT_APPLY,
			parentCompanyId: companies[i].parentCompany ? companies[i].parentCompany.id : NOT_APPLY,
			parentCompanyName: companies[i].parentCompany ? companies[i].parentCompany.name : NOT_APPLY,
			address: companies[i].address ? companies[i].address : NOT_APPLY,
			contractNumber: companies[i].contractNumber ? companies[i].contractNumber : NOT_APPLY,
		});
	}
	return excelCompanies;
};

export const buildBranchesJsonToExcel = (branches) => {
	const excelBranches = [];
	for (let i = 0; i < branches.length; i++) {
		excelBranches.push({
			idBranch: branches[i].id ? branches[i].id : NOT_APPLY,
			idCustom: branches[i].idCustom ? branches[i].idCustom : NOT_APPLY,
			name: branches[i].name ? branches[i].name : NOT_APPLY,
			address: branches[i].location ? branches[i].location.address : NOT_APPLY,
			latitude: branches[i].location ? branches[i].location.latitude : NOT_APPLY,
			longitude: branches[i].location ? branches[i].location.longitud : NOT_APPLY,
			parentCompanyId: branches[i].company ? branches[i].company.id : NOT_APPLY,
			parentCompanyName: branches[i].company ? branches[i].company.name : NOT_APPLY,
			attentionTimeFrom: branches[i].attentionTimeFrom ? branches[i].attentionTimeFrom : NOT_APPLY,
			attentionTimeTo: branches[i].attentionTimeTo ? branches[i].attentionTimeTo : NOT_APPLY,
		});
	}
	return excelBranches;
};
