import { REQUEST_TYPES } from '@core/constants/inventory/inventory.request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import {handlePostRequest} from '@core/api/request'
import API_LINKS from '../apiGateWayLinks';


export const getInventoriesConfigurationRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_INVENTORY_CONFIG,
		idAccount: user.idAccount,
		accountId: user.idAccount,
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const updateInventoriesConfigurationRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPDATE_INVENTORY_CONFIG,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getInventoryCategoriesRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_VIEW_INVENTORY_CATEGORIES,
		idAccount: user.idAccount,
		accountId: user.idAccount,
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getInventorySubCategoriesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_VIEW_INVENTORY_CATEGORIES,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getInventoriesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_VIEW_INVENTORIES,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getInventoriesTableRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_VIEW_INVENTORIES_TABLE,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const createInventoryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_CREATE_INVENTORY,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const updateInventoryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPDATE_INVENTORY,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const deleteInventoryImageRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_REMOVE_INVENTORY_IMAGE,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const deleteInventoryImageFromTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_REMOVE_INVENTORY_IMAGE_FROM_TASK,
		idAccount: user.idAccount,
		accountId: body.accountId,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getInventoryByIdRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_SPECIFIC_INVENTORY,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getInventoryCategoryById = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_INVENTORY_CATEGORY_BY_ID,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const deleteInventoryCategoryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_DELETE_INVENTORY_CATEGORY,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const changeStatusInventoriesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.CHANGE_INVENTORY_STATUS,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const deleteInventoriesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_DELETE_INVENTORY,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const importInventoriesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_IMPORT_CATEGORY_INVENTORY,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const exportInventoriesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_INVENTORIES_TO_EXPORT,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const createInventoryCategoryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_CREATE_INVENTORY_CATEGORIES,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const updateInventoryCategoryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPDATE_INVENTORY_CATEGORY,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getTemplateInventoriesListRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_ALL_INVENTORIES,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getTaskInventoriesReport = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_INVENTORIES_FROM_TASKS,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};


export const generateInventoryReportRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		request: REQUEST_TYPES.DO_ACTION_GENERATE_INVENTORY_REPORT,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsXlsxParser, requestBody, onSuccess, onError, loadingConfig);
};


export const uploadInventoryCategoryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPLOAD_INVENTORY_CATEGORY_TEMPLATE,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getInventoryHTMLRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		request: REQUEST_TYPES.DO_ACTION_GET_INVENTORY_HTML,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsXlsxParser, requestBody, onSuccess, onError, loadingConfig);
};


export const updateInventoryFromTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPDATE_TASK_INVENTORY,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getXLSXOrHTMLFromInventoryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		request: REQUEST_TYPES.DO_ACTION_GET_INVENTORY_XLSX_OR_HTML_FROM_FILE,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsXlsxParser, requestBody, onSuccess, onError, loadingConfig);
};
