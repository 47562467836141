import React, { Component } from 'react';

import Status from 'FielderUtils/status/taskStatus';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import EditIcon from '@assets/icons/edit-icon.svg';
import ModifyTask from '@assets/icons/arrow-modify.svg';
import taskConst from 'FielderUtils/roleManagementConst/taskConst';
import Modal from '~/FielderElements/Modal';
import BranchIcon from '@assets/icons/branch-icon.svg';
import {
	listToTree,
	getAllTaskTypeList,
	getAllTaskTypeListPartner,
	hasTaskTypeDynamoTable,
	hasTaskTypeDynamoTablePartner,
} from 'FielderUtils/taskType/taskType';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import StarRatings from 'react-star-ratings';
import Tooltip from '@mui/material/Tooltip';
import ModifyTaskStatus from './ModifyTaskStatus';
import { isRoleValid } from '../../core/utils/roles/validateRoles';
import EditorTask from '../CreateTask/EditorTask';
import Popup from '../Popup';
import './taskInfo.css';
import TaskStatus from '~/Status/TaskStatus';
import { FIELDER } from '@core/constants/base.constants';
import DetailEditBranchOffice from 'OldComponents/BranchOffices/DetailEditBranchOffice';

/**
 * Contains the Info content from the {@link TaskDetails} component
 */
const { pop: POP_ID } = FIELDER.idElement;
const POP_EDIT_BRANCH = POP_ID.formBranch.replace('{0}', 'branchModal5');

class TaskInfo extends Component {
	constructor(props) {
		super(props);

		
		console.log("props.idBranch")
		console.log(props.idBranch)

		console.log("this.props.taskData")
		console.log(this.props.taskData)
		
		this.state = {
			edit: false,
			statusTask: false,
			isAllowedToEditTask: false,
			showModifyTaskStatus: false,
			taskTypes: [],
			branchesToShow: {
				id: props.idBranch,
			},
		};
	}

	componentDidMount = () => {
		let state = this.state;

		state.isAllowedToEditTask = isRoleValid(taskConst.TASKS, taskConst.EDIT, this.props.session.roles);
		this.setState(state);

		let partnerTaskRelations = this.props.partnerTaskRelations;
		let idAccount = this.props.session.user.idAccount;
		let idUser = this.props.session.user.idUser;

		if (
			partnerTaskRelations !== undefined &&
			partnerTaskRelations != null &&
			partnerTaskRelations.accountOwner != null &&
			partnerTaskRelations.accountOwner.id != idAccount &&
			partnerTaskRelations.accountOwner.idUserClient != idUser &&
			partnerTaskRelations.partnerLevel == 1
		) {
			var idAccountPartner = partnerTaskRelations.accountOwner.id;
			var idUserPartner = partnerTaskRelations.accountOwner.idUserClient;

			this.executeGetAllTaskInfoPartner(idAccountPartner, idUserPartner);
		} else {
			this.executeGetAllTaskInfo();
		}
	};

	executeGetAllTaskInfo = () => {
		hasTaskTypeDynamoTable().then((taskTypeFunctionality) => {
			if (taskTypeFunctionality) {
				getAllTaskTypeList(
					(formatTypes, taskTypes) => {
						let types = listToTree(formatTypes);
						let state = this.state;
						state.taskTypes = types;
						state.taskTypeFunctionality = taskTypeFunctionality;
						this.setState(state);
					},
					this.props,
					true
				);
			}
		});
	};

	executeGetAllTaskInfoPartner = (idAccountPartner, idUserPartner) => {
		hasTaskTypeDynamoTablePartner(idAccountPartner, idUserPartner).then((taskTypeFunctionality) => {
			if (taskTypeFunctionality) {
				getAllTaskTypeListPartner(
					(formatTypes, taskTypes) => {
						let types = listToTree(formatTypes);
						let state = this.state;
						state.taskTypes = types;
						state.taskTypeFunctionality = taskTypeFunctionality;
						this.setState(state);
					},
					this.props,
					idAccountPartner,
					idUserPartner
				);
			}
		});
	};

	handleShow = () => {
		
	}


	/**
	 * Helper for the Labels and text components to reduce
	 * code written
	 *
	 * @param {*} props - Text for the label and text
	 */
	LabelText = (props) => {
		var text;
		if (Array.isArray(props.text)) {
			text = props.text.join(' > ');
		} else {
			text = props.text;
		}

		return (
			<Col sm={12} className={props.className}>
				<Row className='my-2'>
					<Col className={'td-label-input ' + props.labelClass}>{props.label}</Col>
					{props.showMoreInfo && (
						<>
							<Col className='d-flex justify-content-end'>
								<span
									onClick={this.editModal}
									style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
								>
								{this.props.lan.moreInformation}
								</span>
							</Col>
						</>
					)}
				</Row>
				<Row>
					<Col className={'td-default-text align-left ' + props.textClass}>
						<input type='text' className='task-details-input' defaultValue={text} disabled style={{ width: '100%' }} />
					</Col>
				</Row>
			</Col>
		);
	};

	LabelArea = (props) => {
		return (
			<Col sm={12} className={props.className}>
				<Row className='my-2'>
					<Col className={'td-label-input ' + props.labelClass}>{props.label}</Col>
				</Row>
				<Row>
					<Col className={'td-default-text align-left ' + props.textClass}>
						<textarea
							rows='3'
							cols='20'
							className='taskinfo-scrollTextarea task-details-input'
							value={props.text}
							disabled
							style={{ width: '100%' }}
						/>
					</Col>
				</Row>
			</Col>
		);
	};

	Tags = (props) => {
		return (
			<Col className='ml-3'>
				<Row className='my-2'>
					<Col className='td-label-input '>{props.lan.tags}</Col>
				</Row>
				{props.tags.length > 0 ? (
					<div className='taskinfo-scrollTextarea'>
						<Row className='ml-3'>
							{props.tags.map((type, i) => (
								<Col sm={'auto'} className='m-1' key={type + '-tag'}>
									<Row className='tag-button my-auto'>
										<div className='mt-1'>
											<div className='container'>
												<span id={'TaskInfo-tag-' + i} className='mr-3'>
													#{type}{' '}
												</span>
											</div>
										</div>
									</Row>
								</Col>
							))}
						</Row>
					</div>
				) : (
					<Row>
						<Col className={'td-default-text align-left ' + props.textClass}>
							<input
								type='text'
								className='task-details-input'
								defaultValue={props.noTags}
								disabled
								style={{ width: '100%' }}
							/>
						</Col>
					</Row>
				)}
			</Col>
		);
	};

	clickModifyStatus = () => {
		this.setState({ showModifyTaskStatus: true });
		this.props.changeOpacity();
	};

	closeModifyStatus = (refresh) => {
		this.setState({ showModifyTaskStatus: false });
		this.props.changeOpacityReset();

		if (refresh) {
			this.props.executeRefresh();
		}
	};

	validateModifyStatus = () => {
		if (this.props.taskData.hasGroup || this.props.taskData.hasRoute) {
			return false;
		} else if (
			this.props.taskData.status == Status.CANCEL_BY_PARTNER ||
			this.props.taskData.status == Status.CANCEL ||
			this.props.taskData.status == Status.FINISH ||
			this.props.taskData.status == Status.REVIEW
		) {
			return false;
		} else if (RoleUtils.isUserClient(this.props.session.user)) {
			return false;
		} else if (RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(this.props.session.user)) {
			return true;
		} else if (
			(this.props.session &&
				this.props.session.accountPreferences &&
				this.props.session.accountPreferences.allowAdminToModifyTasksStatus &&
				RoleUtils.isUserAdministrator(this.props.session.user)) ||
			(this.props.session &&
				this.props.session.accountPreferences &&
				this.props.session.accountPreferences.allowManagerToModifyTasksStatus &&
				RoleUtils.isUserManager(this.props.session.user))
		) {
			return true;
		} else {
			return false;
		}
	};

	setStatusParent = (status) => {
		this.props.taskData.statusInfo = status;
	};

	setDefaultFalseEdit = () => {
		this.props.fielderModal.closeAllModals();
	}

	setDefaultFalseModalEdit = () => {
		this.props.fielderModal.closeAllModals();
	}


	editModal = () => {
			this.props.fielderModal.setContent(null);
			this.props.fielderModal.closeModal(POP_EDIT_BRANCH);
			const dynamicContent = (
				<DetailEditBranchOffice
					{...this.props}
					branchesToShow={{id:this.props.taskData.branchId}}
					showModalDetailEditBranch={true}
					idAccountBranch={this.props.taskData.idAccount}
					setDefaultFalseModal={this.setDefaultFalseEdit}
					setDefaultFalse={this.setDefaultFalseModalEdit}
				/>
			);
			this.props.fielderModal.setContent(POP_EDIT_BRANCH, dynamicContent);
			this.props.fielderModal.openModal(POP_EDIT_BRANCH);
		};


		renderModal = (showModal, titleKey, id, fullWidth = false) => {
			if (!showModal) return null;
			return (
				<Modal 
					id={id}
					title={this.props.lan[titleKey]} 
					icon={<img src={BranchIcon} alt={this.props.lan[titleKey]} />} 
					fullWidth={fullWidth}
				/>
			);
		};


	render() {
		return (
			<React.Fragment>
				{this.renderModal(
					true, 'editBranch', POP_EDIT_BRANCH, true)}
				
				<div className='container' style={{ height: '100%' }}>
					{this.state.showModifyTaskStatus && (
						<ModifyTaskStatus
							handleCloseModifyStatus={(refresh) => this.closeModifyStatus(refresh)}
							taskTypeId={this.props.taskData.taskTypeId}
							taskTypes={this.state.taskTypes}
							statusTask={this.props.taskData.status}
							idTask={this.props.idTask}
							{...this.props}
						/>
					)}

					{this.state.edit && (
						<Popup
							className='my-modal'
							size='xl'
							closeButton
							onClose={() => this.setState({ edit: false })}
							title={this.props.lan.editTask}
							image='tasks.svg'
							show
							width='xl'
							centerprop={false}
						>
							<EditorTask onCloseModal={this.props.onCloseModal} taskInfo={this.props.allData.taskInfo} {...this.props} />
						</Popup>
					)}
					{!this.state.edit && (
						<Row>
							<Col sm={6}>
								<Col>
									<Row>
										<Col sm={4}>
											<this.LabelText
												label={this.props.lan.idCustom}
												text={this.props.taskData.number}
												className='mt-3'
												textClass='td-task-number'
											/>
										</Col>
										<Col sm={5} className='mt-4 pt-1 pl-0'>
											<TaskStatus task={this.props.taskData} setStatusParent={this.setStatusParent}  />
										</Col>

										{this.props.showIconModify && this.validateModifyStatus() && this.state.isAllowedToEditTask && (
											<div className='col-auto my-auto justify-content-center'>
												<Tooltip title={this.props.lan.modifyTaskStatus}>
													<img
														id='modify-task-status-button'
														src={ModifyTask}
														className='edit-cursor-pointer'
														style={{ height: '25px', width: '22px' }}
														alt=''
														onClick={this.clickModifyStatus}
													/>
												</Tooltip>
											</div>
										)}
										{this.props.statusEditTask && !this.props.canNotEdit && this.state.isAllowedToEditTask && (
											<div className='col-auto mr-4 pr-1  align-self-center'>
												<img
													id='edit-task-button'
													src={EditIcon}
													className='icon edit-cursor-pointer'
													onClick={() => this.setState({ edit: true })}
												/>
											</div>
										)}
									</Row>
								</Col>

								{this.props.taskData.idParent > 0 && (
									<Col>
										<this.LabelText
											label={this.props.lan.idParentTask}
											text={this.props.taskData.idParent}
											className='my-3'
										/>
									</Col>
								)}
								<Col>
									<this.LabelText label={this.props.lan.folio} text={this.props.taskData.folio} className='my-3' />
								</Col>
								<Col>
									<this.LabelText label={this.props.lan.caseNumber} text={this.props.taskData.caseNumber} className='my-3' />
								</Col>
								{this.props.taskData.showBranchFields && (
									<Col>
										<this.LabelText
											label={this.props.lan.idBranch}
											text={this.props.taskData.branchId}
											className='my-3'
										/>
									</Col>
								)}
								{this.props.taskData.showBranchFields && (
									<Col>
										<this.LabelText
											label={this.props.lan.taskBranchIdCustom}
											text={this.props.taskData.branchIdCustom}
											className='my-3'
										/>
									</Col>
								)}
								<Col>
									<this.LabelText
										label={this.props.taskData.showClientFields ? this.props.lan.nameOfClient : this.props.lan.nameOfBranch}
										showMoreInfo={true}
										text={this.props.taskData.client.name}
										className='my-3'
									/>
								</Col>

								{this.props.taskData.showClientFields && (
									<Col>
										<this.LabelText
											label={this.props.lan.lastNameClient}
											text={this.props.taskData.lastName}
											className='my-3'
										/>
									</Col>
								)}
								{this.props.taskData.showClientFields && (
									<Col>
										<this.LabelText
											label={this.props.lan.userName}
											text={this.props.taskData.userName}
											className='my-3'
										/>
									</Col>
								)}
								{!this.props.taskData.showClientFields && (
									<Col>
										<this.LabelText
											label={this.props.lan.company}
											text={this.props.taskData.company}
											className='my-3'
										/>
									</Col>
								)}
								{this.props.taskData.showClientFields && (
									<Col>
										<this.LabelText
											label={this.props.lan.phoneNumberClient}
											text={this.props.taskData.client.phone}
											className='my-3'
										/>
									</Col>
								)}
								{this.props.taskData.showClientFields && (
									<Col>
										<this.LabelText
											label={this.props.lan.emailOfClient}
											text={this.props.taskData.client.mail}
											className='my-3'
										/>
									</Col>
								)}
								{this.props.taskData.userCreateTask !== undefined &&
									this.props.taskData.userCreateTask &&
									this.props.taskData.userCreateTask != '' && (
										<Col>
											<this.LabelText
												label={this.props.lan.createdTaskBy}
												text={this.props.taskData.userCreateTask}
												className='my-3'
											/>
										</Col>
									)}
							</Col>
							<Col sm={6}>
								<Col>
									<this.LabelText
										label={this.props.lan.typeTask}
										text={this.props.taskData.taskType}
										className='my-3'
									/>
								</Col>
								<Col>
									<this.LabelText
										label={this.props.lan.serviceOrigin}
										text={this.props.taskData.serviceOrigin}
										className='my-3'
									/>
								</Col>
								{this.props.taskData.showMyTaskPartner && (
									<Col>
										<this.LabelText
											label={this.props.lan.assignedPartner}
											text={this.props.taskData.taskPartner}
											className='my-3'
										/>
									</Col>
								)}
								<Col>
									<this.LabelText
										label={this.props.lan.priority}
										text={this.props.taskData.priority}
										className='my-3'
									/>
								</Col>
								<Col>
									<this.LabelArea
										label={this.props.lan.descriptionTask}
										text={this.props.taskData.description}
										className='my-3'
									/>
								</Col>
								<Col>
									<this.LabelArea
										label={this.props.lan.clientComment}
										text={this.props.taskData.rateComment}
										className='my-3'
									/>
								</Col>
								<Col>
									<Row className='mt-2 ml-1'>
										<Col className='td-label-input '>{this.props.lan.rating}</Col>
									</Row>
									<div className='ml-5'>
										<StarRatings
											rating={this.props.taskData.rating}
											starSpacing='5px'
											starDimension='30px'
											numberOfStars={5}
											starRatedColor='yellow'
											starHoverColor='yellow'
										/>
									</div>
								</Col>
								<this.Tags
									tags={this.props.taskData.tags}
									noTags={this.props.taskData.noTags}
									lan={this.props.lan}
									className='my-3'
								/>
							</Col>
						</Row>
					)}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(TaskInfo);
export function withSnackbar(Component) {
	return function WrappedComponent(props) {
		const fielderModal = useModal();
		return <Component {...props} fielderModal={fielderModal} />;
	}
}