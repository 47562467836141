import React, { useState, useEffect, useRef } from 'react';

import { sanitizeInput } from '@core/utils/dompurify/dompurify.js';
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import {
	getCompaniesByFilterAsyncSelectCreateTask,
	getCompaniesInitialOptionsAsyncSelect,
} from 'FielderUtils/company/company';
import ErrorCodes from 'FielderUtils/ErrorCodes';
import { GOOGLE_API_KEY } from 'FielderUtils/session/Session';
import Datetime from 'react-datetime';
import Geocode from 'react-geocode';
import { Chip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Location from '../../components/Location/Location';
import { ENDPOINTS } from '../../core/constants/pageEndpoints.constants';
import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import model from "Object/model/model.js";
import { createBranchRequest, getLastIdsCustomRequest } from '@core/api/branch/branch.service';
import MoreBranchInformation from './MoreBranchInformation';
import { Col } from 'react-bootstrap';

import 'FielderUtils/style.css';
import './DateTime.css';
import './AddBranchOffice.css';
import '../Register/RegisterComponentStyles.css';

let moment = require('moment');
Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();
let beginningTime = null;
let endTime = null;
let validTime;
const AddBranchOffice = (props) => {
	const lan = useLanguage();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const moreBranchInformationRef = useRef({});
	const [idCustom, setIdCustom] = useState('');
	const [name, setName] = useState('');
	const [newDirection, setNewDirection] = useState('');
	const [latitude, setLatitude] = useState(0);
	const [longitude, setLongitude] = useState(0);
	const [postalCode, setPostalCode] = useState('');
	const [branchState, setBranchState] = useState('');
	const [timeZone, setTimeZone] = useState('');
	const [country, setCountry] = useState('');
	const [errorLocation, setErrorLocation] = useState(false);
	const [id, setId] = useState(null);
	const [legalAddress, setLegalAddress] = useState(null);
	const [activateAlert, setActivateAlert] = useState(false);
	const [attentionTimeFrom, setAttentionTimeFrom] = useState(null);
	const [attentionTimeTo, setAttentionTimeTo] = useState(null);
	const [showErrorDuplicateBranch, setShowErrorDuplicateBranch] = useState(false);
	const [lastIdsCustom, setLastIdsCustom] = useState([]);
	const [showMoreBranchInfo, setShowMoreBranchInfo] = useState(false);

	useEffect(() => {
		const onSuccess = (response) => {
			let branches = response.branches;
			if (branches) {
				setLastIdsCustom(branches);
			}
		}
		const onError = (error) => {
			console.log(error)
		}
		const loadingConfig = { name:"GET_LAST_IDS_CUSTOM", dispatch }
		getLastIdsCustomRequest(onSuccess, onError, loadingConfig)
	}, []);
	
	const getCompanyList = async (event) => {
		if (event.length > 0) {
			let assignList = await getCompaniesByFilterAsyncSelectCreateTask(event);

			return assignList;
		}
	};
	const getInitialOptionsCompany = async () => {
		return await getCompaniesInitialOptionsAsyncSelect();
	};
	const updateLocation = (newLocation, coordinates) => {
		if (coordinates.lat != latitude && coordinates.lng != longitude) {
			setLatitude(coordinates.lat)
			setLongitude(coordinates.lng)
			setNewDirection(newLocation.address)
			setPostalCode(newLocation.postalCode)
			setBranchState(newLocation.state)
			setTimeZone(newLocation.timezone)
			setCountry(newLocation.country)
		}
	};
	const handleOptionSelected = (selectedOption) => {
		if (selectedOption == null) {
			setId(null);
		} else {
			document.getElementById('company-alert-icon').style.display = 'none';
			setId(selectedOption.value.id)
		}
	};
	const setValidateName = () => {
		if (name !== '') {
			document.getElementById('name-alert-icon').style.display = 'none';
			document.getElementById('name-red-input').style.borderColor = '#ced4da';
		}
	};
	const setFieldValue = (value, field) => {

		const sanitizedInput = sanitizeInput(value);

		if (field === 'idCustom') {
			setIdCustom(sanitizedInput)
			document.getElementById('register-form-hint').style.display = 'none';
			setShowErrorDuplicateBranch(false)
		} else if (field === 'name') {
			setName(sanitizedInput)
			setValidateName();
			document.getElementById('register-form-hint').style.display = 'none';
			setShowErrorDuplicateBranch(false)
		}
	};
	const validateIdCustom = (idCustom) => {
		if (idCustom !== '') {
			document.getElementById('idCustom-alert-icon').style.display = 'none';
			document.getElementById('id-input-branch-idCustom').style.borderColor = '#ced4da';
			return true;
		}
		document.getElementById('idCustom-alert-icon').style.display = 'inline';
		document.getElementById('id-input-branch-idCustom').style.borderColor = '#c70101';
		return false;
	};
	const validateName = (name) => {
		if (name !== '') {
			document.getElementById('name-alert-icon').style.display = 'none';
			return true;
		}
		document.getElementById('name-alert-icon').style.display = 'inline';
		document.getElementById('name-red-input').style.borderColor = '#c70101';
		return false;
	};
	const validateCompany = (id) => {
		if (id !== null) {
			document.getElementById('company-alert-icon').style.display = 'none';
			return true;
		}
		document.getElementById('company-alert-icon').style.display = 'inline';
		return false;
	};
	const validateLocation = () => {
		let isValid = latitude !== 0 && longitude !== 0 && newDirection !== ''
		setErrorLocation(!isValid)
		return isValid;
	};

	const openMoreBranchInfo = () => {
		setShowMoreBranchInfo(!showMoreBranchInfo);
	}


	const handleDoActionAddBranch = (createWithTask) => {
		const isValidName = validateName(name);
		const isValidIdCustom = validateIdCustom(idCustom);
		const isValidIdCompany = validateCompany(id);
		const isValidLocation = validateLocation();

		if (isValidName && isValidIdCustom && isValidLocation && isValidIdCompany && activateAlert === false) {

			let branchInfoPass = {
				parentCompanyId: id,
				idCustom: idCustom,
				name: name,
				state: branchState,
				country: country,
				postalCode: postalCode,
				address: newDirection,
				latitude: latitude,
				longitud: longitude,
				timeZone: timeZone,
				attentionTimeFrom: attentionTimeFrom,
				attentionTimeTo: attentionTimeTo,
				legalAddress: legalAddress != null && legalAddress !='' ? legalAddress : null,
				createWithDirection: true,
				nameMoreInfo: moreBranchInformationRef.current.name,
				lastNameMoreInfo: moreBranchInformationRef.current.lastName,
				emailMoreInfo: moreBranchInformationRef.current.email,
				cellphoneMoreInfo: moreBranchInformationRef.current.cellphone,
				officePhoneMoreInfo: moreBranchInformationRef.current.officePhone,
				commentMoreInfo: moreBranchInformationRef.current.comment,
			};

			document.getElementById('register-form-hint').style.display = 'none';
			let newCompanyInfo = model.doActionCreateBranch(branchInfoPass);
			const body = newCompanyInfo.data;

			const onSuccess = (response) => {
				if (createWithTask == false) {
					handleClose();
				} else {
					let idBranch = response.idBranch;
					handleClose();
					navigate(`${ENDPOINTS.createTask}?branch=${idBranch}`)
				}
			}

			const onError = (response) => {
				if (response.status === ErrorCodes.DUPLICATED_BRANCH) {
					setShowErrorDuplicateBranch(true)
					document.getElementById('register-form-hint').style.display = 'inline';
				} else {
					props.errorMessageCreation();
				}
			}

			const loadingConfig = { name:"CRATE_BRANCH", dispatch }
			createBranchRequest(body, onSuccess, onError, loadingConfig)

		} else {
			document.getElementById('register-form-hint').style.display = 'inline';
		}
	};
	const onChangeTimeOne = (value) => {
		if (value != undefined) {
			let timeFrom = new Date(value._d);
			beginningTime = timeFrom;
			validateTime();
		}
	};
	const onChangeTimeTwo = (value) => {
		if (value != undefined) {
			let timeFromTo = new Date(value._d);
			endTime = timeFromTo;
			validateTime();
		}
	};
	const validateTime = () => {
		if (beginningTime != null && endTime != null) {
			let initialTime = beginningTime;
			let finalTime = endTime;
			validTime = moment(initialTime, 'h:mm a').isBefore(moment(finalTime, 'h:mm a'));
			if (validTime == true) {
				let formatInitialTime = moment(initialTime, 'hh:mm a').format('HH:mm');
				let formatEndTime = moment(finalTime, 'hh:mm a').format('HH:mm');
				setAttentionTimeFrom(formatInitialTime)
				setAttentionTimeTo(formatEndTime)
				setActivateAlert(false)
			} else {
				setActivateAlert(true)
			}
		}
	};
	const handleClose = () => {
		props.setDefaultFalse();
	};
	return (<>
		<div className='px-4 mt-2' style={{ height: '100%' }}>
			<div className='mb-4'>
				<div className='col-sm-12 Fielder-cards-txt-label-input'>
					{lan.idCustomBranch}
				</div>
				<div className='col-sm-12 font-inputs'>
					<div className='input-group input-group-sm mb-3'>
						<div className='input-group-prepend' />
						<input
							id='id-input-branch-idCustom'
							type='text'
							className='form-control Fielder-cards-font-inputs'
							aria-label='Small'
							value={idCustom}
							aria-describedby='inputGroup-sizing-sm'
							onChange={(e) => setFieldValue(e.target.value, 'idCustom')}
						/>
						<img
						src={redAlertIcon}
						alt=''
						id='idCustom-alert-icon'
						className='CreateTask-idCustom-icon'

						/>
					</div>
				</div>
			</div>

		{ lastIdsCustom && lastIdsCustom.length >0 &&(
			<div className='mb-4'>
				<div className='col-sm-12 Fielder-cards-txt-label-input'>
					{lan.lastIdsCustomUsed}
				</div>

				<div className='col-sm-12 font-inputs'>
					<div className='input-group input-group-sm mb-3'>
						<div className='input-group-prepend' />
						{lastIdsCustom.map((data) => (
							<Chip
								className='mx-2 my-1'
								key={data.id}
								label={data.idCustom}
							/>
						))}
					</div>
				</div>
			</div>
		)}

			<div className='mb-4'>
				<div className='col-sm-12 Fielder-cards-txt-label-input'>
					{lan.branchNameText}
				</div>
				<div className='col-sm-12 font-inputs'>
					<div className='input-group input-group-sm mb-3'>
						<div className='input-group-prepend' />
						<input
							type='text'
							className='form-control Fielder-cards-font-inputs'
							aria-label='Small'
							id='name-red-input'
							value={name}
							aria-describedby='inputGroup-sizing-sm'
							onChange={(e) => setFieldValue(e.target.value, 'name')}
						/>
					</div>
					<img
						src={redAlertIcon}
						alt=''
						id='name-alert-icon'
						className='CreateTask-nameContainer-icon'
					/>
				</div>

				{showErrorDuplicateBranch && (
					<div className='alert alert-warning' role='alert' id='showAlert'>
						{lan.idCustomBranchDuplicated}
					</div>
				)}
			</div>

			<div className='mb-4'>
				<div className='col-sm-12 Fielder-cards-txt-label-input'>
					{lan.rangeOfAttentionTime}
				</div>
				<div className='col-sm-12'>
					{activateAlert && (
						<div className='alert alert-warning' role='alert' id='showAlert'>
							{lan.hourValidation}
						</div>
					)}

					<div className='row mx-auto justify-content-center'>
						<div className='col-6'>
							<Datetime
								id='id-date-from'
								inputProps={{ placeholder: lan.from, readOnly: true }}
								dateFormat={false}
								onChange={onChangeTimeOne}
							/>
						</div>

						<div className='col-6'>
							<Datetime
								id='id-date-to'
								inputProps={{ placeholder: lan.to, readOnly: true }}
								dateFormat={false}
								onChange={onChangeTimeTwo}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className='mb-4'>
				<div className='col-sm-12 Fielder-cards-txt-label-input'>
					{lan.companyBranch}
				</div>

				<div className='col-sm-12 font-inputs'>
					<AsyncSearch
						className='id-select-branch-company full-width'
						getOptions={(e) => getCompanyList(e)}
						onChange={(e) => handleOptionSelected(e)}
						label={lan.placeHolderCompany}
						id='id-select-branch-company'
						showInitialOptions
						getInitialOptions={getInitialOptionsCompany}
					/>

					<img
						src={redAlertIcon}
						alt=''
						id='company-alert-icon'
						className='CreateTask-nameContainer-icon'
					/>
				</div>
			</div>

			<div className='mt-3 mb-4'>
				<div className='col-sm-12 Fielder-cards-txt-label-input' />

				<Location
					edit
					includeCoordinates
					label={lan.branchLocation}
					locationUpdate={updateLocation}
					error={errorLocation}
					coordinates={{
						lat: latitude,
						lng: longitude,
					}}
				/>
			</div>

			<div className='mt-2 mb-4'>
				<div className='col-sm-12 Fielder-cards-txt-label-input'>
					{lan.legalAddress}
				</div>
				<div className='col-sm-12'>
					<input
						id='id-input-branch-legalAddress'
						placeholder={lan.legalAddress}
						type='text'
						className='form-control Fielder-cards-font-inputs'
						value={legalAddress}
						onChange={(e) => setLegalAddress(value => sanitizeInput(e.target.value)) }
					/>
				</div>
			</div>

			<Col sm={12} className='branch-label-input mt-2'>
					<span
						onClick={openMoreBranchInfo}
						style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
					>
						{lan.moreInformation}
					</span>
			</Col>
			<Col sm={12}>
				<MoreBranchInformation 
					showMoreBranchInfo={showMoreBranchInfo} 
					ref={moreBranchInformationRef}
					nameValue={null}
					lastNameValue={null}
					emailValue={null} 
					cellphoneValue={null}
					officePhoneValue={null} 
					legalAddressValue={null}
					editData={true}
				/>
			</Col>

			<div id='register-form-hint' className='Register-form-hint mt-3'>
				<p>*{lan.requiredFields}</p>
			</div>

			<div className='mb-3 mt-5'>
				<div className='row'>
					<div className='col-6 d-flex justify-content-center'>
						<button
							id='id-input-branch-add-button'
							type='submit'
							className='btn btn-outline-primary btn-success  Add-branch-office-btn-center Add-branch-office-btn-size'
							onClick={() => handleDoActionAddBranch(false)}
						>
							{lan.addBranch}
						</button>
					</div>
					{props.showCreateBranchWithTask && (
						<div className='col-6 d-flex justify-content-center'>
							<button
								id='id-input-branch-add-button'
								type='submit'
								className='btn btn-outline-primary btn-success  Add-branch-office-btn-center Add-branch-office-btn-size'
								onClick={() => handleDoActionAddBranch(true)}
							>
								{lan.addBranchAndCreateTask}
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	</>);
}

export default AddBranchOffice;
